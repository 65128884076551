import { AutoFormPropertyDefinition } from "../app/shared/form/auto-form/auto-form-types";
import { Field } from "../types/shared";

export const flattenFields = (fields?: Field[]) => {
  let flattened: Field[] = [];
  if (!fields) return flattened;

  fields.forEach((field) => {
    switch (field.type as any) {
      case 'set': {
        if (!field.subFields) break;
        let subFields = flattenFields(field.subFields!);
        if (subFields.length > 0) {
          flattened = flattened.concat(subFields);
        }
        break;
      }
      default:
        flattened.push(field);
        break;
    }
  });
  return flattened;
}

export const toAutoFormPropertyDefinition = (field: Field) => {
  const model: AutoFormPropertyDefinition = {
    name: field.name,
    display: field.label,
    type: field.type as any,
    options: {
      imageData: field.imageData,
      options: field.options,
      onChange: field.onChange,
      defaultValue: () => field.value
    }
  };
  return model;
}