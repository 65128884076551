
import { SonicPage } from '../../utils/SonicPage'
import { prefixes } from '../../utils/page-prefixes'
//keeping this separate for readability.
// this object is spread into the the pages object in ./index.ts

const locPages=
{
  rootLoc: new SonicPage({
    page: 'RootLoc',
    relativePath: prefixes.loc.replace('/', ''),
    display: 'Home',
    pageTitle: '',
  }),
  startLoc: new SonicPage({
    page: 'StartLoc',
    relativePath: '/start',
    prefix: 'line-of-credit',
    display: 'Start',
    pageTitle: 'Loan Application'
  }),
  loanDetailsLoc: new SonicPage({
    page: 'LoanDetailsLoc',
    relativePath: '/submit-loan-details',
    prefix: 'line-of-credit',
    display: 'Loan Details',
    pageTitle: 'Your Loan'
  }),
  loanDetailsLocIncrease: new SonicPage({
    page: 'LoanDetailsLocIncrease',
    relativePath: '/credit-limit-increase',
    prefix: 'line-of-credit',
    display: 'Loan Details',
    pageTitle: 'Line Of Credit Increase'
  }),
  personalDetailsLoc: new SonicPage({
    page: 'PersonalDetailsLoc',
    relativePath: '/submit-personal-details',
    prefix: 'line-of-credit',
    display: 'Personal Details',
    pageTitle: 'Your Details'
  }),
  personalDetailsConfirmLoc: new SonicPage({
    page: 'PersonalDetailsConfirmLoc',
    relativePath: '/submit-personal-details-confirm',
    prefix: 'line-of-credit',
    display: 'Personal Details Confirm',
    pageTitle: 'Your Details'
  }),
  mobileVerificationLoc: new SonicPage({
    page: 'MobileVerification',
    relativePath: '/submit-mobile-verification',
    prefix: 'line-of-credit',
    display: 'Mobile Verification',
    pageTitle: 'Security Check'
  }),
  resetMobileVerificationLoc: new SonicPage({
    page: 'ResetMobileVerification',
    relativePath: '/reset-mobile-verification',
    prefix: 'line-of-credit',
    display: 'Reset Mobile Verification',
    pageTitle: 'Security Check'
  }),

}

export default locPages