import { DeclaredIncome, IncomeType, FrequencyType, EmploymentType } from "../../../types/bank-statement";
import { UiState } from "../../../types/state";
import { removeStars } from "../../../utils/string-helper";
import { v4 } from 'uuid';

export interface IncomeFormDto {
  id: string
  isDetected?: boolean
  wasFoundFromTransactions?: boolean
  incomeType?: IncomeType | ''
  frequencyType: FrequencyType | ''
  amount?: number | string
  transactionDescription?: string
  employerName?: string
  employerPhone?: string
  employmentType?: EmploymentType | ''
}

export const emptyIncome = () => {
  const income: IncomeFormDto = {
    isDetected: false,
    wasFoundFromTransactions: false,
    id: v4(),
    incomeType: '',
    frequencyType: 'Weekly',
  };
  return income
};

export const toIncomeFormDto = (incomes: DeclaredIncome[]) => {
  if (!incomes || !incomes.length) return [] as IncomeFormDto[]
  return incomes.map<IncomeFormDto>(i => {

    const dto: IncomeFormDto = {
      id: v4(),
      frequencyType: i.frequencyType,
      amount: i.amount,
      employerName: safeGetEmployerName(i),
      transactionDescription: safeGetTransactionDescription(i),
      employerPhone: i.suburbOrPhone,
      employmentType: i.employmentType,
      incomeType: i.incomeType,
      isDetected: safeGetIsDetected(i),
      wasFoundFromTransactions: i.wasFoundFromTransactions
    };
    return dto;
  });
};


export const getInitiaIncomeFormDto = (state: UiState) => {
  const { detectedIncomes, declaredIncomes } = state;

  let initialIncome: IncomeFormDto[] = [];
  if (detectedIncomes && detectedIncomes.length > 0) {
    return detectedIncomes.map<IncomeFormDto>((i) => ({
      isDetected: true,
      incomeType: i.incomeType,
      frequencyType: i.frequencyType,
      amount: i.amount,
      transactionDescription: removeStars(i.description),
      employmentType: 'None',
      id: v4()
    }));
  } else {
    if (declaredIncomes && declaredIncomes.length > 0) {
      return toIncomeFormDto([...declaredIncomes]);
    }
  }

  return initialIncome;
}

//If Employer Type then we set the EmployerName as `${employerName} ** ${transactionDescription}`
export const toDeclaredIncome = (dto: IncomeFormDto) => {
  const income: DeclaredIncome = {
    amount: dto.amount,
    frequencyType: dto.frequencyType,
    employerName: '',
    incomeType: dto.incomeType,
    isDetected: dto.isDetected,
    wasFoundFromTransactions: dto.wasFoundFromTransactions
  };

  const employerName = removeStars(dto.employerName);
  const transactionDescription = removeStars(dto.transactionDescription);

  switch (income.incomeType) {
    case 'Employment':
      income.employerName = `${employerName} ** ${transactionDescription}`;
      income.employmentType = dto.employmentType;
      income.suburbOrPhone = dto.employerPhone;
      break;
    case 'Centrelink':
      income.employerName = transactionDescription || '';
      income.employmentType = 'None';
      break;
    case 'Other':
      income.employerName = transactionDescription || '';
      income.suburbOrPhone = '';
      income.employmentType = 'None';
      break;
  }

  return income;
}

//If Employer Type then the Transaction will be the first part split by **
export const safeGetEmployerName = (income: DeclaredIncome) => {
  if (income.incomeType !== 'Employment') return undefined;
  const split = income.employerName.split('**');
  if (split.length <= 1) return income.employerName;
  return split[0].trim();
}

//If Employer Type then the Transaction will be the second part split by **
//If Other Type then will be the employerName
export const safeGetTransactionDescription = (income: DeclaredIncome) => {
  const split = income.employerName.split('**');
  if (split.length <= 1) {
    return income.employerName;
  }
  return split[1].trim();
}

export const safeGetIsDetected = (income: DeclaredIncome) => {
  if (income.incomeType !== 'Employment') return income.isDetected;
  const split = income.employerName.split('**');
  return split.length > 1 && income.isDetected;
}