import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Page, Row, Button } from '../../shared';
import Form from './form';
import * as BankApi from '../../../api/bank-statement';
import { BankLoginRequest } from '../../../api/bank-statement/api models';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { pageHasError } from '../../../utils/error-helper';
import canBeOnPage, { BasePageProps } from '../../hoc/can-be-on-page';
import HelpModal from './help-modal';

interface BankLoginProps extends BasePageProps, RouteComponentProps { }

interface State {
  showModal: boolean
  credentials: string[]
}

const BankLogin: React.FC<BankLoginProps> = ({ appContext }) => {
  const { navigate, uiState, executeCommandAndUpdateState, loading } = appContext;

  useEffect(() => {
    if (!uiState.bankLoginForm) {
      navigate('BankSelect');
    }
  }, []);

  const [state, setState] = useState<State>({
    showModal: false,
    credentials: []
  });

  useEffect(() => {
    if (!appContext.uiState.bankLoginForm) {
      navigate('BankSelect', false);
    }
  }, []);

  const handleAddNewBankClick = () => navigate('BankSelect', false);

  const handleLoginDetailHelpClick = () => {
    setState((oldState) => {
      const newState = { ...oldState };
      newState.showModal = !oldState.showModal
      return newState;
    })
  }

  const handleSubmit = (values: any) => {
    const { analysisId, consumerId, bankLoginForm } = uiState;
    setState((ps) => ({ ...ps, submitting: true }));

    //Build credentials
    let credentials: any = {};
    for (const field of bankLoginForm.fields) {
      credentials[field.name] = values[field.name] ? values[field.name] : '';
    }

    const request: BankLoginRequest = {
      analysisId: analysisId!,
      consumerId: consumerId!,
      credentials: credentials
    };

    const command =
      bankLoginForm.provider === 'Yodlee' ? () => BankApi.yodleeLogin(request) :
        bankLoginForm.provider === 'Proviso' ? () => BankApi.provisoLogin(request) :
          bankLoginForm.provider === 'Fortuna' ? () => BankApi.fortunaLogin(request) :
            () => BankApi.testLogin(request);

    executeCommandAndUpdateState(command, true).then(newState => {
      if (!pageHasError(newState.bankLoginForm)) {
        if (newState.bankMfaForm) {
          navigate('BankMfa', true);
        }
        else {
          navigate('BankAnother', true);
        }
      }
    });
  }

  if (!uiState.bankLoginForm) {
    return null;
  }

  const { bankName } = uiState.bankLoginForm;
  return (
    <Page title='Your bank statements'>
      <Row size='x-large'>
        <div className='text__lead'>
          By entering your banking details below we can securely access your {bankName} transactions and continue with your application. <br />
          This is the fastest way to complete the process and your account details and password remain 100% secure and we are not given any access to your account or your password.
        </div>
        <div className='text__lead'>
          Enter your internet banking details below.
        </div>
        <Button
          id='about_this_process'
          variant='tertiary'
          size='small'
          text='Unsure about your login details?'
          faIcon={faInfoCircle}
          iconPlacement='before'
          onClick={handleLoginDetailHelpClick} />
        <Form
          submitting={loading}
          onSubmit={handleSubmit}
          onAddNewBank={handleAddNewBankClick}
          formDetails={uiState.bankLoginForm}
        />
        <div className='text__lead'>
          The above form uses 256-bit fully encrypted bank level secure connection between you and your bank to quickly and easily deliver us a read only copy of your
          bank statements. It is a legal requirement for all lenders to obtain a bank statement before paying out a loan. This is a one-time secure access and your details are never stored.
      </div>
      </Row>
      <HelpModal
        show={state.showModal}
        onClose={handleLoginDetailHelpClick}
        bankName={bankName}
      />
    </Page>
  );
}

export default canBeOnPage(BankLogin, 'Bank');