import React from 'react';
import { Formik } from 'formik';
import { FormikValidator, Submit, FormikSideEffects, FormikTextBox, Button } from '../../shared';
import { nameOf } from '../../../utils/object-helper';
import { requiredWithMessage } from '../../shared/form/formik/validators';
import { SubmitMobileVerificationRequest } from '../../../api/loan-application/api models';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface SubmitMobileVerificationFromProps {
  onSubmit: (values: SubmitMobileVerificationRequest) => void
  submitting?: boolean
  onSkip?: () => void
  initialValues?: SubmitMobileVerificationRequest
}

export interface SubmitMobileVerificationFormValues extends SubmitMobileVerificationRequest { }

const SubmitMobileVerificationForm: React.FC<SubmitMobileVerificationFromProps> = (props) => {

  const validator = new FormikValidator<SubmitMobileVerificationFormValues, SubmitMobileVerificationFromProps>({
    fields: [
      { name: 'verificationCode', validators: [requiredWithMessage("Please enter the verification code we sent to your phone.")] }
    ]
  });

  const formId = 'submit-mobile-verification-form';
  return (
    <Formik
      initialValues={validator.getInitial(props.initialValues)}
      onSubmit={props.onSubmit}
      validate={(v) => validator.validate(v, props)}
      render={(formikProps) => {
        return (
          <form id={formId} onSubmit={formikProps.handleSubmit}>
            <h4><strong>Verification Code</strong></h4>
            <FormikTextBox type='number' name={nameOf<SubmitMobileVerificationFormValues>("verificationCode")} formikProps={formikProps} variant='secure' />
            <Submit disabled={props.submitting} id='mobile-verification-submit' onSubmit={formikProps.handleSubmit} text='Continue' />
            <Button disabled={props.submitting} id='mobile-verification-reset' onClick={() => { if (props.onSkip) props.onSkip(); }} text='Code not received? Try again' variant='tertiary' faIcon={faArrowRight} iconPlacement='before' />
            <FormikSideEffects formId={formId} />
          </form>
        );
      }}
    />
  );
};

export default SubmitMobileVerificationForm;