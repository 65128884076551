import React from 'react';
import classNames from 'classnames';
import Downshift from 'downshift';
import { OptionModel } from '../../../types/shared';

interface ButtonDropdownProps {
  className?: string
  value: any
  label?: string
  options: OptionModel<any>[]
  onChange: (value: any) => void
  buttonLabel?: () => any
  equalityCompare?: (value1: any, value2: any) => boolean
}

const ButtonDropdown:React.FC<ButtonDropdownProps> = ({ className, equalityCompare, buttonLabel, value, options, label, onChange }) => {

  const isEqual = (value1: any, value2: any) => {
    return (equalityCompare && equalityCompare(value1, value2)) || value1 === value2;
  }

  const handleChange = (item: OptionModel<any>) => {
    if(onChange) onChange(item.value);
  }

  return (
    <Downshift
      onChange={handleChange}
      selectedItem={value}
      itemToString={item => item}
    >
      {({
        isOpen,
        getToggleButtonProps,
        getItemProps,
        highlightedIndex,
        selectedItem: dsSelectedItem,
        getLabelProps
      }) => {
        return (
          <div className='button-dropdown-container'>
            {label && <label {...getLabelProps()}>{label}</label>}
            <button className="button button-secondary button-dropdown" {...getToggleButtonProps()}>
              {!buttonLabel && value}
              {buttonLabel && buttonLabel()}
            </button>
            <div className='button-dropdown-menu-container'>
              {isOpen && (
                <div className="button-dropdown-menu">
                  {options.map((option, index) => {
                    const classes = classNames({
                      'button-dropdown-item': true,
                      'focused': highlightedIndex === index,
                      'selected': !dsSelectedItem ? false : isEqual(value, option.value)
                    });
                    return (
                    <div className={classes} {...getItemProps({ key: index, index, item: option })}>
                      {option.label}
                    </div>
                    ) 
                  })}
                </div>
              )}
            </div>
          </div>
        );
      }}
    </Downshift>
  );
};

export default ButtonDropdown;