import React from 'react';
import { TrackerLoanApplication, TrackerItemContentProgress } from '../../../../../types/tracker';
import * as Service from '../../../tracker-service';
import { TrackerProgressItem } from '../..';

interface MoneySentProps {
  application: TrackerLoanApplication
  status: string
  stepNumber: number
  progress?: TrackerItemContentProgress
}

const MoneySent: React.FC<MoneySentProps> = (props) => {
  const { application, status, stepNumber, progress } = props;

  let contentBlock: any = undefined;
  let compiledStatus = status;

  if (application.disbursedTime) {
    contentBlock = (
      <div>
        <span>Sent to account ending in <span className="fs-hide">{application.approvedBankAccountNumber}</span></span><br />
        <span className="label">Date:</span>{Service.formatDate(application.disbursedTime)}<br />
        <span>This can take up to 1 - 3 business days depending on your bank.</span>
      </div>
    );
  }

  // Special case
  if (application.currentState === 'MoneySent') {
    compiledStatus = 'complete';
  }

  const title = progress && progress.subStatus ? progress.subStatus : 'Money sent to your account';

  return (
    <TrackerProgressItem
      title={title}
      key={stepNumber}
      index={stepNumber}
      status={compiledStatus}
    >
      {contentBlock}
    </TrackerProgressItem>
  );
}

export default MoneySent;