import React, { useState } from "react";
import { AxiosResponse } from "axios";
import v4 from 'uuid';
import useReactRouter from 'use-react-router';
import { get, set, remove } from 'js-cookie';

import { UiOptions } from "../../api/options/api models";
import AppContext, { AppContextProps, AppStateProps, TrackerStateProps, UiStateProps } from './app-context';
import * as AppService from '../app-service';
import * as LoanApi from '../../api/loan-application';
import * as TrackerApi from '../../api/tracker';
import { getPageByType, pages } from '../routes';
import { UiState } from "../../types/state";
import ResumeModel from "../resume/resume-model";

export interface withAppStateManagerProps {
  appContext: AppContextProps
}

function withAppStateManager<P extends withAppStateManagerProps>(Component: React.ComponentType<P>) {

  return function withAppStateManagerComponent(props: Pick<P, Exclude<keyof P, keyof withAppStateManagerProps>>) {

    const { history } = useReactRouter();
    const [appState, setAppState] = useState<AppStateProps>({
      loading: true,
      errorMessage: undefined,
      sourceId: undefined,
      tempId: v4(),
      sessionToken: undefined
    });

    const [uiStateProps, setUiStateProps] = useState<UiStateProps>({ uiState: undefined as any });
    const [config, setConfig] = useState<UiOptions>({ reasons: [], banks: [] });

    const [trackerState, setTrackerState] = useState<TrackerStateProps>({ trackerApplication: {} as any });

    const setErrorMessage = (error: string, loading?: boolean) => {
      setAppState((ps) => ({
        ...ps,
        errorMessage: error,
        loading: loading !== undefined ? loading : ps.loading
      }));
    };

    const setLoading = (loading: boolean) => setAppState((prevState) => ({ ...prevState, loading: loading }));
    const setUiState = (state: UiState) => setUiStateProps((s) => ({ ...s, uiState: state }))

    function safeApiCall<T = any>(request: () => Promise<AxiosResponse<T>>) {
      return new Promise<AxiosResponse<T>>((resolve, reject) => {
        return request()
          .then(r => { resolve(r); })
          .catch((errorResponse) => {
            setErrorMessage('Sorry, we were not able to process your request.', false);
            return reject(errorResponse);
          });
      });
    };

    function executeCommandAndUpdateState<T = any>(request: () => Promise<AxiosResponse<T>>, shouldSetLoading?: boolean) {
      return new Promise<UiState>((resolve, reject) => {
        if (shouldSetLoading) setLoading(true);
        return safeApiCall(request).then(r => {
          return LoanApi.getState({ loanApplicationId: appState.sourceId! })
            .then(stateResponse => {
              setUiState(stateResponse.data);
              resolve(stateResponse.data);
              if (shouldSetLoading) setLoading(false);
            }).catch(r => {
              if (shouldSetLoading) setLoading(false);
              reject(r);
            });
        }).catch(r => {
          reject(r);
          if (shouldSetLoading) setLoading(false);
        })
      });
    }

    const restoreSourceId = () => {
      //Try get Source from Cookies:
      let sourceId = get(AppService.cookieNames.sourceId);

      //No Coookie but we have state so fallback to the state
      if (!sourceId && uiStateProps.uiState && uiStateProps.uiState.loanApplicationId) {
        sourceId = uiStateProps.uiState.loanApplicationId;
        set(AppService.cookieNames.sourceId, sourceId, { secure: true });
      }

      //We have SourceId and it differs to state, clear ui state
      if (sourceId && uiStateProps.uiState && uiStateProps.uiState.loanApplicationId !== sourceId) {
        setUiState({} as any);
      }

      setAppState((ps) => ({ ...ps, sourceId: sourceId }))
      return sourceId;
    }

    const resetSourceId = (sourceId: string) => {
      set(AppService.cookieNames.sourceId, sourceId, { expires: 1, secure: true });

      //Only update if the source ids are different
      if (appState.sourceId !== sourceId) {
        setAppState((ps) => ({ ...ps, sourceId: sourceId }));
      }
    }

    const resetSessionToken = (sessionToken: string) => {
      set(AppService.cookieNames.sessionToken, sessionToken, { expires: 1, secure: true });

      if (appState.sessionToken !== sessionToken) {
        setAppState((ps) => ({ ...ps, sessionToken: sessionToken}));
      }
    }

    const restoreSessionToken = () => {
      //Try get Source from Cookies:
      let sessionToken = get(AppService.cookieNames.sessionToken);

      //No Coookie but we have state so fallback to the state
      if (!sessionToken && appState.sessionToken) {
        sessionToken = appState.sessionToken;
        set(AppService.cookieNames.sessionToken, sessionToken, { secure: true });
      }

      setAppState((ps) => ({ ...ps, sessionToken: sessionToken }))
      return sessionToken;
    }

    const resume = (resumeModel: ResumeModel) => {

      //Clear cookies
      remove(AppService.cookieNames.sourceId);
      remove(AppService.cookieNames.sessionToken);
        
      if (!resumeModel.isComplete && (resumeModel.cannotResume || !resumeModel.page)) {
        history.replace('/');
        return;
      }

      //Set new cookies
      set(AppService.cookieNames.sourceId, resumeModel.loanId, { secure: true });

      setAppState((ps) => ({ ...ps, sourceId: resumeModel.loanId }));
      if (resumeModel.state) setUiState(resumeModel.state);

      // If the application is complete, prepare a request to tracker, otherwise resume it
      if (resumeModel.isComplete) {
        const sessionToken = get(AppService.cookieNames.sessionToken);
        if (sessionToken) {
          LoanApi.exchangeForTrackerSessionToken({ loanApplicationId: resumeModel.loanId })
          .then(r =>
          {
            resetSessionToken(JSON.stringify(r.data));
            const sessionTokenQueryString = `?expiry=${r.data.Expiry}&signature=${r.data.Signature}`;
            TrackerApi.getLoanApplication({ loanApplicationId: resumeModel.loanId })
              .then(r => history.replace(pages.tracker.fullPath(`${resumeModel.loanId}${sessionTokenQueryString}`)))
              .catch(r =>
                {
                  //Thank you page requires a resume token to be accessed. We need to reset it to said token prior to redirecting the user
                  resetSessionToken(sessionToken);
                  history.replace(pages.thankYou.fullPath());
                });
          });
        } else {
          TrackerApi.getLoanApplication({ loanApplicationId: resumeModel.loanId })
            .then(r => history.replace(pages.tracker.fullPath(`${resumeModel.loanId}`)))
            .catch(r => history.replace(pages.thankYou.fullPath()));
        }
      } else {
        const path = !resumeModel.page ? '/' : resumeModel.page.fullPath();
        history.replace(path);
      }
    }

    const appContextValue: AppContextProps = {
      //App
      loading: appState.loading,
      errorMessage: appState.errorMessage,
      sourceId: appState.sourceId,
      tempId: appState.tempId,
      sessionToken: appState.sessionToken,

      executeCommandAndUpdateState: executeCommandAndUpdateState,
      getAndUpdateUiState: (overriddenId?: string) => LoanApi.getState({ loanApplicationId: overriddenId ? overriddenId : appState.sourceId! }).then(r => {
        setUiState(r.data);
        return r.data
      }),
      safeApiCall: safeApiCall,
      navigate: (type, keepHistory = true, id) => keepHistory ? history.push(getPageByType(type)!.fullPath(id)) : history.replace(getPageByType(type)!.fullPath(id)),
      navigateUnsafe: (route, keepHistory = true) => keepHistory ? history.push(route) : history.replace(route),
      setLoading: setLoading,
      setErrorMessage: setErrorMessage,
      resetSourceId: resetSourceId,
      restoreSourceId: restoreSourceId,
      resetSessionToken: resetSessionToken,
      restoreSessionToken: restoreSessionToken,
      resume: resume,

      //Loan
      uiState: uiStateProps.uiState,
      setUiState: (state) => setUiStateProps((s) => ({ ...s, uiState: state })),

      //Config
      reasons: config.reasons,
      banks: config.banks,
      setConfig: (config) => setConfig(ps => ({ ...ps, ...config })),

      //Tracker
      trackerApplication: trackerState.trackerApplication,
      trackerError: trackerState.trackerError,
      setTrackerApplication: (application) => setTrackerState((s) => ({ ...s, trackerApplication: application })),
      setTrackerError: (error) => setTrackerState((s) => ({ ...s, trackerError: error }))
    };

    return (
      <AppContext.Provider value={appContextValue}>
        <Component {...props as P} appContext={appContextValue} />
      </AppContext.Provider>
    );
  }
}

export default withAppStateManager;