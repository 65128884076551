import React, { useState } from 'react';
import { SliderWithAmount } from '../../shared';
import { getMaxAmount, getRepayment, getIncrement, getTerm, isValidAmount, getValidAmounts } from "../data";
import AmountTotals from './amount-totals';

interface ChooseAmountFormProps {
  amount: number;
  minAmount: number;
  originalCreditLimit: number;
  onChange: (amount: number, term: number) => void
  disabled?: boolean
}

interface State {
  amount: number;
  term: number;
  repayment: number;
  submitting: boolean;
  incrementAmount: number;
}

const ChooseAmountForm: React.FC<ChooseAmountFormProps> = ({
  amount,
  minAmount,
  originalCreditLimit,
  onChange,
  disabled
}) => {

  const maxAmount = getMaxAmount();
  const validAmounts = getValidAmounts().filter(x => x >= minAmount);
  const term = getTerm(amount);

  const [state, setState] = useState<State>({
    amount,
    term,
    repayment: getRepayment(amount, term),
    submitting: false,
    incrementAmount: getIncrement(amount),
  });

  const handleAmountChange = (newAmount: number) => {
    if (isValidAmount(newAmount) && newAmount >= minAmount) {
      const newTerm = getTerm(newAmount);
      update({
        ...state,
        amount: newAmount,
        term: newTerm,
        repayment: getRepayment(newAmount, newTerm),
        incrementAmount: getIncrement(newAmount)
      });
    }
  };

  const update = (newState: State) => {
    setState(newState);
    onChange(newState.amount, newState.term);
  };

  return (
    <React.Fragment>
      <h2 className='mb-0'>Your current credit is ${originalCreditLimit}, what would you like to change it to?</h2>

      <div className='choose-amount-container'>
        <SliderWithAmount
          disabled={disabled}
          steps={validAmounts}
          value={amount}
          min={minAmount}
          max={maxAmount}
          inputIncrement={state.incrementAmount}
          onChange={handleAmountChange}
          idPrefix="choose-amount"
          label=""
          labelPrefix="$"
        />
        <p className="text-center">
          For Lines of Credit up to $2,000, you'll have 18 months to repay. For amounts greater than $2,000, you'll have 36 months.
        </p>

      </div>
      <AmountTotals
        className="mt-30"
        amount={state.amount}
        term={state.term}
        repayment={state.repayment}
        calculating={false}
      />
    </React.Fragment>
  )
}

export default ChooseAmountForm;