import { validLocConfigs } from './loc-config-data';

const keysAsNumber = (dict: { [key: number]: any }) => {
  return Object.keys(dict).map(x => parseInt(x));
}

const figuresLookup = (() => {
  var result: { [amount: number]: { [term: number]: number } } = {};
  for (const { amount, term, repayment } of validLocConfigs) {
    if (!(amount in result)) {
      result[amount] = {};
    }
    result[amount][term] = repayment;
  }
  return result;
})();

const orderedAmounts = Array.from(keysAsNumber(figuresLookup)).sort((a, b) => (a - b));

const maxAmount = orderedAmounts.slice(-1)[0];

const incrementsLookup = (() => {
  var result: { [amount: number]: number } = {};
  for (var i = 0; i < orderedAmounts.length - 1; ++i) {
    const amount = orderedAmounts[i];
    const step = orderedAmounts[i + 1] - amount;
    result[amount] = step;
    result[maxAmount] = step;
  }
  return result;
})();

export const getMinAmount = (exclusiveLowerBound: number) => {
  for (var x of orderedAmounts) {
    if (x > exclusiveLowerBound) {
      return x
    }
  }
  throw new Error(`invalid argument ${exclusiveLowerBound}`);
};

export const getMaxAmount = () => {
  return maxAmount;
};

export const getDefaultAmount = () => {
  return 2500;
}

export const isValidAmount = (amount: number) => {
  return amount in figuresLookup;
}

export const getValidAmounts = () => {
  return orderedAmounts;
}

export const getRepayment = (amount: number, term: number) => {
  const termsLookup = figuresLookup[amount];
  if (termsLookup !== undefined) {
    const repayment = termsLookup[term]
    if (repayment !== undefined) {
      return repayment;
    }
  }
  throw new Error(`invalid amount ${amount} or term ${term}`);
};

export const getTerm = (amount: number) => {
  const terms = figuresLookup[amount];
  if (terms !== undefined) {
    return Math.min(...keysAsNumber(terms));
  }
  throw new Error(`invalid amount ${amount}`);
};

export const getIncrement = (amount: number) => {
  var step = incrementsLookup[amount];
  if (step !== undefined) {
    return step;
  }
  throw new Error(`invalid amount ${amount}`);
};
