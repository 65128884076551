import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { ChangeEvent } from 'cleave.js/react/props';
import { DateTextBoxProps } from './date-text-box';
import * as DateFormHelpers from './date-form-helpers';
import { DateTextBox } from '../..';

export interface DateValueTextBoxProps extends DateTextBoxProps {
  onValuesChange?: (values: DateValueInputValues) => void
}

export interface DateValueInputValues {
  textValue: string
  momentValue?: Moment
  dateValue?: Date
}

interface DateValueTextBoxState extends DateValueInputValues {
}

const getInputValues = (textValue?: string, datePattern?: DateFormHelpers.DatePattern, delimiter?: DateFormHelpers.Delimeter) => {
  const values: DateValueInputValues = {
    textValue: textValue || ''
  };

  if (!values.textValue) return values;
  const momentValue = moment(values.textValue, DateFormHelpers.resolveMomentFormatForCleave(datePattern, delimiter), true);
  values.momentValue = momentValue;
  values.dateValue = momentValue.toDate();
  return values;
}


const DateValueTextBox: React.FC<DateValueTextBoxProps> = (props) => {

  const { onChange, onValuesChange, datePattern, delimiter, value, ...rest } = props;

  const [state, setState] = useState<DateValueTextBoxState>({
    ...getInputValues(value, datePattern, delimiter)
  });


  const handleChange = (event: ChangeEvent<HTMLInputElement>, momentValue?: Moment) => {
    const values = getInputValues(event.currentTarget.value, datePattern, delimiter);
    setState((ps) => ({ ...ps, textValue: values.textValue, momentValue: values.momentValue, dateValue: values.dateValue }));
    if (onChange) onChange(event, momentValue);
    if (onValuesChange) onValuesChange(values);
  }

  return (
    <DateTextBox
      onChange={handleChange}
      datePattern={datePattern}
      delimiter={delimiter}
      value={state.textValue}
      {...rest}
    />
  );
}

export default DateValueTextBox;
