import axios from 'axios';
import appSettings from '../../settings';
import * as Models from './api models';

export const relativeUrls = {
  newApplication: '/car-loan',
}

export const newApplication = (request: Models.CarLoanApplicationApiRequest) => {
  return axios.post(`${appSettings.apiUrl}${relativeUrls.newApplication}`, request);
};