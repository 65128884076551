import React from 'react';
import { Page, Row } from '../shared';
interface OfflineProps {
}

const Offline: React.FC<OfflineProps> = () => {

  return (
    <Page title="We'll be back shortly">
      <Row size='medium'>
        <p className="text__lead">We're offline for maintenance but will be back online in {(window as any).offlineDuration}. Feel free to leave this page open and refresh it later to resume your application.</p>
      </Row>
    </Page>
  );
};

export default Offline;