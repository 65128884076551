import React from 'react';
import Button from './buttons/button';
import Card from './card';

interface ProgressBoxProps {
  logoSrc?: string
  heading: string
  steps: Array<{ incompleteText: string, completeText: string, isComplete?: boolean }>
  skipped?: boolean
  reattempted?: boolean
  failed?: boolean
  disabled?: boolean
  errorMessage?: string
  onReattempt?: () => void
  retryText?: string
}

const ProgressBox: React.FC<ProgressBoxProps> = ({ logoSrc, heading, steps, skipped, reattempted, failed, errorMessage, onReattempt, disabled, retryText }) => {
  if (skipped || reattempted) {
    return (
      <Card variant='information'>
        <div className='login login-small'>
          {logoSrc && <img className='login__heading-logo' src={logoSrc} alt={heading} />}
          <div className='login__heading-text'>
            <h2>{heading}</h2>
            <p>{skipped ? 'Skipped' : 'Reattempted'}</p>
          </div>
        </div>
      </Card>
    );
  }

  if (failed) {
    return (
      <Card variant='fail'>
        <div className='login login-small'>
          {logoSrc && <img className='login__heading-logo' src={logoSrc} alt={heading} />}
          <div className='login__heading-text'>
            <h2>{heading}</h2>
            <p>{errorMessage}</p>
          </div>
        </div>
        {onReattempt && (
          <Button
            id='reattempt'
            text={retryText || 'Try again'}
            variant='primary'
            size='small'
            onClick={onReattempt}
            disabled={disabled}
          />
        )}
      </Card>
    );
  }

  const isComplete = steps.every(step => { return step.isComplete === true; });
  if (isComplete) {
    return (
      <Card variant='confirm'>
        <div className='login login-small'>
          {logoSrc && <img className='login__heading-logo' src={logoSrc} alt={heading} />}
          <div className='login__heading-text'>
            <h2>{heading}</h2>
            <p>{steps[steps.length - 1].completeText}</p>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div className='login'>
        {logoSrc && <img className='login__heading-logo' src={logoSrc} alt='myGov' />}
        <div className='login__heading-text'>
          <h2>{heading}</h2>
        </div>
      </div>
      <div className='login__progress'>
        <ul>
          {steps &&
            steps.map((step, index) => {
              if (step.isComplete) {
                return (
                  <li key={index}>
                    <img alt='done' className='login__progress-icon' src='/images/done-circle-fill.svg' />
                    {step.completeText}
                  </li>
                );
              }
              return (
                <li key={index}>
                  <img alt='loader' className='login__progress-icon loading' src='/images/loader.svg' />
                  {step.incompleteText}
                </li>
              );
            })
          }
        </ul>
      </div>
    </Card>
  );
}

export default ProgressBox;