import React, { useEffect, useState } from 'react';
import { Page, Row, Button, Alert, SaveProgressButton } from '../../shared';
import { RouteComponentProps } from 'react-router';
import * as BankApi from '../../../api/bank-statement';
import { StatementProgress } from '../../../types/bank-statement';
import { PreloadRequest, checkOpenBankingAccountsRequest } from '../../../api/bank-statement/api models';
import canBeOnPage, { BasePageProps } from '../../hoc/can-be-on-page';
import { UiState } from '../../../types/state';
import BankProgressItem from './bank-progress-item';

export interface AnotherBankProps extends BasePageProps, RouteComponentProps { }

const AnotherBank: React.FC<AnotherBankProps> = ({ appContext: { navigate, executeCommandAndUpdateState, getAndUpdateUiState, uiState, banks } }) => {

  let refreshInterval: any = null;

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [triggered, setTriggered] = useState<number>(0);

  const updateProgress = async (state: UiState) => {
    if (state.bankProgress.every((item) => item.isAnalysed || item.isFailed)) {
      window.clearInterval(refreshInterval);
      return;
    } else {
     state.bankProgress.some((bank) => bank.provider === 'OpenBanking' && !bank.hasAccounts && !bank.hasTransactions) ? getAndUpdateUiStateWithOpenBanking() : getAndUpdateUiState();
    }
  }
  const getAndUpdateUiStateWithOpenBanking = () => {
    const request: checkOpenBankingAccountsRequest = {analysisId: uiState.analysisId!, consumerId: uiState.consumerId!}
      if (triggered === 0) {
        BankApi.triggerOpenBankingDownload({ ...request })
        setTriggered(triggered + 1)
      }
      else {
        setTriggered(triggered + 1)
        if (triggered >= 6) {
            setTriggered(0);
        }
      }
      getAndUpdateUiState();
  }

  useEffect(() => {
    const jobId = new URLSearchParams(window.location.search).get('jobId');
    if (!uiState.bankProgress?.length || jobId==='null') {
      navigate('BankSelect');
      return;
    }
  }, [uiState]);

  useEffect(() => {
    refreshInterval = window.setInterval(() => updateProgress(uiState), 5000);
    return () => window.clearInterval(refreshInterval);
  }, [triggered]);

  



  const resolveTopAlert = () => {
    let message = 'We\'re connecting to your account. This should only take a minute. Navigating away from this page may require a new login.';
    if (uiState.bankProgress.some(item => { return item.isFailed === true; })) {
      message = uiState.bankProgress.length > 1
        ? 'We were unable to connect to one or more of your accounts.'
        : 'We were unable to connect to your account.';
    }
    if (uiState.bankProgress.every(item => { return item.isAnalysed === true; })) {
      message = 'We\'ve completed connecting to your account.'
    }

    return <Alert message={message} center variant='info' />;
  }

  const handleReattempt = (statement: StatementProgress) => {

    const erroredBank = banks.find((bank) => statement.bankName === bank.name);

    if(erroredBank?.supportsOpenBanking && !erroredBank.supportsScreenScraping)
    {
      navigate('BankSelect');
      return;
    }

    const request: PreloadRequest = {
      analysisId: uiState.analysisId!,
      bankId: statement.bankId as any,
      consumerId: statement.consumerId!
    };

    setSubmitting(true);
    executeCommandAndUpdateState(() => BankApi.preload(request), true)
      .then((response) => {
        setSubmitting(false);
        navigate('BankLogin', false);
      })
      .catch(() => setSubmitting(false));
  }

  if (!uiState.bankProgress) {
    return null;
  }

  const isAnalysing = uiState.bankProgress.some(item => !item.isAnalysed && !item.errorMessage);
  const canContinue = uiState.bankProgress.some(item => item.isAnalysed);

  return (
    <Page title='Your bank statements'>
      <Row size='small'>
        {resolveTopAlert()}
      </Row>
      <Row size='small'>
        {uiState.bankProgress.map((item, index) => (
          <BankProgressItem
            key={index}
            item={item}
            disabled={submitting || isAnalysing}
            onReattempt={handleReattempt}
          />
        ))
        }
      </Row>
      {!isAnalysing && (
        <React.Fragment>
          <Row
            title='Do you have another bank?'
            lead={'Please let us know if you have other bank accounts we should be aware of.'}
            size='large' />
          <Row size='x-small'>
            {canContinue && !isAnalysing && (
              <Button
                id='continue_button'
                text='No, continue'
                variant='primary'
                onClick={() => navigate('IncomeDeclaration')}
              />
            )}
            <Button
              id='another_bank_button'
              text='Yes, add another bank'
              variant='secondary'
              onClick={() => navigate('BankSelect')}
            />
            <SaveProgressButton />
          </Row>
        </React.Fragment>
      )}
    </Page>
  );
}

export default canBeOnPage(AnotherBank, 'BankStatements');
