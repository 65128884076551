import React, { useContext } from 'react';
import { UiState } from '../../types/state';
import { TrackerLoanApplication } from '../../types/tracker';
import ResumeModel from '../resume/resume-model';
import { UiOptions } from '../../api/options/api models';
import { SonicPageType } from '../../types/route-types';
import { AxiosResponse } from 'axios';

//Global
export interface AppStateProps {
  loading: boolean
  errorMessage?: string
  sourceId?: string
  tempId: string,
  sessionToken?: string
}

interface AppFuncProps {
  safeApiCall: <T = any>(request: () => Promise<AxiosResponse<T>>) => Promise<AxiosResponse<T>>
  executeCommandAndUpdateState: <T = any>(request: () => Promise<AxiosResponse<T>>, setLoading: boolean) => Promise<UiState>
  getAndUpdateUiState: (overriddenId?: string) => Promise<UiState>,
  setLoading: (loading: boolean) => void
  setErrorMessage: (errorMessage: string, loading?: boolean) => void
  resetSourceId: (sourceId: string) => void
  restoreSourceId: () => string | undefined
  resetSessionToken: (sessionToken: string) => void
  restoreSessionToken: () => string | undefined
  navigate: (pageType: SonicPageType, keepHistory?: boolean, id?: string | number) => void
  navigateUnsafe: (route: string, keepHistory?: boolean) => void
  resume: (state: ResumeModel) => void
}

//State
export interface UiStateProps {
  uiState: UiState
}

interface UiStateFuncProps {
  setUiState: (loanState: UiState) => void
}

export interface ConfigFuncProps {
  setConfig: (config: Partial<UiOptions>) => void
}

//Tracker
export interface TrackerStateProps {
  trackerApplication: TrackerLoanApplication
  trackerError?: string
}

interface TrackerFuncProps {
  setTrackerApplication: (application: TrackerLoanApplication) => void
  setTrackerError: (error: string) => void
}

//All together
export interface AppContextProps extends AppStateProps, AppFuncProps,
  UiStateProps, UiStateFuncProps, TrackerStateProps, TrackerFuncProps,
  UiOptions, ConfigFuncProps {

}

export interface GlobalStateProps extends AppStateProps, UiStateProps { }

export const defaultAppContextValues = () => {
  const values: AppContextProps = {
    //Global
    safeApiCall: (r) => ({}) as any,
    executeCommandAndUpdateState: (r, sl) => ({}) as any,
    getAndUpdateUiState: (overriddenId?: string) => ({}) as any,
    navigate: (v, kh = true) => { },
    navigateUnsafe: (v, kh = true) => { },
    loading: false,
    errorMessage: undefined,
    sourceId: undefined,
    tempId: '',
    sessionToken: undefined,
    setLoading: (l) => { },

    setErrorMessage: (e, l) => { },
    resetSourceId: (s) => { },
    restoreSourceId: () => undefined,
    resetSessionToken: (s) => { },
    restoreSessionToken: () => undefined,
    resume: (s) => { },

    //State
    uiState: undefined as any,
    setUiState: (ls) => { },

    //Config
    banks: [],
    reasons: [],
    setConfig: (c) => { },

    //Tracker
    trackerApplication: {} as any,
    trackerError: undefined,
    setTrackerApplication: (ta) => { },
    setTrackerError: (e) => { }
  };

  return values;
}

const AppContext = React.createContext<AppContextProps>(defaultAppContextValues());

export const useAppContext = () => useContext(AppContext);

export default AppContext;