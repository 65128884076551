import React, { useState } from 'react';
import { Slider, SliderWithAmount } from '../../shared';
import { getMinAmount, getMaxAmount, getConfiguration, getIncrement, getTermRange, isValidAmount, getValidAmounts, isValidTerm } from "../data";
import { generateNumberArray } from '../../../utils/number-helper';
import AmountTotals from './amount-totals';

interface ChooseAmountFormProps {
  amount: number;
  term: number;
  onChange: (amount: number, term: number) => void;
  showAmountTotals?: boolean;
  disabled?: boolean;
  overridedMinimumLoanAmount?: number;
}

interface State {
  amount: number;
  term: number;
  fees: any;
  repayment: any;
  borrowed: any;
  total: any;
  submitting: boolean;
  incrementAmount: number;
  minTerm: number;
  maxTerm: number;
}

const ChooseAmountForm: React.FC<ChooseAmountFormProps> = ({
  amount,
  term,
  onChange,
  showAmountTotals,
  disabled,
  overridedMinimumLoanAmount,
}) => {
  const validAmounts = getValidAmounts();
  const minAmount = overridedMinimumLoanAmount
    && isValidAmount(overridedMinimumLoanAmount)
    ? overridedMinimumLoanAmount : getMinAmount(0);

  const maxAmount = getMaxAmount();

  const [state, setState] = useState<State>({
    ...getConfiguration(amount, term),
    submitting: false,
    incrementAmount: getIncrement(amount),
    ...getTermRange(amount)
  });

  const handleAmountChange = (newAmount: number) => {
    if (isValidAmount(newAmount)) {
      const { minTerm: newMinTerm, maxTerm: newMaxTerm } = getTermRange(newAmount);
      const newTerm = Math.min(newMaxTerm, Math.max(state.term, newMinTerm))
      update({
        ...state,
        ...getConfiguration(newAmount, newTerm),
        incrementAmount: getIncrement(newAmount),
        minTerm: newMinTerm,
        maxTerm: newMaxTerm
      })
    }
  };

  const handleTermChange = (newTerm: number) => {
    if (isValidTerm(state.amount, newTerm)) {
      update({
        ...state,
        ...getConfiguration(state.amount, newTerm),
      });
    }
  }

  const update = (newState: State) => {
    setState(newState);
    onChange(newState.amount, newState.term);
  };

  return (
    <React.Fragment>
      <h2 className='mb-0'>I would like to borrow:</h2>
      <div className='choose-amount-container'>
        <SliderWithAmount
          disabled={disabled}
          steps={validAmounts}
          value={amount}
          min={minAmount}
          max={maxAmount}
          inputIncrement={state.incrementAmount}
          onChange={handleAmountChange}
          idPrefix='choose-amount'
          label='Cash in your account'
          labelPrefix='$'
        />
      </div>
      <div className='loan-term mt-50'>
        <h2>I need the loan for {state.term} months</h2>
        <Slider
          disabled={disabled}
          id='choose-term-slider'
          labelSuffix='months'
          steps={generateNumberArray(state.minTerm, state.maxTerm, 1)}
          value={state.term}
          onValuesUpdated={({ values }) => handleTermChange(values[0])}
          onChange={({ values }) => handleTermChange(values[0])}
        />
      </div>
      {showAmountTotals ?
        <AmountTotals
          className='mt-30'
          amount={state.amount}
          term={state.term}
          repayment={state.repayment}
          fees={state.fees}
          borrowed={state.borrowed}
          total={state.total}
          calculating={false}
        /> : null
      }
    </React.Fragment>
  )
}

export default ChooseAmountForm;