import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps } from '../../../types/tracker';

interface FundsDisbursedProps extends BaseTrackerContentProps {
}

const FundsDisbursed: React.FC<FundsDisbursedProps> = (props) => {

  return (
    <Page
      title="Your money is coming!"
      lead="Your money has been sent and will be in your account soon! This can take up to 1 - 3 business days depending on your bank.">
      <Row>
        <TrackerPageMainContent
          {...props}
          optional={{
            descriptor: "If you haven't received your money in 3 business days, you can contact us on 13 22 74",
          }}
        />
      </Row>
      <Row>
        <TrackerPageSteps {...props} />
      </Row>
    </Page>
  );
}

export default FundsDisbursed;
