import React from 'react';
import classNames from 'classnames';

interface LoadingSpinnerProps {
  className?: string
  text?: string
  height?: number
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ className, height, text, children }) => {

  const classes = classNames({
    'loading-spinner': true,
    [className!]: className !== undefined
  });

  const imageClasses = classNames({
    [`h-${height}`]: height !== undefined
  });

  return (
    <div className={classes}>
      <div className='loading-spinnner-image-container'>
        <img alt='loader' className={imageClasses} src='/images/loader.svg' />
      </div>
      {text && <div className='text-center'>{text}</div>}
      {children}
    </div>
  );
}

export default LoadingSpinner;
