import { FormikProps } from "formik";
import { AutoFormInputType } from "../app/shared/form/auto-form/auto-form-types";

export type SonicFeatures = 'Kyc' | 'MultipleReasons'

export type DocumentType = 'BankStatement' | 'CentrelinkStatement' | 'NameChange' | 'AccountOwnership' | 'Identification';
export const DocumentTypes: DocumentType[] = ['BankStatement', 'CentrelinkStatement', 'NameChange', 'AccountOwnership', 'Identification'];

export interface PageInfo {
  info?: string
  error?: string
}

export interface Field {
  type?: AutoFormInputType
  name: string
  label?: string
  description?: string
  placeholder?: string
  value?: string
  imageData?: string
  width?: number
  height?: number
  disabled?: string
  options?: OptionModel<string>[]
  subFields?: Field[]
  validations?: any
  onChange?: (value: any, formikProps: FormikProps<any>) => void
}

export interface ErrorWithType {
  message: string
  type: string
}

export interface Dictionary<T> {
  [key: string]: T;
}

export interface OptionModel<T> {
  value: T
  label: string
  description?: string
  readOnly?: boolean
}

export type AusState = 'ACT' | 'NT' | 'NSW' | 'SA' | 'TAS' | 'QLD' | 'VIC' | 'WA';

export class Range {
  min: number
  max: number
  constructor(minimum: number, maximum: number) {
    this.min = minimum;
    this.max = maximum;
  }
}

export interface StateWithValidRanges {
  state: AusState
  ranges: Array<Range>
}

export const stateRanges: StateWithValidRanges[] = [
  { state: 'ACT', ranges: [new Range(200, 299), new Range(2600, 2619), new Range(2900, 2920)] },
  { state: 'NSW', ranges: [new Range(1000, 2599), new Range(2620, 2899), new Range(2921, 2999)] },
  { state: 'NT', ranges: [new Range(800, 999)] },
  { state: 'QLD', ranges: [new Range(4000, 4999), new Range(9000, 9999)] },
  { state: 'SA', ranges: [new Range(5000, 5999)] },
  { state: 'TAS', ranges: [new Range(7000, 7999)] },
  { state: 'VIC', ranges: [new Range(3000, 3999), new Range(8000, 8999)] },
  { state: 'WA', ranges: [new Range(6000, 6999)] },
]