import { TrackerLoanApplication, WithdrawnTrackerStates, DefaultTrackerStates, TrackerState } from '../../../../types/tracker';
import { toCapitalCase } from '../../../../utils/string-helper';

export const sanitiseStateKeys = (application: TrackerLoanApplication) => {
  const keys: string[] = [];

  Object.keys(application.states).forEach(key => {
    // Show path that got application to withdrawn/declined
    const captializedKey = toCapitalCase(key);

    if (WithdrawnTrackerStates.includes(application.subState as any)) {
      if (application.states[key].entered) {
        keys.push(captializedKey);
      }
    }
    // Show deafult path
    else if (DefaultTrackerStates.includes(captializedKey as any)) {
      keys.push(captializedKey);
    }
  });

  return keys.reverse() as TrackerState[];
}

export type TrackerStepStatus = 'current' | 'complete' | 'todo';
export const resolveStatus = (application: TrackerLoanApplication, state: TrackerState, pastStepWasComplete: boolean) => {
  const status: TrackerStepStatus =
    state === application.currentState ? 'current' :
      pastStepWasComplete ? 'complete' :
        'todo';
  return status;
}