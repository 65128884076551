import React, { useState } from 'react';
import Button, { ButtonProps, ButtonVariant } from './button';
import ConfirmModal from '../modal/confirm-modal';

interface ButtonWithConfirmProps extends ButtonProps  {
  modalTitle?: string
  modalConfirmText?: string
  modalCancelText?: string
  modalBody?: any
  onConfirm: () => void
  buttonVariant?: ButtonVariant
  isExternal?: boolean
}

const ButtonWithConfirm: React.FC<ButtonWithConfirmProps> = (props) => {

  const [dialogOpen, setDialogOpen] = useState(false);

  const { children, onClick, modalTitle, modalConfirmText, modalCancelText, modalBody, onConfirm, buttonVariant, isExternal,  ...rest } = props;

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    setDialogOpen(false);
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) onClick(e);
    setDialogOpen(true);
  }

  return (
    <React.Fragment>
      <Button onClick={handleClick} {...rest} variant={buttonVariant || 'secondary'}> {children}</Button>
      <ConfirmModal
        title={modalTitle}
        cancelText={modalCancelText}
        confirmText={modalConfirmText}
        onConfirm={handleConfirm}
        show={dialogOpen}
        onClose={() => setDialogOpen(false)}
        isExternal={isExternal}
      >
        {modalBody}
      </ConfirmModal>
    </React.Fragment>
  );
}

export default ButtonWithConfirm;