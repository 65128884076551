import React from 'react';
import TextBox, { TextBoxProps } from '../text-box';
import { safeGetValue, safeGetError, shouldShowError } from './formik-helper';
import { FormikProps } from 'formik';

interface FormikRadioButtonGroupProps<T> extends TextBoxProps {
  name: string
  formikProps: FormikProps<T>
}

const FormikTextBox: React.FC<FormikRadioButtonGroupProps<any>> = ({ formikProps, name, onChange, onBlur, ref, ...rest }) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (formikProps && formikProps.handleChange) {
      formikProps.handleChange(event);
    }

    if (onChange) onChange(event);
  };

  const handleBlur = (event: any) => {
    if (formikProps && formikProps.handleBlur) {
      formikProps.handleBlur(event);
    }

    if (onBlur) onBlur(event);
  };

  return (
    <TextBox
      {...rest}
      ref={ref as any}
      name={name}
      value={safeGetValue(formikProps, name, '')}
      onChange={handleChange}
      onBlur={handleBlur}
      isValid={!shouldShowError(formikProps, name)}
      errorMessage={safeGetError(formikProps, name)}
    />
  );
};

export default FormikTextBox;