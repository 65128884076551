import React from 'react';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import { Row, FormikValidator, FormikTextBox, FormikSelectBox, FormikMobilePhone, Submit, FormikSideEffects, FormikSliderWithAmount,
  FormikDateTextBox, FormikTextArea} from '../../shared';
import { SliderWithAmountProps } from '../../shared/form/slider-with-amount';

import { nameOf, toOptionModels } from '../../../utils/object-helper';
import { required, maxCharLength, email, reggex, noFullStops, noMultiSpecialChars, number, integer, numberGreaterThan, numberLessThan, numberRange, dollarRange, mustBeTrueRequired, minAge, dateNotFuture, date } from '../../shared/form/formik/validators';
import { stateRanges } from '../../../types/shared';

import DeclarationFields from './declaration-fields';

interface CarLoanFormProps {
  referralCode?: string
  onSubmit: (values: CarLoanFormValues) => void
  submitting: boolean
}

export interface CarLoanFormValues {
  referralCode?: string

  cashiesFee: boolean
  glaAdmission: boolean
  termsAndConditions: boolean
  softEnquiryConsent: boolean
  marketingConsent: boolean

  title: string
  firstName: string
  middleName: string
  surname: string
  dateOfBirth: string
  driversLicenceNumber: string
  email: string
  mobile: string

  streetNumber: string
  streetName: string
  suburb: string
  postCode: string
  state: string

  maritalStatus: string
  numberOfDependants: string
  residentialStatus: string
  durationAtCurrentAddress: number
  durationAtCurrentEmployer: number

  loanPurpose: string
  employmentStatus: string
  netMonthlyIncome: number
  loanAmount: number
  desiredLoanTerm: number

  totalLivingExpenses: number
  totalHousingExpenses: number
  totalLoanExpenses: number
  totalCreditCardLimits: number
  totalOtherOngoingExpenses: number

  message?: string

  awareOfImpactToPay: string;
  awareOfImpactToPayExplanation?: string;
}



const CarLoanForm: React.FC<CarLoanFormProps> = (props) => {

  const { referralCode, onSubmit } = props;

  const jobSeekerOption = 'Centrelink Newstart/Jobseeker';
  const incomeSources = ['Full Time', 'Part Time', 'Casual', 'Self Employed', 'Centrelink', jobSeekerOption]

  const noJobSeeker = (value: string, values?: CarLoanFormValues) => {
    if (value === jobSeekerOption) {
      return 'Sorry, Newstart/Jobseeker income is an unacceptable source of income';
    }
    return null;
  };

  const validPostCodeState = (value: string, values?: CarLoanFormValues) => {
    if (!values || !values.state || !values.postCode) return null;
    const postCodeNumber = parseInt(values.postCode);
    const stateWithRange = stateRanges.find(e => e.state === values.state);
    if (!stateWithRange) return null;
    if (!stateWithRange.ranges.some(e => postCodeNumber >= e.min && postCodeNumber <= e.max)) {
      return 'Make sure your postcode is valid for your selected state' as string;
    }
    return null;
  }

  const validExplanation = (value: string, values?: CarLoanFormValues) => {
    if (!values) return null;
    if (values.awareOfImpactToPay === 'Yes' && (!value || value.toString().length < 1)) 
      return 'Please provide explanation';
    return null;
  }

  const validator = new FormikValidator<CarLoanFormValues, CarLoanFormProps>({
    fields: [
      { name: 'cashiesFee', validators: [mustBeTrueRequired()] },
      { name: 'glaAdmission', validators: [mustBeTrueRequired()] },
      { name: 'termsAndConditions', validators: [mustBeTrueRequired()] },
      { name: 'softEnquiryConsent', validators: [mustBeTrueRequired()] },
      { name: 'marketingConsent', validators: [] },

      { name: 'loanAmount', validators: [] },
      { name: 'loanPurpose', validators: [required] },
      { name: 'desiredLoanTerm', validators: [required, number, numberGreaterThan(2), numberLessThan(6)] },
      { name: 'employmentStatus', validators: [required, noJobSeeker] },
      { name: 'netMonthlyIncome', validators: [required, number, numberGreaterThan(0), numberLessThan(100000)] },

      { name: 'totalLivingExpenses', validators: [required, number, dollarRange(0, 100000)] },
      { name: 'totalHousingExpenses', validators: [number, dollarRange(0, 100000)] },
      { name: 'totalLoanExpenses', validators: [number, dollarRange(0, 100000)] },
      { name: 'totalCreditCardLimits', validators: [number, dollarRange(0, 100000)] },
      { name: 'totalOtherOngoingExpenses', validators: [number, dollarRange(0, 100000)] },

      { name: 'title', validators: [required] },
      { name: 'firstName', validators: [required, maxCharLength(100)] },
      { name: 'middleName', validators: [maxCharLength(100)] },
      { name: 'surname', validators: [required, maxCharLength(100)] },
      { name: 'dateOfBirth', validators: [required, date('DD/MM/YYYY'), maxCharLength(50), dateNotFuture('DD/MM/YYYY'), minAge(18)] },
      { name: 'driversLicenceNumber', validators: [required, maxCharLength(20)] },
      { name: 'email', validators: [required, email, maxCharLength(200)] },
      { name: 'mobile', validators: [required, maxCharLength(200)] },
      { name: 'maritalStatus', validators: [required] },
      { name: 'numberOfDependants', validators: [required] },
      { name: 'residentialStatus', validators: [required] },
      { name: 'durationAtCurrentAddress', validators: [required, number, integer, numberRange(0, 100000)] },
      { name: 'durationAtCurrentEmployer', validators: [number, integer, numberRange(0, 100000)] },

      { name: 'streetNumber', validators: [required, maxCharLength(20)] },
      { name: 'streetName', validators: [required, maxCharLength(200)] },
      { name: 'suburb', validators: [required, maxCharLength(50), noFullStops, noMultiSpecialChars] },
      { name: 'postCode', validators: [required, validPostCodeState, reggex(/^[0-9]{4}$/, 'Postcode should be a 4-digit number')] },
      { name: 'state', validators: [required] },

      { name: 'awareOfImpactToPay', validators: [required] },
      { name: 'awareOfImpactToPayExplanation', validators: [validExplanation, maxCharLength(1000)]},

      { name: 'referralCode', validators: [maxCharLength(200)] },
      { name: 'message', validators: [maxCharLength(1000)] },
    ]
  });

  const formId = 'car-loans-form';

  const getSliderSteps = () => {
    const sliderProps: Pick<SliderWithAmountProps, 'min' | 'max' | 'steps'> = {
      min: 6000,
      max: 45000,
      steps: [6000, 6050]
    };

    let i: number = 6000
    for (i = 6000; i < sliderProps.max + 1; i = i + 50) {
      sliderProps.steps!.push(i);
    }

    return sliderProps;
  }

  const [showExplanation, setShowExplanation] = React.useState(false)
  const onExplanatiChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const value: string | null = (event.currentTarget.value as any);
    if (!value) setShowExplanation(false);
    if (value === 'Yes') {
      setShowExplanation(true);
    } else {
      setShowExplanation(false);
    }
  }

  return (
    <Formik<CarLoanFormValues>
      initialValues={validator.getInitial({ referralCode: referralCode, loanAmount: 25000 })}
      validate={(values) => validator.validate(values, props)}
      onSubmit={onSubmit}
      render={(fp) => {
        return (
          <form id={formId}>
            <Row>
              <p className="text__lead__bold">Do you qualify to apply for a vehicle loan?</p>

              <ul>
                <li>All applicants are over the age of 18.</li>
                <li>Hold a valid and current Australian driver’s licence.</li>
                <li>All applicants are an Australian citizen, permanent resident or hold a valid Working Visa.</li>
                <li>Do not receive Centrelink Newstart/Jobseeker, Youth allowance or Austudy/Abstudy payments as my primary source of income.</li>
              </ul>
              <hr />

              <p className="text__lead__bold">Loan Details</p>
              <FormikSliderWithAmount
                formikProps={fp}
                label='Required Amount'
                labelPrefix='$'
                name={nameOf<CarLoanFormValues>('loanAmount')}
                {...getSliderSteps()}
              />
              <div className='car-loan-form-row'>
                <FormikSelectBox formikProps={fp} label='Loan Purpose' name={nameOf<CarLoanFormValues>('loanPurpose')} emptyFirstOption emptyOptionLabel='Please select' options={toOptionModels(['New Car', 'Upgrade', 'Refinance'])} />
                <FormikSelectBox formikProps={fp} label='Desired Loan Term (in Years)' name={nameOf<CarLoanFormValues>('desiredLoanTerm')} emptyFirstOption emptyOptionLabel='Please select' options={toOptionModels(['3', '4', '5'])} />
              </div>
              <div className='car-loan-form-row'>
                <FormikSelectBox formikProps={fp} name={nameOf<CarLoanFormValues>('employmentStatus')} label='Primary Source of Income' emptyFirstOption emptyOptionLabel='Please select' options={toOptionModels(incomeSources)} />
                <FormikTextBox faIconPrefix={faDollarSign} type='number' formikProps={fp} name={nameOf<CarLoanFormValues>('netMonthlyIncome')} label='Net Monthly Income' />
              </div>
              <FormikTextBox formikProps={fp} type="number" name={nameOf<CarLoanFormValues>('durationAtCurrentEmployer')} label='How long have you worked at your current employer (in Months)?' />
              <hr />
              <p className="text__lead__bold">Expenses</p>
              <div className='car-loan-form-row'>
                <FormikTextBox faIconPrefix={faDollarSign} type='number' formikProps={fp} name={nameOf<CarLoanFormValues>('totalLivingExpenses')} label='Total Living Expenses (Groceries/Utilities/Telephone/Transport) per month' />
                <FormikTextBox faIconPrefix={faDollarSign} type='number' formikProps={fp} name={nameOf<CarLoanFormValues>('totalHousingExpenses')} label='Housing Expenses (Mortgage/Rent/Board) per month' />
              </div>
              <div className='car-loan-form-row'>
                <FormikTextBox faIconPrefix={faDollarSign} type='number' formikProps={fp} name={nameOf<CarLoanFormValues>('totalLoanExpenses')} label='Total spent on all other loans per month' />
                <FormikTextBox faIconPrefix={faDollarSign} type='number' formikProps={fp} name={nameOf<CarLoanFormValues>('totalCreditCardLimits')} label='Total of all credit card limits' />
              </div>
              <FormikTextBox faIconPrefix={faDollarSign} type='number' formikProps={fp} name={nameOf<CarLoanFormValues>('totalOtherOngoingExpenses')} label='Total of all other ongoing expenses (child care/school fees etc) per month' />
              <hr />

              <p className="text__lead__bold">Personal Details</p>
              <div className='car-loan-form-row'>
                <FormikSelectBox formikProps={fp} name={nameOf<CarLoanFormValues>('title')} label='Title' emptyFirstOption emptyOptionLabel='Please select' options={toOptionModels(['Mr', 'Mrs', 'Miss', 'Ms', 'Other'])} />
                <FormikTextBox formikProps={fp} name={nameOf<CarLoanFormValues>('firstName')} label='First Name' />
              </div>
              <div className='car-loan-form-row'>
                <FormikTextBox formikProps={fp} name={nameOf<CarLoanFormValues>('middleName')} label='Middle Name' />
                <FormikTextBox formikProps={fp} name={nameOf<CarLoanFormValues>('surname')} label='Last Name' />
              </div>
              <div className='car-loan-form-row'>
                <FormikDateTextBox formikProps={fp} placeholder='DD/MM/YYYY' name={nameOf<CarLoanFormValues>('dateOfBirth')} label='Date of Birth' />
                <FormikTextBox formikProps={fp} name={nameOf<CarLoanFormValues>('driversLicenceNumber')} label='Drivers Licence Number' />
              </div>
              <div className='car-loan-form-row'>
                <FormikTextBox formikProps={fp} name={nameOf<CarLoanFormValues>('email')} label='Email' />
                <FormikMobilePhone formikProps={fp} name={nameOf<CarLoanFormValues>('mobile')} label='Mobile' />
              </div>
              <div className='car-loan-form-row'>
                <FormikSelectBox formikProps={fp} name={nameOf<CarLoanFormValues>('maritalStatus')} label='Marital Status' emptyFirstOption emptyOptionLabel='Please select' options={toOptionModels(['Single', 'Married', 'Defacto'])} />
                <FormikSelectBox formikProps={fp} name={nameOf<CarLoanFormValues>('numberOfDependants')} label='Number of Dependants' emptyFirstOption emptyOptionLabel='Please select' options={toOptionModels(['0', '1', '2', '3', '4', '5', 'More than 5'])} />
              </div>
              <div className='car-loan-form-row'>
                <FormikSelectBox formikProps={fp} name={nameOf<CarLoanFormValues>('residentialStatus')} label='Residential Status' emptyFirstOption emptyOptionLabel='Please select' options={toOptionModels(['Home Owner (no Mortgage)', 'Home Owner (With Mortgage)', 'Renting', 'Boarding', 'Living with Parents'])} />
                <FormikTextBox formikProps={fp} type="number" name={nameOf<CarLoanFormValues>('durationAtCurrentAddress')} label='How long have you been living at your current address (in Months)?' />
              </div>
              <hr />
              <p className="text__lead__bold">Address</p>
              <div className='car-loan-form-row'>
                <FormikTextBox formikProps={fp} name={nameOf<CarLoanFormValues>('streetNumber')} label='Street Number' />
                <FormikTextBox formikProps={fp} name={nameOf<CarLoanFormValues>('streetName')} label='Street Name' />
              </div>
              <div className='car-loan-form-row'>
                <FormikTextBox formikProps={fp} name={nameOf<CarLoanFormValues>('suburb')} label='Suburb' className={'multi-line-error'} />
                <FormikSelectBox formikProps={fp} label='State' name={nameOf<CarLoanFormValues>('state')} emptyFirstOption emptyOptionLabel='Please select' options={toOptionModels(stateRanges.map(e => e.state))} wrapperClasses={'multi-line-error'} />
                <FormikTextBox formikProps={fp} name={nameOf<CarLoanFormValues>('postCode')} label='Post Code' className={'multi-line-error'} />
              </div>

              <hr />
              <p className="text__lead__bold">Anything Else?</p>
              <div className='car-loan-form-row'>                
                <FormikSelectBox formikProps={fp} label='Are you aware of anything that could impact your ability to pay the loan in the future, such as a change in employment, financial position, a substantial expense, or similar?' name={nameOf<CarLoanFormValues>('awareOfImpactToPay')} emptyFirstOption emptyOptionLabel='Please select' options={toOptionModels(['No', 'Yes'])} wrapperClasses={'multi-line-error'} onChange={onExplanatiChange} />
              </div>
              { showExplanation ?
                <div>
                  <FormikTextArea formikProps={fp} label='Please explain details of impact' name={nameOf<CarLoanFormValues>('awareOfImpactToPayExplanation')} />
                </div> : null}
              <div className='car-loan-form-row'>
                <FormikTextBox formikProps={fp} name={nameOf<CarLoanFormValues>('referralCode')} label='Referral Code' />
                <FormikTextBox formikProps={fp} name={nameOf<CarLoanFormValues>('message')} label='Notes' />
              </div>

              <hr />
              <DeclarationFields formikProps={fp} />

              <Submit id={`${formId}-submit`} disabled={props.submitting} onSubmit={fp.handleSubmit} text='Submit' />
              <FormikSideEffects formId={formId} dontFocusFirstInputOnInit />
            </Row>
          </form>
        )
      }}
    >
    </Formik >
  );
}

export default CarLoanForm;