import React from 'react';
import classNames from 'classnames';

export const toPillItems = (items: string[]) => {
  return !items || !items.length ? [] as PillItem[] : items.map(i => {
    const pillItem: PillItem = { value: i, label: i };
    return pillItem;
  });
}

export interface PillItem { value: any, label: string };

export interface PillProps extends React.HTMLProps<HTMLInputElement> {
  errorMessage?: string
  hideLabel?: boolean
  isValid?: boolean
  variant?: string
  label?: string
  helpText?: string
  items?: PillItem[]
}

class Pill extends React.Component<PillProps> {

  focus() {
    const input = (this.refs.input as any);
    if (input && input.focus) input.focus();
  }

  render() {
    const { value, name, items, onChange, id, isValid, errorMessage, hideLabel, label, variant, helpText, ...rest } = this.props;

    const hasItems = items && items.length ? true : false;
    const ulClassName = classNames({
      'form__row-list': true,
      'form__group-pills': true,
      [`form__group-${(items || []).length}`]: hasItems
    });

    const compiledIsValid = isValid === undefined ? true : isValid;

    return (
      <div data-test={`form-control-${name}`} className='form__row'>
        <fieldset>
          <legend className='form__legend'>{label}</legend>
          <ul className={ulClassName}>
            {items && items.map((item, index) => {
              const id = `${name}_${index}`;
              return (
                <li key={`pill_item_${item.value}_${item.value}`}>
                  <input
                    name={name}
                    ref='input'
                    id={id}
                    value={item.value}
                    checked={value === item.value}
                    onChange={onChange}
                    type='radio'
                    className='form__input-radio'
                    tabIndex={0}
                    {...rest}
                  />
                  <label
                    id={`${id}_label`}
                    htmlFor={id}
                    className='form__label form__label-pill'
                    tabIndex={-1}
                  >
                    {item.label}
                  </label>
                </li>
              );
            })}
          </ul>
        </fieldset>
        {!compiledIsValid && <span className='form__error-message'>{errorMessage}</span>}
      </div>
    );
  }
};


export default Pill;
