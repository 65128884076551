import React from "react";
import { Formik } from "formik";
import { Button, FormikValidator, Submit, FormikSideEffects } from "../..";
import { AutoFormProps, AutoFormInputType } from "./auto-form-types";
import * as Helper from './auto-form-helper';

function AutoForm<TData = any, TSuccessResponse = any>(props: AutoFormProps<TData, TSuccessResponse>) {

  const { initialValues, onSubmit, fields,
    onBack, header, aboveButtonContent, belowButtonContent, headerContent, submitButtonText, hideBack, additionalButtonsFunc,
    debug, formId, className, submitting, submitButtonStyle, backButtonText } = props;

  let compiledInitialValues: any = initialValues || {};

  const validator = new FormikValidator<any, any>({
    fields: []
  });

  const fieldTypesToIgnoreInitialValues: AutoFormInputType[] = ['paragraph', 'header', 'script', 'instructions'];
  fields.filter(e => !fieldTypesToIgnoreInitialValues.some(a => a === e.type)).forEach(field => {
    if (compiledInitialValues[field.name] === undefined && field.options) {
      compiledInitialValues[field.name] = field.options.defaultValue ? field.options.defaultValue() : undefined;
    }
    const validators = !field.options ? undefined : field.options.validators;
    validator.fields.push({ name: field.name as any, validators: validators });
  });

  return (
    <Formik<TData>
      initialValues={validator.getInitial(compiledInitialValues)}
      validate={(values) => validator.validate(values, props)}
      onSubmit={onSubmit}
      render={(formikProps) => {
        return (
          <React.Fragment>
            <form id={formId} onSubmit={formikProps.handleSubmit} className={className}>
              {header && <p>{header}</p>}
              {headerContent}
              {fields.map((field, i) => Helper.renderInputComponent(field, formikProps, submitting, i))}
              <div>
                {aboveButtonContent}
                <Submit style={submitButtonStyle} data-test='auto-form-submit-button' id='auto-form-submit' text={submitButtonText || 'Save'} onSubmit={formikProps.handleSubmit} />
                {!hideBack && <Button className='w-100' variant='secondary' id='auto-form-back-button' text={backButtonText || 'Back'} onClick={onBack} />}
                {additionalButtonsFunc && additionalButtonsFunc(formikProps).map((p) => <Button {...p} />)}
                {belowButtonContent}
              </div>
            </form>
            <FormikSideEffects formId={formId} logOnChange={debug} />
          </React.Fragment>
        )
      }}
    >
    </Formik >
  )
}

export default AutoForm;