import React from 'react';
import classNames from 'classnames';
import Popover from 'react-tiny-popover'
import { ResumePage } from '../../resume/resume-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface ProgressOptionsProps {
  pages: ResumePage[]
  show: boolean
  onToggle: () => void
}

const ProgressOptions: React.FC<ProgressOptionsProps> = ({ pages, onToggle, show }) => {

  if (!pages || !pages.length) {
    return null;
  }

  const contentClasses = classNames({
    'progress-bar-options-content': true,
    'd-none': !show
  });
  return (
    <div className='progress-bar-options'>
      <Popover
        containerClassName='progress-bar-options-overlay-container'
        isOpen={show}
        position='bottom'
        onClickOutside={onToggle}
        content={(
          <ul className={contentClasses}>
            {pages.map((p, i) => (
              <li key={i} className='progress-bar-options-item'>
                <Link to={p.path} onClick={onToggle} className={p.disabled ? 'link-disabled' : undefined}>
                  <span>{p.display}</span>
                  {p.complete && <FontAwesomeIcon icon={faCheck} className='progress-complete' />}
                </Link>
              </li>
            ))}
          </ul>
        )}
      >
        <label className="progress-bar-options-button-container">
          <button onClick={onToggle}></button>
        </label>
      </Popover>
    </div>
  );
};

export default ProgressOptions;