import React from 'react';
import { SimpleModal } from '../../shared';

interface AcknowledgmentAndConsentModalProps {
  open: boolean
  onClose: () => void
}

const AcknowledgmentAndConsentModal: React.FC<AcknowledgmentAndConsentModalProps> = ({ open, onClose }) => {

  return (
    <SimpleModal className='terms-modal' show={open} onClose={onClose}>
      <h2>Green Light Auto Group Pty Ltd <span>Privacy Disclosure Acknowledgement and Consent<span></span></span></h2>
      <h3>General</h3>
      <p>References to "GLA", “Greenlight Auto” or Greenlight Auto Finance” in the following clauses, means Green Light Auto Group Pty Limited (ACN 131 507 474) trading as Green Light Auto Finance and all its subsidiaries, related entities, controlled entities, or any one of these depending on the context. Each GLA entity may perform a different role with respect to your loan. For example, one company may act as broker and one may act as lender and this consent is intended to cover the role of each company. References to the singular ("I" or "me" for example) include the plural ("We" or "us" for example).</p>
      <p>Information about you from which your identity is apparent or can reasonably be ascertained ("your personal information"") will be collected by GLA when you complete and submit this application in accordance with our <a href="https://greenlightauto.finance/legal/privacy-policy/" target='_blank' rel='noopener noreferrer'>Privacy Policy</a>. We encourage you to read this Privacy Policy.</p>
      <p>When you give us personal information about another person, you represent that you are authorised to do so and agree to inform that person of the contents of this Statement as it relates to them.</p>

      <h3>Privacy Consent for GLA to collect, use and disclose personal information</h3>
      <p>I authorise GLA to collect my personal information to enable it to assess my application and, if it is approved, to provide me with the product(s) or service(s) for which I am applying. I understand that without this information GLA may not be able to consider or approve my application(s).</p>

      <h3>Authority to disclose personal information</h3>
      <p>I acknowledge that subject to GLA general duty of confidentiality towards its customers, GLA may need to disclose my personal information.</p>
      <p>I authorise GLA to disclose my personal information on an ongoing basis (including permitted identity details and information about my credit worthiness, current loans, loan history, any potential serious credit infringements, standing or capacity) to:
					</p><ul>
        <li><span>affiliated product and service providers and external product and service providers for whom we act as agent (so that they may provide you with the product or service you seek or in which you have expressed an interest);</span></li>
        <li><span>auditors we appoint to ensure the integrity of our operations;</span></li>
        <li><span>any person acting on your behalf, including your financial adviser, solicitor, settlement agent, accountant, executor, administrator, trustee, guardian or attorney;</span></li>
        <li><span>your employer(s), referee(s), guarantor(s) or potential guarantor(s);</span></li>
        <li><span>if required or authorised to do so, regulatory bodies, government agencies or courts or anyone authorised by any one of them to receive such information;</span></li>
        <li><span>participants in the payments system (including payment organisations and merchants) and other financial
institutions (such as banks);</span></li>
        <li><span>insurers generally and trade insurers specifically;</span></li>
        <li><span>any joint borrower(s) or account holder(s);</span></li>
        <li><span>credit reporting bodies or debt collection agencies;</span></li>
        <li><span>other financial institutions and organisations at their request if you seek credit from them (so that they may assess whether to offer you credit);</span></li>
        <li><span>other parties where you have consented to the disclosure;</span></li>
        <li><span>any person considering acquiring an interest in our business or assets;</span></li>
        <li><span>suppliers from whom we order goods on your behalf (so that the goods may be provided to you);</span></li>
        <li><span>other organisations or individuals specified elsewhere in this policy; and</span></li>
        <li><span>other organisations or individuals to assist where it is reasonably necessary for the prevention or to help identify and/or investigate any actual or suspected fraud, unlawful activity, or misconduct.</span></li>
      </ul>
      <p></p>
      <p>I authorise the disclosure of my details to a credit reporting body, including my name, address and date of birth, for the purposes of GLA seeking and receiving an Electronic Identity Verification (“E-verification”) assessment as to whether that personal information matches (in whole or in part) personal information held by that credit reporting body (including on any database of Politically Exposed Persons), in accordance with:</p>
			<ul>
        <li><span>the Anti-Money Laundering and Counter-Terrorism Financing Act 2006 (Cth) (“AML/CTF Act”) and the AML/CTF Rules;</span></li>
        <li><span>the National Consumer Credit Protection Act 2009 (Cth) (“NCCPA”), including the National Credit Code (“NCC”) that comprises Schedule 1 to that Act; and</span></li>
        <li><span>any other relevant act which requires us to conduct verification of customer identification.</span></li>
      </ul>
      <p></p>
      <p>I understand that:</p>
			<ul>
        <li><span>if identity E-verification produces a partial match or no match I will have the opportunity to submit further documentation in support of my application to enable GLA to conduct further checks to satisfy their obligations to properly verify my identity.</span></li>
      </ul>
      <p></p>
      <p>I acknowledge that GLA may only give my personal information to another credit provider:</p>
      <ul>
				<li><span>To assess my credit application;</span></li>
        <li><span>To assess my credit worthiness;</span></li>
        <li><span>To help me avoid a default on my obligations; or</span></li>
        <li><span>To tell a credit provider of any default I have made.</span></li>
        <li><span>I authorise GLA to confirm the details contained in my Application with my employer. A copy of this consent may be given to my employer as evidence of that consent.</span></li>
      </ul>
      <p></p>
      <h3>Authority to obtain certain Personal and Commercial Credit Information</h3>
      <p>I authorise GLA:
					</p><ul>
        <li><span>to obtain and use a credit report(s) or Identity E-verification assessment result(s) containing my personal information in assessing my credit application;</span></li>
        <li><span>to obtain information about my commercial activities, Identity E-verification assessment results and commercial credit</span></li>
        <li><span>worthiness from any business which provides this type of information;</span></li>
        <li><span>to obtain information about whether I am a Politically Exposed Person under the AML/CTF Act;</span></li>
        <li><span>to obtain and use a banker's opinion about my credit worthiness;</span></li>
        <li><span>to use this information to assess my credit application; and</span></li>
        <li><span>to give my personal information (including permitted identity details and information about my credit worthiness, current loans, loan history, any potential serious credit infringements, Identity E-verification assessment results, standing or capacity) to all parties to this application.</span></li>
      </ul>
      <p></p>

      <h3>Online forms, storage and delivery of information</h3>
      <p>I understand that if I decide to complete an online application form, GLA will collect the information that I enter into the online form and the IP address of my computer. If I cancel my application prior to submission, the information I have entered up to that point will be deleted automatically.</p>
      <p>I understand that GLA may use third party platforms to deliver information. These are sites hosted and managed by organisations other than GLA. Before deciding if I want to contribute information to any third party site I should read their privacy policy.</p>
      <p>I understand that Green Light may store information collected from me at secure third party internet based hosting locations in Singapore, The United Kingdom, The United States of America, Ireland, Hong Kong and Australia. These third party providers store the data securely using systems and processes which are equivalent to those required under the Australian Privacy Principles, so that Green Light can update, use or disclose the information remotely.</p>
      <p>I understand that GLA websites may contain links to non-GLA websites. Whilst such links are provided for my convenience, I should be aware that the information handling practices of the linked websites might not be the same as GLA.</p>

      <h3>Electronic Communications Consent (ECC)</h3>
      <p>I understand and acknowledge that if I give this consent to electronic communications:
					</p><ul>
        <li><span>paper based documents may no longer be given;</span></li>
        <li><span>electronic communications must be regularly checked for documents; and</span></li>
        <li><span>consent to the giving of documents by electronic communication may be withdrawn at any time.</span></li>
      </ul>
      <p></p>
      <p>I authorise GLA to provide any or all information to me through electronic communication in accordance with the Electronic Transactions Act 1999 and the National Consumer Credit Protection Act 2009, including but not limited to:
					</p><ul>
        <li><span>notices and disclosure documents about my credit application such as a Credit Guide or a copy of my assessment;</span></li>
        <li><span>financial services disclosures such as a Financial Services Guide;</span></li>
        <li><span>credit contract documents or variations to a credit contract;</span></li>
        <li><span>any other notices or documents required by any law; and</span></li>
        <li><span>notices or documents from GLA.</span></li>
      </ul>
      <p></p>
      <p>I authorise GLA to act on instructions I send to them electronically, including but not limited to:
					</p><ul>
        <li><span>email to the email address provided by GLA on its websites; or</span></li>
        <li><span>any other method of electronic communication permitted by law.</span></li>
      </ul>
      <p></p>

      <h3>Accessing or correcting personal information</h3>
      <p>I understand that I may access my personal information at any time by contacting the Green Light Privacy Officer at: 1300 081 488 or <a href="mailto:privacy@greenlightauto.finance">privacy@greenlightauto.finance</a>. Access will be granted in accordance with the Privacy Act 1988 and a reasonable fee may be charged. If any of my information is inaccurate, I may request that it be corrected.</p>

      <h3>Providing personal information about other persons</h3>
      <p>I undertake that if I give GLA personal information about another person e.g. my referee, I will show them a copy of this document so they may understand the manner in which their personal information may be used or disclosed by GLA in connection with my dealings with GLA.</p>

      <h3>Advertising &amp; Performance Tracking</h3>
      <p>I authorise GLA to use “cookies” and other technology to collect anonymous information about me on how I use GLA websites and the GLA advertisements I have clicked on third party websites and to use this information to help deliver online advertising to me.</p>

      <h3>Declaration and consent</h3>
      <ul>
        <li><span>I declare that the information about me/us set out above is true and correct;<span></span></span></li>
        <li><span>I acknowledge that GLA will rely on this information in considering my application for credit; I declare that I have read <a href="https://greenlightauto.finance/legal/privacy-policy/" target='_blank' rel='noopener noreferrer'>GLA Privacy Policy</a> and <a href="https://greenlightauto.finance/legal/credit-reporting-policy/" target='_blank' rel='noopener noreferrer'>Credit Reporting Policy</a>; and<span></span></span></li>
        <li><span>I understand, authorise and consent to all the terms set out within this document.<span></span></span></li>
      </ul>
    </SimpleModal>
  );
}

export default AcknowledgmentAndConsentModal;