import React from 'react';
import { SimpleModal } from '../../shared';

interface LoanScenarioProcessModalProps {
  open: boolean
  onClose: () => void
}

const LoanScenarioProcessModal: React.FC<LoanScenarioProcessModalProps> = ({ open, onClose }) => {

  return (
    <SimpleModal className='terms-modal' show={open} onClose={onClose}>
      <h2>Green Light Auto Group Pty Ltd Loan Scenario Process</h2>
      <h3>Acknowledgment and Consent</h3>
      <p>You authorise Green Light Auto Group to use a tool provided by CC Acquisitions Pty Ltd and make a request to a credit reporting body to access your credit reporting information. In order to do this, CC Acquisitions Pty Ltd will first submit your personal information to the credit reporting body and will be acting as an "Access Seeker" to make the request. The Access Seeker will then disclose your credit reporting information and other personal information to Green Light Auto Group Pty Limited (the credit provider) to obtain a personalised loan scenario. This process will NOT impact your credit score.</p>

      <p>Please view the Access Seekers (CC Acquisitions Pty Ltd) privacy policy by visiting this URL: <a href="https://getmyquote.greenlightauto.finance/cca-privacy-policy" target='_blank' rel='noopener noreferrer'>https://getmyquote.greenlightauto.finance/cca-privacy-policy</a></p>
    </SimpleModal>
  );
}

export default LoanScenarioProcessModal;