import React from 'react';
import { FormikProps } from 'formik';
import SliderWithAmount, { SliderWithAmountProps } from '../slider-with-amount';
import { safeGetValue } from './formik-helper';

interface FormikSliderWithAmountProps<T> extends Omit<SliderWithAmountProps, 'value'> {
  name: string
  formikProps: FormikProps<T>
}

const FormikSliderWithAmount: React.FC<FormikSliderWithAmountProps<any>> = ({ formikProps, name, onChange, min, ...rest }) => {

  const initialValue = safeGetValue(formikProps, name, min);

  const handleChange = (value: number) => {
    formikProps.setFieldValue(name, value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <SliderWithAmount
      onChange={handleChange}
      value={initialValue}
      min={min}
      idPrefix={name}
      {...rest}
    />
  )
};

export default FormikSliderWithAmount;