import React, { useState } from 'react';
import { DocumentUploadForm } from '../../shared';
import { DocumentType } from '../../../types/shared';

export interface TrackerDocumentUploadProps {
  applicationId: string
  documentType: DocumentType
}

const TrackerDocumentUpload: React.FC<TrackerDocumentUploadProps> = ({ applicationId, documentType }) => {

  const [complete, setComplete] = useState<boolean>(false);

  return (
    <React.Fragment>
      {!complete && (
        <DocumentUploadForm
          applicationId={applicationId}
          documentType={documentType as any}
          onSuccess={() => setComplete(true)}
          isFollowUp
        />
      )}
      {complete && (
        <p>
          Thanks for your upload! It has been forwarded to our review team.
        </p>
      )}
    </React.Fragment>
  );
}

export default TrackerDocumentUpload;