import React from 'react';
import { Page, Row } from '../index';

interface ErrorPageProps {
  errorMessage: string
}

const ErrorPage: React.FC<ErrorPageProps> = ({ errorMessage }) => {
  return (
    <div id='error-page'>
      <Page
        title='An error has occurred'
        lead={errorMessage}>
        <Row
          size='x-small'>
          <div className="tracker__errors">
            <p><a onClick={window.location.reload}>Refresh this page</a> or check back later.</p>
            <p>Here are some links that you might find useful:</p>
            <ul>
              <li><a href="https://www.cashconverters.com.au/">Return to the home page</a></li>
              <li><a href="/">Start a new application</a></li>
              <li><a href="https://www.cashconverters.com.au/company/contact-us">Contact us</a></li>
            </ul>
          </div>
        </Row>
      </Page>
    </div>

  );
};

export default ErrorPage;