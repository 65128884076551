import React from 'react';
import { SimpleModal } from '../../shared';

interface PrivacyPolicyModalProps {
  open: boolean
  onClose: () => void
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ open, onClose }) => {

  return (
    <SimpleModal className='terms-modal' show={open} onClose={onClose}>
      <h2>Green Light Auto Group Pty Ltd <span>Privacy Policy<span></span></span></h2>
      <h3>General</h3>
      <p>References to “Green Light”, "GLA", “Greenlight Auto” or Greenlight Auto Finance” in the following clauses, means Green Light Auto Group Pty Limited (ACN 131 507 474) trading as Green Light Auto Finance and all its subsidiaries, related entities, controlled entities, or any one of these depending on the context. Each GLA entity may perform a different role with respect to your loan. For example, one company may act as broker and one may act as lender and this consent is intended to cover the role of each company. References to the singular ("I" or "me" for example) include the plural ("We" or "us" for example).</p>
      <p>This privacy policy sets out how we comply with our obligations under the Privacy Act 1988 (Privacy Act). In this privacy policy, 'personal information' and ‘information’ are used interchangeably and have the same meaning as defined by section 6 of the Privacy Act.</p>
      <p>Green Light collects personal information to provide you with the products and services you request as well as information on other products and services offered by or through us.</p>
      <p>Green Light will act to protect your personal information in accordance with the Australian Privacy Principles (APP) under the Privacy Act (which can be found at: http://www.oaic.gov.au/privacy/about-privacy) and any applicable industry privacy code.</p>

      <h3>Why we collect information</h3>
      <p>Any information you provide Green Light, either using the internet or by any other means, is only collected for purposes which are reasonably necessary for, or directly related to, one or more of Green Light business functions or activities. These purposes include:
					</p><ul>
        <li><span>to provide customers with the products and services they request and to monitor, evaluate and provide products and services;</span></li>
        <li><span>to assist customers with queries, resolve customer complaints, or requests for access to information, or to manage any legal action involving Green Light;</span></li>
        <li><span>to manage customer relationships or accounts and perform other administrative and operational tasks (including risk management, systems development and testing, credit scoring, staff training, collecting debts and market or customer satisfaction research);</span></li>
        <li><span>to provide information on products and services offered by Green Light, affiliated product and service providers, and external product and service providers for whom we act as agent (unless the customer has opted out);</span></li>
        <li><span>to comply with our legal obligations under acts like the National Consumer Credit Protection Act 2009, the Privacy Act, the Anti-Money Laundering and Counter-Terrorism Financing Act 2006 in your state or territory;</span></li>
        <li><span>to identify customers or to establish their tax status under any Australian or foreign legislation, regulation or treaty or pursuant to an agreement with any tax authority;</span></li>
        <li><span>to gather and aggregate information for statistical, prudential, actuarial and research purposes;</span></li>
        <li><span>to develop and implement measures to detect or prevent fraud and credit loss; and</span></li>
        <li><span>to assist us with marketing and promotional purposes including the disclosure of personal information to third-parties, contractors or organisations who assist us in marketing and promotion of our products and services.</span></li>
      </ul>
      <p>In addition, as required by Part IIIA of the Privacy Act, we may obtain your consent to collect, use and disclose information about you.</p>
      <p>If you choose not to provide information which we require for the above purposes, we may be unable to provide you with the goods and services you request from us.</p>
      <p></p>

      <h3>What we collect</h3>
      <p>Personal information collected by Green Light (or on our behalf) generally comprises name, address, date of birth, gender, marital status, occupation, account details, contact details (including telephone, facsimile and e- mail) and financial information. Sometimes we collect a few personal details unlikely to be known to other people to help us identify you over the telephone.</p>
      <p>If you are applying for credit we may also collect the number and ages of your dependants, the length of time at your current address, your employer’s name and contact details, the length of your employment, proof of earnings and, if you have changed employer in the last few years, details of your previous employment.</p>

      <h3>Disclosure</h3>
      <p>Green Light will not give your information to Government agencies, private sector organisations or anyone else unless one of the following applies:</p>
			<ul>
        <li><span>you have consented;</span></li>
        <li><span>you would reasonably expect, or have been told, that information of that kind is usually passed to those individuals, bodies or agencies;</span></li>
        <li><span>it is otherwise required or authorised by law;</span></li>
        <li><span>by your actions we consider you to have released us from our duty of confidentiality or to have consented to the disclosure of information about you without actually saying so (for example, if you discuss your financial position publicly with the media);</span></li>
        <li><span>it will prevent or lessen a serious and imminent threat to somebody's life or health;</span></li>
        <li><span>it is reasonably necessary for the enforcement of the criminal law or of a law imposing a pecuniary penalty, or for the protection of public revenue; or</span></li>
        <li><span>it is reasonably necessary for the prevention or to help identify and/or investigate any actual or suspected fraud, unlawful activity, or misconduct.</span></li>
      </ul>
      <p></p>
      <p>Depending on the product or service Green Light has provided you with, we may disclose information to:
					</p><ul>
        <li><span>affiliated product and service providers and external product and service providers for whom we act as agent (so that they may provide you with the product or service you seek or in which you have expressed an interest);</span></li>
        <li><span>auditors we appoint to ensure the integrity of our operations;</span></li>
        <li><span>any person acting on your behalf, including your financial adviser, solicitor, settlement agent, accountant, executor, administrator, trustee, guardian or attorney;</span></li>
        <li><span>your employer(s), referee(s), guarantor(s) or potential guarantor(s);</span></li>
        <li><span>if required or authorised to do so, regulatory bodies, government agencies or courts or anyone authorised by any one of them to receive such information;</span></li>
        <li><span>participants in the payments system (including payment organisations and merchants) and other financial institutions (such as banks);</span></li>
        <li><span>insurers generally and trade insurers specifically;</span></li>
        <li><span>any joint borrower(s) or account holder(s);</span></li>
        <li><span>credit reporting bodies or debt collection agencies;</span></li>
        <li><span>other financial institutions and organisations at their request if you seek credit from them (so that they may assess whether to offer you credit);</span></li>
        <li><span>other parties where you have consented to the disclosure;</span></li>
        <li><span>any person considering acquiring an interest in our business or assets;</span></li>
        <li><span>suppliers from whom we order goods or services on your behalf (so that the goods or services may be provided to you); and</span></li>
        <li><span>other organisations or individuals specified elsewhere in this policy.</span></li>
      </ul>

      <h3>Identity Verification and Credit checks</h3>
      <p>Green Light is required by law to conduct identity verification and credit checks under legislation such as the Anti-Money Laundering and Counter-Terrorism Financing Act 2006 (identity verification) and the National Consumer Credit Protection Act 2009 (identity verification and credit checks).</p>
      <p>Information you give us when you fill out an application online or in-store, including your name, date of birth, residential address and other personal or sensitive information may be used to verify your details and to:</p>
			<ul>
        <li><span>conduct a credit check with a credit reporting body or any other credit provider with whom you have had dealings, including via e-verification;</span></li>
        <li><span>conduct a Politically Exposed Person or identity verification check with a credit reporting body, including via e-verification; and</span></li>
        <li><span>for such a credit reporting body or other credit provider to prepare and provide any assessment, report or result from those checks to us.</span></li>        
      </ul>
      <p>At the time of your application, we will specifically seek your consent for these verifications and checks to be conducted. If there is a partial or no match, you will have an opportunity to submit further documentation in support of your application to enable us to conduct further checks and properly verify your identity.</p>
      <p>Where you have given us consent and provided your login and account information, we may access and collect your financial records from your financial institutions on your behalf via an online portal. This service is provided to remove the need for you to provide the physical financial statements we need to assess applications and comply with our obligations under the National Consumer Credit Protection Act 2009.</p>
      <p>Please note that if you fail to meet a payment obligation in relation to credit we have provided to you or commit a serious credit infringement, we may disclose this to a credit reporting body.</p>
      <p>You may request a credit reporting agency not to use your credit reporting body information for the purposes of pre-screening of direct marketing by a credit provider or where you believe on reasonable grounds that you have been, or are likely to be, a victim of fraud.</p>
      <p>You have a right to request a copy of your credit reporting information from credit reporting bodies: once a year; or if it relates to our refusal to provide you with credit; or you wish to correct information held on you.</p>
      <p></p>
      <p>We may disclose information to the following credit reporting bodies:</p>
      <p style={{ marginLeft: 15 }}>
        <strong>Equifax Pty Ltd.</strong>, a wholly owned subsidiary of Equifax Inc.<br />
          Equifax's Privacy Policy: <a href="http://www.equifax.com.au/privacy" target='_blank' rel='noopener noreferrer'>http://www.equifax.com.au/privacy</a><br />
          Address: Equifax - Public Access,<br />
          Equifax Australia Information Services and Solutions Pty Limited,<br/> 
          PO Box 964,<br />
          NORTH SYDNEY, NSW 2059<br />
      </p>
      <p style={{ marginLeft: 15 }}>
        <strong>illion Australia Pty Ltd</strong> (Formerly Dun &amp; Bradstreet (Australia) Pty Ltd)<br />
          illion’s Privacy Policy: <a href="https://www.illion.com.au/privacy-policy/" target='_blank' rel='noopener noreferrer'>https://www.illion.com.au/privacy-policy/</a><br />
          Postal Address: PO Box 7405<br />
          St Kilda Rd Melbourne VIC 3004<br />
          Phone: 1300 734 806<br />
          Email: <a href="mailto:chc-au@illion.com.au">chc-au@illion.com.au</a>
      </p>
      <p style={{ marginLeft: 15 }}>
        <strong>Experian Asia Pacific Pty Ltd</strong><br />
          Experian’s Privacy Policy: <a href="http://www.experian.com.au/privacy-policy/" target='_blank' rel='noopener noreferrer'>http://www.experian.com.au/privacy-policy/</a><br />
          Postal Address: Level 20, 101 Miller Street,<br />
          North Sydney, NSW 2060<br />
          Phone: 1300 783 684<br />
          Email: <a href="mailto:creditreport@au.experian.com">creditreport@au.experian.com</a>
      </p>

      <h3>Website data</h3>
      <p>If you visit the Green Light website to read, browse or download information, our system may record the following information which is provided by your browser:
					</p><ul>
        <li><span>the date and time of your visit;</span></li>
        <li><span>the type of browser and operating system you are using;</span></li>
        <li><span>your Internet Service Provider and top level domain name (for example - .com, .gov, .au, .uk etc);</span></li>
        <li><span>the address of any referring web site (for example - the previous web site you visited); and</span></li>
        <li><span>your computer's IP (Internet Protocol) address (a number which is unique to the machine through which you are connected to the internet).</span></li>
      </ul>
      <p></p>
      <p>Website data is kept strictly confidential. It is used for internal research purposes only. It helps us monitor which sections of the site are most popular to give us a clear idea of what type of information and services to include on our site. It also informs us which members and subscribers are using the site so that we can customise the website information and presentation.</p>
      <p>When you use one of our web sites, we may send you a “cookie” that gives you a unique identification number. A “cookie” is a packet of information that allows the server (the computer that houses the website) to identify and interact more effectively with your computer. A different identification number is sent each time you use one of our websites. These cookies may be Session cookies which only last until you close your browser, or they may be Persistent cookies which are stored longer term on your computer. Cookies do not identify individual users, although they may identify a user’s browser type and your Internet Service Provider (ISP).</p>
      <p>You can configure your browser to accept all cookies, reject all cookies, or notify you when a cookie is sent. Please refer to your browser instructions or help screens to learn more about these functions. If you reject all cookies, you may not be able to use our websites or certain website functions may not work properly or at all.</p>

      <h3>Online forms</h3>
      <p>If you decide to complete an online application form, we will collect the information that you enter into the online form and the IP address of your computer. If you cancel your application prior to submission, any information entered on the current screen, which has not yet been submitted, will be automatically deleted. Information you have already submitted to us prior to you cancelling your application may be used to contact you about your application, after which it will be automatically deleted. The deletion referred to above will happen within a reasonable period of time but in any event, no longer than 30 business days.</p>


      <h3>Links to other websites</h3>
      <p>Our websites may contain links to non-Green Light websites. While such links are provided for your convenience, you should be aware that the information handling practices of the linked websites might not be the same as ours and we encourage you to read and understand the privacy policies on these websites prior to providing any information to them.</p>

      <h3>Advertising &amp; Performance Tracking</h3>
      <p>We use several advertising companies to devise and deliver our online advertising. This often involves displaying banner advertisements on third party websites.</p>
      <p>When you see one of our advertisements on a third party website, cookies and, in some cases, Spotlight tags are sometimes used to collect information such as:
					</p><ul>
        <li><span>the server your computer is logged on to;</span></li>
        <li><span>your browser type;</span></li>
        <li><span>the date and time of your visit; and</span></li>
        <li><span>the performance of their marketing efforts.</span></li>
      </ul>
      <p></p>
      <p>When you visit our websites directly or after seeing one of our advertisements on a third party website, anonymous information is collected on how you use our website. This might include which pages you view, and whether you start or complete an online application. The tracking companies aggregate all the captured information and use it to study internet user behaviour patterns.</p>
      <p>They do not include personal information in their studies and use the data mainly to measure advertising effectiveness and relative consumer interest in the various areas of our websites. No personal information is collected during this process. If, however, any information is automatically collected, the tracking companies are required under their arrangements with us to maintain the privacy and confidentiality of that personal information.</p>
      <p>We may disclose the information collected, in an aggregated form only, to third parties including advertisers or potential advertisers.</p>

      <h3>Overseas storage and delivery of information</h3>
      <p>Green Light may use third party platforms to deliver information. These are sites hosted and managed by organisations other than Green Light. Before deciding if you want to contribute information to any third party site you should read their privacy policy.</p>
      <p>Green Light may store information collected from you at secure third party internet based hosting locations in Australia, Singapore, The United Kingdom, The United States of America, Ireland or Hong Kong. These third party providers store the data securely using systems and processes which are equivalent to those required under the Australian Privacy Principles, so that Green Light can update, use or disclose the collected information remotely.</p>

      <h3>Telephone</h3>
      <p>We may monitor and record telephone calls for training and security purposes.</p>

      <h3>Email</h3>
      <p>We will preserve the content of any email that you send us if we are legally required to do so. Where appropriate, we may record your email address against the relevant company name on our confidential customer database.</p>
      <p>Your email address is used only to send you information that you have requested and as necessary to complete a transaction or application or for marketing purposes. As part of your use of our email marketing software, Green Light allows you to elect to receive, or not to receive, certain information.</p>
      <p>Green Light will not send you unsolicited email information related to marketing and promotional purposes where you have opted out of receiving such communication, offers or advertisements. Green Light will not sell, rent or lend our contact lists or our customers’ contact lists (including customer data) to third parties.</p>
      <p>All emails that you have requested will have an option to unsubscribe. To unsubscribe from all future Green Light emails, please opt out here.</p>

      <h3>Security of Information</h3>
      <p>Green Light takes all reasonable care to preserve the security of personal information collected or submitted to us both physically and digitally in accordance with the <a href="http://greenlightauto.finance/legal/website-policy" target='_blank' rel='noopener noreferrer'>Green Light Website Terms and Conditions</a> and this Privacy Policy.</p>
      <p>Green Light regularly reviews developments in security and encryption technologies. As no data transmission over the Internet can be guaranteed as totally secure, Green Light cannot guarantee the security of any information you transmit to us or which is generated from our online products or services and you engage with us at your own risk.</p>
      <p>Upon receiving your transmission, we ensure that reasonable steps are taken to preserve the security of the information in our customer database.</p>

      <h3>Contact us</h3>
      <p>If you have any questions or would like further information about our privacy and credit information handling practices and policies, or a hard copy of this policy, or you wish to exercise your right to make a correction, complaint or request access to any information which we have collected about you, please contact our Privacy Officer at:</p>
      <p style={{ marginLeft: 15 }}>
        The Privacy Officer<br />
        Green Light Auto Group<br />
        Level 11, 141 St Georges Terrace<br />
        Perth WA 6000<br />
        Phone: 1300 081 488<br />
        Email: <a href="mailto:privacy@greenlightauto.finance">privacy@greenlightauto.finance</a>
      </p>
      <p>Our Privacy Officer will attend to your matter within a reasonable period after your communication is received and if access to information has been requested, will give access to the information in the manner requested by you, for a reasonable fee where it is reasonable and practical to do so.</p>

      <h3>Acceptance and Changes to Privacy Policy</h3>
      <p>You acknowledge and accept that your use of Green Light website or of products which direct you to this Privacy Policy indicates your acceptance of the Green Light Website Policy and of the use and disclosure of information provided for under this Privacy Policy.</p>
      <p>This is the current Privacy Policy. It replaces any other Privacy Policy for the Green Light website previously published on the Green Light website. Green Light may at any time vary the Privacy Policy by publishing the varied Privacy Policy on the Green Light website. You accept that by doing this, Green Light has provided you with sufficient notice of the variation.</p>
    </SimpleModal>
  );
}

export default PrivacyPolicyModal;