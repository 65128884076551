import { LoanStateOptions } from '../types/loan';
import { SonicFeatures } from '../types/shared';
import { UiState } from '../types/state';

export const overridedMinimumLoanAmount = 400;

export const cookieNames = {
  sourceId: 'SOURCE_ID',
  completedSourceIds: 'COMPLETED_SOURCE_IDS',
  googleClickId: '_gcl_aw',
  googleAnalytics: '_ga',
  facebookClickId: '_fbc',
  facebookBrowserId: '_fbp',
  sessionToken: 'SESSION_TOKEN',
};


export const loanStateHasFeature = (loanState: UiState, feature: SonicFeatures) => {
  if (!loanState) return false;
  return loanStateOptionsHasFeature(loanState.options, feature);
}

export const loanStateOptionsHasFeature = (options: LoanStateOptions, feature: SonicFeatures) => {
  return options && hasFeature(options.enabledFeatures, feature);
}

export const hasFeature = (features: SonicFeatures[], feature: SonicFeatures) => {
  return features && features.some(e => e.toLowerCase() === feature.toLowerCase());
}