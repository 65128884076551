import React from 'react';
import { SimpleModal } from '../../shared';

interface AboutThisProcessModalProps {
  show: boolean
  onClose: () => void
}

const AboutThisProcessModal: React.FC<AboutThisProcessModalProps> = ({ show, onClose }) => {
  return (
    <SimpleModal show={show} onClose={onClose}>
      <h2>More about this process</h2>
      <ul>
        <li>We only request the last 90 days worth of bank statements to ensure you can afford to pay back your loan.</li>
        <li>Your internet banking details are never stored.</li>
        <li>The connection is secured by bank level 256-bit encryption.</li>
        <li>Over 500,000 Cash Converters customers have used this to fast-track their applications.</li>
      </ul>
    </SimpleModal>
  )
};

export default AboutThisProcessModal; 
