import axios from 'axios';
import appSettings from '../../settings';
import { TrackerLoanApplication } from '../../types/tracker';
import { addSpacesOnCaps } from '../../utils/string-helper';
import { GetTrackerProjectionRequest, ExchangeSessionTokenRequest } from './api models';

export const getLoanApplication = (request: GetTrackerProjectionRequest) => {
  return axios.post<TrackerLoanApplication>(`${appSettings.trackerApiUrl}`, request)
    .then(r => {
      if (r.data && r.data.latestDocumentRequest && r.data.latestDocumentRequest.requestReason) {
        if (!r.data.latestDocumentRequest.requestReason.includes(' ')) {
          r.data.latestDocumentRequest.requestReason = addSpacesOnCaps(r.data.latestDocumentRequest.requestReason);
        }
      }
      return r;
    });
};

export const exchangeForResumeSessionToken = (request: ExchangeSessionTokenRequest) => {
  return axios.post<string>(`${appSettings.trackerApiUrl}/exchangeForResumeSessionToken`, request);
};