import React from 'react';
import CSS from 'csstype';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SubmitProps {
  id: string
  text?: string
  onSubmit: () => void
  disabled?: boolean
  variant?: 'primary' | 'secondary' | 'tertiary'
  style?: CSS.Properties
  faIcon?: IconDefinition
  className?: string
  iconPlacement?: 'before' | 'after'
}

const Submit: React.FC<SubmitProps> = ({ faIcon, text, iconPlacement, onSubmit, variant, className, style, ...rest }) => {

  const compiledVariant = !variant ? 'primary' : variant;
  const compiledClassName = classNames({
    [`button button--full button-${compiledVariant}`]: true,
    [className!]: className !== undefined
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (onSubmit) onSubmit();
  }

  return (
    <button
      className={compiledClassName}
      style={style}
      onClick={handleSubmit}
      type="submit"
      {...rest}>
      {faIcon && iconPlacement === 'before' && <FontAwesomeIcon className='mr-10' icon={faIcon} />}
      {text || 'Submit'}
      {faIcon && iconPlacement === 'after' && <FontAwesomeIcon className='ml-10' icon={faIcon} />}
    </button>
  );
}

export default Submit;
