import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import sanitizeHtml from "sanitize-html";

export interface AlertProps {
  className?: string
  message?: string
  variant: 'error' | 'info'
  id?: string
  errorIconExclamation?: boolean
  center?: boolean
}

const Alert: React.FC<AlertProps> = ({ id, className, message, variant, errorIconExclamation, children, center, ...rest }) => {

  const compiledVariant = !variant ? 'error' : variant;
  const compiledClass = classNames({
    'alert': true,
    'alert__fail': compiledVariant === 'error',
    'alert__information': compiledVariant === 'info',
    'text-center': center,
    [className!]: className !== undefined
  });

  return (
    <div className={compiledClass} id={id} {...rest}>
      {compiledVariant === 'error' && <FontAwesomeIcon className='alert-icon' icon={errorIconExclamation ? faExclamationTriangle : faTimesCircle} />}
      {compiledVariant === 'info' && <FontAwesomeIcon className='alert-icon' icon={faExclamationCircle} />}
      {message && <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }} />}
      {children}
    </div>
  );
}

export default Alert;