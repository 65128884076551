import React, { useState } from 'react';
import { notify } from 'react-notify-toast';

import { UiState } from '../../../types/state';
import Button from '../buttons/button';
import SimpleModal from '../modal/simple-modal';
import Pill, { toPillItems } from '../form/pill';
import TextBox from '../form/text-box';
import { SendResumneLinkChoice } from '../../../api/loan-application/api models';
import * as LoanApi from '../../../api/loan-application';
import useReactRouter from 'use-react-router';

interface SaveProgressModalProps {
  uiState: UiState
  disabled?: boolean
  show: boolean
  onClose: () => void
  setLoading: (loading: boolean) => void
}

interface State {
  sendChoices: SendResumneLinkChoice[]
  sendChoice?: SendResumneLinkChoice
  emailConfirm: string
  emailConfirmTouched: boolean
}

const SaveProgressModal: React.FC<SaveProgressModalProps> = ({ uiState, disabled, show, onClose, setLoading }) => {

  const { location: { pathname } } = useReactRouter();
  const resolveInitialState = () => {
    const state: State = { sendChoices: ['Email', 'Mobile'], emailConfirm: '', emailConfirmTouched: false };
    if (!uiState.completionStatus.mobileVerified) {
      state.sendChoices = state.sendChoices.filter(e => e !== 'Mobile');
    }

    if (state.sendChoices.length === 1) {
      state.sendChoice = state.sendChoices[0];
    }

    return state;
  }

  const [state, setState] = useState<State>(resolveInitialState());

  const isSendButtonDisabled = () => {
    return disabled || !state.sendChoice || (state.sendChoice === 'Email' && state.emailConfirm !== uiState.personalDetails.email);
  }

  const handleSendLink = () => {
    setLoading(true)
    LoanApi.sendResumeLink({ loanApplicationId: uiState.loanApplicationId, type: state.sendChoice!, pageLink: pathname })
      .then(r => {
        notify.show(state.sendChoice === 'Mobile' ? 'Sms Sent' : 'Email Sent', 'success');
        onClose();
        setState(resolveInitialState());
      })
      .catch(r => notify.show('An error occurred.  Please try again', 'error'))
      .finally(() => setLoading(false))

  }

  return (
    <SimpleModal title='Save Progress' show={show} onClose={onClose}>
      <p>Don't have time right now?  We can send you a link so you can finish this application later.</p>
      {state.sendChoices.length > 1 && (
        <React.Fragment>
          <p className='text-center mb-0'><strong>Let us know where to send the link:</strong></p>
          <Pill
            id='save-progress-choice'
            name='save-progress-choice'
            disabled={disabled}
            items={toPillItems(state.sendChoices)}
            value={state.sendChoice}
            onChange={(e) => {
              const value = e.currentTarget.value as SendResumneLinkChoice;
              if (value !== state.sendChoice) {
                setState(ps => ({ ...ps, sendChoice: value, emailConfirm: '', emailConfirmTouched: false }));
              }
            }}
          />
        </React.Fragment>
      )}
      {state.sendChoice === 'Mobile' && (
        <p className='text-center'>We will send a link for this application to <strong className='fs-exclude'>{uiState.personalDetails.mobile}</strong>.</p>
      )}
      {state.sendChoice === 'Email' && (
        <React.Fragment>
          <p className='text-center'>We will send a link for this application to <strong className='fs-exclude'>{uiState.personalDetails.email}</strong>.</p>
          <TextBox
            disabled={disabled}
            label='Please confirm your email address'
            value={state.emailConfirm}
            onBlur={() => setState(ps => ({ ...ps, emailConfirmTouched: true }))}
            onChange={e => {
              const value = e.currentTarget.value;
              setState(ps => ({ ...ps, emailConfirm: value }));
            }}
            errorMessage='Must match email above'
            isValid={!state.emailConfirmTouched || uiState.personalDetails.email.toLowerCase() === state.emailConfirm.toLowerCase()}
          />
        </React.Fragment>
      )}
      {state.sendChoice !== undefined && (
        <Button text='Send link' variant='primary' onClick={handleSendLink} disabled={isSendButtonDisabled()} />
      )}
    </SimpleModal>
  );
}

export default SaveProgressModal;