import React from 'react';
import TextBox, { TextBoxProps } from './text-box';

export interface CaptchaBoxProps extends TextBoxProps {
  imageData?: string,
  width?: number,
  height?: number,
}

class CaptchaBox extends React.Component<CaptchaBoxProps> {

  focus() {
    const input = (this.refs.input as any);
    if (input && input.focus) input.focus();
  }

  render() {
    const { imageData, width, height, ...rest } = this.props;
    return (
      <div className="form__row">
        <img
          src={imageData}
          width={width}
          height={height}
          alt='Captcha' />
        <TextBox ref={'input' as any} {...rest} />
      </div>
    );
  }
}

export default CaptchaBox;