import React from "react";


interface ConfirmProps extends React.InputHTMLAttributes<HTMLInputElement> {
  legend: string;
  description?: string;
  displayErrorAsAlert?: boolean | null;
  isValid?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  helpText?: string
  showTrue?: boolean | null
  reverse?: boolean
}

// this one does not need to be wrapped in formik so it can be reused in places that formik does not touch. 
// where we use this, it also has conflicting implementations of the validator which would cause a rather large amount 
// of overhead to implement. maybe a TODO for later but with a hard deadline on CALI-1 not for now. 

const ConfirmRadio = ({ legend, name, description, displayErrorAsAlert, showTrue, reverse, onChange }: ConfirmProps) => {
  const [formValue, setFormValue] = React.useState<boolean | null>(showTrue!);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue(event.target.value === "true");
    onChange && onChange(event);
  }

  return !reverse ?  (
    <fieldset className="radio-btns" tabIndex={0}>
      <legend>
        <h3>{legend}</h3>
        {description ? <p>{description}</p> : null}
      </legend>
      <div>
        <input
          className="radio-btn"
          type="radio"
          name={name}
          id={`${name}-yes`}
          value="true"
          onChange={handleChange}
          checked={formValue === true}
        />
        <label htmlFor={`${name}-yes`}>Yes</label>
      </div>
      <div>
        <input
          className="radio-btn"
          type="radio"
          name={name}
          id={`${name}-no`}
          value="false"
          onChange={handleChange}
          checked={formValue === false}
        />
        <label htmlFor={`${name}-no`}>No</label>
      </div>
      {displayErrorAsAlert && <span className='form__error-message'>{"Field is required"}</span>}
    </fieldset>
  ) : (
    <fieldset className="radio-btns" tabIndex={0}>
    <legend>
      <h3>{legend}</h3>
      {description ? <p>{description}</p> : null}
    </legend>
    <div>
      <input
        className="radio-btn"
        type="radio"
        name={name}
        id={`${name}-no`}
        value="false"
        onChange={handleChange}
        checked={formValue === false}
      />
      <label htmlFor={`${name}-no`}>No</label>
    </div>
    <div>
      <input
        className="radio-btn"
        type="radio"
        name={name}
        id={`${name}-yes`}
        value="true"
        onChange={handleChange}
        checked={formValue === true}
      />
      <label htmlFor={`${name}-yes`}>Yes</label>
    </div>
    
    {displayErrorAsAlert && <span className='form__error-message'>{"Field is required"}</span>}
  </fieldset>
  )
};

export default ConfirmRadio;
