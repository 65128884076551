import React, { useState, useEffect } from 'react';
import { UiState } from '../../../types/state';
import ResumeModel from '../../resume/resume-model';
import ProgressStep, { Step } from './progress-step';

interface ProgressBarProps {
  uiState: UiState
}

const steps: Step[] = [
  { number: 1, type: 'Start', name: 'Get Started' },
  { number: 2, type: 'Details', name: 'Details', image: '/images/icons/loans/your-details-black.svg', },
  { number: 3, type: 'Bank', name: 'Bank', image: '/images/icons/loans/your-bank-black.svg' },
  { number: 4, type: 'Income', name: 'Income', image: '/images/icons/loans/income-black.svg' },
  { number: 5, type: 'Expenses', name: 'Expenses', image: '/images/icons/loans/expenses-black.svg' },
  { number: 6, type: 'Complete', name: 'Complete' }
];

//TODO MOVE TO HEADER OR TOP LEVEL COMPONENT SHOULDNT BE IN SHARED??
const ProgressBar: React.FC<ProgressBarProps> = ({ uiState }) => {

  const [resumeModel, setResumeModel] = useState<ResumeModel>(new ResumeModel(uiState));

  useEffect(() => {
    setResumeModel(new ResumeModel(uiState))
  }, [uiState]);
  let current: Step = steps.find(e => e.type === 'Start') as Step;
  if (resumeModel.isComplete) current = steps.find(e => e.type === 'Complete') as Step;
  else if (resumeModel.canBeOnPage('ExpenseDeclaration') || resumeModel.canBeOnPage('Centrelink')) current = steps.find(e => e.type === 'Expenses') as Step;
  else if (resumeModel.canBeOnPage('IncomeDeclaration')) current = steps.find(e => e.type === 'Income') as Step;
  else if (resumeModel.canBeOnPage('Bank')) current = steps.find(e => e.type === 'Bank') as Step;
  else if (resumeModel.canBeOnPage('PersonalDetails') || resumeModel.canBeOnPage('PersonalDetailsLoc')) {current = steps.find(e => e.type === 'Details') as Step;}

  if (!current || current.type === 'Start') {
    return null;
  }

  const percent = (current.number - 1) / (steps.length - 1) * 100;

  return (
    <aside className="progress">
      <div className="progress__label">
        <span className="value">{`${percent}%`}</span><span className="label">complete</span>
      </div>
      <div className="progress__bar" aria-valuenow={percent} aria-valuemin={0} aria-valuemax={100} role="progressbar">
        <div className="progress__bar-meter">
          <div className="progress__bar-gutter">
            {steps && steps.map((step, index) => (
              <ProgressStep
                key={step.number}
                current={current}
                step={step}
                index={index}
                resumeModel={resumeModel}
              />
            ))}
          </div>
          <div className="progress__bar-progress" style={{ width: `${percent}%` }}></div>
        </div>
      </div>
    </aside>
  );
}

export default ProgressBar;
