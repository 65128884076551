import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps } from '../../../types/tracker';

interface LapsedAndDeclinedProps extends BaseTrackerContentProps {
}

const LapsedAndDeclined: React.FC<LapsedAndDeclinedProps> = (props) => {

  return (
    <Page
      title="Application lapsed"
      lead="It’s been a while since you visited this page and your application has lapsed. Unfortunately, on this occasion your application has also been declined.">
      <Row>
        <TrackerPageMainContent
          {...props}
          optional={{
            descriptor: "If you'd like to speak to someone, you can contact us on 13 22 74",
            callToAction: {
              type: "externalLink",
              label: "Answers to common questions",
              action: "https://www.cashconverters.com.au/faq"
            }
          }}
        />
      </Row>
      <Row>
        <TrackerPageSteps {...props} />
      </Row>
    </Page>
  );
}

export default LapsedAndDeclined;
