import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button } from '../../shared';
import { DocumentType } from '../../../types/shared';
import { TrackerDocumentUpload } from './';
import BookmarkButton from './bookmark-button';

export type CallToActionVariant = 'primary' | 'tertiary'
export type CallToActionType = 'bookmark' | 'link' | 'externalLink' | 'sonicFollowUpRoute' | 'sonicRoute' | 'uploadForm';

export interface CallToActionProps {
  applicationId: string
  type: 'bookmark' | 'link' | 'externalLink' | 'sonicFollowUpRoute' | 'sonicRoute' | 'uploadForm'
  label: string
  action?: string
  documentType?: DocumentType
  variant?: CallToActionVariant
  onSonicFollowUpRoute: (route: string) => void
  onSonicRoute: (route: string) => void
}

const CallToAction: React.FC<CallToActionProps> = ({ type, action, label, documentType,
  variant = 'primary', applicationId, onSonicFollowUpRoute, onSonicRoute }) => {

  switch (type) {
    case 'bookmark':
      return (
        <BookmarkButton className={`button button-${variant}`}>
          {label}
          {variant === 'tertiary' && <Icon type="arrow-right" />}
        </BookmarkButton>
      );

    case 'link':
      return (
        <Link to={action || ''} className={`button button-${variant}`}>
          {label}
          {variant === 'tertiary' && <Icon type="arrow-right" />}
        </Link>
      );

    case 'externalLink':
      return (
        <a href={action} className={`button button-${variant}`}>
          {label}
          {variant === 'tertiary' && <Icon type="arrow-right" />}
        </a>
      );

    case 'sonicFollowUpRoute':
    case 'sonicRoute':
      return (
        <Button
          onClick={() => type === 'sonicFollowUpRoute' ? onSonicFollowUpRoute(action || '') : onSonicRoute(action || '')}
          text={label}
          id="cta"
          variant={variant}
        />
      )

    case 'uploadForm':
      return (
        <TrackerDocumentUpload
          applicationId={applicationId}
          documentType={documentType as any}
        />
      );

    default:
      return null;
  }
};

export default CallToAction;