
export interface LoanConfigData {
  amount: number;
  term: number;
  fees: number;
  borrowed: number;
  repayment: number;
  total: number;
}

export const validLoanConfigs: LoanConfigData[] = [
  {
    "amount": 300,
    "term": 2,
    "fees": 60,
    "borrowed": 360,
    "repayment": 44,
    "total": 396
  },
  {
    "amount": 350,
    "term": 2,
    "fees": 70,
    "borrowed": 420,
    "repayment": 52,
    "total": 462
  },
  {
    "amount": 400,
    "term": 2,
    "fees": 80,
    "borrowed": 480,
    "repayment": 59,
    "total": 528
  },
  {
    "amount": 450,
    "term": 2,
    "fees": 90,
    "borrowed": 540,
    "repayment": 66,
    "total": 594
  },
  {
    "amount": 500,
    "term": 2,
    "fees": 100,
    "borrowed": 600,
    "repayment": 74,
    "total": 660
  },
  {
    "amount": 550,
    "term": 2,
    "fees": 110,
    "borrowed": 660,
    "repayment": 81,
    "total": 726
  },
  {
    "amount": 600,
    "term": 2,
    "fees": 120,
    "borrowed": 720,
    "repayment": 88,
    "total": 792
  },
  {
    "amount": 650,
    "term": 2,
    "fees": 130,
    "borrowed": 780,
    "repayment": 96,
    "total": 858
  },
  {
    "amount": 700,
    "term": 2,
    "fees": 140,
    "borrowed": 840,
    "repayment": 103,
    "total": 924
  },
  {
    "amount": 750,
    "term": 2,
    "fees": 150,
    "borrowed": 900,
    "repayment": 110,
    "total": 990
  },
  {
    "amount": 800,
    "term": 2,
    "fees": 160,
    "borrowed": 960,
    "repayment": 118,
    "total": 1056
  },
  {
    "amount": 850,
    "term": 2,
    "fees": 170,
    "borrowed": 1020,
    "repayment": 125,
    "total": 1122
  },
  {
    "amount": 900,
    "term": 2,
    "fees": 180,
    "borrowed": 1080,
    "repayment": 132,
    "total": 1188
  },
  {
    "amount": 950,
    "term": 2,
    "fees": 190,
    "borrowed": 1140,
    "repayment": 140,
    "total": 1254
  },
  {
    "amount": 1000,
    "term": 2,
    "fees": 200,
    "borrowed": 1200,
    "repayment": 147,
    "total": 1320
  },
  {
    "amount": 1050,
    "term": 2,
    "fees": 210,
    "borrowed": 1260,
    "repayment": 154,
    "total": 1386
  },
  {
    "amount": 1100,
    "term": 2,
    "fees": 220,
    "borrowed": 1320,
    "repayment": 162,
    "total": 1452
  },
  {
    "amount": 1150,
    "term": 2,
    "fees": 230,
    "borrowed": 1380,
    "repayment": 169,
    "total": 1518
  },
  {
    "amount": 1200,
    "term": 2,
    "fees": 240,
    "borrowed": 1440,
    "repayment": 176,
    "total": 1584
  },
  {
    "amount": 1250,
    "term": 2,
    "fees": 250,
    "borrowed": 1500,
    "repayment": 184,
    "total": 1650
  },
  {
    "amount": 1300,
    "term": 2,
    "fees": 260,
    "borrowed": 1560,
    "repayment": 191,
    "total": 1716
  },
  {
    "amount": 1350,
    "term": 2,
    "fees": 270,
    "borrowed": 1620,
    "repayment": 198,
    "total": 1782
  },
  {
    "amount": 1400,
    "term": 2,
    "fees": 280,
    "borrowed": 1680,
    "repayment": 206,
    "total": 1848
  },
  {
    "amount": 1450,
    "term": 2,
    "fees": 290,
    "borrowed": 1740,
    "repayment": 213,
    "total": 1914
  },
  {
    "amount": 1500,
    "term": 2,
    "fees": 300,
    "borrowed": 1800,
    "repayment": 220,
    "total": 1980
  },
  {
    "amount": 1550,
    "term": 2,
    "fees": 310,
    "borrowed": 1860,
    "repayment": 228,
    "total": 2046
  },
  {
    "amount": 1600,
    "term": 2,
    "fees": 320,
    "borrowed": 1920,
    "repayment": 235,
    "total": 2112
  },
  {
    "amount": 1650,
    "term": 2,
    "fees": 330,
    "borrowed": 1980,
    "repayment": 242,
    "total": 2178
  },
  {
    "amount": 1700,
    "term": 2,
    "fees": 340,
    "borrowed": 2040,
    "repayment": 250,
    "total": 2244
  },
  {
    "amount": 1750,
    "term": 2,
    "fees": 350,
    "borrowed": 2100,
    "repayment": 257,
    "total": 2310
  },
  {
    "amount": 1800,
    "term": 2,
    "fees": 360,
    "borrowed": 2160,
    "repayment": 264,
    "total": 2376
  },
  {
    "amount": 1850,
    "term": 2,
    "fees": 370,
    "borrowed": 2220,
    "repayment": 272,
    "total": 2442
  },
  {
    "amount": 1900,
    "term": 2,
    "fees": 380,
    "borrowed": 2280,
    "repayment": 279,
    "total": 2508
  },
  {
    "amount": 1950,
    "term": 2,
    "fees": 390,
    "borrowed": 2340,
    "repayment": 286,
    "total": 2574
  },
  {
    "amount": 2000,
    "term": 2,
    "fees": 400,
    "borrowed": 2400,
    "repayment": 294,
    "total": 2640
  },
  {
    "amount": 300,
    "term": 3,
    "fees": 60,
    "borrowed": 360,
    "repayment": 33,
    "total": 396
  },
  {
    "amount": 350,
    "term": 3,
    "fees": 70,
    "borrowed": 420,
    "repayment": 39,
    "total": 462
  },
  {
    "amount": 400,
    "term": 3,
    "fees": 80,
    "borrowed": 480,
    "repayment": 44,
    "total": 528
  },
  {
    "amount": 450,
    "term": 3,
    "fees": 90,
    "borrowed": 540,
    "repayment": 50,
    "total": 594
  },
  {
    "amount": 500,
    "term": 3,
    "fees": 100,
    "borrowed": 600,
    "repayment": 55,
    "total": 660
  },
  {
    "amount": 550,
    "term": 3,
    "fees": 110,
    "borrowed": 660,
    "repayment": 61,
    "total": 726
  },
  {
    "amount": 600,
    "term": 3,
    "fees": 120,
    "borrowed": 720,
    "repayment": 66,
    "total": 792
  },
  {
    "amount": 650,
    "term": 3,
    "fees": 130,
    "borrowed": 780,
    "repayment": 72,
    "total": 858
  },
  {
    "amount": 700,
    "term": 3,
    "fees": 140,
    "borrowed": 840,
    "repayment": 77,
    "total": 924
  },
  {
    "amount": 750,
    "term": 3,
    "fees": 150,
    "borrowed": 900,
    "repayment": 83,
    "total": 990
  },
  {
    "amount": 800,
    "term": 3,
    "fees": 160,
    "borrowed": 960,
    "repayment": 88,
    "total": 1056
  },
  {
    "amount": 850,
    "term": 3,
    "fees": 170,
    "borrowed": 1020,
    "repayment": 94,
    "total": 1122
  },
  {
    "amount": 900,
    "term": 3,
    "fees": 180,
    "borrowed": 1080,
    "repayment": 99,
    "total": 1188
  },
  {
    "amount": 950,
    "term": 3,
    "fees": 190,
    "borrowed": 1140,
    "repayment": 105,
    "total": 1254
  },
  {
    "amount": 1000,
    "term": 3,
    "fees": 200,
    "borrowed": 1200,
    "repayment": 110,
    "total": 1320
  },
  {
    "amount": 1050,
    "term": 3,
    "fees": 210,
    "borrowed": 1260,
    "repayment": 116,
    "total": 1386
  },
  {
    "amount": 1100,
    "term": 3,
    "fees": 220,
    "borrowed": 1320,
    "repayment": 121,
    "total": 1452
  },
  {
    "amount": 1150,
    "term": 3,
    "fees": 230,
    "borrowed": 1380,
    "repayment": 127,
    "total": 1518
  },
  {
    "amount": 1200,
    "term": 3,
    "fees": 240,
    "borrowed": 1440,
    "repayment": 132,
    "total": 1584
  },
  {
    "amount": 1250,
    "term": 3,
    "fees": 250,
    "borrowed": 1500,
    "repayment": 138,
    "total": 1650
  },
  {
    "amount": 1300,
    "term": 3,
    "fees": 260,
    "borrowed": 1560,
    "repayment": 143,
    "total": 1716
  },
  {
    "amount": 1350,
    "term": 3,
    "fees": 270,
    "borrowed": 1620,
    "repayment": 149,
    "total": 1782
  },
  {
    "amount": 1400,
    "term": 3,
    "fees": 280,
    "borrowed": 1680,
    "repayment": 154,
    "total": 1848
  },
  {
    "amount": 1450,
    "term": 3,
    "fees": 290,
    "borrowed": 1740,
    "repayment": 160,
    "total": 1914
  },
  {
    "amount": 1500,
    "term": 3,
    "fees": 300,
    "borrowed": 1800,
    "repayment": 165,
    "total": 1980
  },
  {
    "amount": 1550,
    "term": 3,
    "fees": 310,
    "borrowed": 1860,
    "repayment": 171,
    "total": 2046
  },
  {
    "amount": 1600,
    "term": 3,
    "fees": 320,
    "borrowed": 1920,
    "repayment": 176,
    "total": 2112
  },
  {
    "amount": 1650,
    "term": 3,
    "fees": 330,
    "borrowed": 1980,
    "repayment": 182,
    "total": 2178
  },
  {
    "amount": 1700,
    "term": 3,
    "fees": 340,
    "borrowed": 2040,
    "repayment": 187,
    "total": 2244
  },
  {
    "amount": 1750,
    "term": 3,
    "fees": 350,
    "borrowed": 2100,
    "repayment": 193,
    "total": 2310
  },
  {
    "amount": 1800,
    "term": 3,
    "fees": 360,
    "borrowed": 2160,
    "repayment": 198,
    "total": 2376
  },
  {
    "amount": 1850,
    "term": 3,
    "fees": 370,
    "borrowed": 2220,
    "repayment": 204,
    "total": 2442
  },
  {
    "amount": 1900,
    "term": 3,
    "fees": 380,
    "borrowed": 2280,
    "repayment": 209,
    "total": 2508
  },
  {
    "amount": 1950,
    "term": 3,
    "fees": 390,
    "borrowed": 2340,
    "repayment": 215,
    "total": 2574
  },
  {
    "amount": 2000,
    "term": 3,
    "fees": 400,
    "borrowed": 2400,
    "repayment": 220,
    "total": 2640
  },
  {
    "amount": 400,
    "term": 4,
    "fees": 80,
    "borrowed": 480,
    "repayment": 32,
    "total": 544
  },
  {
    "amount": 450,
    "term": 4,
    "fees": 90,
    "borrowed": 540,
    "repayment": 36,
    "total": 612
  },
  {
    "amount": 500,
    "term": 4,
    "fees": 100,
    "borrowed": 600,
    "repayment": 40,
    "total": 680
  },
  {
    "amount": 550,
    "term": 4,
    "fees": 110,
    "borrowed": 660,
    "repayment": 44,
    "total": 748
  },
  {
    "amount": 600,
    "term": 4,
    "fees": 120,
    "borrowed": 720,
    "repayment": 48,
    "total": 816
  },
  {
    "amount": 650,
    "term": 4,
    "fees": 130,
    "borrowed": 780,
    "repayment": 52,
    "total": 884
  },
  {
    "amount": 700,
    "term": 4,
    "fees": 140,
    "borrowed": 840,
    "repayment": 56,
    "total": 952
  },
  {
    "amount": 750,
    "term": 4,
    "fees": 150,
    "borrowed": 900,
    "repayment": 60,
    "total": 1020
  },
  {
    "amount": 800,
    "term": 4,
    "fees": 160,
    "borrowed": 960,
    "repayment": 64,
    "total": 1088
  },
  {
    "amount": 850,
    "term": 4,
    "fees": 170,
    "borrowed": 1020,
    "repayment": 68,
    "total": 1156
  },
  {
    "amount": 900,
    "term": 4,
    "fees": 180,
    "borrowed": 1080,
    "repayment": 72,
    "total": 1224
  },
  {
    "amount": 950,
    "term": 4,
    "fees": 190,
    "borrowed": 1140,
    "repayment": 76,
    "total": 1292
  },
  {
    "amount": 1000,
    "term": 4,
    "fees": 200,
    "borrowed": 1200,
    "repayment": 80,
    "total": 1360
  },
  {
    "amount": 1050,
    "term": 4,
    "fees": 210,
    "borrowed": 1260,
    "repayment": 84,
    "total": 1428
  },
  {
    "amount": 1100,
    "term": 4,
    "fees": 220,
    "borrowed": 1320,
    "repayment": 88,
    "total": 1496
  },
  {
    "amount": 1150,
    "term": 4,
    "fees": 230,
    "borrowed": 1380,
    "repayment": 92,
    "total": 1564
  },
  {
    "amount": 1200,
    "term": 4,
    "fees": 240,
    "borrowed": 1440,
    "repayment": 96,
    "total": 1632
  },
  {
    "amount": 1250,
    "term": 4,
    "fees": 250,
    "borrowed": 1500,
    "repayment": 100,
    "total": 1700
  },
  {
    "amount": 1300,
    "term": 4,
    "fees": 260,
    "borrowed": 1560,
    "repayment": 104,
    "total": 1768
  },
  {
    "amount": 1350,
    "term": 4,
    "fees": 270,
    "borrowed": 1620,
    "repayment": 108,
    "total": 1836
  },
  {
    "amount": 1400,
    "term": 4,
    "fees": 280,
    "borrowed": 1680,
    "repayment": 112,
    "total": 1904
  },
  {
    "amount": 1450,
    "term": 4,
    "fees": 290,
    "borrowed": 1740,
    "repayment": 116,
    "total": 1972
  },
  {
    "amount": 1500,
    "term": 4,
    "fees": 300,
    "borrowed": 1800,
    "repayment": 120,
    "total": 2040
  },
  {
    "amount": 1550,
    "term": 4,
    "fees": 310,
    "borrowed": 1860,
    "repayment": 124,
    "total": 2108
  },
  {
    "amount": 1600,
    "term": 4,
    "fees": 320,
    "borrowed": 1920,
    "repayment": 128,
    "total": 2176
  },
  {
    "amount": 1650,
    "term": 4,
    "fees": 330,
    "borrowed": 1980,
    "repayment": 132,
    "total": 2244
  },
  {
    "amount": 1700,
    "term": 4,
    "fees": 340,
    "borrowed": 2040,
    "repayment": 136,
    "total": 2312
  },
  {
    "amount": 1750,
    "term": 4,
    "fees": 350,
    "borrowed": 2100,
    "repayment": 140,
    "total": 2380
  },
  {
    "amount": 1800,
    "term": 4,
    "fees": 360,
    "borrowed": 2160,
    "repayment": 144,
    "total": 2448
  },
  {
    "amount": 1850,
    "term": 4,
    "fees": 370,
    "borrowed": 2220,
    "repayment": 148,
    "total": 2516
  },
  {
    "amount": 1900,
    "term": 4,
    "fees": 380,
    "borrowed": 2280,
    "repayment": 152,
    "total": 2584
  },
  {
    "amount": 1950,
    "term": 4,
    "fees": 390,
    "borrowed": 2340,
    "repayment": 156,
    "total": 2652
  },
  {
    "amount": 2000,
    "term": 4,
    "fees": 400,
    "borrowed": 2400,
    "repayment": 160,
    "total": 2720
  },
  {
    "amount": 2050,
    "term": 4,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 157,
    "total": 2665
  },
  {
    "amount": 2100,
    "term": 4,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 160,
    "total": 2719
  },
  {
    "amount": 2150,
    "term": 4,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 164,
    "total": 2774
  },
  {
    "amount": 2200,
    "term": 4,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 167,
    "total": 2828
  },
  {
    "amount": 2250,
    "term": 4,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 170,
    "total": 2882
  },
  {
    "amount": 2300,
    "term": 4,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 173,
    "total": 2936
  },
  {
    "amount": 2350,
    "term": 4,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 176,
    "total": 2991
  },
  {
    "amount": 2400,
    "term": 4,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 180,
    "total": 3045
  },
  {
    "amount": 2450,
    "term": 4,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 183,
    "total": 3099
  },
  {
    "amount": 2500,
    "term": 4,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 186,
    "total": 3153
  },
  {
    "amount": 2550,
    "term": 4,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 189,
    "total": 3208
  },
  {
    "amount": 2600,
    "term": 4,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 192,
    "total": 3262
  },
  {
    "amount": 2650,
    "term": 4,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 196,
    "total": 3316
  },
  {
    "amount": 2700,
    "term": 4,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 199,
    "total": 3370
  },
  {
    "amount": 2750,
    "term": 4,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 202,
    "total": 3424
  },
  {
    "amount": 2800,
    "term": 4,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 205,
    "total": 3479
  },
  {
    "amount": 2850,
    "term": 4,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 208,
    "total": 3533
  },
  {
    "amount": 2900,
    "term": 4,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 211,
    "total": 3587
  },
  {
    "amount": 2950,
    "term": 4,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 215,
    "total": 3642
  },
  {
    "amount": 3000,
    "term": 4,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 218,
    "total": 3696
  },
  {
    "amount": 3050,
    "term": 4,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 221,
    "total": 3750
  },
  {
    "amount": 3100,
    "term": 4,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 224,
    "total": 3804
  },
  {
    "amount": 3150,
    "term": 4,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 227,
    "total": 3858
  },
  {
    "amount": 3200,
    "term": 4,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 231,
    "total": 3913
  },
  {
    "amount": 3250,
    "term": 4,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 234,
    "total": 3967
  },
  {
    "amount": 3300,
    "term": 4,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 237,
    "total": 4021
  },
  {
    "amount": 3350,
    "term": 4,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 240,
    "total": 4075
  },
  {
    "amount": 3400,
    "term": 4,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 243,
    "total": 4130
  },
  {
    "amount": 3450,
    "term": 4,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 247,
    "total": 4184
  },
  {
    "amount": 3500,
    "term": 4,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 250,
    "total": 4238
  },
  {
    "amount": 3550,
    "term": 4,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 253,
    "total": 4292
  },
  {
    "amount": 3600,
    "term": 4,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 256,
    "total": 4347
  },
  {
    "amount": 3650,
    "term": 4,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 259,
    "total": 4401
  },
  {
    "amount": 3700,
    "term": 4,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 263,
    "total": 4455
  },
  {
    "amount": 3750,
    "term": 4,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 266,
    "total": 4509
  },
  {
    "amount": 3800,
    "term": 4,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 269,
    "total": 4564
  },
  {
    "amount": 3850,
    "term": 4,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 272,
    "total": 4618
  },
  {
    "amount": 3900,
    "term": 4,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 275,
    "total": 4672
  },
  {
    "amount": 3950,
    "term": 4,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 278,
    "total": 4726
  },
  {
    "amount": 4000,
    "term": 4,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 282,
    "total": 4781
  },
  {
    "amount": 4050,
    "term": 4,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 285,
    "total": 4835
  },
  {
    "amount": 4100,
    "term": 4,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 288,
    "total": 4889
  },
  {
    "amount": 4150,
    "term": 4,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 291,
    "total": 4943
  },
  {
    "amount": 4200,
    "term": 4,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 294,
    "total": 4997
  },
  {
    "amount": 4250,
    "term": 4,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 298,
    "total": 5052
  },
  {
    "amount": 4300,
    "term": 4,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 301,
    "total": 5106
  },
  {
    "amount": 4350,
    "term": 4,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 304,
    "total": 5160
  },
  {
    "amount": 4400,
    "term": 4,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 307,
    "total": 5214
  },
  {
    "amount": 4450,
    "term": 4,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 310,
    "total": 5269
  },
  {
    "amount": 4500,
    "term": 4,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 314,
    "total": 5323
  },
  {
    "amount": 4550,
    "term": 4,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 317,
    "total": 5377
  },
  {
    "amount": 4600,
    "term": 4,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 320,
    "total": 5431
  },
  {
    "amount": 400,
    "term": 5,
    "fees": 80,
    "borrowed": 480,
    "repayment": 27,
    "total": 560
  },
  {
    "amount": 450,
    "term": 5,
    "fees": 90,
    "borrowed": 540,
    "repayment": 30,
    "total": 630
  },
  {
    "amount": 500,
    "term": 5,
    "fees": 100,
    "borrowed": 600,
    "repayment": 34,
    "total": 700
  },
  {
    "amount": 550,
    "term": 5,
    "fees": 110,
    "borrowed": 660,
    "repayment": 37,
    "total": 770
  },
  {
    "amount": 600,
    "term": 5,
    "fees": 120,
    "borrowed": 720,
    "repayment": 40,
    "total": 840
  },
  {
    "amount": 650,
    "term": 5,
    "fees": 130,
    "borrowed": 780,
    "repayment": 44,
    "total": 910
  },
  {
    "amount": 700,
    "term": 5,
    "fees": 140,
    "borrowed": 840,
    "repayment": 47,
    "total": 980
  },
  {
    "amount": 750,
    "term": 5,
    "fees": 150,
    "borrowed": 900,
    "repayment": 50,
    "total": 1050
  },
  {
    "amount": 800,
    "term": 5,
    "fees": 160,
    "borrowed": 960,
    "repayment": 54,
    "total": 1120
  },
  {
    "amount": 850,
    "term": 5,
    "fees": 170,
    "borrowed": 1020,
    "repayment": 57,
    "total": 1190
  },
  {
    "amount": 900,
    "term": 5,
    "fees": 180,
    "borrowed": 1080,
    "repayment": 60,
    "total": 1260
  },
  {
    "amount": 950,
    "term": 5,
    "fees": 190,
    "borrowed": 1140,
    "repayment": 64,
    "total": 1330
  },
  {
    "amount": 1000,
    "term": 5,
    "fees": 200,
    "borrowed": 1200,
    "repayment": 67,
    "total": 1400
  },
  {
    "amount": 1050,
    "term": 5,
    "fees": 210,
    "borrowed": 1260,
    "repayment": 70,
    "total": 1470
  },
  {
    "amount": 1100,
    "term": 5,
    "fees": 220,
    "borrowed": 1320,
    "repayment": 74,
    "total": 1540
  },
  {
    "amount": 1150,
    "term": 5,
    "fees": 230,
    "borrowed": 1380,
    "repayment": 77,
    "total": 1610
  },
  {
    "amount": 1200,
    "term": 5,
    "fees": 240,
    "borrowed": 1440,
    "repayment": 80,
    "total": 1680
  },
  {
    "amount": 1250,
    "term": 5,
    "fees": 250,
    "borrowed": 1500,
    "repayment": 84,
    "total": 1750
  },
  {
    "amount": 1300,
    "term": 5,
    "fees": 260,
    "borrowed": 1560,
    "repayment": 87,
    "total": 1820
  },
  {
    "amount": 1350,
    "term": 5,
    "fees": 270,
    "borrowed": 1620,
    "repayment": 90,
    "total": 1890
  },
  {
    "amount": 1400,
    "term": 5,
    "fees": 280,
    "borrowed": 1680,
    "repayment": 94,
    "total": 1960
  },
  {
    "amount": 1450,
    "term": 5,
    "fees": 290,
    "borrowed": 1740,
    "repayment": 97,
    "total": 2030
  },
  {
    "amount": 1500,
    "term": 5,
    "fees": 300,
    "borrowed": 1800,
    "repayment": 100,
    "total": 2100
  },
  {
    "amount": 1550,
    "term": 5,
    "fees": 310,
    "borrowed": 1860,
    "repayment": 104,
    "total": 2170
  },
  {
    "amount": 1600,
    "term": 5,
    "fees": 320,
    "borrowed": 1920,
    "repayment": 107,
    "total": 2240
  },
  {
    "amount": 1650,
    "term": 5,
    "fees": 330,
    "borrowed": 1980,
    "repayment": 110,
    "total": 2310
  },
  {
    "amount": 1700,
    "term": 5,
    "fees": 340,
    "borrowed": 2040,
    "repayment": 114,
    "total": 2380
  },
  {
    "amount": 1750,
    "term": 5,
    "fees": 350,
    "borrowed": 2100,
    "repayment": 117,
    "total": 2450
  },
  {
    "amount": 1800,
    "term": 5,
    "fees": 360,
    "borrowed": 2160,
    "repayment": 120,
    "total": 2520
  },
  {
    "amount": 1850,
    "term": 5,
    "fees": 370,
    "borrowed": 2220,
    "repayment": 124,
    "total": 2590
  },
  {
    "amount": 1900,
    "term": 5,
    "fees": 380,
    "borrowed": 2280,
    "repayment": 127,
    "total": 2660
  },
  {
    "amount": 1950,
    "term": 5,
    "fees": 390,
    "borrowed": 2340,
    "repayment": 130,
    "total": 2730
  },
  {
    "amount": 2000,
    "term": 5,
    "fees": 400,
    "borrowed": 2400,
    "repayment": 134,
    "total": 2800
  },
  {
    "amount": 2050,
    "term": 5,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 130,
    "total": 2713
  },
  {
    "amount": 2100,
    "term": 5,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 132,
    "total": 2768
  },
  {
    "amount": 2150,
    "term": 5,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 135,
    "total": 2823
  },
  {
    "amount": 2200,
    "term": 5,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 138,
    "total": 2878
  },
  {
    "amount": 2250,
    "term": 5,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 140,
    "total": 2934
  },
  {
    "amount": 2300,
    "term": 5,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 143,
    "total": 2989
  },
  {
    "amount": 2350,
    "term": 5,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 145,
    "total": 3044
  },
  {
    "amount": 2400,
    "term": 5,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 148,
    "total": 3099
  },
  {
    "amount": 2450,
    "term": 5,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 151,
    "total": 3154
  },
  {
    "amount": 2500,
    "term": 5,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 153,
    "total": 3210
  },
  {
    "amount": 2550,
    "term": 5,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 156,
    "total": 3265
  },
  {
    "amount": 2600,
    "term": 5,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 159,
    "total": 3320
  },
  {
    "amount": 2650,
    "term": 5,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 161,
    "total": 3375
  },
  {
    "amount": 2700,
    "term": 5,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 164,
    "total": 3431
  },
  {
    "amount": 2750,
    "term": 5,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 166,
    "total": 3486
  },
  {
    "amount": 2800,
    "term": 5,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 169,
    "total": 3541
  },
  {
    "amount": 2850,
    "term": 5,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 172,
    "total": 3596
  },
  {
    "amount": 2900,
    "term": 5,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 174,
    "total": 3651
  },
  {
    "amount": 2950,
    "term": 5,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 177,
    "total": 3707
  },
  {
    "amount": 3000,
    "term": 5,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 180,
    "total": 3762
  },
  {
    "amount": 3050,
    "term": 5,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 182,
    "total": 3817
  },
  {
    "amount": 3100,
    "term": 5,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 185,
    "total": 3872
  },
  {
    "amount": 3150,
    "term": 5,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 187,
    "total": 3927
  },
  {
    "amount": 3200,
    "term": 5,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 190,
    "total": 3983
  },
  {
    "amount": 3250,
    "term": 5,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 193,
    "total": 4038
  },
  {
    "amount": 3300,
    "term": 5,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 195,
    "total": 4093
  },
  {
    "amount": 3350,
    "term": 5,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 198,
    "total": 4148
  },
  {
    "amount": 3400,
    "term": 5,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 201,
    "total": 4204
  },
  {
    "amount": 3450,
    "term": 5,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 203,
    "total": 4259
  },
  {
    "amount": 3500,
    "term": 5,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 206,
    "total": 4314
  },
  {
    "amount": 3550,
    "term": 5,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 209,
    "total": 4369
  },
  {
    "amount": 3600,
    "term": 5,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 211,
    "total": 4424
  },
  {
    "amount": 3650,
    "term": 5,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 214,
    "total": 4480
  },
  {
    "amount": 3700,
    "term": 5,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 216,
    "total": 4535
  },
  {
    "amount": 3750,
    "term": 5,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 219,
    "total": 4590
  },
  {
    "amount": 3800,
    "term": 5,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 222,
    "total": 4645
  },
  {
    "amount": 3850,
    "term": 5,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 224,
    "total": 4701
  },
  {
    "amount": 3900,
    "term": 5,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 227,
    "total": 4756
  },
  {
    "amount": 3950,
    "term": 5,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 230,
    "total": 4811
  },
  {
    "amount": 4000,
    "term": 5,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 232,
    "total": 4866
  },
  {
    "amount": 4050,
    "term": 5,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 235,
    "total": 4921
  },
  {
    "amount": 4100,
    "term": 5,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 237,
    "total": 4977
  },
  {
    "amount": 4150,
    "term": 5,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 240,
    "total": 5032
  },
  {
    "amount": 4200,
    "term": 5,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 243,
    "total": 5087
  },
  {
    "amount": 4250,
    "term": 5,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 245,
    "total": 5142
  },
  {
    "amount": 4300,
    "term": 5,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 248,
    "total": 5197
  },
  {
    "amount": 4350,
    "term": 5,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 251,
    "total": 5253
  },
  {
    "amount": 4400,
    "term": 5,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 253,
    "total": 5308
  },
  {
    "amount": 4450,
    "term": 5,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 256,
    "total": 5363
  },
  {
    "amount": 4500,
    "term": 5,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 258,
    "total": 5418
  },
  {
    "amount": 4550,
    "term": 5,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 261,
    "total": 5474
  },
  {
    "amount": 4600,
    "term": 5,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 264,
    "total": 5529
  },
  {
    "amount": 400,
    "term": 6,
    "fees": 80,
    "borrowed": 480,
    "repayment": 24,
    "total": 576
  },
  {
    "amount": 450,
    "term": 6,
    "fees": 90,
    "borrowed": 540,
    "repayment": 26,
    "total": 648
  },
  {
    "amount": 500,
    "term": 6,
    "fees": 100,
    "borrowed": 600,
    "repayment": 29,
    "total": 720
  },
  {
    "amount": 550,
    "term": 6,
    "fees": 110,
    "borrowed": 660,
    "repayment": 32,
    "total": 792
  },
  {
    "amount": 600,
    "term": 6,
    "fees": 120,
    "borrowed": 720,
    "repayment": 35,
    "total": 864
  },
  {
    "amount": 650,
    "term": 6,
    "fees": 130,
    "borrowed": 780,
    "repayment": 38,
    "total": 936
  },
  {
    "amount": 700,
    "term": 6,
    "fees": 140,
    "borrowed": 840,
    "repayment": 41,
    "total": 1008
  },
  {
    "amount": 750,
    "term": 6,
    "fees": 150,
    "borrowed": 900,
    "repayment": 44,
    "total": 1080
  },
  {
    "amount": 800,
    "term": 6,
    "fees": 160,
    "borrowed": 960,
    "repayment": 47,
    "total": 1152
  },
  {
    "amount": 850,
    "term": 6,
    "fees": 170,
    "borrowed": 1020,
    "repayment": 49,
    "total": 1224
  },
  {
    "amount": 900,
    "term": 6,
    "fees": 180,
    "borrowed": 1080,
    "repayment": 52,
    "total": 1296
  },
  {
    "amount": 950,
    "term": 6,
    "fees": 190,
    "borrowed": 1140,
    "repayment": 55,
    "total": 1368
  },
  {
    "amount": 1000,
    "term": 6,
    "fees": 200,
    "borrowed": 1200,
    "repayment": 58,
    "total": 1440
  },
  {
    "amount": 1050,
    "term": 6,
    "fees": 210,
    "borrowed": 1260,
    "repayment": 61,
    "total": 1512
  },
  {
    "amount": 1100,
    "term": 6,
    "fees": 220,
    "borrowed": 1320,
    "repayment": 64,
    "total": 1584
  },
  {
    "amount": 1150,
    "term": 6,
    "fees": 230,
    "borrowed": 1380,
    "repayment": 67,
    "total": 1656
  },
  {
    "amount": 1200,
    "term": 6,
    "fees": 240,
    "borrowed": 1440,
    "repayment": 70,
    "total": 1728
  },
  {
    "amount": 1250,
    "term": 6,
    "fees": 250,
    "borrowed": 1500,
    "repayment": 72,
    "total": 1800
  },
  {
    "amount": 1300,
    "term": 6,
    "fees": 260,
    "borrowed": 1560,
    "repayment": 75,
    "total": 1872
  },
  {
    "amount": 1350,
    "term": 6,
    "fees": 270,
    "borrowed": 1620,
    "repayment": 78,
    "total": 1944
  },
  {
    "amount": 1400,
    "term": 6,
    "fees": 280,
    "borrowed": 1680,
    "repayment": 81,
    "total": 2016
  },
  {
    "amount": 1450,
    "term": 6,
    "fees": 290,
    "borrowed": 1740,
    "repayment": 84,
    "total": 2088
  },
  {
    "amount": 1500,
    "term": 6,
    "fees": 300,
    "borrowed": 1800,
    "repayment": 87,
    "total": 2160
  },
  {
    "amount": 1550,
    "term": 6,
    "fees": 310,
    "borrowed": 1860,
    "repayment": 90,
    "total": 2232
  },
  {
    "amount": 1600,
    "term": 6,
    "fees": 320,
    "borrowed": 1920,
    "repayment": 93,
    "total": 2304
  },
  {
    "amount": 1650,
    "term": 6,
    "fees": 330,
    "borrowed": 1980,
    "repayment": 96,
    "total": 2376
  },
  {
    "amount": 1700,
    "term": 6,
    "fees": 340,
    "borrowed": 2040,
    "repayment": 98,
    "total": 2448
  },
  {
    "amount": 1750,
    "term": 6,
    "fees": 350,
    "borrowed": 2100,
    "repayment": 101,
    "total": 2520
  },
  {
    "amount": 1800,
    "term": 6,
    "fees": 360,
    "borrowed": 2160,
    "repayment": 104,
    "total": 2592
  },
  {
    "amount": 1850,
    "term": 6,
    "fees": 370,
    "borrowed": 2220,
    "repayment": 107,
    "total": 2664
  },
  {
    "amount": 1900,
    "term": 6,
    "fees": 380,
    "borrowed": 2280,
    "repayment": 110,
    "total": 2736
  },
  {
    "amount": 1950,
    "term": 6,
    "fees": 390,
    "borrowed": 2340,
    "repayment": 113,
    "total": 2808
  },
  {
    "amount": 2000,
    "term": 6,
    "fees": 400,
    "borrowed": 2400,
    "repayment": 116,
    "total": 2880
  },
  {
    "amount": 2050,
    "term": 6,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 111,
    "total": 2762
  },
  {
    "amount": 2100,
    "term": 6,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 113,
    "total": 2818
  },
  {
    "amount": 2150,
    "term": 6,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 115,
    "total": 2875
  },
  {
    "amount": 2200,
    "term": 6,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 118,
    "total": 2931
  },
  {
    "amount": 2250,
    "term": 6,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 120,
    "total": 2987
  },
  {
    "amount": 2300,
    "term": 6,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 122,
    "total": 3043
  },
  {
    "amount": 2350,
    "term": 6,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 124,
    "total": 3099
  },
  {
    "amount": 2400,
    "term": 6,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 127,
    "total": 3156
  },
  {
    "amount": 2450,
    "term": 6,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 129,
    "total": 3212
  },
  {
    "amount": 2500,
    "term": 6,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 131,
    "total": 3268
  },
  {
    "amount": 2550,
    "term": 6,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 133,
    "total": 3324
  },
  {
    "amount": 2600,
    "term": 6,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 136,
    "total": 3380
  },
  {
    "amount": 2650,
    "term": 6,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 138,
    "total": 3437
  },
  {
    "amount": 2700,
    "term": 6,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 140,
    "total": 3493
  },
  {
    "amount": 2750,
    "term": 6,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 142,
    "total": 3549
  },
  {
    "amount": 2800,
    "term": 6,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 145,
    "total": 3605
  },
  {
    "amount": 2850,
    "term": 6,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 147,
    "total": 3661
  },
  {
    "amount": 2900,
    "term": 6,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 149,
    "total": 3718
  },
  {
    "amount": 2950,
    "term": 6,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 151,
    "total": 3774
  },
  {
    "amount": 3000,
    "term": 6,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 154,
    "total": 3830
  },
  {
    "amount": 3050,
    "term": 6,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 156,
    "total": 3886
  },
  {
    "amount": 3100,
    "term": 6,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 158,
    "total": 3942
  },
  {
    "amount": 3150,
    "term": 6,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 160,
    "total": 3999
  },
  {
    "amount": 3200,
    "term": 6,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 163,
    "total": 4055
  },
  {
    "amount": 3250,
    "term": 6,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 165,
    "total": 4111
  },
  {
    "amount": 3300,
    "term": 6,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 167,
    "total": 4167
  },
  {
    "amount": 3350,
    "term": 6,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 169,
    "total": 4223
  },
  {
    "amount": 3400,
    "term": 6,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 172,
    "total": 4280
  },
  {
    "amount": 3450,
    "term": 6,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 174,
    "total": 4336
  },
  {
    "amount": 3500,
    "term": 6,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 176,
    "total": 4392
  },
  {
    "amount": 3550,
    "term": 6,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 178,
    "total": 4448
  },
  {
    "amount": 3600,
    "term": 6,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 181,
    "total": 4504
  },
  {
    "amount": 3650,
    "term": 6,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 183,
    "total": 4561
  },
  {
    "amount": 3700,
    "term": 6,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 185,
    "total": 4617
  },
  {
    "amount": 3750,
    "term": 6,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 187,
    "total": 4673
  },
  {
    "amount": 3800,
    "term": 6,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 190,
    "total": 4729
  },
  {
    "amount": 3850,
    "term": 6,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 192,
    "total": 4785
  },
  {
    "amount": 3900,
    "term": 6,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 194,
    "total": 4842
  },
  {
    "amount": 3950,
    "term": 6,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 196,
    "total": 4898
  },
  {
    "amount": 4000,
    "term": 6,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 199,
    "total": 4954
  },
  {
    "amount": 4050,
    "term": 6,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 201,
    "total": 5010
  },
  {
    "amount": 4100,
    "term": 6,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 203,
    "total": 5066
  },
  {
    "amount": 4150,
    "term": 6,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 205,
    "total": 5123
  },
  {
    "amount": 4200,
    "term": 6,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 208,
    "total": 5179
  },
  {
    "amount": 4250,
    "term": 6,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 210,
    "total": 5235
  },
  {
    "amount": 4300,
    "term": 6,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 212,
    "total": 5291
  },
  {
    "amount": 4350,
    "term": 6,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 214,
    "total": 5347
  },
  {
    "amount": 4400,
    "term": 6,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 217,
    "total": 5404
  },
  {
    "amount": 4450,
    "term": 6,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 219,
    "total": 5460
  },
  {
    "amount": 4500,
    "term": 6,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 221,
    "total": 5516
  },
  {
    "amount": 4550,
    "term": 6,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 223,
    "total": 5572
  },
  {
    "amount": 4600,
    "term": 6,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 226,
    "total": 5628
  },
  {
    "amount": 400,
    "term": 7,
    "fees": 80,
    "borrowed": 480,
    "repayment": 20,
    "total": 592
  },
  {
    "amount": 450,
    "term": 7,
    "fees": 90,
    "borrowed": 540,
    "repayment": 23,
    "total": 666
  },
  {
    "amount": 500,
    "term": 7,
    "fees": 100,
    "borrowed": 600,
    "repayment": 25,
    "total": 740
  },
  {
    "amount": 550,
    "term": 7,
    "fees": 110,
    "borrowed": 660,
    "repayment": 28,
    "total": 814
  },
  {
    "amount": 600,
    "term": 7,
    "fees": 120,
    "borrowed": 720,
    "repayment": 30,
    "total": 888
  },
  {
    "amount": 650,
    "term": 7,
    "fees": 130,
    "borrowed": 780,
    "repayment": 33,
    "total": 962
  },
  {
    "amount": 700,
    "term": 7,
    "fees": 140,
    "borrowed": 840,
    "repayment": 35,
    "total": 1036
  },
  {
    "amount": 750,
    "term": 7,
    "fees": 150,
    "borrowed": 900,
    "repayment": 37,
    "total": 1110
  },
  {
    "amount": 800,
    "term": 7,
    "fees": 160,
    "borrowed": 960,
    "repayment": 40,
    "total": 1184
  },
  {
    "amount": 850,
    "term": 7,
    "fees": 170,
    "borrowed": 1020,
    "repayment": 42,
    "total": 1258
  },
  {
    "amount": 900,
    "term": 7,
    "fees": 180,
    "borrowed": 1080,
    "repayment": 45,
    "total": 1332
  },
  {
    "amount": 950,
    "term": 7,
    "fees": 190,
    "borrowed": 1140,
    "repayment": 47,
    "total": 1406
  },
  {
    "amount": 1000,
    "term": 7,
    "fees": 200,
    "borrowed": 1200,
    "repayment": 50,
    "total": 1480
  },
  {
    "amount": 1050,
    "term": 7,
    "fees": 210,
    "borrowed": 1260,
    "repayment": 52,
    "total": 1554
  },
  {
    "amount": 1100,
    "term": 7,
    "fees": 220,
    "borrowed": 1320,
    "repayment": 55,
    "total": 1628
  },
  {
    "amount": 1150,
    "term": 7,
    "fees": 230,
    "borrowed": 1380,
    "repayment": 57,
    "total": 1702
  },
  {
    "amount": 1200,
    "term": 7,
    "fees": 240,
    "borrowed": 1440,
    "repayment": 60,
    "total": 1776
  },
  {
    "amount": 1250,
    "term": 7,
    "fees": 250,
    "borrowed": 1500,
    "repayment": 62,
    "total": 1850
  },
  {
    "amount": 1300,
    "term": 7,
    "fees": 260,
    "borrowed": 1560,
    "repayment": 65,
    "total": 1924
  },
  {
    "amount": 1350,
    "term": 7,
    "fees": 270,
    "borrowed": 1620,
    "repayment": 67,
    "total": 1998
  },
  {
    "amount": 1400,
    "term": 7,
    "fees": 280,
    "borrowed": 1680,
    "repayment": 70,
    "total": 2072
  },
  {
    "amount": 1450,
    "term": 7,
    "fees": 290,
    "borrowed": 1740,
    "repayment": 72,
    "total": 2146
  },
  {
    "amount": 1500,
    "term": 7,
    "fees": 300,
    "borrowed": 1800,
    "repayment": 74,
    "total": 2220
  },
  {
    "amount": 1550,
    "term": 7,
    "fees": 310,
    "borrowed": 1860,
    "repayment": 77,
    "total": 2294
  },
  {
    "amount": 1600,
    "term": 7,
    "fees": 320,
    "borrowed": 1920,
    "repayment": 79,
    "total": 2368
  },
  {
    "amount": 1650,
    "term": 7,
    "fees": 330,
    "borrowed": 1980,
    "repayment": 82,
    "total": 2442
  },
  {
    "amount": 1700,
    "term": 7,
    "fees": 340,
    "borrowed": 2040,
    "repayment": 84,
    "total": 2516
  },
  {
    "amount": 1750,
    "term": 7,
    "fees": 350,
    "borrowed": 2100,
    "repayment": 87,
    "total": 2590
  },
  {
    "amount": 1800,
    "term": 7,
    "fees": 360,
    "borrowed": 2160,
    "repayment": 89,
    "total": 2664
  },
  {
    "amount": 1850,
    "term": 7,
    "fees": 370,
    "borrowed": 2220,
    "repayment": 92,
    "total": 2738
  },
  {
    "amount": 1900,
    "term": 7,
    "fees": 380,
    "borrowed": 2280,
    "repayment": 94,
    "total": 2812
  },
  {
    "amount": 1950,
    "term": 7,
    "fees": 390,
    "borrowed": 2340,
    "repayment": 97,
    "total": 2886
  },
  {
    "amount": 2000,
    "term": 7,
    "fees": 400,
    "borrowed": 2400,
    "repayment": 99,
    "total": 2960
  },
  {
    "amount": 2050,
    "term": 7,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 95,
    "total": 2825
  },
  {
    "amount": 2100,
    "term": 7,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 97,
    "total": 2883
  },
  {
    "amount": 2150,
    "term": 7,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 98,
    "total": 2940
  },
  {
    "amount": 2200,
    "term": 7,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 100,
    "total": 2997
  },
  {
    "amount": 2250,
    "term": 7,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 102,
    "total": 3055
  },
  {
    "amount": 2300,
    "term": 7,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 104,
    "total": 3112
  },
  {
    "amount": 2350,
    "term": 7,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 106,
    "total": 3170
  },
  {
    "amount": 2400,
    "term": 7,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 108,
    "total": 3227
  },
  {
    "amount": 2450,
    "term": 7,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 110,
    "total": 3285
  },
  {
    "amount": 2500,
    "term": 7,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 112,
    "total": 3342
  },
  {
    "amount": 2550,
    "term": 7,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 114,
    "total": 3399
  },
  {
    "amount": 2600,
    "term": 7,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 116,
    "total": 3457
  },
  {
    "amount": 2650,
    "term": 7,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 118,
    "total": 3514
  },
  {
    "amount": 2700,
    "term": 7,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 120,
    "total": 3572
  },
  {
    "amount": 2750,
    "term": 7,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 121,
    "total": 3629
  },
  {
    "amount": 2800,
    "term": 7,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 123,
    "total": 3687
  },
  {
    "amount": 2850,
    "term": 7,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 125,
    "total": 3744
  },
  {
    "amount": 2900,
    "term": 7,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 127,
    "total": 3801
  },
  {
    "amount": 2950,
    "term": 7,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 129,
    "total": 3859
  },
  {
    "amount": 3000,
    "term": 7,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 131,
    "total": 3916
  },
  {
    "amount": 3050,
    "term": 7,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 133,
    "total": 3974
  },
  {
    "amount": 3100,
    "term": 7,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 135,
    "total": 4031
  },
  {
    "amount": 3150,
    "term": 7,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 137,
    "total": 4089
  },
  {
    "amount": 3200,
    "term": 7,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 139,
    "total": 4146
  },
  {
    "amount": 3250,
    "term": 7,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 141,
    "total": 4204
  },
  {
    "amount": 3300,
    "term": 7,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 143,
    "total": 4261
  },
  {
    "amount": 3350,
    "term": 7,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 144,
    "total": 4319
  },
  {
    "amount": 3400,
    "term": 7,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 146,
    "total": 4376
  },
  {
    "amount": 3450,
    "term": 7,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 148,
    "total": 4434
  },
  {
    "amount": 3500,
    "term": 7,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 150,
    "total": 4491
  },
  {
    "amount": 3550,
    "term": 7,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 152,
    "total": 4548
  },
  {
    "amount": 3600,
    "term": 7,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 154,
    "total": 4606
  },
  {
    "amount": 3650,
    "term": 7,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 156,
    "total": 4663
  },
  {
    "amount": 3700,
    "term": 7,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 158,
    "total": 4721
  },
  {
    "amount": 3750,
    "term": 7,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 160,
    "total": 4778
  },
  {
    "amount": 3800,
    "term": 7,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 162,
    "total": 4836
  },
  {
    "amount": 3850,
    "term": 7,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 164,
    "total": 4893
  },
  {
    "amount": 3900,
    "term": 7,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 165,
    "total": 4950
  },
  {
    "amount": 3950,
    "term": 7,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 167,
    "total": 5008
  },
  {
    "amount": 4000,
    "term": 7,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 169,
    "total": 5065
  },
  {
    "amount": 4050,
    "term": 7,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 171,
    "total": 5123
  },
  {
    "amount": 4100,
    "term": 7,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 173,
    "total": 5180
  },
  {
    "amount": 4150,
    "term": 7,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 175,
    "total": 5238
  },
  {
    "amount": 4200,
    "term": 7,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 177,
    "total": 5295
  },
  {
    "amount": 4250,
    "term": 7,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 179,
    "total": 5352
  },
  {
    "amount": 4300,
    "term": 7,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 181,
    "total": 5410
  },
  {
    "amount": 4350,
    "term": 7,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 183,
    "total": 5467
  },
  {
    "amount": 4400,
    "term": 7,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 185,
    "total": 5525
  },
  {
    "amount": 4450,
    "term": 7,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 187,
    "total": 5582
  },
  {
    "amount": 4500,
    "term": 7,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 188,
    "total": 5640
  },
  {
    "amount": 4550,
    "term": 7,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 190,
    "total": 5697
  },
  {
    "amount": 4600,
    "term": 7,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 192,
    "total": 5755
  },
  {
    "amount": 400,
    "term": 8,
    "fees": 80,
    "borrowed": 480,
    "repayment": 18,
    "total": 608
  },
  {
    "amount": 450,
    "term": 8,
    "fees": 90,
    "borrowed": 540,
    "repayment": 21,
    "total": 684
  },
  {
    "amount": 500,
    "term": 8,
    "fees": 100,
    "borrowed": 600,
    "repayment": 23,
    "total": 760
  },
  {
    "amount": 550,
    "term": 8,
    "fees": 110,
    "borrowed": 660,
    "repayment": 25,
    "total": 836
  },
  {
    "amount": 600,
    "term": 8,
    "fees": 120,
    "borrowed": 720,
    "repayment": 27,
    "total": 912
  },
  {
    "amount": 650,
    "term": 8,
    "fees": 130,
    "borrowed": 780,
    "repayment": 30,
    "total": 988
  },
  {
    "amount": 700,
    "term": 8,
    "fees": 140,
    "borrowed": 840,
    "repayment": 32,
    "total": 1064
  },
  {
    "amount": 750,
    "term": 8,
    "fees": 150,
    "borrowed": 900,
    "repayment": 34,
    "total": 1140
  },
  {
    "amount": 800,
    "term": 8,
    "fees": 160,
    "borrowed": 960,
    "repayment": 36,
    "total": 1216
  },
  {
    "amount": 850,
    "term": 8,
    "fees": 170,
    "borrowed": 1020,
    "repayment": 38,
    "total": 1292
  },
  {
    "amount": 900,
    "term": 8,
    "fees": 180,
    "borrowed": 1080,
    "repayment": 41,
    "total": 1368
  },
  {
    "amount": 950,
    "term": 8,
    "fees": 190,
    "borrowed": 1140,
    "repayment": 43,
    "total": 1444
  },
  {
    "amount": 1000,
    "term": 8,
    "fees": 200,
    "borrowed": 1200,
    "repayment": 45,
    "total": 1520
  },
  {
    "amount": 1050,
    "term": 8,
    "fees": 210,
    "borrowed": 1260,
    "repayment": 47,
    "total": 1596
  },
  {
    "amount": 1100,
    "term": 8,
    "fees": 220,
    "borrowed": 1320,
    "repayment": 50,
    "total": 1672
  },
  {
    "amount": 1150,
    "term": 8,
    "fees": 230,
    "borrowed": 1380,
    "repayment": 52,
    "total": 1748
  },
  {
    "amount": 1200,
    "term": 8,
    "fees": 240,
    "borrowed": 1440,
    "repayment": 54,
    "total": 1824
  },
  {
    "amount": 1250,
    "term": 8,
    "fees": 250,
    "borrowed": 1500,
    "repayment": 56,
    "total": 1900
  },
  {
    "amount": 1300,
    "term": 8,
    "fees": 260,
    "borrowed": 1560,
    "repayment": 59,
    "total": 1976
  },
  {
    "amount": 1350,
    "term": 8,
    "fees": 270,
    "borrowed": 1620,
    "repayment": 61,
    "total": 2052
  },
  {
    "amount": 1400,
    "term": 8,
    "fees": 280,
    "borrowed": 1680,
    "repayment": 63,
    "total": 2128
  },
  {
    "amount": 1450,
    "term": 8,
    "fees": 290,
    "borrowed": 1740,
    "repayment": 65,
    "total": 2204
  },
  {
    "amount": 1500,
    "term": 8,
    "fees": 300,
    "borrowed": 1800,
    "repayment": 68,
    "total": 2280
  },
  {
    "amount": 1550,
    "term": 8,
    "fees": 310,
    "borrowed": 1860,
    "repayment": 70,
    "total": 2356
  },
  {
    "amount": 1600,
    "term": 8,
    "fees": 320,
    "borrowed": 1920,
    "repayment": 72,
    "total": 2432
  },
  {
    "amount": 1650,
    "term": 8,
    "fees": 330,
    "borrowed": 1980,
    "repayment": 74,
    "total": 2508
  },
  {
    "amount": 1700,
    "term": 8,
    "fees": 340,
    "borrowed": 2040,
    "repayment": 76,
    "total": 2584
  },
  {
    "amount": 1750,
    "term": 8,
    "fees": 350,
    "borrowed": 2100,
    "repayment": 79,
    "total": 2660
  },
  {
    "amount": 1800,
    "term": 8,
    "fees": 360,
    "borrowed": 2160,
    "repayment": 81,
    "total": 2736
  },
  {
    "amount": 1850,
    "term": 8,
    "fees": 370,
    "borrowed": 2220,
    "repayment": 83,
    "total": 2812
  },
  {
    "amount": 1900,
    "term": 8,
    "fees": 380,
    "borrowed": 2280,
    "repayment": 85,
    "total": 2888
  },
  {
    "amount": 1950,
    "term": 8,
    "fees": 390,
    "borrowed": 2340,
    "repayment": 88,
    "total": 2964
  },
  {
    "amount": 2000,
    "term": 8,
    "fees": 400,
    "borrowed": 2400,
    "repayment": 90,
    "total": 3040
  },
  {
    "amount": 2050,
    "term": 8,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 85,
    "total": 2876
  },
  {
    "amount": 2100,
    "term": 8,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 87,
    "total": 2934
  },
  {
    "amount": 2150,
    "term": 8,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 89,
    "total": 2993
  },
  {
    "amount": 2200,
    "term": 8,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 90,
    "total": 3051
  },
  {
    "amount": 2250,
    "term": 8,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 92,
    "total": 3109
  },
  {
    "amount": 2300,
    "term": 8,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 94,
    "total": 3168
  },
  {
    "amount": 2350,
    "term": 8,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 95,
    "total": 3226
  },
  {
    "amount": 2400,
    "term": 8,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 97,
    "total": 3285
  },
  {
    "amount": 2450,
    "term": 8,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 99,
    "total": 3343
  },
  {
    "amount": 2500,
    "term": 8,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 101,
    "total": 3402
  },
  {
    "amount": 2550,
    "term": 8,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 102,
    "total": 3460
  },
  {
    "amount": 2600,
    "term": 8,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 104,
    "total": 3519
  },
  {
    "amount": 2650,
    "term": 8,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 106,
    "total": 3577
  },
  {
    "amount": 2700,
    "term": 8,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 107,
    "total": 3636
  },
  {
    "amount": 2750,
    "term": 8,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 109,
    "total": 3694
  },
  {
    "amount": 2800,
    "term": 8,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 111,
    "total": 3753
  },
  {
    "amount": 2850,
    "term": 8,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 113,
    "total": 3811
  },
  {
    "amount": 2900,
    "term": 8,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 114,
    "total": 3869
  },
  {
    "amount": 2950,
    "term": 8,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 116,
    "total": 3928
  },
  {
    "amount": 3000,
    "term": 8,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 118,
    "total": 3986
  },
  {
    "amount": 3050,
    "term": 8,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 119,
    "total": 4045
  },
  {
    "amount": 3100,
    "term": 8,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 121,
    "total": 4103
  },
  {
    "amount": 3150,
    "term": 8,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 123,
    "total": 4162
  },
  {
    "amount": 3200,
    "term": 8,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 125,
    "total": 4220
  },
  {
    "amount": 3250,
    "term": 8,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 126,
    "total": 4279
  },
  {
    "amount": 3300,
    "term": 8,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 128,
    "total": 4337
  },
  {
    "amount": 3350,
    "term": 8,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 130,
    "total": 4396
  },
  {
    "amount": 3400,
    "term": 8,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 131,
    "total": 4454
  },
  {
    "amount": 3450,
    "term": 8,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 133,
    "total": 4513
  },
  {
    "amount": 3500,
    "term": 8,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 135,
    "total": 4571
  },
  {
    "amount": 3550,
    "term": 8,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 137,
    "total": 4629
  },
  {
    "amount": 3600,
    "term": 8,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 138,
    "total": 4688
  },
  {
    "amount": 3650,
    "term": 8,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 140,
    "total": 4746
  },
  {
    "amount": 3700,
    "term": 8,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 142,
    "total": 4805
  },
  {
    "amount": 3750,
    "term": 8,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 144,
    "total": 4863
  },
  {
    "amount": 3800,
    "term": 8,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 145,
    "total": 4922
  },
  {
    "amount": 3850,
    "term": 8,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 147,
    "total": 4980
  },
  {
    "amount": 3900,
    "term": 8,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 149,
    "total": 5039
  },
  {
    "amount": 3950,
    "term": 8,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 150,
    "total": 5097
  },
  {
    "amount": 4000,
    "term": 8,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 152,
    "total": 5156
  },
  {
    "amount": 4050,
    "term": 8,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 154,
    "total": 5214
  },
  {
    "amount": 4100,
    "term": 8,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 156,
    "total": 5273
  },
  {
    "amount": 4150,
    "term": 8,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 157,
    "total": 5331
  },
  {
    "amount": 4200,
    "term": 8,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 159,
    "total": 5389
  },
  {
    "amount": 4250,
    "term": 8,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 161,
    "total": 5448
  },
  {
    "amount": 4300,
    "term": 8,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 162,
    "total": 5506
  },
  {
    "amount": 4350,
    "term": 8,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 164,
    "total": 5565
  },
  {
    "amount": 4400,
    "term": 8,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 166,
    "total": 5623
  },
  {
    "amount": 4450,
    "term": 8,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 168,
    "total": 5682
  },
  {
    "amount": 4500,
    "term": 8,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 169,
    "total": 5740
  },
  {
    "amount": 4550,
    "term": 8,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 171,
    "total": 5799
  },
  {
    "amount": 4600,
    "term": 8,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 173,
    "total": 5857
  },
  {
    "amount": 400,
    "term": 9,
    "fees": 80,
    "borrowed": 480,
    "repayment": 17,
    "total": 624
  },
  {
    "amount": 450,
    "term": 9,
    "fees": 90,
    "borrowed": 540,
    "repayment": 19,
    "total": 702
  },
  {
    "amount": 500,
    "term": 9,
    "fees": 100,
    "borrowed": 600,
    "repayment": 21,
    "total": 780
  },
  {
    "amount": 550,
    "term": 9,
    "fees": 110,
    "borrowed": 660,
    "repayment": 23,
    "total": 858
  },
  {
    "amount": 600,
    "term": 9,
    "fees": 120,
    "borrowed": 720,
    "repayment": 25,
    "total": 936
  },
  {
    "amount": 650,
    "term": 9,
    "fees": 130,
    "borrowed": 780,
    "repayment": 27,
    "total": 1014
  },
  {
    "amount": 700,
    "term": 9,
    "fees": 140,
    "borrowed": 840,
    "repayment": 29,
    "total": 1092
  },
  {
    "amount": 750,
    "term": 9,
    "fees": 150,
    "borrowed": 900,
    "repayment": 31,
    "total": 1170
  },
  {
    "amount": 800,
    "term": 9,
    "fees": 160,
    "borrowed": 960,
    "repayment": 33,
    "total": 1248
  },
  {
    "amount": 850,
    "term": 9,
    "fees": 170,
    "borrowed": 1020,
    "repayment": 35,
    "total": 1326
  },
  {
    "amount": 900,
    "term": 9,
    "fees": 180,
    "borrowed": 1080,
    "repayment": 37,
    "total": 1404
  },
  {
    "amount": 950,
    "term": 9,
    "fees": 190,
    "borrowed": 1140,
    "repayment": 39,
    "total": 1482
  },
  {
    "amount": 1000,
    "term": 9,
    "fees": 200,
    "borrowed": 1200,
    "repayment": 42,
    "total": 1560
  },
  {
    "amount": 1050,
    "term": 9,
    "fees": 210,
    "borrowed": 1260,
    "repayment": 44,
    "total": 1638
  },
  {
    "amount": 1100,
    "term": 9,
    "fees": 220,
    "borrowed": 1320,
    "repayment": 46,
    "total": 1716
  },
  {
    "amount": 1150,
    "term": 9,
    "fees": 230,
    "borrowed": 1380,
    "repayment": 48,
    "total": 1794
  },
  {
    "amount": 1200,
    "term": 9,
    "fees": 240,
    "borrowed": 1440,
    "repayment": 50,
    "total": 1872
  },
  {
    "amount": 1250,
    "term": 9,
    "fees": 250,
    "borrowed": 1500,
    "repayment": 52,
    "total": 1950
  },
  {
    "amount": 1300,
    "term": 9,
    "fees": 260,
    "borrowed": 1560,
    "repayment": 54,
    "total": 2028
  },
  {
    "amount": 1350,
    "term": 9,
    "fees": 270,
    "borrowed": 1620,
    "repayment": 56,
    "total": 2106
  },
  {
    "amount": 1400,
    "term": 9,
    "fees": 280,
    "borrowed": 1680,
    "repayment": 58,
    "total": 2184
  },
  {
    "amount": 1450,
    "term": 9,
    "fees": 290,
    "borrowed": 1740,
    "repayment": 60,
    "total": 2262
  },
  {
    "amount": 1500,
    "term": 9,
    "fees": 300,
    "borrowed": 1800,
    "repayment": 62,
    "total": 2340
  },
  {
    "amount": 1550,
    "term": 9,
    "fees": 310,
    "borrowed": 1860,
    "repayment": 64,
    "total": 2418
  },
  {
    "amount": 1600,
    "term": 9,
    "fees": 320,
    "borrowed": 1920,
    "repayment": 66,
    "total": 2496
  },
  {
    "amount": 1650,
    "term": 9,
    "fees": 330,
    "borrowed": 1980,
    "repayment": 68,
    "total": 2574
  },
  {
    "amount": 1700,
    "term": 9,
    "fees": 340,
    "borrowed": 2040,
    "repayment": 70,
    "total": 2652
  },
  {
    "amount": 1750,
    "term": 9,
    "fees": 350,
    "borrowed": 2100,
    "repayment": 72,
    "total": 2730
  },
  {
    "amount": 1800,
    "term": 9,
    "fees": 360,
    "borrowed": 2160,
    "repayment": 74,
    "total": 2808
  },
  {
    "amount": 1850,
    "term": 9,
    "fees": 370,
    "borrowed": 2220,
    "repayment": 76,
    "total": 2886
  },
  {
    "amount": 1900,
    "term": 9,
    "fees": 380,
    "borrowed": 2280,
    "repayment": 78,
    "total": 2964
  },
  {
    "amount": 1950,
    "term": 9,
    "fees": 390,
    "borrowed": 2340,
    "repayment": 81,
    "total": 3042
  },
  {
    "amount": 2000,
    "term": 9,
    "fees": 400,
    "borrowed": 2400,
    "repayment": 83,
    "total": 3120
  },
  {
    "amount": 2050,
    "term": 9,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 78,
    "total": 2927
  },
  {
    "amount": 2100,
    "term": 9,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 79,
    "total": 2987
  },
  {
    "amount": 2150,
    "term": 9,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 81,
    "total": 3046
  },
  {
    "amount": 2200,
    "term": 9,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 82,
    "total": 3105
  },
  {
    "amount": 2250,
    "term": 9,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 84,
    "total": 3165
  },
  {
    "amount": 2300,
    "term": 9,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 85,
    "total": 3224
  },
  {
    "amount": 2350,
    "term": 9,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 87,
    "total": 3284
  },
  {
    "amount": 2400,
    "term": 9,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 88,
    "total": 3343
  },
  {
    "amount": 2450,
    "term": 9,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 90,
    "total": 3403
  },
  {
    "amount": 2500,
    "term": 9,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 92,
    "total": 3462
  },
  {
    "amount": 2550,
    "term": 9,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 93,
    "total": 3522
  },
  {
    "amount": 2600,
    "term": 9,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 95,
    "total": 3581
  },
  {
    "amount": 2650,
    "term": 9,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 96,
    "total": 3641
  },
  {
    "amount": 2700,
    "term": 9,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 98,
    "total": 3700
  },
  {
    "amount": 2750,
    "term": 9,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 99,
    "total": 3760
  },
  {
    "amount": 2800,
    "term": 9,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 101,
    "total": 3819
  },
  {
    "amount": 2850,
    "term": 9,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 103,
    "total": 3879
  },
  {
    "amount": 2900,
    "term": 9,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 104,
    "total": 3938
  },
  {
    "amount": 2950,
    "term": 9,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 106,
    "total": 3998
  },
  {
    "amount": 3000,
    "term": 9,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 107,
    "total": 4057
  },
  {
    "amount": 3050,
    "term": 9,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 109,
    "total": 4117
  },
  {
    "amount": 3100,
    "term": 9,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 110,
    "total": 4176
  },
  {
    "amount": 3150,
    "term": 9,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 112,
    "total": 4236
  },
  {
    "amount": 3200,
    "term": 9,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 114,
    "total": 4295
  },
  {
    "amount": 3250,
    "term": 9,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 115,
    "total": 4355
  },
  {
    "amount": 3300,
    "term": 9,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 117,
    "total": 4414
  },
  {
    "amount": 3350,
    "term": 9,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 118,
    "total": 4473
  },
  {
    "amount": 3400,
    "term": 9,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 120,
    "total": 4533
  },
  {
    "amount": 3450,
    "term": 9,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 121,
    "total": 4592
  },
  {
    "amount": 3500,
    "term": 9,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 123,
    "total": 4652
  },
  {
    "amount": 3550,
    "term": 9,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 124,
    "total": 4711
  },
  {
    "amount": 3600,
    "term": 9,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 126,
    "total": 4771
  },
  {
    "amount": 3650,
    "term": 9,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 128,
    "total": 4830
  },
  {
    "amount": 3700,
    "term": 9,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 129,
    "total": 4890
  },
  {
    "amount": 3750,
    "term": 9,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 131,
    "total": 4949
  },
  {
    "amount": 3800,
    "term": 9,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 132,
    "total": 5009
  },
  {
    "amount": 3850,
    "term": 9,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 134,
    "total": 5068
  },
  {
    "amount": 3900,
    "term": 9,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 135,
    "total": 5127
  },
  {
    "amount": 3950,
    "term": 9,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 137,
    "total": 5187
  },
  {
    "amount": 4000,
    "term": 9,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 139,
    "total": 5247
  },
  {
    "amount": 4050,
    "term": 9,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 140,
    "total": 5306
  },
  {
    "amount": 4100,
    "term": 9,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 142,
    "total": 5366
  },
  {
    "amount": 4150,
    "term": 9,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 143,
    "total": 5425
  },
  {
    "amount": 4200,
    "term": 9,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 145,
    "total": 5485
  },
  {
    "amount": 4250,
    "term": 9,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 146,
    "total": 5544
  },
  {
    "amount": 4300,
    "term": 9,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 148,
    "total": 5604
  },
  {
    "amount": 4350,
    "term": 9,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 150,
    "total": 5663
  },
  {
    "amount": 4400,
    "term": 9,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 151,
    "total": 5723
  },
  {
    "amount": 4450,
    "term": 9,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 153,
    "total": 5782
  },
  {
    "amount": 4500,
    "term": 9,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 154,
    "total": 5841
  },
  {
    "amount": 4550,
    "term": 9,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 156,
    "total": 5901
  },
  {
    "amount": 4600,
    "term": 9,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 157,
    "total": 5960
  },
  {
    "amount": 400,
    "term": 10,
    "fees": 80,
    "borrowed": 480,
    "repayment": 15,
    "total": 640
  },
  {
    "amount": 450,
    "term": 10,
    "fees": 90,
    "borrowed": 540,
    "repayment": 17,
    "total": 720
  },
  {
    "amount": 500,
    "term": 10,
    "fees": 100,
    "borrowed": 600,
    "repayment": 19,
    "total": 800
  },
  {
    "amount": 550,
    "term": 10,
    "fees": 110,
    "borrowed": 660,
    "repayment": 21,
    "total": 880
  },
  {
    "amount": 600,
    "term": 10,
    "fees": 120,
    "borrowed": 720,
    "repayment": 23,
    "total": 960
  },
  {
    "amount": 650,
    "term": 10,
    "fees": 130,
    "borrowed": 780,
    "repayment": 25,
    "total": 1040
  },
  {
    "amount": 700,
    "term": 10,
    "fees": 140,
    "borrowed": 840,
    "repayment": 27,
    "total": 1120
  },
  {
    "amount": 750,
    "term": 10,
    "fees": 150,
    "borrowed": 900,
    "repayment": 28,
    "total": 1200
  },
  {
    "amount": 800,
    "term": 10,
    "fees": 160,
    "borrowed": 960,
    "repayment": 30,
    "total": 1280
  },
  {
    "amount": 850,
    "term": 10,
    "fees": 170,
    "borrowed": 1020,
    "repayment": 32,
    "total": 1360
  },
  {
    "amount": 900,
    "term": 10,
    "fees": 180,
    "borrowed": 1080,
    "repayment": 34,
    "total": 1440
  },
  {
    "amount": 950,
    "term": 10,
    "fees": 190,
    "borrowed": 1140,
    "repayment": 36,
    "total": 1520
  },
  {
    "amount": 1000,
    "term": 10,
    "fees": 200,
    "borrowed": 1200,
    "repayment": 38,
    "total": 1600
  },
  {
    "amount": 1050,
    "term": 10,
    "fees": 210,
    "borrowed": 1260,
    "repayment": 40,
    "total": 1680
  },
  {
    "amount": 1100,
    "term": 10,
    "fees": 220,
    "borrowed": 1320,
    "repayment": 41,
    "total": 1760
  },
  {
    "amount": 1150,
    "term": 10,
    "fees": 230,
    "borrowed": 1380,
    "repayment": 43,
    "total": 1840
  },
  {
    "amount": 1200,
    "term": 10,
    "fees": 240,
    "borrowed": 1440,
    "repayment": 45,
    "total": 1920
  },
  {
    "amount": 1250,
    "term": 10,
    "fees": 250,
    "borrowed": 1500,
    "repayment": 47,
    "total": 2000
  },
  {
    "amount": 1300,
    "term": 10,
    "fees": 260,
    "borrowed": 1560,
    "repayment": 49,
    "total": 2080
  },
  {
    "amount": 1350,
    "term": 10,
    "fees": 270,
    "borrowed": 1620,
    "repayment": 51,
    "total": 2160
  },
  {
    "amount": 1400,
    "term": 10,
    "fees": 280,
    "borrowed": 1680,
    "repayment": 53,
    "total": 2240
  },
  {
    "amount": 1450,
    "term": 10,
    "fees": 290,
    "borrowed": 1740,
    "repayment": 54,
    "total": 2320
  },
  {
    "amount": 1500,
    "term": 10,
    "fees": 300,
    "borrowed": 1800,
    "repayment": 56,
    "total": 2400
  },
  {
    "amount": 1550,
    "term": 10,
    "fees": 310,
    "borrowed": 1860,
    "repayment": 58,
    "total": 2480
  },
  {
    "amount": 1600,
    "term": 10,
    "fees": 320,
    "borrowed": 1920,
    "repayment": 60,
    "total": 2560
  },
  {
    "amount": 1650,
    "term": 10,
    "fees": 330,
    "borrowed": 1980,
    "repayment": 62,
    "total": 2640
  },
  {
    "amount": 1700,
    "term": 10,
    "fees": 340,
    "borrowed": 2040,
    "repayment": 64,
    "total": 2720
  },
  {
    "amount": 1750,
    "term": 10,
    "fees": 350,
    "borrowed": 2100,
    "repayment": 66,
    "total": 2800
  },
  {
    "amount": 1800,
    "term": 10,
    "fees": 360,
    "borrowed": 2160,
    "repayment": 67,
    "total": 2880
  },
  {
    "amount": 1850,
    "term": 10,
    "fees": 370,
    "borrowed": 2220,
    "repayment": 69,
    "total": 2960
  },
  {
    "amount": 1900,
    "term": 10,
    "fees": 380,
    "borrowed": 2280,
    "repayment": 71,
    "total": 3040
  },
  {
    "amount": 1950,
    "term": 10,
    "fees": 390,
    "borrowed": 2340,
    "repayment": 73,
    "total": 3120
  },
  {
    "amount": 2000,
    "term": 10,
    "fees": 400,
    "borrowed": 2400,
    "repayment": 75,
    "total": 3200
  },
  {
    "amount": 2050,
    "term": 10,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 70,
    "total": 2986
  },
  {
    "amount": 2100,
    "term": 10,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 71,
    "total": 3047
  },
  {
    "amount": 2150,
    "term": 10,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 73,
    "total": 3108
  },
  {
    "amount": 2200,
    "term": 10,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 74,
    "total": 3168
  },
  {
    "amount": 2250,
    "term": 10,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 76,
    "total": 3229
  },
  {
    "amount": 2300,
    "term": 10,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 77,
    "total": 3290
  },
  {
    "amount": 2350,
    "term": 10,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 78,
    "total": 3351
  },
  {
    "amount": 2400,
    "term": 10,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 80,
    "total": 3411
  },
  {
    "amount": 2450,
    "term": 10,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 81,
    "total": 3472
  },
  {
    "amount": 2500,
    "term": 10,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 83,
    "total": 3533
  },
  {
    "amount": 2550,
    "term": 10,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 84,
    "total": 3594
  },
  {
    "amount": 2600,
    "term": 10,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 85,
    "total": 3654
  },
  {
    "amount": 2650,
    "term": 10,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 87,
    "total": 3715
  },
  {
    "amount": 2700,
    "term": 10,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 88,
    "total": 3776
  },
  {
    "amount": 2750,
    "term": 10,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 90,
    "total": 3837
  },
  {
    "amount": 2800,
    "term": 10,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 91,
    "total": 3898
  },
  {
    "amount": 2850,
    "term": 10,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 93,
    "total": 3958
  },
  {
    "amount": 2900,
    "term": 10,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 94,
    "total": 4019
  },
  {
    "amount": 2950,
    "term": 10,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 95,
    "total": 4080
  },
  {
    "amount": 3000,
    "term": 10,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 97,
    "total": 4141
  },
  {
    "amount": 3050,
    "term": 10,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 98,
    "total": 4201
  },
  {
    "amount": 3100,
    "term": 10,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 100,
    "total": 4262
  },
  {
    "amount": 3150,
    "term": 10,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 101,
    "total": 4323
  },
  {
    "amount": 3200,
    "term": 10,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 102,
    "total": 4383
  },
  {
    "amount": 3250,
    "term": 10,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 104,
    "total": 4445
  },
  {
    "amount": 3300,
    "term": 10,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 105,
    "total": 4505
  },
  {
    "amount": 3350,
    "term": 10,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 107,
    "total": 4566
  },
  {
    "amount": 3400,
    "term": 10,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 108,
    "total": 4626
  },
  {
    "amount": 3450,
    "term": 10,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 109,
    "total": 4687
  },
  {
    "amount": 3500,
    "term": 10,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 111,
    "total": 4748
  },
  {
    "amount": 3550,
    "term": 10,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 112,
    "total": 4809
  },
  {
    "amount": 3600,
    "term": 10,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 114,
    "total": 4870
  },
  {
    "amount": 3650,
    "term": 10,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 115,
    "total": 4930
  },
  {

    "amount": 3700,
    "term": 10,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 117,
    "total": 4991
  },
  {
    "amount": 3750,
    "term": 10,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 118,
    "total": 5052
  },
  {
    "amount": 3800,
    "term": 10,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 119,
    "total": 5113
  },
  {
    "amount": 3850,
    "term": 10,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 121,
    "total": 5173
  },
  {
    "amount": 3900,
    "term": 10,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 122,
    "total": 5234
  },
  {
    "amount": 3950,
    "term": 10,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 124,
    "total": 5295
  },
  {
    "amount": 4000,
    "term": 10,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 125,
    "total": 5356
  },
  {
    "amount": 4050,
    "term": 10,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 126,
    "total": 5417
  },
  {
    "amount": 4100,
    "term": 10,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 128,
    "total": 5477
  },
  {
    "amount": 4150,
    "term": 10,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 129,
    "total": 5538
  },
  {
    "amount": 4200,
    "term": 10,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 131,
    "total": 5599
  },
  {
    "amount": 4250,
    "term": 10,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 132,
    "total": 5660
  },
  {
    "amount": 4300,
    "term": 10,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 134,
    "total": 5720
  },
  {
    "amount": 4350,
    "term": 10,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 135,
    "total": 5781
  },
  {
    "amount": 4400,
    "term": 10,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 136,
    "total": 5842
  },
  {
    "amount": 4450,
    "term": 10,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 138,
    "total": 5903
  },
  {
    "amount": 4500,
    "term": 10,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 139,
    "total": 5963
  },
  {
    "amount": 4550,
    "term": 10,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 141,
    "total": 6024
  },
  {
    "amount": 4600,
    "term": 10,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 142,
    "total": 6085
  },
  {
    "amount": 400,
    "term": 11,
    "fees": 80,
    "borrowed": 480,
    "repayment": 14,
    "total": 656
  },
  {
    "amount": 450,
    "term": 11,
    "fees": 90,
    "borrowed": 540,
    "repayment": 16,
    "total": 738
  },
  {
    "amount": 500,
    "term": 11,
    "fees": 100,
    "borrowed": 600,
    "repayment": 18,
    "total": 820
  },
  {
    "amount": 550,
    "term": 11,
    "fees": 110,
    "borrowed": 660,
    "repayment": 20,
    "total": 902
  },
  {
    "amount": 600,
    "term": 11,
    "fees": 120,
    "borrowed": 720,
    "repayment": 21,
    "total": 984
  },
  {
    "amount": 650,
    "term": 11,
    "fees": 130,
    "borrowed": 780,
    "repayment": 23,
    "total": 1066
  },
  {
    "amount": 700,
    "term": 11,
    "fees": 140,
    "borrowed": 840,
    "repayment": 25,
    "total": 1148
  },
  {
    "amount": 750,
    "term": 11,
    "fees": 150,
    "borrowed": 900,
    "repayment": 27,
    "total": 1230
  },
  {
    "amount": 800,
    "term": 11,
    "fees": 160,
    "borrowed": 960,
    "repayment": 28,
    "total": 1312
  },
  {
    "amount": 850,
    "term": 11,
    "fees": 170,
    "borrowed": 1020,
    "repayment": 30,
    "total": 1394
  },
  {
    "amount": 900,
    "term": 11,
    "fees": 180,
    "borrowed": 1080,
    "repayment": 32,
    "total": 1476
  },
  {
    "amount": 950,
    "term": 11,
    "fees": 190,
    "borrowed": 1140,
    "repayment": 34,
    "total": 1558
  },
  {
    "amount": 1000,
    "term": 11,
    "fees": 200,
    "borrowed": 1200,
    "repayment": 35,
    "total": 1640
  },
  {
    "amount": 1050,
    "term": 11,
    "fees": 210,
    "borrowed": 1260,
    "repayment": 37,
    "total": 1722
  },
  {
    "amount": 1100,
    "term": 11,
    "fees": 220,
    "borrowed": 1320,
    "repayment": 39,
    "total": 1804
  },
  {
    "amount": 1150,
    "term": 11,
    "fees": 230,
    "borrowed": 1380,
    "repayment": 41,
    "total": 1886
  },
  {
    "amount": 1200,
    "term": 11,
    "fees": 240,
    "borrowed": 1440,
    "repayment": 42,
    "total": 1968
  },
  {
    "amount": 1250,
    "term": 11,
    "fees": 250,
    "borrowed": 1500,
    "repayment": 44,
    "total": 2050
  },
  {
    "amount": 1300,
    "term": 11,
    "fees": 260,
    "borrowed": 1560,
    "repayment": 46,
    "total": 2132
  },
  {
    "amount": 1350,
    "term": 11,
    "fees": 270,
    "borrowed": 1620,
    "repayment": 48,
    "total": 2214
  },
  {
    "amount": 1400,
    "term": 11,
    "fees": 280,
    "borrowed": 1680,
    "repayment": 49,
    "total": 2296
  },
  {
    "amount": 1450,
    "term": 11,
    "fees": 290,
    "borrowed": 1740,
    "repayment": 51,
    "total": 2378
  },
  {
    "amount": 1500,
    "term": 11,
    "fees": 300,
    "borrowed": 1800,
    "repayment": 53,
    "total": 2460
  },
  {
    "amount": 1550,
    "term": 11,
    "fees": 310,
    "borrowed": 1860,
    "repayment": 55,
    "total": 2542
  },
  {
    "amount": 1600,
    "term": 11,
    "fees": 320,
    "borrowed": 1920,
    "repayment": 56,
    "total": 2624
  },
  {
    "amount": 1650,
    "term": 11,
    "fees": 330,
    "borrowed": 1980,
    "repayment": 58,
    "total": 2706
  },
  {
    "amount": 1700,
    "term": 11,
    "fees": 340,
    "borrowed": 2040,
    "repayment": 60,
    "total": 2788
  },
  {
    "amount": 1750,
    "term": 11,
    "fees": 350,
    "borrowed": 2100,
    "repayment": 62,
    "total": 2870
  },
  {
    "amount": 1800,
    "term": 11,
    "fees": 360,
    "borrowed": 2160,
    "repayment": 63,
    "total": 2952
  },
  {
    "amount": 1850,
    "term": 11,
    "fees": 370,
    "borrowed": 2220,
    "repayment": 65,
    "total": 3034
  },
  {
    "amount": 1900,
    "term": 11,
    "fees": 380,
    "borrowed": 2280,
    "repayment": 67,
    "total": 3116
  },
  {
    "amount": 1950,
    "term": 11,
    "fees": 390,
    "borrowed": 2340,
    "repayment": 69,
    "total": 3198
  },
  {
    "amount": 2000,
    "term": 11,
    "fees": 400,
    "borrowed": 2400,
    "repayment": 70,
    "total": 3280
  },
  {
    "amount": 2050,
    "term": 11,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 65,
    "total": 3038
  },
  {
    "amount": 2100,
    "term": 11,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 66,
    "total": 3100
  },
  {
    "amount": 2150,
    "term": 11,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 68,
    "total": 3162
  },
  {
    "amount": 2200,
    "term": 11,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 69,
    "total": 3223
  },
  {
    "amount": 2250,
    "term": 11,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 70,
    "total": 3285
  },
  {
    "amount": 2300,
    "term": 11,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 72,
    "total": 3347
  },
  {
    "amount": 2350,
    "term": 11,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 73,
    "total": 3409
  },
  {
    "amount": 2400,
    "term": 11,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 74,
    "total": 3471
  },
  {
    "amount": 2450,
    "term": 11,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 76,
    "total": 3533
  },
  {
    "amount": 2500,
    "term": 11,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 77,
    "total": 3594
  },
  {
    "amount": 2550,
    "term": 11,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 78,
    "total": 3656
  },
  {
    "amount": 2600,
    "term": 11,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 80,
    "total": 3718
  },
  {
    "amount": 2650,
    "term": 11,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 81,
    "total": 3780
  },
  {
    "amount": 2700,
    "term": 11,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 82,
    "total": 3841
  },
  {
    "amount": 2750,
    "term": 11,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 84,
    "total": 3903
  },
  {
    "amount": 2800,
    "term": 11,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 85,
    "total": 3965
  },
  {
    "amount": 2850,
    "term": 11,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 86,
    "total": 4027
  },
  {
    "amount": 2900,
    "term": 11,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 87,
    "total": 4089
  },
  {
    "amount": 2950,
    "term": 11,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 89,
    "total": 4151
  },
  {
    "amount": 3000,
    "term": 11,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 90,
    "total": 4212
  },
  {
    "amount": 3050,
    "term": 11,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 91,
    "total": 4274
  },
  {
    "amount": 3100,
    "term": 11,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 93,
    "total": 4336
  },
  {
    "amount": 3150,
    "term": 11,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 94,
    "total": 4398
  },
  {
    "amount": 3200,
    "term": 11,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 95,
    "total": 4459
  },
  {
    "amount": 3250,
    "term": 11,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 97,
    "total": 4521
  },
  {
    "amount": 3300,
    "term": 11,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 98,
    "total": 4583
  },
  {
    "amount": 3350,
    "term": 11,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 99,
    "total": 4645
  },
  {
    "amount": 3400,
    "term": 11,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 101,
    "total": 4707
  },
  {
    "amount": 3450,
    "term": 11,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 102,
    "total": 4769
  },
  {
    "amount": 3500,
    "term": 11,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 103,
    "total": 4831
  },
  {
    "amount": 3550,
    "term": 11,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 105,
    "total": 4892
  },
  {
    "amount": 3600,
    "term": 11,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 106,
    "total": 4954
  },
  {
    "amount": 3650,
    "term": 11,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 107,
    "total": 5016
  },
  {
    "amount": 3700,
    "term": 11,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 109,
    "total": 5078
  },
  {
    "amount": 3750,
    "term": 11,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 110,
    "total": 5139
  },
  {
    "amount": 3800,
    "term": 11,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 111,
    "total": 5202
  },
  {
    "amount": 3850,
    "term": 11,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 112,
    "total": 5263
  },
  {
    "amount": 3900,
    "term": 11,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 114,
    "total": 5325
  },
  {
    "amount": 3950,
    "term": 11,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 115,
    "total": 5387
  },
  {
    "amount": 4000,
    "term": 11,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 116,
    "total": 5449
  },
  {
    "amount": 4050,
    "term": 11,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 118,
    "total": 5510
  },
  {
    "amount": 4100,
    "term": 11,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 119,
    "total": 5572
  },
  {
    "amount": 4150,
    "term": 11,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 120,
    "total": 5634
  },
  {
    "amount": 4200,
    "term": 11,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 122,
    "total": 5696
  },
  {
    "amount": 4250,
    "term": 11,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 123,
    "total": 5758
  },
  {
    "amount": 4300,
    "term": 11,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 124,
    "total": 5820
  },
  {
    "amount": 4350,
    "term": 11,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 126,
    "total": 5881
  },
  {
    "amount": 4400,
    "term": 11,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 127,
    "total": 5943
  },
  {
    "amount": 4450,
    "term": 11,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 128,
    "total": 6005
  },
  {
    "amount": 4500,
    "term": 11,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 130,
    "total": 6067
  },
  {
    "amount": 4550,
    "term": 11,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 131,
    "total": 6128
  },
  {
    "amount": 4600,
    "term": 11,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 132,
    "total": 6190
  },
  {
    "amount": 400,
    "term": 12,
    "fees": 80,
    "borrowed": 480,
    "repayment": 13,
    "total": 672
  },
  {
    "amount": 450,
    "term": 12,
    "fees": 90,
    "borrowed": 540,
    "repayment": 15,
    "total": 756
  },
  {
    "amount": 500,
    "term": 12,
    "fees": 100,
    "borrowed": 600,
    "repayment": 17,
    "total": 840
  },
  {
    "amount": 550,
    "term": 12,
    "fees": 110,
    "borrowed": 660,
    "repayment": 18,
    "total": 924
  },
  {
    "amount": 600,
    "term": 12,
    "fees": 120,
    "borrowed": 720,
    "repayment": 20,
    "total": 1008
  },
  {
    "amount": 650,
    "term": 12,
    "fees": 130,
    "borrowed": 780,
    "repayment": 21,
    "total": 1092
  },
  {
    "amount": 700,
    "term": 12,
    "fees": 140,
    "borrowed": 840,
    "repayment": 23,
    "total": 1176
  },
  {
    "amount": 750,
    "term": 12,
    "fees": 150,
    "borrowed": 900,
    "repayment": 25,
    "total": 1260
  },
  {
    "amount": 800,
    "term": 12,
    "fees": 160,
    "borrowed": 960,
    "repayment": 26,
    "total": 1344
  },
  {
    "amount": 850,
    "term": 12,
    "fees": 170,
    "borrowed": 1020,
    "repayment": 28,
    "total": 1428
  },
  {
    "amount": 900,
    "term": 12,
    "fees": 180,
    "borrowed": 1080,
    "repayment": 30,
    "total": 1512
  },
  {
    "amount": 950,
    "term": 12,
    "fees": 190,
    "borrowed": 1140,
    "repayment": 31,
    "total": 1596
  },
  {
    "amount": 1000,
    "term": 12,
    "fees": 200,
    "borrowed": 1200,
    "repayment": 33,
    "total": 1680
  },
  {
    "amount": 1050,
    "term": 12,
    "fees": 210,
    "borrowed": 1260,
    "repayment": 34,
    "total": 1764
  },
  {
    "amount": 1100,
    "term": 12,
    "fees": 220,
    "borrowed": 1320,
    "repayment": 36,
    "total": 1848
  },
  {
    "amount": 1150,
    "term": 12,
    "fees": 230,
    "borrowed": 1380,
    "repayment": 38,
    "total": 1932
  },
  {
    "amount": 1200,
    "term": 12,
    "fees": 240,
    "borrowed": 1440,
    "repayment": 39,
    "total": 2016
  },
  {
    "amount": 1250,
    "term": 12,
    "fees": 250,
    "borrowed": 1500,
    "repayment": 41,
    "total": 2100
  },
  {
    "amount": 1300,
    "term": 12,
    "fees": 260,
    "borrowed": 1560,
    "repayment": 42,
    "total": 2184
  },
  {
    "amount": 1350,
    "term": 12,
    "fees": 270,
    "borrowed": 1620,
    "repayment": 44,
    "total": 2268
  },
  {
    "amount": 1400,
    "term": 12,
    "fees": 280,
    "borrowed": 1680,
    "repayment": 46,
    "total": 2352
  },
  {
    "amount": 1450,
    "term": 12,
    "fees": 290,
    "borrowed": 1740,
    "repayment": 47,
    "total": 2436
  },
  {
    "amount": 1500,
    "term": 12,
    "fees": 300,
    "borrowed": 1800,
    "repayment": 49,
    "total": 2520
  },
  {
    "amount": 1550,
    "term": 12,
    "fees": 310,
    "borrowed": 1860,
    "repayment": 51,
    "total": 2604
  },
  {
    "amount": 1600,
    "term": 12,
    "fees": 320,
    "borrowed": 1920,
    "repayment": 52,
    "total": 2688
  },
  {
    "amount": 1650,
    "term": 12,
    "fees": 330,
    "borrowed": 1980,
    "repayment": 54,
    "total": 2772
  },
  {
    "amount": 1700,
    "term": 12,
    "fees": 340,
    "borrowed": 2040,
    "repayment": 55,
    "total": 2856
  },
  {
    "amount": 1750,
    "term": 12,
    "fees": 350,
    "borrowed": 2100,
    "repayment": 57,
    "total": 2940
  },
  {
    "amount": 1800,
    "term": 12,
    "fees": 360,
    "borrowed": 2160,
    "repayment": 59,
    "total": 3024
  },
  {
    "amount": 1850,
    "term": 12,
    "fees": 370,
    "borrowed": 2220,
    "repayment": 60,
    "total": 3108
  },
  {
    "amount": 1900,
    "term": 12,
    "fees": 380,
    "borrowed": 2280,
    "repayment": 62,
    "total": 3192
  },
  {
    "amount": 1950,
    "term": 12,
    "fees": 390,
    "borrowed": 2340,
    "repayment": 63,
    "total": 3276
  },
  {
    "amount": 2000,
    "term": 12,
    "fees": 400,
    "borrowed": 2400,
    "repayment": 65,
    "total": 3360
  },
  {
    "amount": 2050,
    "term": 12,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 60,
    "total": 3104
  },
  {
    "amount": 2100,
    "term": 12,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 61,
    "total": 3167
  },
  {
    "amount": 2150,
    "term": 12,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 63,
    "total": 3230
  },
  {
    "amount": 2200,
    "term": 12,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 64,
    "total": 3293
  },
  {
    "amount": 2250,
    "term": 12,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 65,
    "total": 3356
  },
  {
    "amount": 2300,
    "term": 12,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 66,
    "total": 3419
  },
  {
    "amount": 2350,
    "term": 12,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 67,
    "total": 3482
  },
  {
    "amount": 2400,
    "term": 12,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 69,
    "total": 3546
  },
  {
    "amount": 2450,
    "term": 12,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 70,
    "total": 3609
  },
  {
    "amount": 2500,
    "term": 12,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 71,
    "total": 3672
  },
  {
    "amount": 2550,
    "term": 12,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 72,
    "total": 3735
  },
  {
    "amount": 2600,
    "term": 12,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 74,
    "total": 3798
  },
  {
    "amount": 2650,
    "term": 12,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 75,
    "total": 3861
  },
  {
    "amount": 2700,
    "term": 12,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 76,
    "total": 3924
  },
  {
    "amount": 2750,
    "term": 12,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 77,
    "total": 3987
  },
  {
    "amount": 2800,
    "term": 12,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 78,
    "total": 4051
  },
  {
    "amount": 2850,
    "term": 12,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 80,
    "total": 4114
  },
  {
    "amount": 2900,
    "term": 12,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 81,
    "total": 4177
  },
  {
    "amount": 2950,
    "term": 12,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 82,
    "total": 4240
  },
  {
    "amount": 3000,
    "term": 12,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 83,
    "total": 4303
  },
  {
    "amount": 3050,
    "term": 12,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 84,
    "total": 4366
  },
  {
    "amount": 3100,
    "term": 12,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 86,
    "total": 4429
  },
  {
    "amount": 3150,
    "term": 12,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 87,
    "total": 4493
  },
  {
    "amount": 3200,
    "term": 12,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 88,
    "total": 4556
  },
  {
    "amount": 3250,
    "term": 12,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 89,
    "total": 4619
  },
  {
    "amount": 3300,
    "term": 12,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 91,
    "total": 4682
  },
  {
    "amount": 3350,
    "term": 12,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 92,
    "total": 4745
  },
  {
    "amount": 3400,
    "term": 12,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 93,
    "total": 4808
  },
  {
    "amount": 3450,
    "term": 12,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 94,
    "total": 4871
  },
  {
    "amount": 3500,
    "term": 12,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 95,
    "total": 4935
  },
  {
    "amount": 3550,
    "term": 12,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 97,
    "total": 4998
  },
  {
    "amount": 3600,
    "term": 12,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 98,
    "total": 5061
  },
  {
    "amount": 3650,
    "term": 12,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 99,
    "total": 5124
  },
  {
    "amount": 3700,
    "term": 12,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 100,
    "total": 5187
  },
  {
    "amount": 3750,
    "term": 12,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 101,
    "total": 5250
  },
  {
    "amount": 3800,
    "term": 12,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 103,
    "total": 5313
  },
  {
    "amount": 3850,
    "term": 12,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 104,
    "total": 5376
  },
  {
    "amount": 3900,
    "term": 12,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 105,
    "total": 5440
  },
  {
    "amount": 3950,
    "term": 12,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 106,
    "total": 5503
  },
  {
    "amount": 4000,
    "term": 12,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 108,
    "total": 5566
  },
  {
    "amount": 4050,
    "term": 12,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 109,
    "total": 5629
  },
  {
    "amount": 4100,
    "term": 12,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 110,
    "total": 5692
  },
  {
    "amount": 4150,
    "term": 12,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 111,
    "total": 5755
  },
  {
    "amount": 4200,
    "term": 12,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 112,
    "total": 5818
  },
  {
    "amount": 4250,
    "term": 12,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 114,
    "total": 5882
  },
  {
    "amount": 4300,
    "term": 12,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 115,
    "total": 5945
  },
  {
    "amount": 4350,
    "term": 12,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 116,
    "total": 6008
  },
  {
    "amount": 4400,
    "term": 12,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 117,
    "total": 6071
  },
  {
    "amount": 4450,
    "term": 12,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 118,
    "total": 6134
  },
  {
    "amount": 4500,
    "term": 12,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 120,
    "total": 6197
  },
  {
    "amount": 4550,
    "term": 12,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 121,
    "total": 6260
  },
  {
    "amount": 4600,
    "term": 12,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 122,
    "total": 6324
  },
  {
    "amount": 2050,
    "term": 13,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 57,
    "total": 3157
  },
  {
    "amount": 2100,
    "term": 13,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 58,
    "total": 3221
  },
  {
    "amount": 2150,
    "term": 13,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 59,
    "total": 3285
  },
  {
    "amount": 2200,
    "term": 13,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 60,
    "total": 3349
  },
  {
    "amount": 2250,
    "term": 13,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 61,
    "total": 3414
  },
  {
    "amount": 2300,
    "term": 13,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 63,
    "total": 3478
  },
  {
    "amount": 2350,
    "term": 13,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 64,
    "total": 3542
  },
  {
    "amount": 2400,
    "term": 13,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 65,
    "total": 3606
  },
  {
    "amount": 2450,
    "term": 13,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 66,
    "total": 3671
  },
  {
    "amount": 2500,
    "term": 13,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 67,
    "total": 3735
  },
  {
    "amount": 2550,
    "term": 13,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 68,
    "total": 3799
  },
  {
    "amount": 2600,
    "term": 13,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 69,
    "total": 3863
  },
  {
    "amount": 2650,
    "term": 13,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 71,
    "total": 3927
  },
  {
    "amount": 2700,
    "term": 13,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 72,
    "total": 3992
  },
  {
    "amount": 2750,
    "term": 13,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 73,
    "total": 4055
  },
  {
    "amount": 2800,
    "term": 13,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 74,
    "total": 4120
  },
  {
    "amount": 2850,
    "term": 13,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 75,
    "total": 4184
  },
  {
    "amount": 2900,
    "term": 13,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 76,
    "total": 4248
  },
  {
    "amount": 2950,
    "term": 13,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 77,
    "total": 4312
  },
  {
    "amount": 3000,
    "term": 13,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 79,
    "total": 4377
  },
  {
    "amount": 3050,
    "term": 13,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 80,
    "total": 4441
  },
  {
    "amount": 3100,
    "term": 13,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 81,
    "total": 4505
  },
  {
    "amount": 3150,
    "term": 13,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 82,
    "total": 4570
  },
  {
    "amount": 3200,
    "term": 13,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 83,
    "total": 4633
  },
  {
    "amount": 3250,
    "term": 13,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 84,
    "total": 4698
  },
  {
    "amount": 3300,
    "term": 13,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 86,
    "total": 4762
  },
  {
    "amount": 3350,
    "term": 13,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 87,
    "total": 4826
  },
  {
    "amount": 3400,
    "term": 13,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 88,
    "total": 4890
  },
  {
    "amount": 3450,
    "term": 13,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 89,
    "total": 4955
  },
  {
    "amount": 3500,
    "term": 13,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 90,
    "total": 5019
  },
  {
    "amount": 3550,
    "term": 13,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 91,
    "total": 5083
  },
  {
    "amount": 3600,
    "term": 13,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 92,
    "total": 5147
  },
  {
    "amount": 3650,
    "term": 13,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 94,
    "total": 5211
  },
  {
    "amount": 3700,
    "term": 13,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 95,
    "total": 5276
  },
  {
    "amount": 3750,
    "term": 13,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 96,
    "total": 5340
  },
  {
    "amount": 3800,
    "term": 13,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 97,
    "total": 5404
  },
  {
    "amount": 3850,
    "term": 13,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 98,
    "total": 5468
  },
  {
    "amount": 3900,
    "term": 13,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 99,
    "total": 5532
  },
  {
    "amount": 3950,
    "term": 13,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 100,
    "total": 5597
  },
  {
    "amount": 4000,
    "term": 13,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 102,
    "total": 5661
  },
  {
    "amount": 4050,
    "term": 13,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 103,
    "total": 5725
  },
  {
    "amount": 4100,
    "term": 13,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 104,
    "total": 5789
  },
  {
    "amount": 4150,
    "term": 13,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 105,
    "total": 5854
  },
  {
    "amount": 4200,
    "term": 13,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 106,
    "total": 5917
  },
  {
    "amount": 4250,
    "term": 13,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 107,
    "total": 5982
  },
  {
    "amount": 4300,
    "term": 13,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 108,
    "total": 6046
  },
  {
    "amount": 4350,
    "term": 13,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 110,
    "total": 6110
  },
  {
    "amount": 4400,
    "term": 13,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 111,
    "total": 6174
  },
  {
    "amount": 4450,
    "term": 13,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 112,
    "total": 6239
  },
  {
    "amount": 4500,
    "term": 13,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 113,
    "total": 6303
  },
  {
    "amount": 4550,
    "term": 13,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 114,
    "total": 6367
  },
  {
    "amount": 4600,
    "term": 13,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 115,
    "total": 6431
  },
  {
    "amount": 2050,
    "term": 14,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 54,
    "total": 3210
  },
  {
    "amount": 2100,
    "term": 14,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 55,
    "total": 3276
  },
  {
    "amount": 2150,
    "term": 14,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 56,
    "total": 3341
  },
  {
    "amount": 2200,
    "term": 14,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 57,
    "total": 3407
  },
  {
    "amount": 2250,
    "term": 14,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 58,
    "total": 3471
  },
  {
    "amount": 2300,
    "term": 14,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 59,
    "total": 3537
  },
  {
    "amount": 2350,
    "term": 14,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 61,
    "total": 3602
  },
  {
    "amount": 2400,
    "term": 14,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 62,
    "total": 3668
  },
  {
    "amount": 2450,
    "term": 14,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 63,
    "total": 3733
  },
  {
    "amount": 2500,
    "term": 14,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 64,
    "total": 3798
  },
  {
    "amount": 2550,
    "term": 14,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 65,
    "total": 3863
  },
  {
    "amount": 2600,
    "term": 14,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 66,
    "total": 3929
  },
  {
    "amount": 2650,
    "term": 14,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 67,
    "total": 3994
  },
  {
    "amount": 2700,
    "term": 14,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 68,
    "total": 4059
  },
  {
    "amount": 2750,
    "term": 14,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 69,
    "total": 4124
  },
  {
    "amount": 2800,
    "term": 14,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 70,
    "total": 4190
  },
  {
    "amount": 2850,
    "term": 14,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 71,
    "total": 4255
  },
  {
    "amount": 2900,
    "term": 14,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 72,
    "total": 4320
  },
  {
    "amount": 2950,
    "term": 14,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 74,
    "total": 4386
  },
  {
    "amount": 3000,
    "term": 14,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 75,
    "total": 4451
  },
  {
    "amount": 3050,
    "term": 14,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 76,
    "total": 4516
  },
  {
    "amount": 3100,
    "term": 14,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 77,
    "total": 4581
  },
  {
    "amount": 3150,
    "term": 14,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 78,
    "total": 4647
  },
  {
    "amount": 3200,
    "term": 14,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 79,
    "total": 4712
  },
  {
    "amount": 3250,
    "term": 14,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 80,
    "total": 4778
  },
  {
    "amount": 3300,
    "term": 14,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 81,
    "total": 4842
  },
  {
    "amount": 3350,
    "term": 14,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 82,
    "total": 4908
  },
  {
    "amount": 3400,
    "term": 14,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 83,
    "total": 4973
  },
  {
    "amount": 3450,
    "term": 14,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 84,
    "total": 5039
  },
  {
    "amount": 3500,
    "term": 14,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 86,
    "total": 5104
  },
  {
    "amount": 3550,
    "term": 14,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 87,
    "total": 5169
  },
  {
    "amount": 3600,
    "term": 14,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 88,
    "total": 5234
  },
  {
    "amount": 3650,
    "term": 14,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 89,
    "total": 5300
  },
  {
    "amount": 3700,
    "term": 14,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 90,
    "total": 5365
  },
  {
    "amount": 3750,
    "term": 14,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 91,
    "total": 5430
  },
  {
    "amount": 3800,
    "term": 14,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 92,
    "total": 5495
  },
  {
    "amount": 3850,
    "term": 14,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 93,
    "total": 5561
  },
  {
    "amount": 3900,
    "term": 14,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 94,
    "total": 5626
  },
  {
    "amount": 3950,
    "term": 14,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 95,
    "total": 5691
  },
  {
    "amount": 4000,
    "term": 14,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 96,
    "total": 5757
  },
  {
    "amount": 4050,
    "term": 14,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 98,
    "total": 5822
  },
  {
    "amount": 4100,
    "term": 14,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 99,
    "total": 5887
  },
  {
    "amount": 4150,
    "term": 14,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 100,
    "total": 5952
  },
  {
    "amount": 4200,
    "term": 14,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 101,
    "total": 6018
  },
  {
    "amount": 4250,
    "term": 14,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 102,
    "total": 6083
  },
  {
    "amount": 4300,
    "term": 14,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 103,
    "total": 6149
  },
  {
    "amount": 4350,
    "term": 14,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 104,
    "total": 6213
  },
  {
    "amount": 4400,
    "term": 14,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 105,
    "total": 6279
  },
  {
    "amount": 4450,
    "term": 14,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 106,
    "total": 6344
  },
  {
    "amount": 4500,
    "term": 14,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 107,
    "total": 6410
  },
  {
    "amount": 4550,
    "term": 14,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 108,
    "total": 6475
  },
  {
    "amount": 4600,
    "term": 14,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 109,
    "total": 6540
  },
  {
    "amount": 2050,
    "term": 15,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 52,
    "total": 3265
  },
  {
    "amount": 2100,
    "term": 15,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 53,
    "total": 3331
  },
  {
    "amount": 2150,
    "term": 15,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 54,
    "total": 3398
  },
  {
    "amount": 2200,
    "term": 15,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 55,
    "total": 3464
  },
  {
    "amount": 2250,
    "term": 15,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 56,
    "total": 3530
  },
  {
    "amount": 2300,
    "term": 15,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 57,
    "total": 3597
  },
  {
    "amount": 2350,
    "term": 15,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 58,
    "total": 3663
  },
  {
    "amount": 2400,
    "term": 15,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 59,
    "total": 3730
  },
  {
    "amount": 2450,
    "term": 15,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 60,
    "total": 3796
  },
  {
    "amount": 2500,
    "term": 15,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 61,
    "total": 3862
  },
  {
    "amount": 2550,
    "term": 15,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 62,
    "total": 3929
  },
  {
    "amount": 2600,
    "term": 15,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 63,
    "total": 3995
  },
  {
    "amount": 2650,
    "term": 15,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 64,
    "total": 4061
  },
  {
    "amount": 2700,
    "term": 15,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 65,
    "total": 4128
  },
  {
    "amount": 2750,
    "term": 15,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 66,
    "total": 4194
  },
  {
    "amount": 2800,
    "term": 15,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 67,
    "total": 4260
  },
  {
    "amount": 2850,
    "term": 15,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 68,
    "total": 4327
  },
  {
    "amount": 2900,
    "term": 15,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 69,
    "total": 4393
  },
  {
    "amount": 2950,
    "term": 15,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 70,
    "total": 4459
  },
  {
    "amount": 3000,
    "term": 15,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 71,
    "total": 4526
  },
  {
    "amount": 3050,
    "term": 15,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 72,
    "total": 4592
  },
  {
    "amount": 3100,
    "term": 15,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 73,
    "total": 4659
  },
  {
    "amount": 3150,
    "term": 15,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 74,
    "total": 4725
  },
  {
    "amount": 3200,
    "term": 15,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 75,
    "total": 4792
  },
  {
    "amount": 3250,
    "term": 15,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 76,
    "total": 4858
  },
  {
    "amount": 3300,
    "term": 15,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 77,
    "total": 4924
  },
  {
    "amount": 3350,
    "term": 15,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 78,
    "total": 4991
  },
  {
    "amount": 3400,
    "term": 15,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 80,
    "total": 5057
  },
  {
    "amount": 3450,
    "term": 15,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 81,
    "total": 5124
  },
  {
    "amount": 3500,
    "term": 15,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 82,
    "total": 5190
  },
  {
    "amount": 3550,
    "term": 15,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 83,
    "total": 5256
  },
  {
    "amount": 3600,
    "term": 15,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 84,
    "total": 5323
  },
  {
    "amount": 3650,
    "term": 15,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 85,
    "total": 5389
  },
  {
    "amount": 3700,
    "term": 15,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 86,
    "total": 5455
  },
  {
    "amount": 3750,
    "term": 15,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 87,
    "total": 5522
  },
  {
    "amount": 3800,
    "term": 15,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 88,
    "total": 5588
  },
  {
    "amount": 3850,
    "term": 15,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 89,
    "total": 5654
  },
  {
    "amount": 3900,
    "term": 15,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 90,
    "total": 5721
  },
  {
    "amount": 3950,
    "term": 15,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 91,
    "total": 5787
  },
  {
    "amount": 4000,
    "term": 15,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 92,
    "total": 5853
  },
  {
    "amount": 4050,
    "term": 15,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 93,
    "total": 5920
  },
  {
    "amount": 4100,
    "term": 15,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 94,
    "total": 5986
  },
  {
    "amount": 4150,
    "term": 15,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 95,
    "total": 6053
  },
  {
    "amount": 4200,
    "term": 15,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 96,
    "total": 6119
  },
  {
    "amount": 4250,
    "term": 15,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 97,
    "total": 6185
  },
  {
    "amount": 4300,
    "term": 15,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 98,
    "total": 6252
  },
  {
    "amount": 4350,
    "term": 15,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 99,
    "total": 6318
  },
  {
    "amount": 4400,
    "term": 15,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 100,
    "total": 6384
  },
  {
    "amount": 4450,
    "term": 15,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 101,
    "total": 6451
  },
  {
    "amount": 4500,
    "term": 15,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 102,
    "total": 6518
  },
  {
    "amount": 4550,
    "term": 15,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 103,
    "total": 6584
  },
  {
    "amount": 4600,
    "term": 15,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 104,
    "total": 6650
  },
  {
    "amount": 2050,
    "term": 16,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 49,
    "total": 3318
  },
  {
    "amount": 2100,
    "term": 16,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 50,
    "total": 3385
  },
  {
    "amount": 2150,
    "term": 16,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 51,
    "total": 3453
  },
  {
    "amount": 2200,
    "term": 16,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 52,
    "total": 3520
  },
  {
    "amount": 2250,
    "term": 16,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 53,
    "total": 3587
  },
  {
    "amount": 2300,
    "term": 16,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 54,
    "total": 3655
  },
  {
    "amount": 2350,
    "term": 16,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 55,
    "total": 3722
  },
  {
    "amount": 2400,
    "term": 16,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 56,
    "total": 3790
  },
  {
    "amount": 2450,
    "term": 16,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 57,
    "total": 3857
  },
  {
    "amount": 2500,
    "term": 16,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 58,
    "total": 3925
  },
  {
    "amount": 2550,
    "term": 16,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 59,
    "total": 3992
  },
  {
    "amount": 2600,
    "term": 16,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 60,
    "total": 4060
  },
  {
    "amount": 2650,
    "term": 16,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 61,
    "total": 4127
  },
  {
    "amount": 2700,
    "term": 16,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 62,
    "total": 4195
  },
  {
    "amount": 2750,
    "term": 16,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 63,
    "total": 4262
  },
  {
    "amount": 2800,
    "term": 16,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 64,
    "total": 4330
  },
  {
    "amount": 2850,
    "term": 16,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 65,
    "total": 4397
  },
  {
    "amount": 2900,
    "term": 16,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 66,
    "total": 4465
  },
  {
    "amount": 2950,
    "term": 16,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 67,
    "total": 4532
  },
  {
    "amount": 3000,
    "term": 16,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 68,
    "total": 4600
  },
  {
    "amount": 3050,
    "term": 16,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 69,
    "total": 4667
  },
  {
    "amount": 3100,
    "term": 16,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 70,
    "total": 4735
  },
  {
    "amount": 3150,
    "term": 16,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 71,
    "total": 4802
  },
  {
    "amount": 3200,
    "term": 16,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 72,
    "total": 4869
  },
  {
    "amount": 3250,
    "term": 16,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 73,
    "total": 4937
  },
  {
    "amount": 3300,
    "term": 16,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 74,
    "total": 5005
  },
  {
    "amount": 3350,
    "term": 16,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 75,
    "total": 5072
  },
  {
    "amount": 3400,
    "term": 16,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 76,
    "total": 5139
  },
  {
    "amount": 3450,
    "term": 16,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 77,
    "total": 5207
  },
  {
    "amount": 3500,
    "term": 16,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 78,
    "total": 5275
  },
  {
    "amount": 3550,
    "term": 16,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 79,
    "total": 5342
  },
  {
    "amount": 3600,
    "term": 16,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 80,
    "total": 5409
  },
  {
    "amount": 3650,
    "term": 16,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 81,
    "total": 5477
  },
  {
    "amount": 3700,
    "term": 16,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 82,
    "total": 5545
  },
  {
    "amount": 3750,
    "term": 16,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 83,
    "total": 5612
  },
  {
    "amount": 3800,
    "term": 16,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 84,
    "total": 5679
  },
  {
    "amount": 3850,
    "term": 16,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 85,
    "total": 5747
  },
  {
    "amount": 3900,
    "term": 16,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 86,
    "total": 5814
  },
  {
    "amount": 3950,
    "term": 16,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 87,
    "total": 5882
  },
  {
    "amount": 4000,
    "term": 16,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 88,
    "total": 5949
  },
  {
    "amount": 4050,
    "term": 16,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 89,
    "total": 6016
  },
  {
    "amount": 4100,
    "term": 16,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 90,
    "total": 6084
  },
  {
    "amount": 4150,
    "term": 16,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 91,
    "total": 6152
  },
  {
    "amount": 4200,
    "term": 16,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 92,
    "total": 6219
  },
  {
    "amount": 4250,
    "term": 16,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 93,
    "total": 6286
  },
  {
    "amount": 4300,
    "term": 16,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 94,
    "total": 6354
  },
  {
    "amount": 4350,
    "term": 16,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 95,
    "total": 6422
  },
  {
    "amount": 4400,
    "term": 16,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 96,
    "total": 6489
  },
  {
    "amount": 4450,
    "term": 16,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 97,
    "total": 6556
  },
  {
    "amount": 4500,
    "term": 16,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 98,
    "total": 6624
  },
  {
    "amount": 4550,
    "term": 16,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 99,
    "total": 6692
  },
  {
    "amount": 4600,
    "term": 16,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 100,
    "total": 6759
  },
  {
    "amount": 2050,
    "term": 17,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 47,
    "total": 3373
  },
  {
    "amount": 2100,
    "term": 17,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 48,
    "total": 3441
  },
  {
    "amount": 2150,
    "term": 17,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 49,
    "total": 3510
  },
  {
    "amount": 2200,
    "term": 17,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 50,
    "total": 3579
  },
  {
    "amount": 2250,
    "term": 17,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 51,
    "total": 3647
  },
  {
    "amount": 2300,
    "term": 17,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 52,
    "total": 3716
  },
  {
    "amount": 2350,
    "term": 17,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 53,
    "total": 3784
  },
  {
    "amount": 2400,
    "term": 17,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 54,
    "total": 3853
  },
  {
    "amount": 2450,
    "term": 17,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 55,
    "total": 3922
  },
  {
    "amount": 2500,
    "term": 17,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 56,
    "total": 3990
  },
  {
    "amount": 2550,
    "term": 17,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 57,
    "total": 4058
  },
  {
    "amount": 2600,
    "term": 17,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 58,
    "total": 4128
  },
  {
    "amount": 2650,
    "term": 17,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 59,
    "total": 4196
  },
  {
    "amount": 2700,
    "term": 17,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 60,
    "total": 4264
  },
  {
    "amount": 2750,
    "term": 17,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 61,
    "total": 4333
  },
  {
    "amount": 2800,
    "term": 17,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 62,
    "total": 4402
  },
  {
    "amount": 2850,
    "term": 17,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 63,
    "total": 4470
  },
  {
    "amount": 2900,
    "term": 17,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 64,
    "total": 4539
  },
  {
    "amount": 2950,
    "term": 17,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 64,
    "total": 4608
  },
  {
    "amount": 3000,
    "term": 17,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 65,
    "total": 4676
  },
  {
    "amount": 3050,
    "term": 17,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 66,
    "total": 4745
  },
  {
    "amount": 3100,
    "term": 17,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 67,
    "total": 4813
  },
  {
    "amount": 3150,
    "term": 17,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 68,
    "total": 4882
  },
  {
    "amount": 3200,
    "term": 17,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 69,
    "total": 4950
  },
  {
    "amount": 3250,
    "term": 17,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 70,
    "total": 5019
  },
  {
    "amount": 3300,
    "term": 17,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 71,
    "total": 5087
  },
  {
    "amount": 3350,
    "term": 17,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 72,
    "total": 5156
  },
  {
    "amount": 3400,
    "term": 17,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 73,
    "total": 5225
  },
  {
    "amount": 3450,
    "term": 17,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 74,
    "total": 5293
  },
  {
    "amount": 3500,
    "term": 17,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 75,
    "total": 5362
  },
  {
    "amount": 3550,
    "term": 17,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 76,
    "total": 5431
  },
  {
    "amount": 3600,
    "term": 17,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 77,
    "total": 5499
  },
  {
    "amount": 3650,

    "term": 17,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 78,
    "total": 5568
  },
  {
    "amount": 3700,
    "term": 17,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 79,
    "total": 5637
  },
  {
    "amount": 3750,
    "term": 17,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 80,
    "total": 5705
  },
  {
    "amount": 3800,
    "term": 17,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 81,
    "total": 5773
  },
  {
    "amount": 3850,
    "term": 17,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 82,
    "total": 5842
  },
  {
    "amount": 3900,
    "term": 17,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 83,
    "total": 5911
  },
  {
    "amount": 3950,
    "term": 17,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 84,
    "total": 5979
  },
  {
    "amount": 4000,
    "term": 17,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 84,
    "total": 6048
  },
  {
    "amount": 4050,
    "term": 17,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 85,
    "total": 6116
  },
  {
    "amount": 4100,
    "term": 17,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 86,
    "total": 6185
  },
  {
    "amount": 4150,
    "term": 17,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 87,
    "total": 6254
  },
  {
    "amount": 4200,
    "term": 17,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 88,
    "total": 6322
  },
  {
    "amount": 4250,
    "term": 17,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 89,
    "total": 6391
  },
  {
    "amount": 4300,
    "term": 17,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 90,
    "total": 6460
  },
  {
    "amount": 4350,
    "term": 17,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 91,
    "total": 6528
  },
  {
    "amount": 4400,
    "term": 17,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 92,
    "total": 6596
  },
  {
    "amount": 4450,
    "term": 17,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 93,
    "total": 6666
  },
  {
    "amount": 4500,
    "term": 17,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 94,
    "total": 6734
  },
  {
    "amount": 4550,
    "term": 17,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 95,
    "total": 6802
  },
  {
    "amount": 4600,
    "term": 17,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 96,
    "total": 6871
  },
  {
    "amount": 2050,
    "term": 18,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 44,
    "total": 3484
  },
  {
    "amount": 2100,
    "term": 18,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 45,
    "total": 3556
  },
  {
    "amount": 2150,
    "term": 18,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 46,
    "total": 3626
  },
  {
    "amount": 2200,
    "term": 18,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 47,
    "total": 3697
  },
  {
    "amount": 2250,
    "term": 18,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 48,
    "total": 3768
  },
  {
    "amount": 2300,
    "term": 18,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 48,
    "total": 3839
  },
  {
    "amount": 2350,
    "term": 18,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 49,
    "total": 3910
  },
  {
    "amount": 2400,
    "term": 18,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 50,
    "total": 3980
  },
  {
    "amount": 2450,
    "term": 18,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 51,
    "total": 4052
  },
  {
    "amount": 2500,
    "term": 18,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 52,
    "total": 4122
  },
  {
    "amount": 2550,
    "term": 18,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 53,
    "total": 4193
  },
  {
    "amount": 2600,
    "term": 18,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 54,
    "total": 4264
  },
  {
    "amount": 2650,
    "term": 18,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 55,
    "total": 4335
  },
  {
    "amount": 2700,
    "term": 18,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 56,
    "total": 4406
  },
  {
    "amount": 2750,
    "term": 18,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 56,
    "total": 4476
  },
  {
    "amount": 2800,
    "term": 18,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 57,
    "total": 4548
  },
  {
    "amount": 2850,
    "term": 18,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 58,
    "total": 4618
  },
  {
    "amount": 2900,
    "term": 18,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 59,
    "total": 4689
  },
  {
    "amount": 2950,
    "term": 18,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 60,
    "total": 4760
  },
  {
    "amount": 3000,
    "term": 18,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 61,
    "total": 4831
  },
  {
    "amount": 3050,
    "term": 18,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 62,
    "total": 4902
  },
  {
    "amount": 3100,
    "term": 18,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 63,
    "total": 4972
  },
  {
    "amount": 3150,
    "term": 18,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 64,
    "total": 5044
  },
  {
    "amount": 3200,
    "term": 18,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 64,
    "total": 5114
  },
  {
    "amount": 3250,
    "term": 18,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 65,
    "total": 5185
  },
  {
    "amount": 3300,
    "term": 18,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 66,
    "total": 5256
  },
  {
    "amount": 3350,
    "term": 18,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 67,
    "total": 5327
  },
  {
    "amount": 3400,
    "term": 18,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 68,
    "total": 5398
  },
  {
    "amount": 3450,
    "term": 18,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 69,
    "total": 5468
  },
  {
    "amount": 3500,
    "term": 18,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 70,
    "total": 5540
  },
  {
    "amount": 3550,
    "term": 18,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 71,
    "total": 5610
  },
  {
    "amount": 3600,
    "term": 18,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 72,
    "total": 5681
  },
  {
    "amount": 3650,
    "term": 18,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 72,
    "total": 5752
  },
  {
    "amount": 3700,
    "term": 18,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 73,
    "total": 5823
  },
  {
    "amount": 3750,
    "term": 18,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 74,
    "total": 5894
  },
  {
    "amount": 3800,
    "term": 18,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 75,
    "total": 5964
  },
  {
    "amount": 3850,
    "term": 18,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 76,
    "total": 6036
  },
  {
    "amount": 3900,
    "term": 18,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 77,
    "total": 6106
  },
  {
    "amount": 3950,
    "term": 18,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 78,
    "total": 6177
  },
  {
    "amount": 4000,
    "term": 18,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 79,
    "total": 6248
  },
  {
    "amount": 4050,
    "term": 18,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 79,
    "total": 6319
  },
  {
    "amount": 4100,
    "term": 18,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 80,
    "total": 6390
  },
  {
    "amount": 4150,
    "term": 18,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 81,
    "total": 6460
  },
  {
    "amount": 4200,
    "term": 18,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 82,
    "total": 6532
  },
  {
    "amount": 4250,
    "term": 18,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 83,
    "total": 6602
  },
  {
    "amount": 4300,
    "term": 18,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 84,
    "total": 6673
  },
  {
    "amount": 4350,
    "term": 18,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 85,
    "total": 6744
  },
  {
    "amount": 4400,
    "term": 18,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 86,
    "total": 6815
  },
  {
    "amount": 4450,
    "term": 18,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 87,
    "total": 6886
  },
  {
    "amount": 4500,
    "term": 18,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 87,
    "total": 6956
  },
  {
    "amount": 4550,
    "term": 18,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 88,
    "total": 7028
  },
  {
    "amount": 4600,
    "term": 18,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 89,
    "total": 7098
  },
  {
    "amount": 2050,
    "term": 19,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 43,
    "total": 3537
  },
  {
    "amount": 2100,
    "term": 19,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 43,
    "total": 3609
  },
  {
    "amount": 2150,
    "term": 19,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 44,
    "total": 3681
  },
  {
    "amount": 2200,
    "term": 19,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 45,
    "total": 3754
  },
  {
    "amount": 2250,
    "term": 19,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 46,
    "total": 3825
  },
  {
    "amount": 2300,
    "term": 19,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 47,
    "total": 3897
  },
  {
    "amount": 2350,
    "term": 19,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 48,
    "total": 3969
  },
  {
    "amount": 2400,
    "term": 19,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 49,
    "total": 4041
  },
  {
    "amount": 2450,
    "term": 19,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 49,
    "total": 4113
  },
  {
    "amount": 2500,
    "term": 19,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 50,
    "total": 4185
  },
  {
    "amount": 2550,
    "term": 19,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 51,
    "total": 4258
  },
  {
    "amount": 2600,
    "term": 19,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 52,
    "total": 4329
  },
  {
    "amount": 2650,
    "term": 19,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 53,
    "total": 4401
  },
  {
    "amount": 2700,
    "term": 19,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 54,
    "total": 4473
  },
  {
    "amount": 2750,
    "term": 19,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 55,
    "total": 4545
  },
  {
    "amount": 2800,
    "term": 19,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 55,
    "total": 4617
  },
  {
    "amount": 2850,
    "term": 19,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 56,
    "total": 4689
  },
  {
    "amount": 2900,
    "term": 19,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 57,
    "total": 4762
  },
  {
    "amount": 2950,
    "term": 19,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 58,
    "total": 4833
  },
  {
    "amount": 3000,
    "term": 19,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 59,
    "total": 4905
  },
  {
    "amount": 3050,
    "term": 19,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 60,
    "total": 4977
  },
  {
    "amount": 3100,
    "term": 19,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 61,
    "total": 5049
  },
  {
    "amount": 3150,
    "term": 19,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 61,
    "total": 5121
  },
  {
    "amount": 3200,
    "term": 19,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 62,
    "total": 5193
  },
  {
    "amount": 3250,
    "term": 19,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 63,
    "total": 5266
  },
  {
    "amount": 3300,
    "term": 19,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 64,
    "total": 5337
  },
  {
    "amount": 3350,
    "term": 19,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 65,
    "total": 5409
  },
  {
    "amount": 3400,
    "term": 19,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 66,
    "total": 5481
  },
  {
    "amount": 3450,
    "term": 19,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 67,
    "total": 5553
  },
  {
    "amount": 3500,
    "term": 19,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 67,
    "total": 5625
  },
  {
    "amount": 3550,
    "term": 19,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 68,
    "total": 5697
  },
  {
    "amount": 3600,
    "term": 19,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 69,
    "total": 5770
  },
  {
    "amount": 3650,
    "term": 19,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 70,
    "total": 5841
  },
  {
    "amount": 3700,
    "term": 19,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 71,
    "total": 5913
  },
  {
    "amount": 3750,
    "term": 19,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 72,
    "total": 5985
  },
  {
    "amount": 3800,
    "term": 19,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 73,
    "total": 6057
  },
  {
    "amount": 3850,
    "term": 19,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 73,
    "total": 6129
  },
  {
    "amount": 3900,
    "term": 19,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 74,
    "total": 6201
  },
  {
    "amount": 3950,
    "term": 19,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 75,
    "total": 6274
  },
  {
    "amount": 4000,
    "term": 19,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 76,
    "total": 6345
  },
  {
    "amount": 4050,
    "term": 19,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 77,
    "total": 6417
  },
  {
    "amount": 4100,
    "term": 19,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 78,
    "total": 6489
  },
  {
    "amount": 4150,
    "term": 19,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 79,
    "total": 6561
  },
  {
    "amount": 4200,
    "term": 19,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 79,
    "total": 6633
  },
  {
    "amount": 4250,
    "term": 19,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 80,
    "total": 6705
  },
  {
    "amount": 4300,
    "term": 19,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 81,
    "total": 6778
  },
  {
    "amount": 4350,
    "term": 19,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 82,
    "total": 6849
  },
  {
    "amount": 4400,
    "term": 19,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 83,
    "total": 6921
  },
  {
    "amount": 4450,
    "term": 19,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 84,
    "total": 6993
  },
  {
    "amount": 4500,
    "term": 19,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 85,
    "total": 7065
  },
  {
    "amount": 4550,
    "term": 19,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 85,
    "total": 7137
  },
  {
    "amount": 4600,
    "term": 19,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 86,
    "total": 7209
  },
  {
    "amount": 2050,
    "term": 20,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 41,
    "total": 3594
  },
  {
    "amount": 2100,
    "term": 20,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 42,
    "total": 3667
  },
  {
    "amount": 2150,
    "term": 20,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 43,
    "total": 3740
  },
  {
    "amount": 2200,
    "term": 20,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 44,
    "total": 3814
  },
  {
    "amount": 2250,
    "term": 20,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 45,
    "total": 3887
  },
  {
    "amount": 2300,
    "term": 20,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 45,
    "total": 3960
  },
  {
    "amount": 2350,
    "term": 20,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 46,
    "total": 4034
  },
  {
    "amount": 2400,
    "term": 20,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 47,
    "total": 4107
  },
  {
    "amount": 2450,
    "term": 20,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 48,
    "total": 4180
  },
  {
    "amount": 2500,
    "term": 20,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 49,
    "total": 4253
  },
  {
    "amount": 2550,
    "term": 20,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 50,
    "total": 4326
  },
  {
    "amount": 2600,
    "term": 20,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 50,
    "total": 4399
  },
  {
    "amount": 2650,
    "term": 20,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 51,
    "total": 4473
  },
  {
    "amount": 2700,
    "term": 20,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 52,
    "total": 4546
  },
  {
    "amount": 2750,
    "term": 20,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 53,
    "total": 4619
  },
  {
    "amount": 2800,
    "term": 20,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 54,
    "total": 4692
  },
  {
    "amount": 2850,
    "term": 20,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 55,
    "total": 4765
  },
  {
    "amount": 2900,
    "term": 20,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 55,
    "total": 4838
  },
  {
    "amount": 2950,
    "term": 20,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 56,
    "total": 4911
  },
  {
    "amount": 3000,
    "term": 20,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 57,
    "total": 4984
  },
  {
    "amount": 3050,
    "term": 20,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 58,
    "total": 5058
  },
  {
    "amount": 3100,
    "term": 20,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 59,
    "total": 5131
  },
  {
    "amount": 3150,
    "term": 20,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 60,
    "total": 5204
  },
  {
    "amount": 3200,
    "term": 20,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 60,
    "total": 5277
  },
  {
    "amount": 3250,
    "term": 20,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 61,
    "total": 5350
  },
  {
    "amount": 3300,
    "term": 20,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 62,
    "total": 5423
  },
  {
    "amount": 3350,
    "term": 20,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 63,
    "total": 5496
  },
  {
    "amount": 3400,
    "term": 20,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 64,
    "total": 5570
  },
  {
    "amount": 3450,
    "term": 20,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 65,
    "total": 5643
  },
  {
    "amount": 3500,
    "term": 20,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 65,
    "total": 5716
  },
  {
    "amount": 3550,
    "term": 20,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 66,
    "total": 5789
  },
  {
    "amount": 3600,
    "term": 20,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 67,
    "total": 5862
  },
  {
    "amount": 3650,
    "term": 20,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 68,
    "total": 5935
  },
  {
    "amount": 3700,
    "term": 20,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 69,
    "total": 6008
  },
  {
    "amount": 3750,
    "term": 20,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 70,
    "total": 6081
  },
  {
    "amount": 3800,
    "term": 20,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 70,
    "total": 6155
  },
  {
    "amount": 3850,
    "term": 20,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 71,
    "total": 6228
  },
  {
    "amount": 3900,
    "term": 20,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 72,
    "total": 6301
  },
  {
    "amount": 3950,
    "term": 20,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 73,
    "total": 6374
  },
  {
    "amount": 4000,
    "term": 20,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 74,
    "total": 6447
  },
  {
    "amount": 4050,
    "term": 20,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 75,
    "total": 6520
  },
  {
    "amount": 4100,
    "term": 20,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 75,
    "total": 6593
  },
  {
    "amount": 4150,
    "term": 20,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 76,
    "total": 6667
  },
  {
    "amount": 4200,
    "term": 20,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 77,
    "total": 6740
  },
  {
    "amount": 4250,
    "term": 20,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 78,
    "total": 6813
  },
  {
    "amount": 4300,
    "term": 20,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 79,
    "total": 6886
  },
  {
    "amount": 4350,
    "term": 20,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 80,
    "total": 6960
  },
  {
    "amount": 4400,
    "term": 20,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 80,
    "total": 7033
  },
  {
    "amount": 4450,
    "term": 20,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 81,
    "total": 7106
  },
  {
    "amount": 4500,
    "term": 20,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 82,
    "total": 7179
  },
  {
    "amount": 4550,
    "term": 20,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 83,
    "total": 7253
  },
  {
    "amount": 4600,
    "term": 20,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 84,
    "total": 7326
  },
  {
    "amount": 2050,
    "term": 21,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 40,
    "total": 3652
  },
  {
    "amount": 2100,
    "term": 21,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 41,
    "total": 3726
  },
  {
    "amount": 2150,
    "term": 21,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 42,
    "total": 3801
  },
  {
    "amount": 2200,
    "term": 21,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 43,
    "total": 3875
  },
  {
    "amount": 2250,
    "term": 21,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 43,
    "total": 3949
  },
  {
    "amount": 2300,
    "term": 21,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 44,
    "total": 4024
  },
  {
    "amount": 2350,
    "term": 21,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 45,
    "total": 4098
  },
  {
    "amount": 2400,
    "term": 21,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 46,
    "total": 4173
  },
  {
    "amount": 2450,
    "term": 21,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 47,
    "total": 4246
  },
  {
    "amount": 2500,
    "term": 21,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 47,
    "total": 4321
  },
  {
    "amount": 2550,
    "term": 21,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 48,
    "total": 4395
  },
  {
    "amount": 2600,
    "term": 21,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 49,
    "total": 4470
  },
  {
    "amount": 2650,
    "term": 21,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 50,
    "total": 4543
  },
  {
    "amount": 2700,
    "term": 21,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 51,
    "total": 4618
  },
  {
    "amount": 2750,
    "term": 21,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 51,
    "total": 4692
  },
  {
    "amount": 2800,
    "term": 21,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 52,
    "total": 4767
  },
  {
    "amount": 2850,
    "term": 21,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 53,
    "total": 4842
  },
  {
    "amount": 2900,
    "term": 21,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 54,
    "total": 4915
  },
  {
    "amount": 2950,
    "term": 21,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 55,
    "total": 4990
  },
  {
    "amount": 3000,
    "term": 21,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 56,
    "total": 5064
  },
  {
    "amount": 3050,
    "term": 21,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 56,
    "total": 5139
  },
  {
    "amount": 3100,
    "term": 21,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 57,
    "total": 5213
  },
  {
    "amount": 3150,
    "term": 21,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 58,
    "total": 5287
  },
  {
    "amount": 3200,
    "term": 21,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 59,
    "total": 5361
  },
  {
    "amount": 3250,
    "term": 21,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 60,
    "total": 5436
  },
  {
    "amount": 3300,
    "term": 21,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 60,
    "total": 5510
  },
  {
    "amount": 3350,
    "term": 21,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 61,
    "total": 5584
  },
  {
    "amount": 3400,
    "term": 21,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 62,
    "total": 5658
  },
  {
    "amount": 3450,
    "term": 21,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 63,
    "total": 5733
  },
  {
    "amount": 3500,
    "term": 21,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 64,
    "total": 5808
  },
  {
    "amount": 3550,
    "term": 21,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 64,
    "total": 5882
  },
  {
    "amount": 3600,
    "term": 21,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 65,
    "total": 5956
  },
  {
    "amount": 3650,
    "term": 21,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 66,
    "total": 6030
  },
  {
    "amount": 3700,
    "term": 21,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 67,
    "total": 6105
  },
  {
    "amount": 3750,
    "term": 21,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 68,
    "total": 6179
  },
  {
    "amount": 3800,
    "term": 21,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 68,
    "total": 6254
  },
  {
    "amount": 3850,
    "term": 21,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 69,
    "total": 6327
  },
  {
    "amount": 3900,
    "term": 21,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 70,
    "total": 6402
  },
  {
    "amount": 3950,
    "term": 21,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 71,
    "total": 6476
  },
  {
    "amount": 4000,
    "term": 21,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 72,
    "total": 6551
  },
  {
    "amount": 4050,
    "term": 21,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 72,
    "total": 6624
  },
  {
    "amount": 4100,
    "term": 21,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 73,
    "total": 6699
  },
  {
    "amount": 4150,
    "term": 21,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 74,
    "total": 6774
  },
  {
    "amount": 4200,
    "term": 21,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 75,
    "total": 6848
  },
  {
    "amount": 4250,
    "term": 21,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 76,
    "total": 6923
  },
  {
    "amount": 4300,
    "term": 21,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 77,
    "total": 6996
  },
  {
    "amount": 4350,
    "term": 21,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 77,
    "total": 7071
  },
  {
    "amount": 4400,
    "term": 21,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 78,
    "total": 7145
  },
  {
    "amount": 4450,
    "term": 21,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 79,
    "total": 7220
  },
  {
    "amount": 4500,
    "term": 21,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 80,
    "total": 7294
  },
  {
    "amount": 4550,
    "term": 21,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 81,
    "total": 7368
  },
  {
    "amount": 4600,
    "term": 21,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 81,
    "total": 7442
  },
  {
    "amount": 2050,
    "term": 22,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 39,
    "total": 3710
  },
  {
    "amount": 2100,
    "term": 22,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 40,
    "total": 3786
  },
  {
    "amount": 2150,
    "term": 22,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 41,
    "total": 3861
  },
  {
    "amount": 2200,
    "term": 22,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 41,
    "total": 3936
  },
  {
    "amount": 2250,
    "term": 22,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 42,
    "total": 4012
  },
  {
    "amount": 2300,
    "term": 22,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 43,
    "total": 4087
  },
  {
    "amount": 2350,
    "term": 22,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 44,
    "total": 4163
  },
  {
    "amount": 2400,
    "term": 22,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 45,
    "total": 4239
  },
  {
    "amount": 2450,
    "term": 22,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 45,
    "total": 4314
  },
  {
    "amount": 2500,
    "term": 22,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 46,
    "total": 4390
  },
  {
    "amount": 2550,
    "term": 22,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 47,
    "total": 4465
  },
  {
    "amount": 2600,
    "term": 22,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 48,
    "total": 4540
  },
  {
    "amount": 2650,
    "term": 22,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 49,
    "total": 4616
  },
  {
    "amount": 2700,
    "term": 22,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 49,
    "total": 4692
  },
  {
    "amount": 2750,
    "term": 22,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 50,
    "total": 4767
  },
  {
    "amount": 2800,
    "term": 22,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 51,
    "total": 4843
  },
  {
    "amount": 2850,
    "term": 22,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 52,
    "total": 4919
  },
  {
    "amount": 2900,
    "term": 22,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 53,
    "total": 4993
  },
  {
    "amount": 2950,
    "term": 22,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 53,
    "total": 5069
  },
  {
    "amount": 3000,
    "term": 22,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 54,
    "total": 5144
  },
  {
    "amount": 3050,
    "term": 22,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 55,
    "total": 5220
  },
  {
    "amount": 3100,
    "term": 22,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 56,
    "total": 5296
  },
  {
    "amount": 3150,
    "term": 22,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 56,
    "total": 5371
  },
  {
    "amount": 3200,
    "term": 22,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 57,
    "total": 5447
  },
  {
    "amount": 3250,
    "term": 22,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 58,
    "total": 5522
  },
  {
    "amount": 3300,
    "term": 22,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 59,
    "total": 5597
  },
  {
    "amount": 3350,
    "term": 22,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 60,
    "total": 5673
  },
  {
    "amount": 3400,
    "term": 22,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 60,
    "total": 5749
  },
  {
    "amount": 3450,
    "term": 22,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 61,
    "total": 5824
  },
  {
    "amount": 3500,
    "term": 22,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 62,
    "total": 5900
  },
  {
    "amount": 3550,
    "term": 22,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 63,
    "total": 5976
  },
  {
    "amount": 3600,
    "term": 22,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 64,
    "total": 6050
  },
  {
    "amount": 3650,
    "term": 22,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 64,
    "total": 6126
  },
  {
    "amount": 3700,
    "term": 22,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 65,
    "total": 6202
  },
  {
    "amount": 3750,
    "term": 22,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 66,
    "total": 6277
  },
  {
    "amount": 3800,
    "term": 22,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 67,
    "total": 6353
  },
  {
    "amount": 3850,
    "term": 22,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 67,
    "total": 6428
  },
  {
    "amount": 3900,
    "term": 22,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 68,
    "total": 6504
  },
  {
    "amount": 3950,
    "term": 22,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 69,
    "total": 6579
  },
  {
    "amount": 4000,
    "term": 22,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 70,
    "total": 6654
  },
  {
    "amount": 4050,
    "term": 22,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 71,
    "total": 6730
  },
  {
    "amount": 4100,
    "term": 22,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 71,
    "total": 6806
  },
  {
    "amount": 4150,
    "term": 22,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 72,
    "total": 6881
  },
  {
    "amount": 4200,
    "term": 22,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 73,
    "total": 6957
  },
  {
    "amount": 4250,
    "term": 22,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 74,
    "total": 7032
  },
  {
    "amount": 4300,
    "term": 22,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 75,
    "total": 7107
  },
  {
    "amount": 4350,
    "term": 22,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 75,
    "total": 7183
  },
  {
    "amount": 4400,
    "term": 22,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 76,
    "total": 7259
  },
  {
    "amount": 4450,
    "term": 22,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 77,
    "total": 7334
  },
  {
    "amount": 4500,
    "term": 22,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 78,
    "total": 7410
  },
  {
    "amount": 4550,
    "term": 22,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 78,
    "total": 7486
  },
  {
    "amount": 4600,
    "term": 22,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 79,
    "total": 7560
  },
  {
    "amount": 2050,
    "term": 23,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 38,
    "total": 3768
  },
  {
    "amount": 2100,
    "term": 23,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 39,
    "total": 3845
  },
  {
    "amount": 2150,
    "term": 23,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 40,
    "total": 3922
  },
  {
    "amount": 2200,
    "term": 23,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 40,
    "total": 3998
  },
  {
    "amount": 2250,
    "term": 23,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 41,
    "total": 4075
  },
  {
    "amount": 2300,
    "term": 23,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 42,
    "total": 4152
  },
  {
    "amount": 2350,
    "term": 23,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 43,
    "total": 4228
  },
  {
    "amount": 2400,
    "term": 23,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 44,
    "total": 4305
  },
  {
    "amount": 2450,
    "term": 23,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 44,
    "total": 4382
  },
  {
    "amount": 2500,
    "term": 23,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 45,
    "total": 4458
  },
  {
    "amount": 2550,
    "term": 23,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 46,
    "total": 4535
  },
  {
    "amount": 2600,
    "term": 23,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 47,
    "total": 4612
  },
  {
    "amount": 2650,
    "term": 23,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 47,
    "total": 4689
  },
  {
    "amount": 2700,
    "term": 23,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 48,
    "total": 4765
  },
  {
    "amount": 2750,
    "term": 23,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 49,
    "total": 4842
  },
  {
    "amount": 2800,
    "term": 23,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 50,
    "total": 4919
  },
  {
    "amount": 2850,
    "term": 23,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 50,
    "total": 4995
  },
  {
    "amount": 2900,
    "term": 23,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 51,
    "total": 5072
  },
  {
    "amount": 2950,
    "term": 23,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 52,
    "total": 5149
  },
  {
    "amount": 3000,
    "term": 23,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 53,
    "total": 5225
  },
  {
    "amount": 3050,
    "term": 23,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 54,
    "total": 5302
  },
  {
    "amount": 3100,
    "term": 23,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 54,
    "total": 5379
  },
  {
    "amount": 3150,
    "term": 23,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 55,
    "total": 5455
  },
  {
    "amount": 3200,
    "term": 23,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 56,
    "total": 5532
  },
  {
    "amount": 3250,
    "term": 23,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 57,
    "total": 5609
  },
  {
    "amount": 3300,
    "term": 23,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 57,
    "total": 5685
  },
  {
    "amount": 3350,
    "term": 23,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 58,
    "total": 5762
  },
  {
    "amount": 3400,
    "term": 23,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 59,
    "total": 5839
  },
  {
    "amount": 3450,
    "term": 23,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 60,
    "total": 5916
  },
  {
    "amount": 3500,
    "term": 23,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 60,
    "total": 5992
  },
  {
    "amount": 3550,
    "term": 23,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 61,
    "total": 6069
  },
  {
    "amount": 3600,
    "term": 23,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 62,
    "total": 6146
  },
  {
    "amount": 3650,
    "term": 23,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 63,
    "total": 6222
  },
  {
    "amount": 3700,
    "term": 23,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 63,
    "total": 6299
  },
  {
    "amount": 3750,
    "term": 23,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 64,
    "total": 6376
  },
  {
    "amount": 3800,
    "term": 23,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 65,
    "total": 6452
  },
  {
    "amount": 3850,
    "term": 23,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 66,
    "total": 6529
  },
  {
    "amount": 3900,
    "term": 23,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 67,
    "total": 6606
  },
  {
    "amount": 3950,
    "term": 23,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 67,
    "total": 6682
  },
  {
    "amount": 4000,
    "term": 23,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 68,
    "total": 6759
  },
  {
    "amount": 4050,
    "term": 23,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 69,
    "total": 6836
  },
  {
    "amount": 4100,
    "term": 23,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 70,
    "total": 6912
  },
  {
    "amount": 4150,
    "term": 23,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 70,
    "total": 6989
  },
  {
    "amount": 4200,
    "term": 23,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 71,
    "total": 7066
  },
  {
    "amount": 4250,
    "term": 23,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 72,
    "total": 7143
  },
  {
    "amount": 4300,
    "term": 23,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 73,
    "total": 7219
  },
  {
    "amount": 4350,
    "term": 23,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 73,
    "total": 7296
  },
  {
    "amount": 4400,
    "term": 23,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 74,
    "total": 7373
  },
  {
    "amount": 4450,
    "term": 23,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 75,
    "total": 7449
  },
  {
    "amount": 4500,
    "term": 23,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 76,
    "total": 7526
  },
  {
    "amount": 4550,
    "term": 23,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 77,
    "total": 7603
  },
  {
    "amount": 4600,
    "term": 23,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 77,
    "total": 7679
  },
  {
    "amount": 2050,
    "term": 24,
    "fees": 400,
    "borrowed": 2450,
    "repayment": 37,
    "total": 3828
  },
  {
    "amount": 2100,
    "term": 24,
    "fees": 400,
    "borrowed": 2500,
    "repayment": 38,
    "total": 3906
  },
  {
    "amount": 2150,
    "term": 24,
    "fees": 400,
    "borrowed": 2550,
    "repayment": 39,
    "total": 3984
  },
  {
    "amount": 2200,
    "term": 24,
    "fees": 400,
    "borrowed": 2600,
    "repayment": 40,
    "total": 4062
  },
  {
    "amount": 2250,
    "term": 24,
    "fees": 400,
    "borrowed": 2650,
    "repayment": 40,
    "total": 4140
  },
  {
    "amount": 2300,
    "term": 24,
    "fees": 400,
    "borrowed": 2700,
    "repayment": 41,
    "total": 4218
  },
  {
    "amount": 2350,
    "term": 24,
    "fees": 400,
    "borrowed": 2750,
    "repayment": 42,
    "total": 4296
  },
  {
    "amount": 2400,
    "term": 24,
    "fees": 400,
    "borrowed": 2800,
    "repayment": 43,
    "total": 4373
  },
  {
    "amount": 2450,
    "term": 24,
    "fees": 400,
    "borrowed": 2850,
    "repayment": 43,
    "total": 4451
  },
  {
    "amount": 2500,
    "term": 24,
    "fees": 400,
    "borrowed": 2900,
    "repayment": 44,
    "total": 4529
  },
  {
    "amount": 2550,
    "term": 24,
    "fees": 400,
    "borrowed": 2950,
    "repayment": 45,
    "total": 4607
  },
  {
    "amount": 2600,
    "term": 24,
    "fees": 400,
    "borrowed": 3000,
    "repayment": 46,
    "total": 4685
  },
  {
    "amount": 2650,
    "term": 24,
    "fees": 400,
    "borrowed": 3050,
    "repayment": 46,
    "total": 4763
  },
  {
    "amount": 2700,
    "term": 24,
    "fees": 400,
    "borrowed": 3100,
    "repayment": 47,
    "total": 4841
  },
  {
    "amount": 2750,
    "term": 24,
    "fees": 400,
    "borrowed": 3150,
    "repayment": 48,
    "total": 4919
  },
  {
    "amount": 2800,
    "term": 24,
    "fees": 400,
    "borrowed": 3200,
    "repayment": 49,
    "total": 4997
  },
  {
    "amount": 2850,
    "term": 24,
    "fees": 400,
    "borrowed": 3250,
    "repayment": 49,
    "total": 5074
  },
  {
    "amount": 2900,
    "term": 24,
    "fees": 400,
    "borrowed": 3300,
    "repayment": 50,
    "total": 5152
  },
  {
    "amount": 2950,
    "term": 24,
    "fees": 400,
    "borrowed": 3350,
    "repayment": 51,
    "total": 5230
  },
  {
    "amount": 3000,
    "term": 24,
    "fees": 400,
    "borrowed": 3400,
    "repayment": 52,
    "total": 5308
  },
  {
    "amount": 3050,
    "term": 24,
    "fees": 400,
    "borrowed": 3450,
    "repayment": 52,
    "total": 5386
  },
  {
    "amount": 3100,
    "term": 24,
    "fees": 400,
    "borrowed": 3500,
    "repayment": 53,
    "total": 5464
  },
  {
    "amount": 3150,
    "term": 24,
    "fees": 400,
    "borrowed": 3550,
    "repayment": 54,
    "total": 5542
  },
  {
    "amount": 3200,
    "term": 24,
    "fees": 400,
    "borrowed": 3600,
    "repayment": 55,
    "total": 5620
  },
  {
    "amount": 3250,
    "term": 24,
    "fees": 400,
    "borrowed": 3650,
    "repayment": 55,
    "total": 5698
  },
  {
    "amount": 3300,
    "term": 24,
    "fees": 400,
    "borrowed": 3700,
    "repayment": 56,
    "total": 5775
  },
  {
    "amount": 3350,
    "term": 24,
    "fees": 400,
    "borrowed": 3750,
    "repayment": 57,
    "total": 5853
  },
  {
    "amount": 3400,
    "term": 24,
    "fees": 400,
    "borrowed": 3800,
    "repayment": 58,
    "total": 5931
  },
  {
    "amount": 3450,
    "term": 24,
    "fees": 400,
    "borrowed": 3850,
    "repayment": 58,
    "total": 6009
  },
  {
    "amount": 3500,
    "term": 24,
    "fees": 400,
    "borrowed": 3900,
    "repayment": 59,
    "total": 6087
  },
  {
    "amount": 3550,
    "term": 24,
    "fees": 400,
    "borrowed": 3950,
    "repayment": 60,
    "total": 6165
  },
  {
    "amount": 3600,
    "term": 24,
    "fees": 400,
    "borrowed": 4000,
    "repayment": 61,
    "total": 6243
  },
  {
    "amount": 3650,
    "term": 24,
    "fees": 400,
    "borrowed": 4050,
    "repayment": 61,
    "total": 6321
  },
  {
    "amount": 3700,
    "term": 24,
    "fees": 400,
    "borrowed": 4100,
    "repayment": 62,
    "total": 6399
  },
  {
    "amount": 3750,
    "term": 24,
    "fees": 400,
    "borrowed": 4150,
    "repayment": 63,
    "total": 6476
  },
  {
    "amount": 3800,
    "term": 24,
    "fees": 400,
    "borrowed": 4200,
    "repayment": 64,
    "total": 6554
  },
  {
    "amount": 3850,
    "term": 24,
    "fees": 400,
    "borrowed": 4250,
    "repayment": 64,
    "total": 6632
  },
  {
    "amount": 3900,
    "term": 24,
    "fees": 400,
    "borrowed": 4300,
    "repayment": 65,
    "total": 6710
  },
  {
    "amount": 3950,
    "term": 24,
    "fees": 400,
    "borrowed": 4350,
    "repayment": 66,
    "total": 6788
  },
  {
    "amount": 4000,
    "term": 24,
    "fees": 400,
    "borrowed": 4400,
    "repayment": 67,
    "total": 6866
  },
  {
    "amount": 4050,
    "term": 24,
    "fees": 400,
    "borrowed": 4450,
    "repayment": 67,
    "total": 6944
  },
  {
    "amount": 4100,
    "term": 24,
    "fees": 400,
    "borrowed": 4500,
    "repayment": 68,
    "total": 7022
  },
  {
    "amount": 4150,
    "term": 24,
    "fees": 400,
    "borrowed": 4550,
    "repayment": 69,
    "total": 7100
  },
  {
    "amount": 4200,
    "term": 24,
    "fees": 400,
    "borrowed": 4600,
    "repayment": 69,
    "total": 7176
  },
  {
    "amount": 4250,
    "term": 24,
    "fees": 400,
    "borrowed": 4650,
    "repayment": 70,
    "total": 7254
  },
  {
    "amount": 4300,
    "term": 24,
    "fees": 400,
    "borrowed": 4700,
    "repayment": 71,
    "total": 7332
  },
  {
    "amount": 4350,
    "term": 24,
    "fees": 400,
    "borrowed": 4750,
    "repayment": 72,
    "total": 7410
  },
  {
    "amount": 4400,
    "term": 24,
    "fees": 400,
    "borrowed": 4800,
    "repayment": 72,
    "total": 7488
  },
  {
    "amount": 4450,
    "term": 24,
    "fees": 400,
    "borrowed": 4850,
    "repayment": 73,
    "total": 7566
  },
  {
    "amount": 4500,
    "term": 24,
    "fees": 400,
    "borrowed": 4900,
    "repayment": 74,
    "total": 7644
  },
  {
    "amount": 4550,
    "term": 24,
    "fees": 400,
    "borrowed": 4950,
    "repayment": 75,
    "total": 7722
  },
  {
    "amount": 4600,
    "term": 24,
    "fees": 400,
    "borrowed": 5000,
    "repayment": 75,
    "total": 7800
  }
];
