import React from 'react';
import classNames from 'classnames';
import Cleave from 'cleave.js/react';
require('cleave.js/dist/addons/cleave-phone.au');

export interface MobilePhoneProps {
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  hideLabel?: boolean
  label?: string
  labelSuffix?: string
  labelSuffixBold?: boolean
  placeholder?: string
  id?: string
  name?: string
  value?: any
  disabled?: boolean
  helpText?: string
  isValid?: boolean
  errorMessage?: string
  variant?: string
  ref?: any
}

class MobilePhone extends React.Component<MobilePhoneProps> {

  focus() {
    const input = (this.refs.input as any);
    if (input && input.focus) input.focus();
  }

  renderLabelContent() {
    const { label, labelSuffix, labelSuffixBold, hideLabel } = this.props;
    const classes = classNames({ 'form__label-hide': hideLabel });

    return (
      <span className={classes}>
        {label}
        <span className='pl-5'>
          {labelSuffix && labelSuffixBold && <strong>{labelSuffix}</strong>}
          {labelSuffix && !labelSuffixBold && { labelSuffix }}
        </span>
      </span>
    )

  }

  render() {
    const { id, name, label, variant, hideLabel, isValid, helpText, errorMessage, labelSuffix, labelSuffixBold, ...rest } = this.props;

    const compiledIsValid = isValid === undefined ? true : isValid;

    const labelClasses = classNames({
      'form__label': true,
      'form__error': !compiledIsValid,
      'form__label-emphasized': variant === 'secure'
    });

    return (
      <div data-test={`form-control-${name}`} className='form__row'>
        <label id={`${id}_label`} className={labelClasses}>
          {this.renderLabelContent()}
          <Cleave
            ref='input'
            type='tel'
            name={name}
            className={'form__input'}
            id={id}
            aria-describedby={`${id}_help_text`}
            options={{
              phone: true,
              phoneRegionCode: 'AU'
            }}
            {...rest}
          />
        </label>
        {helpText && <p id={`${id}_help_text`} className="form__help-text" dangerouslySetInnerHTML={{ __html: helpText }}></p>}
        {!compiledIsValid && <span className="form__error-message">{errorMessage}</span>}
      </div>
    );
  }
}

export default MobilePhone;