import React from 'react';
import classNames from 'classnames';

export interface PasswordBoxProps extends React.HTMLProps<HTMLInputElement> {
  errorMessage?: string
  hideLabel?: boolean
  isValid?: boolean
  variant?: string
  label?: string
  helpText?: string
}

class PasswordBox extends React.Component<PasswordBoxProps> {

  focus() {
    const input = (this.refs.input as any);
    if(input && input.focus) input.focus();
  }
  
  render() {
    const { id, isValid, errorMessage, hideLabel, label, variant, helpText, ...rest } = this.props;
    const compiledIsValid = isValid === undefined ? true : isValid;

    const labelClassName = classNames({
      'form__label': true,
      'form__error': !compiledIsValid,
      'form__label-emphasized': variant === 'secure'
    });

    return (
      <div className='form__row'>
          <label id={`${id}_label`} className={labelClassName}>
            { hideLabel ? <span className='form__label-hide'>{label}</span> : label }    
            <input
              ref='input'
              type='password'
              className={'form__input'}
              id={id}
              aria-describedby={`${id}_help_text`}
              {...rest}  
            />
          </label>
          { helpText && <p id={`${id}_help_text`} className="form__help-text" dangerouslySetInnerHTML={{__html: helpText}}></p> }
          { !compiledIsValid && <span className="form__error-message">{errorMessage}</span> }
        </div>
    );
  }
};

export default PasswordBox;
