import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

import { ButtonDropdown } from '../../shared';
import { toOptionModels } from '../../../utils/object-helper';

type AmountTime = 'Weekly' | 'Fortnightly';
const amountTimes: AmountTime[] = ['Weekly', 'Fortnightly']

interface AmountTotalsProps {
  className?: string
  amount: number
  fees: number
  borrowed: number
  repayment: number
  total: number
  term: number
  calculating: boolean
}

interface AmountTotalsState {
  value: AmountTime
}

const AmountTotals: React.FC<AmountTotalsProps> = ({ className, calculating, amount, fees, repayment, total }) => {

  const [state, setState] = useState<AmountTotalsState>({
    value: 'Weekly',
  });

  const onChange = (value: AmountTime) => {
    if (state.value === value) return;
    const mutiplier = value === 'Fortnightly' ? 2 : 1
    const calculatedRepayment = repayment * mutiplier;
    setState((ps) => ({ ...ps, value: value, calculatedRepayment: calculatedRepayment }));
  };

  const getRepayment = () => state.value === 'Fortnightly' ? repayment * 2 : repayment;
  const getInterestDisplay = () => {
    const interest = total - amount - fees;
    return interest < 0 ? '...' : `$${interest}`;
  }

  const renderButtonLabel = () => {
    return (
      <div className='d-flex align-items-center' id='amount-totals-repayment'>
        <FontAwesomeIcon className='fa-icon-primary mr-10' icon={faCalendarAlt} />
        <div className='flex-1'>
          <span style={({ fontWeight: 600 })}> ${getRepayment()} </span>
          <span style={{ fontWeight: "normal" }}>  / Estimated {state.value} Repayment</span>
        </div>
        <FontAwesomeIcon className='text-right ml-10' icon={faChevronDown} />
      </div>
    )
  }

  const classes = classNames({ 'amount-total': true, [className!]: className !== undefined })
  return (
    <div className={classes}>
      <ButtonDropdown key={v4()}
        className='mb-10'
        value={state.value}
        onChange={onChange}
        options={toOptionModels(amountTimes)}
        buttonLabel={() => renderButtonLabel()}
      />
      <p className='text-center'>
        {calculating && <Skeleton count={2} />}
        {!calculating && (
          <React.Fragment>
            <span>Total Repayments of </span>
            <span id='amount-totals-total'><strong>${total} </strong></span>
            <span>are made up of an establishment fee of </span>
            <span id='amount-totals-fees'><strong>${fees}</strong></span>
            <span>, plus fees and charges of </span>
            <span id='amount-totals-interest'><strong>{getInterestDisplay()}</strong>.  </span>
            <span>Values are estimated and may vary depending on actual loan terms.</span>
          </React.Fragment>
        )}
      </p>
    </div>
  );
};

export default AmountTotals;