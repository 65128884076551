import { PrefilledPersonalDetails, PersonalDetailsTitles } from "../types/loan";
import { tryGetDateDisplay } from "./date-helper";
import { SubmitPersonalDetailsRequest } from "../api/loan-application/api models";

export const toSubmitPersonalDetailsRequest = (from: PrefilledPersonalDetails, consent: boolean = false,
  marketing: boolean = false) => {

  if (!from) return {} as SubmitPersonalDetailsRequest
  const foundTitle = !from.title ? undefined : PersonalDetailsTitles.find(e => e.toLowerCase() === from.title.toLowerCase());

  const to: SubmitPersonalDetailsRequest = {
    title: foundTitle as any,
    firstName: from.firstName,
    middleName: from.middleName,
    surname: from.surname,
    dateOfBirth: tryGetDateDisplay(from.dateOfBirth) || '',
    mobile: from.mobilePhone,
    email: from.email,
    unitOrLevel: from.unitOrLevel,
    streetNumber: from.streetNumber,
    street: from.street,
    suburb: from.suburb,
    state: from.region,
    postcode: from.postCode,

    loanApplicationId: undefined as any,
    consent: consent,
    marketing: marketing,
  };
  return to;
}

export const getAddressLineForSubmitPersonalDetails = (values: SubmitPersonalDetailsRequest) => {
  if (!values) return undefined;
  const parts: string[] = [];
  if (values.unitOrLevel) parts.push(values.unitOrLevel);
  if (values.streetNumber) parts.push(values.streetNumber);
  if (values.street) parts.push(`${values.street},`);
  if (values.suburb) parts.push(values.suburb);
  if (values.state) parts.push(values.state);
  if (values.postcode) parts.push(values.postcode);
  return parts.join(' ');
}