import React from 'react';
import { TrackerLoanApplication, TrackerItemContentProgress } from '../../../../../types/tracker';
import { TrackerProgressItem } from '../..';

interface DeclinedStepProps {
  application: TrackerLoanApplication
  status: string
  stepNumber: number
  progress?: TrackerItemContentProgress
}

const DeclinedStep: React.FC<DeclinedStepProps> = (props) => {
  const { application, status, stepNumber, progress } = props;

  const { declineReasons } = application;

  const title = progress && progress.subStatus ? progress.subStatus : 'Application declined';

  return (
    <TrackerProgressItem
      key={stepNumber}
      index={stepNumber}
      title={title}
      error={true}
      status={status}
    >
      {declineReasons &&
        <div>
          <span>
            Your application has been declined for the following reason{declineReasons.length !== 1 ? 's' : ''}:
          </span>
          <ul className="declined-reasons">
            {declineReasons.map((r, i) => <li key={i}><span>{r}</span></li>)}
          </ul>
        </div>
      }
    </TrackerProgressItem>
  );
}

export default DeclinedStep;