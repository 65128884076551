import React from 'react';
import { StatementProgress } from '../../../types/bank-statement';
import { ProgressBox } from '../../shared';

export interface BankProgressItemProps {
  disabled: boolean
  item: StatementProgress
  onReattempt: (item: StatementProgress) => void
}

const BankProgressItem: React.FC<BankProgressItemProps> = ({ item, disabled, onReattempt }) => {

  return (
    <ProgressBox
      heading={item.bankName}
      failed={item.isFailed}
      errorMessage={item.errorMessage}
      onReattempt={() => onReattempt(item)}
      disabled={disabled}
      steps={[
        { incompleteText: 'Connecting', completeText: 'Secured & connected', isComplete: item.hasAccounts },
        { incompleteText: 'Requesting documents', completeText: 'Documents received', isComplete: item.isNormalised },
        { incompleteText: 'Analysing', completeText: 'Complete', isComplete: item.isAnalysed },
      ]} />
  )
};

export default BankProgressItem;
