import React from 'react';
import Page from './page';
import Row from '../layout/row';

interface NotFoundPageProps { }

const NotFoundPage: React.FC<NotFoundPageProps> = (props) => {
  return (
    <div id='not-found-page'>
      <Page
        title="Error - Not Found"
        lead="Sorry, we can't seem to find the page you're looking for.">
        <Row>
          <div className='tracker__errors'>
            <p>Here are some links that you might find useful:</p>
            <ul>
              <li><a href="https://www.cashconverters.com.au/">Return to the home page</a></li>
              <li><a href="/">Start a new application</a></li>
              <li><a href="https://www.cashconverters.com.au/company/contact-us">Contact us</a></li>
            </ul>
          </div>
        </Row>
      </Page>
    </div>
  );
}

export default NotFoundPage;