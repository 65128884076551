import { UiState } from '../../types/state';
import * as Routes from '../routes';
import { SonicPage } from '../../utils/SonicPage';
import { SonicPageType } from '../../types/route-types';
import { ResumePage, ResumePageType } from './resume-types';


export const ResumePageTypes: ResumePageType[] = ['LoanDetails',
  'PersonalDetails',
  'MobileVerification',
  'Bank',
  'BankStatements',
  'IncomeDeclaration',
  'ExpenseDeclaration',
  'LoanDeclaration',
  'Centrelink',
  'ThankYou'];

export const toSonicPage = (type: ResumePageType): SonicPageType => {
  switch (type) {
    case 'LoanDetails': return 'LoanDetails';
    case 'LoanDetailsLoc': return 'LoanDetailsLoc';
    case 'LoanDetailsLocIncrease': return 'LoanDetailsLocIncrease';
    case 'PersonalDetails': return 'PersonalDetails';
    case 'PersonalDetailsLoc': return 'PersonalDetailsLoc';
    case 'MobileVerification': return 'MobileVerification';
    case 'Bank': return 'BankSelect';
    case 'BankStatements': return 'BankAnother';
    case 'IncomeDeclaration': return 'IncomeDeclaration';
    case 'ExpenseDeclaration': return 'ExpenseDeclaration';
    case 'LoanDeclaration': return 'LoanDeclaration';
    case 'Centrelink': return 'CentrelinkLogin';
    case 'ThankYou': return 'ThankYou';
    default: return '' as any;
  }
}


export default class ResumeModel {

  completedPages: ResumePage[]
  loanId: string
  state: UiState
  analysisId?: string
  centrelinkId?: string

  hasCompletedPreviously: boolean
  isComplete: boolean
  cannotResume: boolean
  page?: SonicPage
  isCreditIncrease?: boolean;
  isLoc?: boolean;

  private addCompletedPage(page: SonicPage, completed: boolean) {
    this.completedPages.push({ type: page.page, complete: completed, path: page.fullPath(), display: page.display, disabled: false });
    this.completedPages[this.completedPages.length - 1].disabled = !this.canBeOnPageBySonicPage(page.page);
  }

  canBeOnPage(resumePageType: ResumePageType) {
    const type = toSonicPage(resumePageType);
    if (!type) return false;
    return this.canBeOnPageBySonicPage(type);

  }

  canBeOnPageBySonicPage(type: SonicPageType) {
    //Can only be on last page if completed
    if (type === 'ThankYou') return this.isComplete;

    //When complete cannot navigate to details anymore
    let noNavWhenComplete: SonicPageType[] = ['LoanDetails', 'PersonalDetails', 'MobileVerification', 'LoanDetailsLoc', 'LoanDetailsLocIncrease', 'PersonalDetailsLoc'];
    if (this.state.completionStatus.followUpState === 'CentrelinkStatement') {
      noNavWhenComplete = noNavWhenComplete.concat(['BankSelect', 'BankAnother', 'IncomeDeclaration', 'ExpenseDeclaration', 'LoanDeclaration']);
    }

    if (this.hasCompletedPreviously && noNavWhenComplete.includes(type)) {
      return false;
    }

    //With edge cases out just check the completed pages
    const pageComplete = this.completedPages.some(e => e.type === type);
    const isCurrent = this.page && this.page.page === type;

    return pageComplete || isCurrent;
  }

  constructor(state: UiState) {
    this.state = state;
    this.page = undefined;
    this.completedPages = [];
    this.loanId = state.loanApplicationId;
    this.analysisId = state.analysisId;
    this.centrelinkId = state.centrelinkId;
    this.isCreditIncrease = state.options.applicationType === 'CreditLimitIncrease';
    this.isLoc = state.options.applicationType === 'LineOfCredit';

    const completion = state.completionStatus;
    const hasSubmittedDate = completion.applicationSubmittedDate ? true : false;
    const hasCentrelink = completion.hasCentrelink || (hasSubmittedDate && state.centrelinkId) || state.completionStatus.followUpState === 'CentrelinkStatement' ? true : false;
    this.hasCompletedPreviously = completion.applicationSubmittedDates && completion.applicationSubmittedDates.length ? true : false;
    this.isComplete = hasSubmittedDate || (hasCentrelink && completion.centrelinkComplete) || (!hasCentrelink && completion.loanDeclarationComplete) || false;
    this.cannotResume = this.isComplete || completion.isLapsed || completion.isExpired || false;
    if (this.cannotResume) return;

    const determineLoanDetailsPage = () => {
      switch (true) {
        case this.isLoc:
          return Routes.pages.loanDetailsLoc;
        case this.isCreditIncrease:
          return Routes.pages.loanDetailsLocIncrease;
        default:
          return Routes.pages.loanDetails;
      }
    };

    if (!completion.loanAmountAndTermComplete || !completion.loanReasonComplete) {
      this.page = determineLoanDetailsPage();
      this.addCompletedPage(determineLoanDetailsPage(), false);
      return
    }

    this.addCompletedPage(determineLoanDetailsPage(), true);

    if (!completion.personalDetailsComplete) {
      this.page = this.isCreditIncrease || this.isLoc ? Routes.pages.personalDetailsLoc : Routes.pages.personalDetails;
      this.addCompletedPage(this.isCreditIncrease || this.isLoc  ? Routes.pages.personalDetailsLoc : Routes.pages.personalDetails, false);
      return
    }
    this.addCompletedPage(this.isCreditIncrease || this.isLoc  ? Routes.pages.personalDetailsLoc : Routes.pages.personalDetails, true);

    if (!completion.mobileVerified && !completion.mobileVerificationSkipped) {
      this.page = this.isCreditIncrease || this.isLoc ? Routes.pages.mobileVerificationLoc : Routes.pages.mobileVerification;
      this.addCompletedPage(this.isCreditIncrease || this.isLoc  ? Routes.pages.mobileVerificationLoc : Routes.pages.mobileVerification, false);
      return
    }

    this.addCompletedPage(this.isCreditIncrease || this.isLoc  ? Routes.pages.mobileVerificationLoc : Routes.pages.mobileVerification, true);

    if (!this.analysisId) {
      this.page = Routes.pages.bankSelect;
      this.addCompletedPage(Routes.pages.bankSelect, false);
      return
    }

    const hasLoginProgress = this.state.bankProgress && this.state.bankProgress.length > 0;
    if (!completion.bankComplete) {
      if (hasLoginProgress) {
        this.page = Routes.pages.bankAnother;
        this.addCompletedPage(Routes.pages.bankSelect, true);
        this.addCompletedPage(Routes.pages.bankAnother, false);
        return;
      } else {
        this.page = Routes.pages.bankSelect;
        this.addCompletedPage(Routes.pages.bankSelect, false);
        return;
      }
    }

    this.addCompletedPage(Routes.pages.bankSelect, true);

    if (!completion.incomeDeclarationComplete) {
      this.page = Routes.pages.bankAnother;
      this.addCompletedPage(Routes.pages.bankAnother, true);
      this.addCompletedPage(Routes.pages.incomeDeclaration, false);
      return;
    }

    this.addCompletedPage(Routes.pages.bankAnother, true);
    this.addCompletedPage(Routes.pages.incomeDeclaration, true);

    if (!completion.expensesDeclarationComplete) {
      this.page = Routes.pages.expenseDeclaration;
      this.addCompletedPage(Routes.pages.expenseDeclaration, false);
      return;
    }

    this.addCompletedPage(Routes.pages.expenseDeclaration, true);

    if (!completion.loanDeclarationComplete) {
      this.page = Routes.pages.loanDeclaration;
      this.addCompletedPage(Routes.pages.loanDeclaration, false);
      return;
    }

    this.addCompletedPage(Routes.pages.loanDeclaration, true);

    if (hasCentrelink) {
      this.page = Routes.pages.centrelinkLogin;
      this.addCompletedPage(Routes.pages.centrelinkLogin, false);
      return;
    }

    if (!this.page) {
      this.cannotResume = true;
    }
  }

}