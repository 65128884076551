import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps, TrackerItemCallToActionProps } from '../../../types/tracker';

interface BankStatementsRequestedProps extends BaseTrackerContentProps {
}

const BankStatementsRequested: React.FC<BankStatementsRequestedProps> = (props) => {

  const descriptor = props.application.latestDocumentRequest && props.application.latestDocumentRequest.requestReason ?
    props.application.latestDocumentRequest.requestReason : undefined;

  const callToAction: TrackerItemCallToActionProps = {
    type: "sonicFollowUpRoute",
    label: "Resend bank statements",
    action: "/bank-statement?reset=true&isFollowUp=true"
  };

  return (
    <Page
      title="Bank statements needed"
      lead="You're almost there. But, there was a problem with your bank statements that needs to be fixed before we can continue.">
      <Row>
        <TrackerPageMainContent
          {...props}
          action={{
            title: "Bank statements",
            descriptor: descriptor,
            callToAction: callToAction
          }}
          optional={{
            descriptor: "Please resend us your bank statements to continue.",
            callToAction: {
              type: "bookmark",
              label: "Save this page",
            }
          }}
        />
      </Row>
      <Row>
        <TrackerPageSteps
          {...props}
          progress={{ callToAction: callToAction }}
        />
      </Row>
    </Page>
  );
};

export default BankStatementsRequested;
