import React from 'react';
import { TrackerLoanApplication } from '../../../../../types/tracker';
import * as Service from '../../../tracker-service';
import { TrackerProgressItem } from '../..';

interface SubmissionStepProps {
  application: TrackerLoanApplication
  status: string
  stepNumber: number
}

const SubmissionStep: React.FC<SubmissionStepProps> = ({ application, status, stepNumber }) => {

  let topLevelReason = '';
  const { submittedApplication } = application;

  switch (application.submittedApplication.reason.reason) {
    case 'SchoolEducationWork':
      topLevelReason = 'School / Education / Work';
      break;
    case 'EngagementWeddingFuneral':
      topLevelReason = 'Engagement / Wedding / Funeral';
      break;
    case 'BirthdaySeasonalHolidays':
      topLevelReason = 'Birthday / Seasonal Holidays';
      break;
    default:
      topLevelReason = application.submittedApplication.reason.reason;
      break;
  }
  //Loc Application
  if(submittedApplication.loanType === 'Loc' && submittedApplication.withdrawAmount == null){
    return (
      <TrackerProgressItem
        key={stepNumber}
        index={stepNumber}
        title="Application submitted"
        status={status}
      >
        <div>
          <span className="label">Date:</span>
          {Service.formatDate(submittedApplication.timestamp)
          }<br />
          <span className="label">Time:</span>
          {Service.formatTime(submittedApplication.timestamp)}
          <br />
          <span className="label">Requested Credit Limit:</span>
          ${submittedApplication.loanAmount.toFixed(2)}
          <br />
          <span className="label">Loan purpose:</span>
          {topLevelReason} - {submittedApplication.reason.subReason}
          <br />
        </div>
      </TrackerProgressItem>
    );
  //CLI Application
  } else if(submittedApplication.loanType === 'Loc'){
    return (
      <TrackerProgressItem
        key={stepNumber}
        index={stepNumber}
        title="Application submitted"
        status={status}
      >
        <div>
          <span className="label">Date:</span>
          {Service.formatDate(submittedApplication.timestamp)
          }<br />
          <span className="label">Time:</span>
          {Service.formatTime(submittedApplication.timestamp)}
          <br />
          <span className="label">Withdraw Amount requested:</span>
          ${(submittedApplication.withdrawAmount ?? 0).toFixed(2)}
          <br />
          <span className="label">Requested Credit Limit:</span>
          ${submittedApplication.loanAmount.toFixed(2)}
          <br />
          <span className="label">Loan purpose:</span>
          {topLevelReason} - {submittedApplication.reason.subReason}
          <br />
        </div>
      </TrackerProgressItem>
    );  
  //Loan Application 
  } else {
    return (
      <TrackerProgressItem
        key={stepNumber}
        index={stepNumber}
        title="Application submitted"
        status={status}
      >
        <div>
          <span className="label">Date:</span>
          {Service.formatDate(submittedApplication.timestamp)
          }<br />
          <span className="label">Time:</span>
          {Service.formatTime(submittedApplication.timestamp)}
          <br />
          <span className="label">Cash in your account:</span>
          ${submittedApplication.loanAmount.toFixed(2)}
          <br />
          <span className="label">Loan purpose:</span>
          {topLevelReason} - {submittedApplication.reason.subReason}
          <br />
        </div>
      </TrackerProgressItem>
    );
  } 
}

export default SubmissionStep;