import React from 'react';

import { SimpleModal } from '../../../shared';
import { BankTransactionModel } from '../../../../types/state';
import TransactionList from './transaction-list';

interface FindIncomeDescriptionModalProps {
  show: boolean
  onClose: () => void
  transactions: BankTransactionModel[]
  chosenDescriptions: string[]
  onChoose: (transaction: string) => void
  onICantFindClick: () => void
}

const FindIncomeDescriptionModal: React.FC<FindIncomeDescriptionModalProps> = ({ show, onClose, transactions, onChoose, onICantFindClick, chosenDescriptions }) => {

  return (
    <SimpleModal className='find-income-description-modal' show={show} onClose={onClose}>
      <h3>Add additional income</h3>
      <p className="lead">
        Please select the transaction from your bank statement that best represents your income.
      </p>
      <TransactionList
        transactions={transactions}
        chosenDescriptions={chosenDescriptions}
        onChoose={onChoose}
        onICantFindClick={onICantFindClick}
      />
    </SimpleModal>
  )
};


export default FindIncomeDescriptionModal;