import React, { ReactNode, CSSProperties, HTMLProps, ChangeEvent } from 'react';
import { findDOMNode } from 'react-dom';
import scrollIntoView from 'dom-scroll-into-view';

//DEV NOTE
//Brought this into the project from https://github.com/reactjs/react-autocomplete
//was having a bug when calling scrollIntoView and there was no way to fix it
//TODO - Use a different component which is more maintained

const IMPERATIVE_API = [
  'blur',
  'checkValidity',
  'click',
  'focus',
  'select',
  'setCustomValidity',
  'setSelectionRange',
  'setRangeText',
]

function getScrollOffset() {
  return {
    x: (window.pageXOffset !== undefined)
      ? window.pageXOffset
      : (document.documentElement || document.body.parentNode || document.body).scrollLeft,
    y: (window.pageYOffset !== undefined)
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body).scrollTop,
  }
}

export interface AutoCompleteProps {
  items: any[];
  value?: any;
  name?: string
  isValid?: boolean
  errorMessage?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>, value: string) => void;
  onSelect?: (value: string, item: any) => void;
  shouldItemRender?: (item: any, value: string) => boolean;
  isItemSelectable?: (item: any) => boolean;
  sortItems?: (a: any, b: any, value: string) => number;
  getItemValue: (item: any) => string;
  renderItem: (item: any, isHighlighted: boolean, styles?: CSSProperties) => ReactNode;
  renderMenu?: (
    items: ReactNode[],
    value: string,
    styles: CSSProperties,
  ) => ReactNode;
  menuStyle?: CSSProperties;
  renderInput?: (props: HTMLProps<HTMLInputElement>) => ReactNode;
  inputProps?: HTMLProps<HTMLInputElement>;
  wrapperProps?: HTMLProps<HTMLDivElement>;
  wrapperStyle?: CSSProperties;
  autoHighlight?: boolean;
  selectOnBlur?: boolean;
  onMenuVisibilityChange?: (isOpen: boolean) => void;
  open?: boolean;
  debug?: boolean;
}

interface AutoCompleteState {
  isOpen: boolean;
  highlightedIndex: number | null;
  menuLeft?: number;
  menuTop?: number;
  menuWidth?: number;
}

class Autocomplete extends React.Component<AutoCompleteProps, AutoCompleteState> {

  static defaultProps = {
    value: '',
    wrapperProps: {},
    wrapperStyle: {
      display: 'inline-block'
    },
    inputProps: {},
    renderInput(props: any) {
      return <input {...props} />
    },
    onChange() { },
    onSelect() { },
    isItemSelectable() { return true },
    renderMenu(items: any, value: any, style: any) {
      return <div style={{ ...style, ...this.menuStyle }} children={items} />
    },
    menuStyle: {
      borderRadius: '3px',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '2px 0',
      fontSize: '90%',
      position: 'fixed',
      overflow: 'auto',
      maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
    },
    autoHighlight: true,
    selectOnBlur: false,
    onMenuVisibilityChange() { },
  }

  _debugStates: any[]
  _ignoreBlur: boolean
  _ignoreFocus: boolean
  _scrollOffset: any
  _scrollTimer: any

  constructor(props: AutoCompleteProps) {
    super(props)

    this._ignoreBlur = false
    this._ignoreFocus = false
    this._scrollOffset = null
    this._scrollTimer = null

    this.state = {
      isOpen: false,
      highlightedIndex: null,
      menuLeft: undefined,
      menuTop: undefined,
      menuWidth: undefined
    }

    this._debugStates = []
    this.ensureHighlightedIndex = this.ensureHighlightedIndex.bind(this)
    this.exposeAPI = this.exposeAPI.bind(this)
    this.handleInputFocus = this.handleInputFocus.bind(this)
    this.handleInputBlur = this.handleInputBlur.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleInputClick = this.handleInputClick.bind(this)
    this.maybeAutoCompleteText = this.maybeAutoCompleteText.bind(this)
  }

  componentWillMount() {
    // this.refs is frozen, so we need to assign a new object to it
    this.refs = {}
    this._ignoreBlur = false
    this._ignoreFocus = false
    this._scrollOffset = null
    this._scrollTimer = null
  }

  componentWillUnmount() {
    clearTimeout(this._scrollTimer)
    this._scrollTimer = null
  }

  componentWillReceiveProps(nextProps: AutoCompleteProps) {
    if (this.state.highlightedIndex !== null) {
      this.setState(this.ensureHighlightedIndex as any)
    }
    if (nextProps.autoHighlight && (this.props.value !== nextProps.value || this.state.highlightedIndex === null)) {
      this.setState(this.maybeAutoCompleteText)
    }
  }

  componentDidMount() {
    if (this.isOpen()) {
      this.setMenuPositions()
    }
  }

  componentDidUpdate(prevProps: AutoCompleteProps, prevState: AutoCompleteState) {
    if ((this.state.isOpen && !prevState.isOpen) || ('open' in this.props && this.props.open && !prevProps.open))
      this.setMenuPositions()

    this.maybeScrollItemIntoView()
    if (prevState.isOpen !== this.state.isOpen) {
      this.props.onMenuVisibilityChange!(this.state.isOpen)
    }
  }

  exposeAPI(el: any) {
    const self: any = this;
    self.refs.input = el
    IMPERATIVE_API.forEach(ev => self[ev] = (el && el[ev] && el[ev].bind(el)))
  }

  maybeScrollItemIntoView() {
    if (this.isOpen() && this.state.highlightedIndex !== null) {
      const itemNode = this.refs[`item-${this.state.highlightedIndex}`]
      const menuNode = this.refs.menu;
      if (itemNode && menuNode) {
        scrollIntoView(
          findDOMNode(itemNode),
          findDOMNode(menuNode),
          { onlyScrollIfNeeded: true }
        )
      }
    }
  }

  handleKeyDown(event: any) {
    if ((Autocomplete.keyDownHandlers as any)[event.key])
      (Autocomplete.keyDownHandlers as any)[event.key].call(this, event)
    else if (!this.isOpen()) {
      this.setState({
        isOpen: true
      })
    }
  }

  handleChange(event: any) {
    this.props.onChange!(event, event.target.value)
  }

  static keyDownHandlers = {
    ArrowDown(event: any) {
      const self: any = this;
      event.preventDefault()
      const items = self.getFilteredItems(self.props)
      if (!items.length) return
      const { highlightedIndex } = self.state
      let index = highlightedIndex === null ? -1 : highlightedIndex
      for (let i = 0; i < items.length; i++) {
        const p = (index + i + 1) % items.length
        if (self.props.isItemSelectable(items[p])) {
          index = p
          break
        }
      }
      if (index > -1 && index !== highlightedIndex) {
        self.setState({
          highlightedIndex: index,
          isOpen: true,
        })
      }
    },

    ArrowUp(event: any) {
      event.preventDefault()
      const self: any = this;
      const items = self.getFilteredItems(self.props)
      if (!items.length) return
      const { highlightedIndex } = self.state
      let index = highlightedIndex === null ? items.length : highlightedIndex
      for (let i = 0; i < items.length; i++) {
        const p = (index - (1 + i) + items.length) % items.length
        if (self.props.isItemSelectable(items[p])) {
          index = p
          break
        }
      }
      if (index !== items.length) {
        self.setState({
          highlightedIndex: index,
          isOpen: true,
        })
      }
    },

    Enter(event: any) {
      const self: any = this;
      // Key code 229 is used for selecting items from character selectors (Pinyin, Kana, etc)
      if (event.keyCode !== 13) return
      // In case the user is currently hovering over the menu
      self.setIgnoreBlur(false)
      if (!self.isOpen()) {
        // menu is closed so there is no selection to accept -> do nothing
        return
      }
      else if (self.state.highlightedIndex == null) {
        // input has focus but no menu item is selected + enter is hit -> close the menu, highlight whatever's in input
        self.setState({
          isOpen: false
        }, () => {
          self.refs.input.select()
        })
      }
      else {
        // text entered + menu item has been highlighted + enter is hit -> update value to that of selected menu item, close the menu
        event.preventDefault()
        const item = self.getFilteredItems(self.props)[self.state.highlightedIndex];
        
        const value = self.props.getItemValue(item);
        // eslint-disable-next-line no-unexpected-multiline
        self.setState({
          isOpen: false,
          highlightedIndex: null
        }, () => {
        //this.refs.input.focus() // TODO: file issue
          self.refs.input.setSelectionRange(
            value.length,
            value.length
          )
          // eslint-disable-next-line no-unexpected-multiline
          self.props.onSelect(value, item)
        })
      }
    },

    Escape() {
      const self: any = this;
      // In case the user is currently hovering over the menu
      self.setIgnoreBlur(false)
        // eslint-disable-next-line no-unexpected-multiline
        self.setState({
          highlightedIndex: null,
          isOpen: false
        })
    },

    Tab() {
      // In case the user is currently hovering over the menu
      (this as any).setIgnoreBlur(false)
    },
  }

  getFilteredItems(props: AutoCompleteProps) {
    let items = props.items

    if (props.shouldItemRender) {
      items = items.filter((item) => (
        props.shouldItemRender!(item, props.value)
      ))
    }

    if (props.sortItems) {
      items.sort((a, b) => (
        props.sortItems!(a, b, props.value)
      ))
    }

    return items
  }

  maybeAutoCompleteText(state: any, props: any) {
    const { highlightedIndex } = state
    const { value, getItemValue } = props
    let index = highlightedIndex === null ? 0 : highlightedIndex
    let items = this.getFilteredItems(props)
    for (let i = 0; i < items.length; i++) {
      if (props.isItemSelectable(items[index]))
        break
      index = (index + 1) % items.length
    }
    const matchedItem = items[index] && props.isItemSelectable(items[index]) ? items[index] : null
    if (value !== '' && matchedItem) {
      const itemValue = getItemValue(matchedItem)
      const itemValueDoesMatch = (itemValue.toLowerCase().indexOf(
        value.toLowerCase()
      ) === 0)
      if (itemValueDoesMatch) {
        return { highlightedIndex: index }
      }
    }
    return { highlightedIndex: null }
  }

  ensureHighlightedIndex(state: AutoCompleteState, props: AutoCompleteProps) {
    if (!state.highlightedIndex || state.highlightedIndex >= this.getFilteredItems(props).length) {
      return { highlightedIndex: null }
    }
  }

  setMenuPositions() {
    const node = this.refs.input
    const rect = (node as any).getBoundingClientRect()
    const computedStyle = (global as any).window.getComputedStyle(node)
    const marginBottom = parseInt(computedStyle.marginBottom, 10) || 0
    const marginLeft = parseInt(computedStyle.marginLeft, 10) || 0
    const marginRight = parseInt(computedStyle.marginRight, 10) || 0
    this.setState({
      menuTop: rect.bottom + marginBottom,
      menuLeft: rect.left + marginLeft,
      menuWidth: rect.width + marginLeft + marginRight
    })
  }

  highlightItemFromMouse(index: any) {
    this.setState({ highlightedIndex: index })
  }

  selectItemFromMouse(item: any) {
    const value = this.props.getItemValue(item)
    // The menu will de-render before a mouseLeave event
    // happens. Clear the flag to release control over focus
    this.setIgnoreBlur(false)
    this.setState({
      isOpen: false,
      highlightedIndex: null
    }, () => {
      this.props.onSelect!(value, item)
    })
  }

  setIgnoreBlur(ignore: any) {
    this._ignoreBlur = ignore
  }

  renderMenu() {
    const items = this.getFilteredItems(this.props).map((item: any, index: any) => {
      const element = this.props.renderItem(
        item,
        this.state.highlightedIndex === index,
        { cursor: 'default' }
      )
      return React.cloneElement((element as any), {
        onMouseEnter: this.props.isItemSelectable!(item) ?
          () => this.highlightItemFromMouse(index) : null,
        onClick: this.props.isItemSelectable!(item) ?
          () => this.selectItemFromMouse(item) : null,
        ref: (e: any) => this.refs[`item-${index}`] = e,
      })
    })
    const style = {
      left: this.state.menuLeft,
      top: this.state.menuTop,
      minWidth: this.state.menuWidth,
    }
    const menu = this.props.renderMenu!(items, this.props.value, style)
    return React.cloneElement((menu as any), {
      ref: (e: any) => (this.refs as any).menu = e,
      // Ignore blur to prevent menu from de-rendering before we can process click
      onTouchStart: () => this.setIgnoreBlur(true),
      onMouseEnter: () => this.setIgnoreBlur(true),
      onMouseLeave: () => this.setIgnoreBlur(false),
    })
  }

  handleInputBlur(event: any) {
    if (this._ignoreBlur) {
      this._ignoreFocus = true
      this._scrollOffset = getScrollOffset();
      (this.refs as any).input.focus()
      return
    }
    let setStateCallback
    const { highlightedIndex } = this.state
    if (this.props.selectOnBlur && highlightedIndex !== null) {
      const items = this.getFilteredItems(this.props)
      const item = items[highlightedIndex]
      const value = this.props.getItemValue(item)
      setStateCallback = () => this.props.onSelect!(value, item);
    }
    this.setState({
      isOpen: false,
      highlightedIndex: null
    }, setStateCallback)
    const { onBlur } = this.props.inputProps!;
    if (onBlur) {
      onBlur(event)
    }
  }

  handleInputFocus(event: any) {
    if (this._ignoreFocus) {
      this._ignoreFocus = false
      const { x, y } = this._scrollOffset
      this._scrollOffset = null
      // Focus will cause the browser to scroll the <input> into view.
      // This can cause the mouse coords to change, which in turn
      // could cause a new highlight to happen, cancelling the click
      // event (when selecting with the mouse)
      window.scrollTo(x, y)
      // Some browsers wait until all focus event handlers have been
      // processed before scrolling the <input> into view, so let's
      // scroll again on the next tick to ensure we're back to where
      // the user was before focus was lost. We could do the deferred
      // scroll only, but that causes a jarring split second jump in
      // some browsers that scroll before the focus event handlers
      // are triggered.
      clearTimeout(this._scrollTimer)
      this._scrollTimer = setTimeout(() => {
        this._scrollTimer = null
        window.scrollTo(x, y)
      }, 0)
      return
    }
    this.setState({ isOpen: true })
    const { onFocus } = this.props.inputProps!
    if (onFocus) {
      onFocus(event)
    }
  }

  isInputFocused() {
    const el = this.refs.input
    return (el as any).ownerDocument && (el === (el as any).ownerDocument.activeElement)
  }

  handleInputClick() {
    // Input will not be focused if it's disabled
    if (this.isInputFocused() && !this.isOpen())
      this.setState({ isOpen: true })
  }

  composeEventHandlers(internal: any, external: any) {
    return external
      ? (e: any) => { internal(e); external(e) }
      : internal
  }

  isOpen() {
    return 'open' in this.props ? this.props.open : this.state.isOpen
  }

  render() {
    if (this.props.debug) { // you don't like it, you love it
      this._debugStates.push({
        id: this._debugStates.length,
        state: this.state
      })
    }

    const { inputProps, isValid, errorMessage } = this.props
    const open = this.isOpen()
    const compiledIsValid = isValid === undefined ? true : isValid;

    return (
      <React.Fragment>
        <div style={{ ...this.props.wrapperStyle }} {...this.props.wrapperProps}>
          {this.props.renderInput!({
            ...inputProps,
            name: this.props.name,
            role: 'combobox',
            'aria-autocomplete': 'list',
            'aria-expanded': open,
            autoComplete: 'off',
            ref: this.exposeAPI,
            onFocus: this.handleInputFocus,
            onBlur: this.handleInputBlur,
            onChange: this.handleChange,
            onKeyDown: this.composeEventHandlers(this.handleKeyDown, inputProps!.onKeyDown),
            onClick: this.composeEventHandlers(this.handleInputClick, inputProps!.onClick),
            value: this.props.value,
          })}
          {open && this.renderMenu()}
          {this.props.debug && (
            <pre style={{ marginLeft: 300 }}>
              {JSON.stringify(this._debugStates.slice(Math.max(0, this._debugStates.length - 5), this._debugStates.length), null, 2)}
            </pre>
          )}
        </div>
        {!compiledIsValid && <span className='form__error-message'>{errorMessage}</span>}
      </React.Fragment>
    )
  }
}

export default Autocomplete;