import React from 'react';
import classNames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ListItem, { ListItemProps } from './list-item';
import { Alert } from '../../shared';

interface ListProps {
  listItemClassName?: string
  className?: string
  noItemsMessage?: string
  items?: ListItemProps[]
  loading?: boolean
  showNoItemsMessage?: boolean
}
const List: React.FC<ListProps> = ({ listItemClassName, className, noItemsMessage, items, loading, children, showNoItemsMessage }) => {
  const noItemsCompiledMessage = noItemsMessage || 'No Items';

  const compiledClassName = classNames({
    'list': true,
    [className!]: className !== undefined
  });

  return (
    <div className={compiledClassName}>
      {(!items || !items.length) && !loading && showNoItemsMessage && (
        <Alert data-test='no-list-items-alert' className='text-center' message={noItemsCompiledMessage} variant='info' />
      )}
      {items && (
        <TransitionGroup>
          {items.map((item, i) => (
            <CSSTransition key={item.key || i} timeout={200} classNames='list-item'>
              <ListItem className={listItemClassName} {...item} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      )}
      {children}
    </div>
  );
};

export default List;

export { ListItem };