import React from 'react';
import { ModalContainer, ModalDialog } from './react-modal-dialog';
import Submit from '../buttons/submit';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

interface ConfirmModalProps {
  show?: boolean
  title?: string
  bodyText?: string
  onClose: () => void
  onConfirm: () => void
  confirmText?: string
  cancelText?: string
  isExternal?: boolean
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ title, bodyText, show, onConfirm, onClose, cancelText, confirmText, children, isExternal }) => {

  return !show ? null : (
    <ModalContainer onClose={onClose}>
      <ModalDialog onClose={onClose} className='modal confirm-modal'>
        <h1>{title || 'Are you sure?'}</h1>
        <div className='pt-10 pb-10'>
          {bodyText && <p>{bodyText}</p>}
          {children}
        </div>
        {isExternal ? 
        <Submit id='confirm-modal-ok' variant='primary' text={confirmText || 'Ok'} onSubmit={onConfirm} faIcon={faExternalLinkAlt} iconPlacement='after' />
        :
        <Submit id='confirm-modal-ok' variant='primary' text={confirmText || 'Ok'} onSubmit={onConfirm}  />
        }
        <Submit id='confirm-modal-cancel' variant='secondary' text={cancelText || 'Cancel'} onSubmit={onClose} />
      </ModalDialog>
    </ModalContainer>
  );
};

export default ConfirmModal;