import React from 'react';
import { Page, Row } from '../../shared';
import StatusBox from '../shared/status-box';
import * as Service from '../tracker-service';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps } from '../../../types/tracker';

interface ProofOfIdRequestedProps extends BaseTrackerContentProps {
}

const ProofOfIdRequested: React.FC<ProofOfIdRequestedProps> = (props) => {

  return (
    <Page
      title="Proof of ID needed"
      lead="You're almost there. But, we need to confirm your ID before we can continue.">
      <Row>
        <StatusBox type={"uploadForm"}>
          <h2>Proof of ID</h2>
          <p>Please provide from the following lists of accepted Identification documents either:</p>
          <ul>
            <li>Two forms of Photo ID or;</li>
            <li>One form of Photo ID and One form of Other ID</li>
          </ul>
          <table>
            <thead>
              <th>Photo ID</th>
              <th>Other ID</th>
            </thead>
            <tr>
              <td>Current Driver's License</td>
              <td>Birth Certificate</td>
            </tr>
            <tr>
              <td>Current Passport</td>
              <td>Medicare Card</td>
            </tr>
            <tr>
              <td>Current Proof of Age Card</td>
              <td>Concession or Health Care Card</td>
            </tr>
            <tr>
              <td>Current Photo Identification Card</td>
            </tr>
          </table>
          {Service.renderCallToAction({
              type: "uploadForm",
              label: "Provide copy of ID",
              documentType: 'Identification'
            },
            props.applicationId, props.onSonicFollowUpRoute, props.onSonicRoute)}
        </StatusBox>
      </Row>
      <Row>
        <TrackerPageSteps {...props} />
      </Row>
    </Page>
  );
}

export default ProofOfIdRequested;
