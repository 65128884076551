//Loan Pages
import App from "../index";
import Start from "../1-loan-application/start";
import LoanDetails from "../1-loan-application/submit-loan-details";
import LoanDetailsLoc from "../1-loan-application-loc/submit-loan-details";
import LoanDetailsLocIncrease from "../1-loan-application-loc/submit-increase-details";
import PersonalDetails from "../1-loan-application/submit-personal-details/personal-details";
import PersonalDetailsConfirm from "../1-loan-application/submit-personal-details/confirm";
import MobileVerification from "../1-loan-application/submit-mobile-verification";
import ResetMobileVerification from "../1-loan-application/reset-mobile-verification";
import ThankYou from "../1-loan-application/thank-you";
import PersonalDetailsLoc from '../1-loan-application-loc/submit-personal-details/personal-details'

//Bank Pages
import BankSelect from "../2-bank-statement/select";
import BankLogin from "../2-bank-statement/login";
import BankMfa from "../2-bank-statement/mfa";
import BankAnother from "../2-bank-statement/another-bank";
import IncomeDeclaration from "../2-bank-statement/income";
import ExpensesDeclaration from "../2-bank-statement/expenses";
import LoansDeclaration from "../2-bank-statement/loans";

//Centrelink Pages
import CentrelinkLogin from "../3-centrelink-statement/login";
import CentrelinkMfa from "../3-centrelink-statement/mfa";
import CentrelinkDownloading from "../3-centrelink-statement/downloading";
import CentrelinkManualDocumentUpload from "../3-centrelink-statement/manual-document-upload";
import CentrelinkUnavailable from "../3-centrelink-statement/Unavailable";

//Other Pages
import CarLoans from "../car-loans";
import Resume from "../resume";
import Tracker from "../tracker";
import Offline from "../offline";
import { SonicPageType} from "../../types/route-types";
import StartLoanApplicationLoc from "../1-loan-application-loc/start";
import { prefixes } from "../../utils/page-prefixes";

export const getComponent = (type: SonicPageType) => {
  return window.location.href.includes(prefixes.loc) ? loadLOCComponent(type) : loadComponent(type);
};

const loadLOCComponent = (type: SonicPageType) => {
  switch (type) {
    case "RootLoc":
      return StartLoanApplicationLoc;
    case "StartLoc":
      return StartLoanApplicationLoc;
    case "LoanDetailsLoc":
      return LoanDetailsLoc;
    case "LoanDetailsLocIncrease":
      return LoanDetailsLocIncrease;
    case "PersonalDetailsLoc":
      return PersonalDetailsLoc;
    case "PersonalDetailsConfirmLoc":
      return PersonalDetailsConfirm;
    case "MobileVerification":
      return MobileVerification;
    case "ResetMobileVerification":
      return ResetMobileVerification;
    case "BankSelect":
      return BankSelect;
    case "BankLogin":
      return BankLogin;
    case "BankMfa":
      return BankMfa;
    case "BankAnother":
      return BankAnother;
    case "IncomeDeclaration":
      return IncomeDeclaration;
    case "ExpenseDeclaration":
      return ExpensesDeclaration;
    case "LoanDeclaration":
      return LoansDeclaration;
    case "CentrelinkLogin":
      return CentrelinkLogin;
    case "CentrelinkMfa":
      return CentrelinkMfa;
    case "CentrelinkDownloading":
      return CentrelinkDownloading;
    case "CentrelinkManualDocumentUpload":
      return CentrelinkManualDocumentUpload;
    case "CentrelinkUnavailable":
      return CentrelinkUnavailable;
    case "ThankYou":
      return ThankYou;
    case "Resume":
      return Resume;
    case "Tracker":
      return Tracker;
    case "CarLoans":
      return CarLoans;
    case "Offline":
      return Offline;
    default:
      return undefined as any;
  }
};

const loadComponent = (type: SonicPageType) => {
  switch (type) {
    case "Root":
      return App;
    case "Start":
      return Start;
    case "LoanDetails":
      return LoanDetails;
    case "PersonalDetails":
      return PersonalDetails;
    case "PersonalDetailsConfirm":
      return PersonalDetailsConfirm;
    case "MobileVerification":
      return MobileVerification;
    case "ResetMobileVerification":
      return ResetMobileVerification;
    case "BankSelect":
      return BankSelect;
    case "BankLogin":
      return BankLogin;
    case "BankMfa":
      return BankMfa;
    case "BankAnother":
      return BankAnother;
    case "IncomeDeclaration":
      return IncomeDeclaration;
    case "ExpenseDeclaration":
      return ExpensesDeclaration;
    case "LoanDeclaration":
      return LoansDeclaration;
    case "CentrelinkLogin":
      return CentrelinkLogin;
    case "CentrelinkMfa":
      return CentrelinkMfa;
    case "CentrelinkDownloading":
      return CentrelinkDownloading;
    case "CentrelinkManualDocumentUpload":
      return CentrelinkManualDocumentUpload;
    case "CentrelinkUnavailable":
      return CentrelinkUnavailable;
    case "ThankYou":
      return ThankYou;
    case "Resume":
      return Resume;
    case "Tracker":
      return Tracker;
    case "CarLoans":
      return CarLoans;
    case "Offline":
      return Offline;
    default:
      return undefined as any;
  }
};
