import React, { useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, SimpleModal } from '../../shared';

interface TroubleWithLoginProps {
}

const TroubleWithLogin: React.FC<TroubleWithLoginProps> = () => {
  const [showInformation, setShowInformation] = useState<boolean>(false);
  const toggle = () => setShowInformation(!showInformation);

  return (
    <div>
      <Button
        id='trouble-with-login'
        variant='tertiary'
        size='small'
        text="Having trouble with your myGov login? Click here for help"
        faIcon={faInfoCircle}
        iconPlacement='before'
        onClick={toggle}
      />
      <SimpleModal show={showInformation} onClose={toggle}>
        <h2>Having trouble?</h2>
        <p>Here&apos;s some things that might help:</p>
        <ul>
          <li>To reset your myGov password click <a href="https://my.gov.au/CredentialManager/credentialManager.htm?_flowId=mgv2ResetPassword-flow&_flowExecutionKey=e1s1" target="_blank" rel="noopener noreferrer">here</a></li>
          <li>On occasion the myGov service is not available. Check <a href="https://my.gov.au" target="_blank" rel="noopener noreferrer">my.gov.au</a> to confirm.</li>
          <li>If you need more time you can "Save progress" and return later to try again.</li>
        </ul>
      </SimpleModal>
    </div>
  );
}

export default TroubleWithLogin;