import React from 'react';
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export interface InfoTooltipProps {
  text: string
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({ text }) => {
  return (
    <React.Fragment>
      <FontAwesomeIcon data-tip='react-tooltip' className='form-info-tooltip-button' icon={faInfoCircle} />
      <ReactTooltip className='tooltip-container' type='info' effect='solid' multiline={true}>
        {text}
      </ReactTooltip>
    </React.Fragment>
  )
}

export default InfoTooltip;
