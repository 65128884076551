import React from 'react';

interface CentrelinkFormHeaderProps { }

const CentrelinkFormHeader: React.FC<CentrelinkFormHeaderProps> = (props) => {
  return (
    <div className='login form__row'>
      <img className='login__heading-logo' src='/images/data/_loans/mygov.png' alt='myGov' />
      <div className='login__heading-text'><h2>myGov</h2></div>
    </div>
  );
};

export default CentrelinkFormHeader;