import axios from 'axios';
import appSettings from '../../settings';
import { CreateApiResponse, PreloadRequest, BankLoginRequest, MultiFactorAuthRequest, DeclareExpensesRequest, SubmitIncomeRequest, ConfirmLoansRequest, AddDocumentRequest, CreateApiRequest, GenerarteOpenBankingUrlRequest, checkOpenBankingAccountsRequest } from './api models';

export const relativeUrls = {
  create: '/create',
  preload: '/command/preload',
  provisoLogin: '/command/provisoLogin',
  yodleeLogin: '/command/yodleeLogin',
  fortunaLogin: '/command/fortunaLogin',
  testLogin: '/command/testLogin',
  provisoMfa: '/command/provisoMfa',
  yodleeMfa: '/command/yodleeMfa',
  fortunaMfa: '/command/fortunaMfa',
  declareExpenses: '/command/declareExpenses',
  submitIncome: '/command/submitIncome',
  confirmLoans: '/command/confirmLoans',
  addDocument: '/command/addDocument',
  generateOpenBankingConsent: '/openbanking/command/generateconsenturl',
  triggerOpenBankingStatement: '/openbanking/command/checkAccounts'
}

const baseUrl = `${appSettings.apiUrl}/analysis`;

export const create = (request: CreateApiRequest) => {
  return axios.post<CreateApiResponse>(`${baseUrl}${relativeUrls.create}`, request)
};

export const preload = (request: PreloadRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.preload}`, request);
};

export const provisoLogin = (request: BankLoginRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.provisoLogin}`, request);
};

export const yodleeLogin = (request: BankLoginRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.yodleeLogin}`, request);
};

export const fortunaLogin = (request: BankLoginRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.fortunaLogin}`, request);
};

export const testLogin = (request: BankLoginRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.testLogin}`, request);
};

export const provisoMfa = (request: MultiFactorAuthRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.provisoMfa}`, request);
};

export const yodleeMfa = (request: MultiFactorAuthRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.yodleeMfa}`, request);
};

export const fortunaMfa = (request: MultiFactorAuthRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.fortunaMfa}`, request);
};

export const declareExpenses = (request: DeclareExpensesRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.declareExpenses}`, sanitiseExpenseRequest(request));
};

export const submitIncome = (request: SubmitIncomeRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.submitIncome}`, request);
};

export const confirmLoans = (request: ConfirmLoansRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.confirmLoans}`, request);
};

export const addDocument = (request: AddDocumentRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.addDocument}`, request);
};

//fix to integrate with the back end which expects the amount as a string. 
const sanitiseExpenseRequest = (request: DeclareExpensesRequest) => {
  if (request.accommodation && request.accommodation.amount) (request.accommodation.amount as any) = request.accommodation.amount.toString();
  if (request.groceries && request.groceries.amount) (request.groceries.amount as any) = request.groceries.amount.toString();
  if (request.communication && request.communication.amount) (request.communication.amount as any) = request.communication.amount.toString();
  if (request.insurance && request.insurance.amount) (request.insurance.amount as any) = request.insurance.amount.toString();
  if (request.transport && request.transport.amount) (request.transport.amount as any) = request.transport.amount.toString();
  if (request.bnpl && request.bnpl.amount) (request.bnpl.amount as any) = request.bnpl.amount.toString();
  if (request.utilities && request.utilities.amount) (request.utilities.amount as any) = request.utilities.amount.toString();
  if (request.childcare && request.childcare.amount) (request.childcare.amount as any) = request.childcare.amount.toString();
  if (request.other && request.other.amount) (request.other.amount as any) = request.other.amount.toString();

  return request;
}

export const sanitisePhoneNumber = (phoneNumber: string) => {
  const number = phoneNumber.replaceAll(' ', '');
  if(number.startsWith('04')) return `+614${number.substring(2)}`;
  return number;
  
}

// Open Banking functions

export const generateOpenBankingConsentUrl  = (request: GenerarteOpenBankingUrlRequest) => {
 return axios.post(`${baseUrl}${relativeUrls.generateOpenBankingConsent}`, request)
}

export const triggerOpenBankingDownload = (request: checkOpenBankingAccountsRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.triggerOpenBankingStatement}`, request)
}