import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps } from '../../../types/tracker';

interface LapsedInStoreProps extends BaseTrackerContentProps {
}

const LapsedInStore: React.FC<LapsedInStoreProps> = (props) => {

  return (
    <Page
      title="Application lapsed"
      lead="It’s been a while since you visited this page and your application has lapsed. You can either start a new application or contact our customer service team.">
      <Row>
        <TrackerPageMainContent
          {...props}
          action={{
            title: "Start a new application",
            descriptor: "To start a new application please contact your store.",
            callToAction: {
              type: "externalLink",
              label: "Find your nearest store",
              action: "https://www.cashconverters.com.au/store-locator"
            }
          }}
        />
      </Row>
      <Row>
        <TrackerPageSteps
          {...props}
          progress={{
            callToAction: {
              type: "externalLink",
              label: "Find your nearest store",
              action: "https://www.cashconverters.com.au/store-locator"
            }
          }}
        />
      </Row>
    </Page>
  );
}

export default LapsedInStore;
