import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';

import * as CentrelinkApi from '../../api/centrelink';
import * as LoanApi from '../../api/loan-application';
import { useAppContext } from '../state-manager/app-context';
import { UiState } from '../../types/state';
import ResumeModel from '../resume/resume-model';
import { getQueryParam } from '../../utils/url-helper';
import { getPagesByPrefix } from '../routes';
import { getComponent } from '../routes/route-component-helper';

export interface CentrelinkStatementProps extends RouteComponentProps { }

const CentrelinkStatement: React.FC<CentrelinkStatementProps> = (props) => {

  //Route Props
  const { match, location } = props;

  //AppContext Props
  const { setErrorMessage, uiState, setUiState, setLoading, sourceId, navigate } = useAppContext();

  const [pageInitialising, setPageInitialising] = useState(true);

  const initFinished = () => {
    setPageInitialising(false);
    setLoading(false);
  }

  async function init() {
    try {
      setLoading(true);

      if (!sourceId) {
        navigate('Root');
        return;
      }

      let shouldUpdateUiState = false;
      let currentState: UiState = !uiState ? (undefined as any) : { ...uiState };
      if (!currentState) {
        currentState = (await LoanApi.getState({ loanApplicationId: sourceId })).data;
        shouldUpdateUiState = true;
      }

      //We are up to Centrelink.  Check now if we need to create an centrelink Id
      const shouldReset = getQueryParam(location.search, 'reset')?.toLowerCase() === 'true';
      const isFollowUp = getQueryParam(location.search, 'isFollowUp')?.toLowerCase() === 'true';

      if (shouldReset || isFollowUp) {

        if (shouldReset) {
          await CentrelinkApi.preload({ sourceId: sourceId });
        }

        if (isFollowUp) {
          await LoanApi.updateFollowUpState({ loanApplicationId: sourceId, state: 'CentrelinkStatement' });
        }

        currentState = (await LoanApi.getState({ loanApplicationId: sourceId })).data;
        shouldUpdateUiState = true;
      }

      const resumeModel = new ResumeModel(currentState);
      if (!resumeModel.page) {
        navigate('Resume', true, sourceId);
        return;
      }

      if (!currentState.centrelinkId) {
        await CentrelinkApi.preload({ sourceId: sourceId });
        currentState = (await LoanApi.getState({ loanApplicationId: sourceId })).data;
        shouldUpdateUiState = true;
      }

      //Now we are good to go
      if (shouldUpdateUiState) setUiState(currentState);

      //Navigate to first page if on the index page
      if (match.url === location.pathname) navigate('CentrelinkLogin', false);

      setPageInitialising(false);
      setLoading(false);
    }
    catch (e) {
      setErrorMessage('Sorry, we were not able to process your request.', false);
    }
    finally {
      initFinished();
    }
  }

  useEffect(() => {
    init();
  }, []);

  if (!sourceId || pageInitialising) {
    return null;
  }

  return (
    <React.Fragment>
      <Switch>
        {getPagesByPrefix('centrelink').map(page => (
          <Route
            key={page.fullPath()}
            exact
            path={page.fullPath()}
            component={getComponent(page.page)}
          />
        ))}
      </Switch>
    </React.Fragment>
  )
}

export default CentrelinkStatement;