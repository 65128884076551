import moment, { Moment } from "moment";

export const getAgeFromMoment = (moment: Moment) => {
  const defaultDate = new Date(1970, 0, 1);
  const todayMs = new Date().valueOf();
  const dobMs = new Date(moment.toDate()).valueOf();
  return new Date(todayMs - dobMs).getFullYear() - defaultDate.getFullYear();
}

export const getToday = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today
}

export const tryGetDateDisplay = (obj: any, displayFormat: string = 'DD/MM/YYYY') => {
  if (!obj) return undefined;
  const momentObj = moment(obj);
  if (!moment.isMoment(momentObj)) return undefined;
  return momentObj.format(displayFormat);
}