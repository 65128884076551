import axios from 'axios';
import appSettings from '../../settings';
import * as Models from './api models';
import { UiState } from '../../types/state';
import { nameOf } from '../../utils/object-helper';

export const relativeUrls = {
  //Visit
  visit: '/visit',

  //Commands
  startLoanApplication: '/command/startLoanApplication',
  associateWith: '/command/associateWith',
  addReferrer: '/command/addReferrer',
  submitLoanDetails: '/command/submitLoanDetails',
  submitChooseAmount: '/command/chooseAmount',
  submitChooseReasons: '/command/chooseReasons',
  submitPersonalDetails: '/command/submitPersonalDetails',
  submitAdditionalPersonalDetails: '/command/submitAdditionalPersonalDetails',
  submitMobileVerification: '/command/submitMobileVerification',
  resetMobileVerification: '/command/resetMobileVerification',
  skipMobileVerification: '/command/skipMobileVerification',
  resumeLoanApplication: '/command/resumeLoanApplication',
  updateFollowUpState: '/command/updateFollowUpState',
  sendResumeLink: '/command/sendResumeLink',

  //Token
  decryptPersonalDetailsToken: (token: string) => `/token/personal-details/decrypt/${token}`,

  //State
  getState: '/state',
  exchangeForTrackerSessionToken: '/state/exchangeForTrackerSessionToken',

  //Document
  uploadDocument: '/document',
}

const root = appSettings.apiUrl;
const documentRoot = appSettings.documentApiUrl;

export const visit = () => {
  return axios.get<Models.VisitApiResponse>(`${root}${relativeUrls.visit}`);
};

//Commands
export const startLoanApplication = (request: Models.StartLoanApplicationRequest) => {
  return axios.post(`${root}${relativeUrls.startLoanApplication}`, request);
}

export const associateWith = (request: Models.AssociateWithCommandRequest) => {
  return axios
  .post(`${root}${relativeUrls.associateWith}`, request);
}

export const addReferrer = (request: Models.AddReferrer) => {
  return axios.post(`${appSettings.apiUrl}${relativeUrls.addReferrer}`, request);
};

export const submitLoanDetails = (request: Models.SubmitLoanDetailsRequest) => {
  return axios.post(`${root}${relativeUrls.submitLoanDetails}`, request);
}

export const submitChooseAmount = (request: Models.ChooseLoanAmountRequest) => {
  return axios.post<any>(`${root}${relativeUrls.submitChooseAmount}`, request);
}

export const submitChooseReasons = (request: Models.ChooseLoanReasonsRequest) => {
  return axios.post<any>(`${root}${relativeUrls.submitChooseReasons}`, request);
}

export const submitPersonalDetails = (request: Models.SubmitPersonalDetailsRequest) => {
  return axios.post(`${root}${relativeUrls.submitPersonalDetails}`, request);
}

export const submitAdditionalPersonalDetails = (request: Models.SubmitAdditionalPersonalDetailsRequest) => {
  return axios.post<any>(`${root}${relativeUrls.submitAdditionalPersonalDetails}`, request);
}

export const submitMobileVerification = (request: Models.SubmitMobileVerificationRequest) => {
  return axios.post(`${root}${relativeUrls.submitMobileVerification}`, request);
}

export const resetMobileVerification = (request: Models.ResetMobileVerificationRequest) => {
  return axios.post(`${root}${relativeUrls.resetMobileVerification}`, request);
}

export const skipMobileVerification = (request: Models.BaseCommandRequest) => {
  return axios.post(`${root}${relativeUrls.skipMobileVerification}`, request);
}

export const resumeLoanApplication = (request: Models.BaseCommandRequest) => {
  return axios.post<UiState>(`${root}${relativeUrls.resumeLoanApplication}`, request);
}

export const updateFollowUpState = (request: Models.UpdateFollowUpState) => {
  return axios.post(`${root}${relativeUrls.updateFollowUpState}`, request);
}

export const sendResumeLink = (request: Models.SendResumeLinkCommand) => {
  return axios.post<UiState>(`${root}${relativeUrls.sendResumeLink}`, request);
}

//State
export const getState = (request: Models.GetStateRequest) => {
  return axios.post<UiState>(`${root}${relativeUrls.getState}`, request)
};

export const exchangeForTrackerSessionToken = (request: Models.ExchangeSessionTokenRequest) => {
  return axios.post<Models.ExchangeSessionTokenResult>(`${root}${relativeUrls.exchangeForTrackerSessionToken}`, request)
};

export const decryptPersonalDetails = (token: string) => {
  return axios.post<Models.PersonalDetailsTokenDecryptResult>(`${root}${relativeUrls.decryptPersonalDetailsToken(token)}`);
};

//Document
export const uploadDocument = (request: Models.UploadDocumentRequest) => {
  const data = new FormData();
  data.append(nameOf<Models.UploadDocumentRequest>('loanApplicationId'), request.loanApplicationId);
  data.append(nameOf<Models.UploadDocumentRequest>('centrelinkId'), request.centrelinkId === undefined ? "" : request.centrelinkId);
  data.append(nameOf<Models.UploadDocumentRequest>('documentType'), request.documentType);
  data.append(nameOf<Models.UploadDocumentRequest>('isFollowUp'), request.isFollowUp ? "true" : "false");
  request.files.forEach((file) => {
    data.append(nameOf<Models.UploadDocumentRequest>('files'), file);
  });


  return axios.post<Models.UploadDocumentResponse>(`${documentRoot}${relativeUrls.uploadDocument}`, data)
    .catch(r => {
      return axios.post<Models.UploadDocumentResponse>(`${root}${relativeUrls.uploadDocument}`, data);
    })
}
