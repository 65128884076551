import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

import * as CentrelinkApi from '../../../api/centrelink';
import { Page, Row, Submit, Button, Alert} from '../../shared';
import SkipButton from '../components/skip-button';
import { BaseCommandRequest } from '../../../api/centrelink/api models';
import { useAppContext } from '../../state-manager/app-context';
import DownloadProgress from './download-progress';
import { UiState } from '../../../types/state';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface DownloadingProps extends RouteComponentProps { }

const Downloading: React.FC<DownloadingProps> = () => {

  let refreshInterval: any = null;

  const appContext = useAppContext();
  const { executeCommandAndUpdateState, getAndUpdateUiState, navigate, loading, uiState } = appContext;
  const { centrelinkProgress, completionStatus: { centrelinkComplete, applicationSubmittedDates } } = uiState;
  const hasCentrelinkIssueAlert = (window as any).hasCentrelinkIssueAlert ? true : false;
  const centrelinkIssueAlert = (window as any).centrelinkIssueAlert;

  const updateProgress = (state: UiState) => {
    if (state.completionStatus.centrelinkComplete) {
      window.clearInterval(refreshInterval);
      return
    } else {
      getAndUpdateUiState();
    }
  }
  useEffect(() => {
    refreshInterval = window.setInterval(() => updateProgress(uiState), 5000);
    return () => window.clearInterval(refreshInterval);
  }, [uiState]);

  const resolveLeadText = () => {
    const { centrelinkProgress, completionStatus: { centrelinkComplete } } = appContext.uiState;
      if (centrelinkProgress.isFailed || centrelinkProgress.isSkipped) return `We were unable to connect to your account. <br/><br/>Your Centrelink statement has not been uploaded. <br/>To have your application assessed as quickly as possible, please email your statement to <strong>loans@cashconverters.com</strong> <br/><br/>If you have any questions, please call 13 22 74.`;
    if (centrelinkComplete) return 'We\'ve completed connecting to your account.';
    return 'We\'re connecting to your account. This should only take a minute.';
  }

  const handleReattempt = () => {
    const request: BaseCommandRequest = { centrelinkId: appContext.uiState.centrelinkId! };
    executeCommandAndUpdateState(() => CentrelinkApi.reattempt(request), true)
      .then(() => navigate('CentrelinkLogin'));
  }

  const handleSkip = () => {
    const command = () => CentrelinkApi.skipStatement({ centrelinkId: appContext.uiState.centrelinkId! });
    executeCommandAndUpdateState(command, true);
  }

  const manualUpload = () => navigate('CentrelinkManualDocumentUpload');
  const handleComplete = () => navigate('ThankYou', false);

  const canSkip = centrelinkProgress.isFailed && (!applicationSubmittedDates || !applicationSubmittedDates.length);

  return (
    <Page title='Your Centrelink statements' lead={resolveLeadText()}>
      {canSkip && hasCentrelinkIssueAlert && (<React.Fragment>
        <Row>
          <Alert variant='info' center message={centrelinkIssueAlert} />
        </Row>
      </React.Fragment>)}
      <Row size='small'>
        <DownloadProgress
          progress={centrelinkProgress}
          onReattempt={handleReattempt}
          submitting={loading}
        />
      </Row>
      <Row size='x-small'>
      {canSkip && hasCentrelinkIssueAlert && <Button
                id='manual-upload'
                text='Upload your Centrelink statements manually.'
                variant='tertiary-compact'
                faIcon={faArrowRight}
                iconPlacement='before'
                onClick={manualUpload}
              />}
        {canSkip && <SkipButton onClick={handleSkip} disabled={loading} />}
        {centrelinkComplete && (
          <Submit
            id='continue'
            text='Complete Application'
            onSubmit={handleComplete}
          />
        )}
      </Row>
    </Page>
  );
}

export default Downloading;