import moment, { Moment } from 'moment';
import { getToday } from '../../../../utils/date-helper';

export const required = (value: string): string | null => {
  if (value === undefined || !value.toString().trim().length) {
    return 'Field is required'
  }

  return null;
};

export const requiredArray = (message?: string) => (value: any[]): string | null => {
  if (!value || !value.length) {
    return message || 'Field is required'
  }

  return null;
};

export const requiredStringArray = (message?: string) => (array: string[]) => (value: string): string | null => {
  if (!value || !value.toString().trim().length || !array.some(e => e === value)) {
    return message || 'Field is required';
  }
  return null;
};

export const requiredWithMessage = (message: string) => (value: string): string | null => {
  return required(value) ? message : null;
};

export const mustBeTrueRequired = (message?: string) => (value: any): string | null => {
  if (!value) {
    return message || 'Field is required';
  }
  return null;
};

export const maxCharLength = (max: number) => {
  return (value: string | number): string | null => {
    if (!value || !max || max < 0) return null;
    if (value.toString().length > max) {
      return `Must be less than ${max + 1} characters`;
    }
    return null;
  };
};

export const charLength = (length: number) => {
  return (value: string | number): string | null => {
    if (!value || !length || length < 0) return null;
    if (value.toString().length !== length) {
      return `must be ${length} characters`;
    }
    return null;
  };
};

export const minCharLength = (min: number) => {
  return (value: string | number): string | null => {
    if (!value || !min || min < 0) return null;
    if (value.toString().length < min) {
      return `must be ${min} characters or more`;
    }
    return null;
  };
};

export const email = (value: string): string | null => {
  if (!value) return null;

  if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(value).toLowerCase())) {
    return 'Not a valid email';
  }
  return null;
};

export const number = (value: any): string | null => {
  if (value === null || value === undefined) return null;
  if (isNaN(value)) {
    return 'Not a valid Number';
  }
  return null;
};

export const integer = (value: number): string | null => {
    if (value === null || value === undefined) return null;
    if (isNaN(value)) return `Not a valid Number`;
    if (!Number.isInteger(value)) return `Must be a whole number`;
    return null;
};

export const numberGreaterThan = (number: number) => {
  return (value: any): string | null => {
    if (value === null || value === undefined || isNaN(value)) return null;
    if (value <= number) return `Must be greater than ${number}`;
    return null;
  };
};

export const numberLessThan = (number: number) => {
  return (value: any): string | null => {
    if (value === null || value === undefined || isNaN(value)) return null;
    if (value >= number) return `Must be less than ${number}`;
    return null;
  };
};

export const dollarRange = (minNumber: number, maxNumber: number) => {
  return (value: any): string | null => {
    if (value === null || value === undefined || isNaN(value)) return null;
    if (value < minNumber || value > maxNumber) return `Must be between $${minNumber} and $${maxNumber}`;
    return null;
  };
};

export const numberRange = (minNumber: number, maxNumber: number) => {
  return (value: any): string | null => {
    if (value === null || value === undefined || isNaN(value)) return null;
    if (value < minNumber || value > maxNumber) return `Must be between ${minNumber} and ${maxNumber}`;
    return null;
  };
};

export const matchField = (fieldName: string, message?: string) => {
  return (value: any, values: any): string | null => {
    if (!values) return null;
    if (values[fieldName] !== value) {
      if (!message) message = `Does not match ${fieldName}`;
      return message;
    }
    return null;
  };
};

export const minAge = (minAge: number, errorMessage?: string) => {
  return (value: string) => {
    if (!value) return null
    if (minAge < 0) return null
    const momentDate = moment(value, 'DD/MM/YYYY', true);
    if (!momentDate.isValid()) return null;

    const momentToCheck = moment().startOf('day').subtract(minAge, "years");
    if (momentDate.isAfter(momentToCheck)) {
      return errorMessage || `Must be older than ${minAge}`;
    }
    return null;
  }
}

export const maxAge = (maxAge: number, errorMessage?: string) => {
  return (value: string) => {
    if (!value) return null
    if (maxAge < 0) return null
    const momentDate = moment(value, 'DD/MM/YYYY', true);
    if (!momentDate.isValid()) return null;
    const momentToCheck = moment().startOf('day').subtract(maxAge, "years");

    if (momentDate.isBefore(momentToCheck)) {
      return errorMessage || `Must be younger than ${maxAge}`;
    }
    return null;
  }
}

export const dateNotFuture = (format: string = 'DD/MM/YYYY') => (value: string) => {
  if (!value) return null;
  return momentDateNotFuture(moment(value, format, true))
}

export const momentDateNotFuture = (value: Moment) => {
  if (!value) return null;
  if (!value.isValid()) return null;
  const date = value.toDate();
  date.setHours(0, 0, 0, 0)
  return date > getToday() ? 'Cannot be in the future' : null;
}

export const momentDate = (value: Moment) => {
  if (!value) return null;
  return !value.isValid() ? 'Must be a valid date' : null;
}

export const date = (format: string = 'DD/MM/YYYY') => (value: string) => {
  if (!value) return null;
  if (!moment(value, format, true).isValid()) {
    return `Must be a valid date ${format}`
  }
  return null;
}

export const noFullStops = (value: any): string | null => {
  if (!value) return null;
  return reggex(/^(?!.*[.].*).*$/, 'Please remove any periods (full stops)')(value);
};

export const noStars = (value: any): string | null => {
  if (!value) return null;
  return reggex(/^(?!.*[.].*).*$/, 'Please remove any *')(value);
};

export const noMultiSpecialChars = (value: any): string | null => {
  if (!value) return null;
  return reggex(/^(?!.*[ /\\\\-]{2,}.*).*$/, 'Please do not enter multiple adjacent special characters')(value);
};

export const startsWithNumer = (value: any): string | null => {
  if (!value) return null;
  return reggex(/^[0-9].*$/, 'Must start with a numeric value')(value);
};

export const alphaNumsSpacesHyphensSlashesOnly = (value: any): string | null => {
  if (!value) return null;
  return reggex(/^([A-Za-z0-9/\\\\ -])*$/, 'Please only contain alphanumerics, spaces, hyphens and slashes')(value);
};

export const alphaSpacesHyphensApostrophesOnly = (value: any): string | null => {
  if (!value) return null;
  return reggex(/^([A-Za-z '-]*)$|^$/, 'Please only enter A-Z characters, spaces, hyphens, and apostrophes')(value);
};

export const beginningAlpha = (message: string) => (value: any): string | null => {
  if (!value) return null;
  return reggex(/(^[A-Za-z])|^$/, message)(value);
};

export const noDuplicateSpecialChars = (value: any): string | null => {
  if (!value) return null;
  return reggex(/^(?!.*[ '-]{2,}.*).*$/, 'Please do not enter duplicate special characters')(value);
};

export const reggex = (expression: RegExp | string, message: string) => {
  return (value: any): string | null => {
    if (!value) return null;
    return RegExp(expression).test(value) ? null : message;
  };
};

export const mobilePhoneReggex = (mobileNumber: string) => {
  if (!mobileNumber) return null;
  return reggex(/^\s*0\s*[45]\s*(\d\s*){8}$/, 'Please enter a valid mobile number in the format 0___ ___ ___')(mobileNumber);
}

