import React from 'react';
import { TrackerLoanApplication, TrackerItemContentProgress } from '../../../../types/tracker';
import * as Helper from './helper';

import AssessmentStep from './steps/assessment';
import ContractStep from './steps/contract';
import DeclinedStep from './steps/declined';
import MoneySentStep from './steps/money-sent';
import ReviewStep from './steps/review';
import SubmissionStep from './steps/submission';
import WithdrawnStep from './steps/withdrawn';
import { TrackerProgress } from '..';

interface TrackerPageStepsProps {
  applicationId: string
  application: TrackerLoanApplication
  progress?: TrackerItemContentProgress
  onSonicFollowUpRoute: (route: string) => void
  onSonicRoute: (route: string) => void
}

const TrackerPageSteps: React.FC<TrackerPageStepsProps> = ({ application, applicationId, progress, onSonicFollowUpRoute, onSonicRoute }) => {

  // Reset so on re-render we have correct UI
  let pastStepComplete = false;
  const orderedStateKeys = Helper.sanitiseStateKeys(application);

  const items = orderedStateKeys.map((state, i) => {
    const isStepBeforeTermination = state === orderedStateKeys[1];
    const status = Helper.resolveStatus(application, state, pastStepComplete);
    if (status === 'current') pastStepComplete = true;
    const stepNumber = orderedStateKeys.length - i;

    switch (state) {
      case 'Submission':
        return (
          <SubmissionStep
            key={i}
            application={application}
            status={status}
            stepNumber={stepNumber}
          />
        );
      case 'Review':
        return (
          <ReviewStep
            key={i}
            application={application}
            applicationId={applicationId}
            isStepBeforeTermination={isStepBeforeTermination}
            onSonicFollowUpRoute={onSonicFollowUpRoute}
            onSonicRoute={onSonicRoute}
            status={status}
            stepNumber={stepNumber}
            progress={progress}
          />
        );
      case 'Assessment':
        return (
          <AssessmentStep
            key={i}
            application={application}
            applicationId={applicationId}
            isStepBeforeTermination={isStepBeforeTermination}
            onSonicFollowUpRoute={onSonicFollowUpRoute}
            onSonicRoute={onSonicRoute}
            status={status}
            stepNumber={stepNumber}
            progress={progress}
          />
        );
      case 'Contract':
        return (
          <ContractStep
            key={i}
            application={application}
            applicationId={applicationId}
            isStepBeforeTermination={isStepBeforeTermination}
            onSonicFollowUpRoute={onSonicFollowUpRoute}
            onSonicRoute={onSonicRoute}
            status={status}
            stepNumber={stepNumber}
            progress={progress}
          />
        );
      case 'MoneySent':
        return (
          <MoneySentStep
            key={i}
            application={application}
            status={status}
            stepNumber={stepNumber}
            progress={progress}
          />
        );
      case 'Withdrawn':
        return (
          <WithdrawnStep
            key={i}
            applicationId={applicationId}
            isStepBeforeTermination={isStepBeforeTermination}
            onSonicFollowUpRoute={onSonicFollowUpRoute}
            onSonicRoute={onSonicRoute}
            status={status}
            stepNumber={stepNumber}
            progress={progress}
          />
        );
      case 'Declined':
        return (
          <DeclinedStep
            key={i}
            application={application}
            status={status}
            stepNumber={stepNumber}
            progress={progress}
          />
        );
    }
  });

  return (
    <TrackerProgress
      current={application.currentState}
      lapsed={application.lapsed}
    >
      <h2>Your progress</h2>
      {items.reverse()}
    </TrackerProgress>
  );

}

export default TrackerPageSteps;