import React from 'react';
import MobilePhone, { MobilePhoneProps } from '../mobile-phone';
import { safeGetValue, safeGetError, shouldShowError } from './formik-helper';
import { FormikProps } from 'formik';

interface FormikDateOfBirthProps<T> extends MobilePhoneProps {
  name: string
  formikProps: FormikProps<T>
}

const FormikMobilePhone:React.FC<FormikDateOfBirthProps<any>> = ({ formikProps, name, onChange, onBlur, ref, ...rest }) => {

  const handleChange = (event: any) => {
    if (formikProps && formikProps.handleChange) {
      formikProps.handleChange(event);
    }

    if (onChange) onChange(event);
  };

  const handleBlur = (event: any) => {
    if (formikProps && formikProps.handleBlur) {
      formikProps.handleBlur(event);
    }

    if (onBlur) onBlur(event);
  };

  return (
    <MobilePhone 
      {...rest}
      ref={ref as any}
      name={name}
      value={safeGetValue(formikProps, name)}
      onChange={handleChange}
      onBlur={handleBlur}
      isValid={!shouldShowError(formikProps, name)}
      errorMessage={safeGetError(formikProps, name)}
    />
  );
};

export default FormikMobilePhone;