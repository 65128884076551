import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps } from '../../../types/tracker';

interface ProofDocumentsReceivedProps extends BaseTrackerContentProps {
}

const ProofDocumentsReceived: React.FC<ProofDocumentsReceivedProps> = (props) => {

  return (
    <Page
      title="In review"
      lead="Your application is with our review team to ensure we have everything needed to complete your assessment.">
      <Row>
        <TrackerPageMainContent
          {...props}
          action={{
            title: "Keep an eye on your email or…",
            callToAction: {
              type: "bookmark",
              label: "Save this page",
            }
          }}
          optional={{
            descriptor: "You can check this page any time for updates and information.",
          }}
        />
      </Row>
      <Row>
        <TrackerPageSteps
          {...props}
          progress={{
            callToAction: {
              type: "bookmark",
              label: "Save this page",
            }
          }}
        />
      </Row>
    </Page>
  );
}

export default ProofDocumentsReceived;
