import React from 'react';
import SelectBox, { SelectBoxProps } from '../select-box';
import { safeGetValue, safeGetError, shouldShowError } from './formik-helper';
import { FormikProps } from 'formik';

interface FormikSelectBoxProps<T> extends SelectBoxProps {
  name: string
  formikProps: FormikProps<T>
  cancelFormikHandleChange?: boolean
}

const FormikSelectBox: React.FC<FormikSelectBoxProps<any>> = ({ formikProps, name, onChange, cancelFormikHandleChange, onBlur, ref, ...rest }) => {

  const handleChange = (event: any) => {
    if (!cancelFormikHandleChange) {
      formikProps.handleChange(event);
    }

    if (onChange) onChange(event);
  };

  const handleBlur = (event: any) => {
    if (formikProps && formikProps.handleBlur) {
      formikProps.handleBlur(event);
    }

    if (onBlur) onBlur(event);
  };

  return (
    <SelectBox
      {...rest}
      ref={ref as any}
      name={name}
      value={safeGetValue(formikProps, name)}
      onChange={handleChange}
      onBlur={handleBlur}
      isValid={!shouldShowError(formikProps, name)}
      errorMessage={safeGetError(formikProps, name)}
    />
  );
};

export default FormikSelectBox;