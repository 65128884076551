import React, { useState } from 'react';
import { v4 } from 'uuid';
import { Formik, FormikHelpers } from 'formik';
import { FormikValidator, FormikSideEffects, FormikFileUpload } from '..';
import { requiredArray } from '../form/formik/validators';
import { nameOf } from '../../../utils/object-helper';
import Submit from '../buttons/submit';
import * as LoanApi from '../../../api/loan-application';
import { UploadDocumentRequest } from '../../../api/loan-application/api models';
import { DocumentType } from '../../../types/shared';
import Alert from '../alert/alert';
import Button from '../buttons/button';

interface DocumentUploadFormProps {
  applicationId: string
  centrelinkId?: string
  documentType: DocumentType
  label?: string
  submitButtonText?: string
  onBack?: () => void
  backButtonText?: string
  onSuccess: () => void
  isFollowUp?: boolean
}

interface State {
  error?: string
}

const DocumentUploadForm: React.FC<DocumentUploadFormProps> = (props) => {

  const { label, documentType, onSuccess, applicationId, centrelinkId, submitButtonText, onBack, backButtonText, isFollowUp } = props;

  const [state, setState] = useState<State>({});

  const validator = new FormikValidator<UploadDocumentRequest, DocumentUploadFormProps>({
    fields: [
      { name: 'files', validators: [requiredArray()] },
    ]
  });

  const handleSubmit = (values: UploadDocumentRequest, formikActions: FormikHelpers<UploadDocumentRequest>) => {
    setState((ps) => ({ ...ps, error: undefined }));
    formikActions.setSubmitting(true);
    return LoanApi.uploadDocument(values).then(e => {
      if (e.data.success) {
        onSuccess();
      } else {
        setState((ps) => ({ ...ps, error: e.data.error || 'An Error occured.  Please try again' }));
      }
      return e;
    }).catch(e => {
      setState((ps) => ({ ...ps, error: 'An Error occured.  Please try again' }));
      return e;
    }).finally(() => formikActions.setSubmitting(false));
  }

  const formId = 'submit-personal-details-form';
  const initial: UploadDocumentRequest = { loanApplicationId: applicationId, centrelinkId, isFollowUp, documentType, files: [] };

  return (
    <Formik
      initialValues={validator.getInitial(initial)}
      onSubmit={handleSubmit}
      validate={(v) => validator.validate(v, props)}
      render={(formikProps) => {
        return (
          <form id={formId} onSubmit={formikProps.handleSubmit}>
            {state.error && <Alert variant='error' center message={state.error} />}
            <FormikFileUpload
              uploadingMessage='Uploading and Checking for Viruses.  This may take a sec!'
              uploading={formikProps.isSubmitting}
              disabled={formikProps.isSubmitting}
              fileTypes={['PDF', 'Image']}
              name={nameOf<UploadDocumentRequest>('files')}
              formikProps={formikProps}
              label={label}
              multiple
              maxSizeMb={10}
            />
            <Submit
              disabled={formikProps.isSubmitting}
              onSubmit={formikProps.handleSubmit}
              id={v4()}
              variant='primary'
              text={submitButtonText || 'Submit Documents'}
            />
            {onBack && (
              <Button
                id={v4()}
                text={backButtonText || 'Back'}
                onClick={onBack}
                variant='secondary'
                disabled={formikProps.isSubmitting}
              />
            )}
            <FormikSideEffects formId={formId} />
          </form>
        );
      }}
    />
  );
};

export default DocumentUploadForm;