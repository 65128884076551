import React, { useEffect } from 'react';

interface FullStoryContainerProps {
  loanId?: string
  tempId: string
}

const data = {
  system_str: 'Cash Loans',
  solution_str: 'Sonic',
  context_str: 'Web'
};

const FullStoryContainer: React.FC<FullStoryContainerProps> = ({ loanId, tempId }) => {
  //Set up FullStory Session
  useEffect(() => {
    if ((window as any).FS && loanId) {
      (window as any).FS.identify(loanId, data);
    }
  }, [loanId]);

  useEffect(() => {
    if ((window as any).FS) {
      (window as any).FS.identify(tempId, data);
    }
  }, [tempId]);

  return null
};
export default FullStoryContainer;