import React from 'react';
import { RouteComponentProps } from 'react-router';
import Form from './form';
import * as LoanApi from '../../../api/loan-application';
import { ResetMobileVerificationRequest } from '../../../api/loan-application/api models';
import { Page, Row } from '../../shared';
import canBeOnPage, { BasePageProps } from '../../hoc/can-be-on-page';

export interface ResetMobileVerificationProps extends BasePageProps, RouteComponentProps { }

const ResetMobileVerification: React.FC<ResetMobileVerificationProps> = ({ appContext }) => {

  const {
    navigate, executeCommandAndUpdateState, loading,
    uiState: { loanApplicationId },
  } = appContext;

  const handleSubmit = (values: ResetMobileVerificationRequest) => {
    values.loanApplicationId = loanApplicationId;
    executeCommandAndUpdateState(() => LoanApi.resetMobileVerification(values), true)
      .then(() => navigate('MobileVerification'));
  };

  const handleSkip = () => {
    executeCommandAndUpdateState(() => LoanApi.skipMobileVerification({ loanApplicationId: loanApplicationId }), true)
      .then(() => navigate('BankSelect'));
  };

  return (
    <Page title="Try again" lead="Confirm your mobile number by entering it below. A new code will be sent to your phone.">
      <Row size="small" variant='secure'>
        <Form
          submitting={loading}
          onSubmit={handleSubmit}
          onSkip={handleSkip}
        />
      </Row>
    </Page>
  );
}

export default canBeOnPage(ResetMobileVerification, 'MobileVerification');