import React from 'react';
import classNames from 'classnames';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IconButtonProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  icon: IconDefinition
  variant?: 'primary-background'
  iconSize?: SizeProp
}

const IconButton: React.FC<IconButtonProps> = ({ icon, className, variant, iconSize, ...rest }) => {

  const classes = classNames({
    'button-icon': true,
    [variant as string]: variant !== undefined,
    [className!]: className !== undefined
  });

  return (
    <a className={classes} {...rest}>
      <FontAwesomeIcon icon={icon} size={iconSize} />
    </a>
  );
}

export default IconButton;