import React, { useState, useEffect } from 'react';
import { get, remove } from 'js-cookie';
import { Route, Redirect, RouteComponentProps, Switch } from 'react-router';
import Notifications from 'react-notify-toast';

import { ErrorPage, ProgressBar, Header, Footer, Loader, NotFoundPage } from './shared';
import * as OptionsApi from '../api/options';
import { logPathChange } from '../analytics-logging';
import * as Routes from './routes';
import { cookieNames } from './app-service';
import FullStory from './full-story-container';

import LoanApplication from './1-loan-application';
import BankStatement from './2-bank-statement';
import CentrelinkStatement from './3-centrelink-statement';
import { getComponent } from './routes/route-component-helper';
import withAppStateManager, { withAppStateManagerProps } from './state-manager/with-app-state-manager';
import LoanApplicationLoc from './1-loan-application-loc';
import { prefixes } from '../utils/page-prefixes';
import appSettings from '../settings';

interface AppProps extends RouteComponentProps, withAppStateManagerProps { }
const App: React.FC<AppProps> = (props) => {

  const [pageInitialising, setPageInitialising] = useState(true);

  const {
    location: { pathname },
    appContext: {
      restoreSourceId, restoreSessionToken, getAndUpdateUiState, setConfig, setLoading,
      banks, reasons, uiState, loading, errorMessage, sourceId, tempId
    }
  } = props;

  const { copyrightYear, releaseVersion } = appSettings;

  async function init() {
    try {
      const isTracker = pathname.toLowerCase().includes('tracker');
      const isResume = pathname.toLowerCase().includes('resume');
      if (isTracker || isResume) {
        remove(cookieNames.sourceId);
        remove(cookieNames.sessionToken);
      } else {
        restoreSessionToken();
        const sourceId = restoreSourceId();
        if (sourceId
          && pathname !== '/'
          && !pathname.toLowerCase().startsWith(prefixes.loan)
          && !pathname.toLowerCase().startsWith(prefixes.loc)) {
          getAndUpdateUiState(sourceId);
        }
      }

      //Ensure Ui Options is loaded
      if (!banks.length || !reasons.length) {
        const options = await OptionsApi.getOptions();
        setConfig(options.data);
      }
    }
    catch (e) {
      //Ignore
    }
    finally {

      setLoading(false);
      setPageInitialising(false);
    }
  }

  useEffect(() => {
    init();

  }, []);

  //ga on path change
  useEffect(() => {
    logPathChange(pathname);
    Routes.setPageTitle(pathname);
  }, [pathname]);

  //Force offline if offline setting is set
  if ((window as any).isOffline && pathname !== Routes.pages.offline.relativePath) {
    return <Redirect to='/offline' />
  }

  //If not offline dont allow browsing to offline
  if (!(window as any).isOffline && pathname === Routes.pages.offline.relativePath) {
    const sourceId = get(cookieNames.sourceId);
    if (sourceId) {
      return <Redirect to={Routes.pages.resume.fullPath(sourceId)} />
    } else {
      return <Redirect to={`${prefixes.loan}`} />
    }
  }

  if (pathname === '/') {
    return <Redirect to={`${prefixes.loan}`} />
  }

  return (
    <div className='app' id='app'>
      <Notifications />
      <div className='content'>
        <Header path={pathname} loanProduct={uiState?.product} applicationType={uiState?.options.applicationType} />
        <div id="loader-container" className={loading ? 'loading' : ''}>
          <Loader />
          {uiState ? <ProgressBar uiState={uiState} /> : null}
          {!pageInitialising && errorMessage && <ErrorPage errorMessage={errorMessage} />}
          {!pageInitialising && !errorMessage && (
            <React.Fragment>
              <Switch>
                <Route path={prefixes.loan} component={LoanApplication} />
                <Route path={prefixes.loc} component={LoanApplicationLoc} />
                <Route path={prefixes.bank} component={BankStatement} />
                <Route path={prefixes.centrelink} component={CentrelinkStatement} />
                <Route path={`${Routes.pages.tracker.fullPath()}:id`} component={getComponent('Tracker')} />
                <Route path={`${Routes.pages.resume.fullPath()}:id`} component={getComponent('Resume')} />
                <Route path={Routes.pages.offline.fullPath()} component={getComponent('Offline')} />
                <Route path={Routes.pages.carLoans.fullPath()} component={getComponent('CarLoans')} />
                <Route path='*' component={NotFoundPage} />
              </Switch>
            </React.Fragment>
          )}
        </div>
      </div>
      <Footer copyrightYear={copyrightYear} releaseVersion={releaseVersion} />
      <FullStory loanId={sourceId} tempId={tempId} />
    </div>
  );
};

export default withAppStateManager(App);