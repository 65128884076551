import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps } from '../../../types/tracker';

interface WithdrawnProps extends BaseTrackerContentProps {
}

const Withdrawn: React.FC<WithdrawnProps> = (props) => {

  return (
    <Page
      title="Application withdrawn"
      lead="You have withdrawn your existing application. To continue, you must start a new application.">
      <Row>
        <TrackerPageMainContent
          {...props}
          action={{
            callToAction: {
              type: "sonicRoute",
              label: "Start a new application",
              action: "/loan-application/"
            }
          }}
          optional={{
            descriptor: "If you’d like to speak to someone, you can contact us on 13 22 74",
          }}
        />
      </Row>
      <Row>
        <TrackerPageSteps
          {...props}
          progress={{
            callToAction: {
              type: "sonicRoute",
              label: "Start a new application",
              action: "/loan-application/"
            }
          }}
        />
      </Row>
    </Page>
  );
}

export default Withdrawn;
