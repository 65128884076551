import React, { useState } from 'react';
import classNames from 'classnames';
import { v4 } from 'uuid';
import FormikAutoComplete from '../formik-auto-complete';
import * as Service from './address-search-service';
import FormikTextBox from '../formik-text-box';
import FormikSelectBox from '../formik-select-box';
import { nameOf, toOptionModels } from '../../../../../utils/object-helper';
import { hasBeenTouched } from '../formik-helper';

let requestTimer: any = null;

const minSearchLength = 5;
const FormikAddressSearch: React.FC<Service.FormikAddressSearchProps> = (props) => {

  const [state, setState] = useState<Service.AddressSearchState>({
    manuallyEntered: false,
    open: false,
    loading: false,
    addresses: [],
  });

  const handleSelect = (text: string) => {
    if (text && (text.startsWith('No matches') || text.startsWith('My address'))) {
      setState((ps) => ({ ...ps, open: false, manuallyEntered: true, }));
      Service.clearManualAddress(props);
      return;
    }

    Service.handleParseAddress(text, props, setState);
  }

  const handleChange = (event: any, value: any) => {
    setState((ps) => ({ ...ps, loading: true }));
    if (requestTimer) clearTimeout(requestTimer);
    if (value && value.length >= 5) {
      requestTimer = setTimeout(() => { Service.searchAddresses(value, setState); }, 500);
    }
  }

  const renderMenu = (items: any, value: any) => {
    if (value === '') {
      return <div className='menu'><div className='item'>Start typing your address...</div></div>;
    }
    else if (state.loading) {
      if (!value || value.length < minSearchLength) {
        return <div className='menu'><div className='item'>Please enter more than {minSearchLength} characters</div></div>;
      } else {
        return <div className='menu'><div className='item'>Loading...</div></div>;
      }
    }
    else {
      return (<div className='menu'>{items}</div>);
    }
  }

  const { label, formikProps, placeholder, onBlur } = props;

  const showAddressSearchError = !state.manuallyEntered &&
    !Service.standardAddressValidator.isValid(formikProps.values, props) &&
    hasBeenTouched(formikProps, nameOf<Service.AddressFormValues>('addressSearch'));

  const compiledClassName = classNames({
    'form__error': showAddressSearchError,
    'form__label': true
  });

  return (
    <div className='form__row'>
      <div data-test={`form-control-${nameOf<Service.AddressFormValues>('addressSearch')}`} className='form__autocomplete'>
        <label id={`${v4()}_label`} className={compiledClassName}>
          {label}
          <FormikAutoComplete
            formikProps={formikProps}
            name={nameOf<Service.AddressFormValues>('addressSearch')}
            items={state.addresses}
            onSelect={handleSelect}
            onChange={handleChange}
            renderMenu={renderMenu}
            renderItem={(item, isHighlighted) => {
              var index = state.addresses.indexOf(item);
              return <div data-test='address-search-item' id={`address_search_item_${index}`} key={index} className={`item fs-hide ${isHighlighted ? 'item-highlighted' : 'item-not-highlighted'}`}>{item}</div>;
            }}
            wrapperStyle={{}}
            getItemValue={(item) => item}
            inputProps={{
              name: nameOf<Service.AddressFormValues>('addressSearch'),
              placeholder: placeholder,
              className: 'form__input',
              onBlur: (e) => { if (onBlur) onBlur(e); },
            }}
            onMenuVisibilityChange={(open) => { if (!formikProps.values[nameOf<Service.AddressFormValues>('addressSearch')]) setState(ps => ({ ...ps, open: open })) }}
            open={state.open}
          />
        </label>
        {showAddressSearchError && <span className="form__error-message">Please find or enter your address</span>}
      </div>
      {state.manuallyEntered && (
        <div id='address_input_manual_address_entry' className='form__row' style={{ display: state.manuallyEntered ? 'block' : 'none' }}>
          <FormikTextBox label='Unit or level' name={nameOf<Service.AddressFormValues>('unitOrLevel')} formikProps={formikProps} />
          <FormikTextBox label='Street number' name={nameOf<Service.AddressFormValues>('streetNumber')} formikProps={formikProps} />
          <FormikTextBox label='Street name' name={nameOf<Service.AddressFormValues>('street')} formikProps={formikProps} />
          <FormikTextBox label='Suburb' name={nameOf<Service.AddressFormValues>('suburb')} formikProps={formikProps} />
          <FormikSelectBox
            formikProps={formikProps}
            label='State'
            name={nameOf<Service.AddressFormValues>('state')}
            wrapperClasses='form__select-wrap'
            hideLabel={false}
            options={[
              { value: '', label: 'Please select' },
              ...toOptionModels(['ACT', 'NT', 'NSW', 'SA', 'TAS', 'QLD', 'VIC', 'WA'])
            ]}
          />
          <FormikTextBox inputMode='numeric' label='Postcode' name={nameOf<Service.AddressFormValues>('postcode')} formikProps={formikProps} />
        </div>
      )}
    </div>
  );
}

export default FormikAddressSearch;