import get from 'lodash/get';
import set from 'lodash/set';
import { OptionModel } from "../types/shared";
import { addSpacesOnCaps } from "./string-helper";

export const isInteger = (obj: any) => Number.isInteger(obj);

export const isArray = (obj: any) => obj instanceof Array;

export const isString = (obj: any) => Object.prototype.toString.call(obj) === "[object String]";

export const isObject = (obj: any) => typeof obj === "object" && (obj !== null);

export const isBoolean = (obj: any) => typeof obj === "boolean";

export const isRegex = (obj: any) => !(obj) && obj.constructor === RegExp;

export function nameOf<T, TNested = {}>(name: keyof T, nestedName?: keyof TNested) {
  const names: string[] = [name as string];
  if (nestedName) names.push(nestedName as string);
  return names.join('.');
};

export function getType<T>(type: T) {
  return type;
};

export const toOptionModels = (items: string[], addSpacesForLabelOnCaps?: boolean) => {
  return !items || !items.length ? [] as OptionModel<string>[] : items.map(i => {
    const item: OptionModel<string> = { value: i, label: addSpacesForLabelOnCaps ? addSpacesOnCaps(i) : i };
    return item;
  });
}

export const ensurePathExists = (obj: any, key: string) => {
  if (get(obj, key, null) === null) {
    set(obj, key, undefined);
  }
}