import React from 'react';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { replaceSpacesWithDashes } from '../../../utils/string-helper';

export interface ListItemProps {
  key?: string
  disabled?: boolean
  primaryText?: string
  secondaryText?: string
  faIcon?: IconDefinition
  iconImageSource?: string
  className?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onDelete?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  content?: any
  testDataOverrideName?: string
}

const ListItem: React.FC<ListItemProps> = ({ primaryText, secondaryText, faIcon, disabled, iconImageSource, className, children, onClick, onDelete, content, testDataOverrideName, ...rest }) => {

  const compiledClassName = classNames({
    'list-item': true,
    'disabled': disabled,
    [className!]: className !== undefined,
    'clickable': !disabled && onClick !== undefined
  });

  const dataName = !testDataOverrideName ? replaceSpacesWithDashes((primaryText || '')) : replaceSpacesWithDashes(testDataOverrideName);
  return (
    <div data-test={dataName} className={compiledClassName} onClick={onClick} {...rest}>
      <div className='list-item-main'>
        {(faIcon || iconImageSource) && (
          <div className='list-item-icon-container'>
            <div className='list-item-icon'>
              {faIcon && <FontAwesomeIcon icon={faIcon} />}
              {iconImageSource && <img src={iconImageSource} alt={primaryText} />}
            </div>
          </div>
        )}
        {(primaryText || secondaryText) && (
          <div className='list-item-text-container'>
            {primaryText && (<div className='list-item-primary-text'>{primaryText}</div>)}
            {secondaryText && (<div className='list-item-secondary-text'>{secondaryText}</div>)}
          </div>
        )}
        {content && (
          <div className='list-item-content-container'>
            {content}
          </div>
        )}
        {!disabled && onDelete && (
          <div className='list-item-actions'>
            <a className='list-item-delete-action' onClick={onDelete}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </a>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default ListItem;