import React from 'react';
import CaptchaBox, { CaptchaBoxProps } from '../captcha-box';
import { FormikProps } from 'formik';
import { safeGetValue } from './formik-helper';

interface FormikCaptchaBoxProps<T> extends CaptchaBoxProps {
  name: string
  formikProps: FormikProps<T>
}

const FormikCaptchaBox: React.FC<FormikCaptchaBoxProps<any>> = ({ formikProps, name, onChange, onBlur, ref, ...rest }) => {

  const handleChange = (event: any) => {
    if (formikProps && formikProps.handleChange) {
      formikProps.handleChange(event);
    }

    if (onChange) onChange(event);
  }

  const handleBlur = (event: any) => {
    if (formikProps && formikProps.handleBlur) {
      formikProps.handleBlur(event);
    }

    if (onBlur) onBlur(event);
  };

  return (
    <CaptchaBox
      {...rest}
      ref={ref as any}
      name={name}
      value={safeGetValue(formikProps, name)}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default FormikCaptchaBox;