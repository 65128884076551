import React from 'react';
import { FormikProps } from 'formik';
import { FormikTextBox } from '../../../../shared';
import { nameOf } from '../../../../../utils/object-helper';
import { IncomeFormDto } from '../../income-service';

interface TransactionInputProps {
  formikProps: FormikProps<IncomeFormDto>
  disabled?: boolean
  infoTooltipText?: string
}

const TransactionInput: React.FC<TransactionInputProps> = (props) => {

  return (
    <FormikTextBox
      className='income-transaction-field'
      name={nameOf<IncomeFormDto>('transactionDescription')}
      label='Transaction description'
      {...props}
    />
  )
};


export default TransactionInput;