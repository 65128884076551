import React from 'react';

interface LoaderProps { }

const Loader: React.FC<LoaderProps> = (props) => {
  return (
    <div className="loader-wrapper" key="loader">
      <div className="loader">
        <img src="/images/loader.svg" alt="Loading" />
        <div className="text">Please wait...</div>
      </div>
    </div>
  );
}

export default Loader;