import React from 'react';
import { Page, Row } from '../shared';

interface TrackerErrorPageProps { }

const TrackerErrorPage: React.FC<TrackerErrorPageProps> = () => {

  return (
    <Page
      title="Error - Not Found"
      lead="Sorry, we can’t seem to find the page you’re looking for.">
      <Row>
        <div id='tracker-error-page' className="tracker__errors">
          <p>Here are some links that you might find useful:</p>
          <ul>
            <li><a href="https://www.cashconverters.com.au/">Return to the home page</a></li>
            <li><a href="/">Start a new application</a></li>
            <li><a href="https://www.cashconverters.com.au/company/contact-us">Contact us</a></li>
          </ul>
        </div>
      </Row>
    </Page>
  );
}

export default TrackerErrorPage;