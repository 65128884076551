
export const generateNumberArray = (min: number, max: number, step: number) => {
  const array = [];
  for (let i = min; i <= max; i = i + step) {
    array.push(i);
  }
  return array;
};

export const getClosest = (numberArray: number[], value: number) => {
  if (!numberArray || !numberArray.length) {
    return value;
  }
  return numberArray.reduce((prev, curr) => {
    return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
  });
};

export const toCurrencyString = (num: number) => {
  const compiledNum = !num || isNaN(num) ? 0 : num;
  return '$' + compiledNum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}