import React from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';

import { List, Button, Alert } from '../../../shared';
import { BankTransactionModel } from '../../../../types/state';
import { ListItemProps } from '../../../shared/list/list-item';
import { toCurrencyString } from '../../../../utils/number-helper';

export interface GroupedTransactionModel {
  description: string
  items: BankTransactionModel[]
  itemCount: number
  totalCredit: number
  disabled?: boolean
  dates: string[]
}

interface TransactionListProps {
  transactions: BankTransactionModel[]
  chosenDescriptions: string[]
  onChoose: (transaction: string) => void
  onICantFindClick: () => void
}

const TransactionList: React.FC<TransactionListProps> = ({ transactions, chosenDescriptions, onChoose, onICantFindClick }) => {

  let groupedTransactions: GroupedTransactionModel[] = [];

  (transactions || [])
    .filter(t => t.type === 'Credit' && t.amount > 0 && moment(t.date).isAfter(moment().add('month', -1)))
    .forEach(t => {
      const found = groupedTransactions.find(tt => tt.description.toLowerCase() === t.description.toLowerCase());
      if (found) {
        found.items.push(t);
        found.itemCount++;
        found.totalCredit += t.amount;
        found.dates.push(t.date)
      } else {
        groupedTransactions.push({ description: t.description, items: [t], itemCount: 1, totalCredit: t.amount, disabled: chosenDescriptions.includes(t.description), dates: [t.date] });
      }
    });

  groupedTransactions = orderBy(groupedTransactions.filter(e => e.totalCredit >= 100), ['totalCredit'], 'desc');

  const getSecondaryDescription = (model: GroupedTransactionModel) => {
    const displays: string[] = [`Total: ${toCurrencyString(model.totalCredit)}`];
    displays.push(`Found ${model.itemCount} ${model.itemCount === 1 ? 'time' : 'times'}`);
    return displays.join(' | ');
  }

  const hasTransactions = groupedTransactions.length ? true : false;
  const canSelectItems = hasTransactions && !groupedTransactions.every(e => e.disabled);

  return (
    <React.Fragment>
      {!hasTransactions && (
        <Alert center variant='info' message="We couldn't find any credits on your bank statement" />
      )}
      <List
        items={groupedTransactions.map<ListItemProps>(t => ({
          primaryText: t.description,
          className: 'fs-hide', 
          secondaryText: getSecondaryDescription(t),
          onClick: () => onChoose(t.description),
          disabled: t.disabled
        }))}
      />
      <Button id='cant-find-income' variant='primary' text={canSelectItems ? "I can't find my income" : "Enter income manually"} onClick={onICantFindClick} />
    </React.Fragment>
  );
};


export default TransactionList;