import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps, TrackerItemCallToActionProps } from '../../../types/tracker';

interface CentrelinkStatementsRequestedProps extends BaseTrackerContentProps {
}

const CentrelinkStatementsRequested: React.FC<CentrelinkStatementsRequestedProps> = (props) => {

  const descriptor = props.application.latestDocumentRequest && props.application.latestDocumentRequest.requestReason ?
    props.application.latestDocumentRequest.requestReason : undefined;

  const callToAction: TrackerItemCallToActionProps = {
    type: "sonicFollowUpRoute",
    label: "Send my Centrelink statements",
    action: '/centrelink-statement?reset=true&isFollowUp=true'
  };

  return (
    <Page
      title="Centrelink statements needed"
      lead="You’re almost there. But, you need to send us your Centrelink statements to continue.">
      <Row>
        <TrackerPageMainContent
          {...props}
          action={{
            title: "Centrelink statements",
            descriptor: descriptor,
            callToAction: callToAction
          }}
          optional={{
            descriptor: "You can check this page any time for updates and information.",
            callToAction: {
              type: "bookmark",
              label: "Save this page",
            }
          }}
        />
      </Row>
      <Row>
        <TrackerPageSteps
          {...props}
          progress={{ callToAction: callToAction }}
        />
      </Row>
    </Page>
  );
}

export default CentrelinkStatementsRequested;
