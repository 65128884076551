import React from 'react';
import { RouteComponentProps } from 'react-router';
import * as CentrelinkApi from '../../../api/centrelink';
import { Page, Row, Alert, Button } from '../../shared';
import FormHeader from '../components/form-header';
import AboutThisProcess from '../components/about-this-process';
import { CentrelinkLoginRequest } from '../../../api/centrelink/api models';
import { useAppContext } from '../../state-manager/app-context';
import LoginForm from './login-form';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface LoginProps extends RouteComponentProps { }

const Login: React.FC<LoginProps> = (props) => {

  const appContext = useAppContext();

  const hasCentrelinkIssueAlert = (window as any).hasCentrelinkIssueAlert ? true : false;
  const centrelinkIssueAlert = (window as any).centrelinkIssueAlert;
  const removeCentrelinkLogin = (window as any).removeCentrelinkLogin ? true : false;

  const { navigate, executeCommandAndUpdateState, loading,
    uiState: { centrelinkLoginForm, centrelinkId, completionStatus: { applicationSubmittedDates } }
  } = appContext;

  const handleSubmit = (values: CentrelinkLoginRequest) => {
    const fortunaValues = {providerId: centrelinkLoginForm.providerId, 
      reference: '', username: values.username, password: values.password, centrelinkId: values.centrelinkId };

    const command =
    centrelinkLoginForm.provider === 'Proviso' ? () => CentrelinkApi.login(values) :
    centrelinkLoginForm.provider === 'Fortuna' ? () => CentrelinkApi.fortunaLogin(fortunaValues) :
        () => CentrelinkApi.login(values);

    executeCommandAndUpdateState(command, true)
      .then((newState) => {
        const hasError = newState.centrelinkLoginForm && newState.centrelinkLoginForm.error;
        if (!hasError) {
          navigate('CentrelinkMfa');
        }
      });
  }

  const handleSkip = () => {
    executeCommandAndUpdateState(() => CentrelinkApi.skipLogin({ centrelinkId: centrelinkId! }), true)
      .then(() => navigate('CentrelinkDownloading'))
  }

  const manualUpload = () => navigate('CentrelinkManualDocumentUpload');

  return (
    <Page
      title='Your Centrelink statements'
      lead='In order to accurately assess your financial situation we require access to your Centrelink statements. Connect to your myGov account below.'>
      <Row size='x-small'>
        <AboutThisProcess />
      </Row>
      {hasCentrelinkIssueAlert && (<React.Fragment>
        <Row>
          <Alert variant='info' center message={centrelinkIssueAlert} />
          <Button
            id='manual-upload'
            text='Upload your Centrelink statements manually.'
            variant='tertiary-compact'
            faIcon={faArrowRight}
            iconPlacement='before'
            onClick={manualUpload} 
          />
        </Row>
      </React.Fragment>)}
      {removeCentrelinkLogin == false && (<React.Fragment>
      <Row size='small' variant='secure'>
        <FormHeader />
        <LoginForm
          formDetails={centrelinkLoginForm}
          centrelinkId={centrelinkId!}
          onSubmit={handleSubmit}
          onSkip={handleSkip}
          submitting={loading}
          canSkip={!applicationSubmittedDates || !applicationSubmittedDates.length}
          manualUpload={manualUpload}
        />
      </Row>
      </React.Fragment>)}
    </Page>
  );
}

export default Login;