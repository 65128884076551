
//Loc Routes, kept separately for readability.
import { SonicPage } from "../../utils/SonicPage";
import { prefixes } from "../../utils/page-prefixes";
import locPages from "./locPages";
import {  PrefixType, SonicPageType, SonicPages } from "../../types/route-types";



export const getPrefix = (type?: PrefixType) => {
  switch (type) {
    case 'loan': return prefixes.loan;
    case 'line-of-credit': return prefixes.loc;
    case 'bank': return prefixes.bank;
    case 'centrelink': return prefixes.centrelink;
    default: return ''
  }
};

export const resolvePath = (prefixType?: PrefixType, path?: string) => {
  const prefix = getPrefix(prefixType);
  if (!path) return `${prefix}/`;
  const pathWithStartingSlash = !path.startsWith('/') ? `/${path}` : path;
  return `${prefix}${pathWithStartingSlash}/`
}

export const pages: SonicPages = {
  root: new SonicPage({
    page: 'Root',
    relativePath: '/',
    display: 'Home',
    pageTitle: ''
  }),
  start: new SonicPage({
    page: 'Start',
    relativePath: '/start',
    prefix: 'loan',
    display: 'Start',
    pageTitle: 'Loan Application'
  }),
  loanDetails: new SonicPage({
    page: 'LoanDetails',
    relativePath: '/submit-loan-details',
    prefix: 'loan',
    display: 'Loan Details',
    pageTitle: 'Your Loan'
  }),
  personalDetails: new SonicPage({
    page: 'PersonalDetails',
    relativePath: '/submit-personal-details',
    prefix: 'loan',
    display: 'Personal Details',
    pageTitle: 'Your Details'
  }),
  personalDetailsConfirm: new SonicPage({
    page: 'PersonalDetailsConfirm',
    relativePath: '/submit-personal-details-confirm',
    prefix: 'loan',
    display: 'Personal Details Confirm',
    pageTitle: 'Your Details'
  }),
  mobileVerification: new SonicPage({
    page: 'MobileVerification',
    relativePath: '/submit-mobile-verification',
    prefix: 'loan',
    display: 'Mobile Verification',
    pageTitle: 'Security Check'
  }),
  resetMobileVerification: new SonicPage({
    page: 'ResetMobileVerification',
    relativePath: '/reset-mobile-verification',
    prefix: 'loan',
    display: 'Reset Mobile Verification',
    pageTitle: 'Security Check'
  }),
  bankSelect: new SonicPage({
    page: 'BankSelect',
    relativePath: '/select',
    prefix: 'bank',
    display: 'Bank',
    pageTitle: 'Your Bank'
  }),
  bankLogin: new SonicPage({
    page: 'BankLogin',
    relativePath: '/login',
    prefix: 'bank',
    display: 'Bank Login',
    pageTitle: 'Login To Your Bank'
  }),
  bankMfa: new SonicPage({
    page: 'BankMfa',
    relativePath: '/mfa',
    prefix: 'bank',
    display: 'Bank MFA',
    pageTitle: 'Login To Your Bank'
  }),
  bankAnother: new SonicPage({
    page: 'BankAnother',
    relativePath: '/another',
    prefix: 'bank',
    display: 'Bank Statements',
    pageTitle: 'Another Bank?'
  }),
  incomeDeclaration: new SonicPage({
    page: 'IncomeDeclaration',
    relativePath: '/income',
    prefix: 'bank',
    display: 'Income Declaration',
    pageTitle: 'Confirm Your Income'
  }),
  expenseDeclaration: new SonicPage({
    page: 'ExpenseDeclaration',
    relativePath: '/expenses',
    prefix: 'bank',
    display: 'Expense Declaration',
    pageTitle: 'Essential Living Expenses'
  }),
  loanDeclaration: new SonicPage({
    page: 'LoanDeclaration',
    relativePath: '/loans',
    prefix: 'bank',
    display: 'Loans Declaration',
    pageTitle: 'Confirm Your Loans'
  }),
  centrelinkLogin: new SonicPage({
    page: 'CentrelinkLogin',
    relativePath: '/login',
    prefix: 'centrelink',
    display: 'Centrelink Login',
    pageTitle: 'Login To Centrelink'
  }),
  centrelinkMfa: new SonicPage({
    page: 'CentrelinkMfa',
    relativePath: '/mfa',
    prefix: 'centrelink',
    display: 'Centrelink MFA',
    pageTitle: 'Login To Centrelink'
  }),
  centrelinkDownloading: new SonicPage({
    page: 'CentrelinkDownloading',
    relativePath: '/downloading',
    prefix: 'centrelink',
    display: 'Centrelink Statements',
    pageTitle: 'Downloading Centrelink Statements'
  }),
  centrelinkManualDocumentUpload: new SonicPage({
    page: 'CentrelinkManualDocumentUpload',
    relativePath: '/document-upload',
    prefix: 'centrelink',
    display: 'Centrelink Statements',
    pageTitle: 'Uploading Centrelink Statements'
  }),
  centrelinkUnavailable: new SonicPage({
    page: 'CentrelinkUnavailable',
    relativePath: '/unavailable',
    prefix: 'centrelink',
    display: 'Centrelink Unavailable',
    pageTitle: 'Centrelink Unavailable'
  }),
  thankYou: new SonicPage({
    page: 'ThankYou',
    relativePath: '/thank-you',
    prefix: 'loan',
    display: 'Thank You',
    pageTitle: ''
  }),
  resume: new SonicPage({
    page: 'Resume',
    relativePath: '/resume',
    display: 'Resume',
    pageTitle: ''
  }),
  tracker: new SonicPage({
    page: 'Tracker',
    relativePath: '/tracker',
    display: 'Tracker',
    pageTitle: 'Loan Application Tracker'
  }),
  carLoans: new SonicPage({
    page: 'CarLoans',
    relativePath: '/car-loans',
    display: 'Car Loans',
    pageTitle: 'Car Loans'
  }),
  offline: new SonicPage({
    page: 'Offline',
    relativePath: '/offline',
    display: 'Offline',
    pageTitle: 'Offline'
  }),
  ...locPages
};



export const pagesArray: SonicPage[] = Object.keys(pages).map(key => (pages as any)[key] as SonicPage);
export const getPagesByPrefix = (prefix: PrefixType) => pagesArray.filter(e => e.prefix === prefix);
export const getPageByType = (type: SonicPageType) => pagesArray.find(e => e.page === type);

export const setPageTitle = (path: string) => {
  const parts = ['Cash Converters'];
  const page = pagesArray.find(e => e.fullPath().toLowerCase() === path.toLowerCase());
  if (page) parts.push(page.pageTitle);
  document.title = parts.join(' | ');
}