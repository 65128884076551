import { get, set } from "js-cookie";
import { cookieNames } from "./app/app-service";

import { LoanProduct, PersonalDetailsModel } from "./types/loan";
import { safeJsonStringToArray } from "./utils/string-helper";

const logPathChange = (pathName: string) => {
  if ((window as any).ga) {
    (window as any).ga('send', 'pageview', pathName);
  } else {
    console.log(pathName); // eslint-disable-line no-console
  }
}

const isCompleteByCookies = (id: string, setIfNotFound: boolean = false) => {
  const completedIds: any[] = safeJsonStringToArray(get(cookieNames.completedSourceIds)) || [];
  const isInCookie = completedIds.some(e => e === id);
  if (setIfNotFound && !isInCookie) {
    completedIds.push(id);
    set(cookieNames.completedSourceIds, completedIds, { secure: true });
  }

  return isInCookie;
};

const analyticsConversion = (id: string, details: PersonalDetailsModel, loanProduct: LoanProduct) => {

  if (isCompleteByCookies(id, true)) {
    return;
  }

  (window as any).dataLayer = (window as any).dataLayer || [];
  let dobYear = details.dateOfBirth.split('/')[2];
  let gender = details.title === 'Mr' ? 'm' : 'f';
  (window as any).dataLayer.push({
    'event': 'SonicConversionCompleted',
    'OnlineApplicationId': id,
    'Postcode': details.postcode,
    'BirthYear': dobYear,
    'Gender': gender,
    'LoanAmount': loanProduct.amount,
    'AverageWeeklyPayment': loanProduct.repayment,
    'LoanTermInMonths': loanProduct.term,
    'ProductName': parseFloat(loanProduct.amount) > 2000 ? 'MACC' : 'SACC'
  });

  if ((window as any)._satellite) {
    (window as any)._satellite.track('appComplete');
  }
}

export { logPathChange, analyticsConversion };