import React, { useEffect } from 'react';
import classNames from 'classnames';
import Row from '../layout/row';

interface PageProps {
  title?: string
  lead?: string
  topContent?: any
  additionalInfo?: string
  contentClassName?: string
}

const Page: React.FC<PageProps> = ({ title, lead, topContent, additionalInfo, children, contentClassName }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const contentClasses = classNames({
    'canvas__wrapper__content': true,
    [contentClassName!]: contentClassName !== undefined
  });

  return (
    <section id="canvas" className="canvas">
      <div className="row">
        <div id="wrapper" className="canvas__wrapper medium-11 xlarge-9 medium-centered columns">
          {topContent}
          <div className={contentClasses}>
            <Row>
              <h1>{title}</h1>
             {lead && <p className="text__lead" dangerouslySetInnerHTML={{ __html: lead }}></p>}
              {additionalInfo && <p className="text__lead">{additionalInfo}</p>}
            </Row>
            {children}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Page;