import React from "react";
import { ScrollPosition } from "../types/scroll-helper";

const scrollHelper = {

/**
 * Retrieves the scroll position of the provided element.
 *
 * @param {React.RefObject<HTMLElement>} ref - The reference to the element.
 * @return {ScrollPosition} The scroll position object.
 */
getPosition: (ref: React.RefObject<HTMLElement>): ScrollPosition => {
  if (!ref.current) {
    return {} as ScrollPosition;
  }

  const {
    scrollTop,
    scrollHeight,
    clientHeight,
    scrollLeft,
    scrollWidth,
    clientWidth,
  } = ref.current;

  const percentageScrolledY = (scrollTop / (scrollHeight - clientHeight)) * 100;
  const percentageScrolledX = (scrollLeft / (scrollWidth - clientWidth)) * 100;


  return {
    scrollTop,
    totalHeight: scrollHeight - clientHeight,
    percentageScrolledY,
    scrollLeft,
    totalWidth: scrollWidth - clientWidth,
    percentageScrolledX,
  };
},


  /**
   * Checks if the given element can be scrolled based upon stylesheet define scrolling
   *
   * @param {React.RefObject<HTMLElement>} ref - The reference to the element.
   * @return {boolean} Returns true if the element can be scrolled, false otherwise.
   */
  canScroll(ref: React.RefObject<HTMLElement>): boolean {
    if (!ref.current) {
      return false;
    }

    const { overflowY, overflowX, overflow } = window.getComputedStyle(
      ref.current as Element
    );

    return (
      overflowY === "scroll" ||
      overflowY === "auto" ||
      overflowX === "scroll" ||
      overflowX === "auto" ||
      overflow === "scroll" ||
      overflow === "auto"
    );
  },
};

export default scrollHelper;
