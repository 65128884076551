import React from 'react';
import { ErrorWithType, Field } from '../../../types/shared';
import { toAutoFormPropertyDefinition } from '../../../utils/form-helper';
import { Alert, AutoForm } from '../../shared';
import { getBankProviderButtonColour } from '../bank-helper';

interface BankMfaFormProps {
  fields: Field[]
  bankName: string
  onSubmit: (values: any) => void
  error?: ErrorWithType
}

const BankMfaForm: React.FC<BankMfaFormProps> = (props) => {
  const { fields, bankName, error, onSubmit } = props;

  const resolveTopContent = () => {
    return (
      <React.Fragment>
        <div className='login form__row'>
          <div className='login__heading-text'><h2>{bankName}</h2></div>
        </div>
        {error && <Alert message={error.message} variant={error.type as any} className={'text-center'} />}
      </React.Fragment>
    )
  }

  const resolveAboveButtonContent = () => {
    return !error && (
      <p className="form__help-text">
        Info: multiple incorrect attempts may lock your account. Contact your bank if unsure of your internet banking details.
      </p>
    );
  }

  return (
    <AutoForm<any, BankMfaFormProps>
      formId='bank-mfa-form'
      headerContent={resolveTopContent()}
      onSubmit={onSubmit}
      fields={fields.map(toAutoFormPropertyDefinition)}
      aboveButtonContent={resolveAboveButtonContent()}
      submitButtonStyle={getBankProviderButtonColour(bankName || '')}
      submitButtonText='Continue'
      hideBack
    />
  )
};

export default BankMfaForm; 
