import React, {useEffect} from 'react';
import { RouteComponentProps } from 'react-router';
import { Page, Row, Submit } from '../../shared';
import { useAppContext } from '../../state-manager/app-context';
import DocumentUploadForm from './document-upload';
import { UiState } from '../../../types/state';

interface ManualUploadProps extends RouteComponentProps { }

const ManualUpload: React.FC<ManualUploadProps> = () => {

  let refreshInterval: any = null;

  const appContext = useAppContext();
  const { getAndUpdateUiState, navigate, uiState } = appContext;
  const { loanApplicationId, centrelinkId, completionStatus: { centrelinkComplete } } = uiState;

  const updateProgress = (state: UiState) => {
    if (state.completionStatus.centrelinkComplete) {
      window.clearInterval(refreshInterval);
      return
    } else {
      getAndUpdateUiState();
    }
  }
  useEffect(() => {
    refreshInterval = window.setInterval(() => updateProgress(uiState), 5000);
    return () => window.clearInterval(refreshInterval);
  }, [uiState]);

  const centrelinkLogin = () => navigate('CentrelinkLogin', false);
  const handleComplete = () => navigate('ThankYou', false);

  return (
    <Page
      title="Centrelink statements needed"
      lead="In order to accurately assess your financial situation we require access to your Centrelink statements. Please upload a current Centrelink Income Statement that is no more than 2 weeks old.">
      <Row size='small' variant='secure'>
        <DocumentUploadForm
          applicationId={loanApplicationId}
          centrelinkId={centrelinkId!}
          centrelinkLogin={centrelinkLogin}
        />
      </Row>
      <Row size='x-small'>
        {centrelinkComplete && (
          <Submit
            id='continue'
            text='Submit Application'
            onSubmit={handleComplete}
          />
        )}
      </Row>
    </Page>
  );
}

export default ManualUpload;