import React from 'react';
import classNames from 'classnames';

interface ErrorWrapperProps {
  id?: string
  errorMessage?: string
  label?: string
  showError?: boolean
  className?: string
  children?: any
  noBorder?: boolean
}

const ErrorWrapper: React.FC<ErrorWrapperProps> = ({ errorMessage, label, showError, className, noBorder, children, id }) => {

  const shouldShow = showError && errorMessage ? true : false;

  const classes = classNames({
    'error-wrapper': true,
    'no-border': noBorder,
    'show-error': showError,
    [className!]: className !== undefined
  });

  return (
    <div id={id} className={classes}>
      <div className='error-wrapper-inner'>
        {label && <label className='form__label' >{label}</label>}
        {children}
      </div>
      {shouldShow && <span className="form__error-message">{errorMessage}</span>}
    </div>
  );
}

export default ErrorWrapper;