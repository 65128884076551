import { resolvePath } from "../app/routes";
import { PrefixType, SonicPageProps, SonicPageType } from "../types/route-types";

export class SonicPage {
    _props: SonicPageProps
  
    get relativePath(): string {
      return this._props.relativePath;
    }
  
    get display(): string {
      return this._props.display;
    }
  
    get page(): SonicPageType {
      return this._props.page;
  
    }
    get prefix(): PrefixType | undefined {
      return this._props.prefix;
    }
  
    get pageTitle(): string {
      return this._props.pageTitle;
    }
  
    fullPath(id?: string | number): string {
      let path = resolvePath(this.prefix, this.relativePath);
      if (id) path = `${path}${id}`;
      return path;
    }
  
    constructor(props: SonicPageProps) {
      this._props = props;
    }
  }