import React, { useState } from 'react';
import { FormikCheckbox } from '../../shared';
import { nameOf } from '../../../utils/object-helper';
import { FormikProps } from 'formik';
import { CarLoanFormValues } from '.';
import AcknowledgmentAndConsentModal from './acknowledgment-and-consent-modal';
import PrivacyPolicyModal from './privacy-policy-modal';
import LoanScenarioProcessModal from './loan-scenario-process-modal';
import MarketingModal from './marketing-modal';

interface DeclarationFieldsProps {
  formikProps: FormikProps<CarLoanFormValues>
}

interface State {
  showPrivacyModal: boolean
  showAcknowledgementAndConsentModal: boolean
  showLoanScenarioProcessModal: boolean
  showMarketingModal:boolean
}

const DeclarationFields: React.FC<DeclarationFieldsProps> = ({ formikProps }) => {

  const [state, setState] = useState<State>({
    showPrivacyModal: false,
    showAcknowledgementAndConsentModal: false,
    showLoanScenarioProcessModal: false,
    showMarketingModal:false
  });

  return (
    <React.Fragment>
      <FormikCheckbox
        formikProps={formikProps}
        name={nameOf<CarLoanFormValues>('glaAdmission')}
        label='I understand that finance is provided by Green Light Auto Group Pty Ltd ACN 131 507 474 Australian Credit Licence Number 392740 (Green Light Auto). Terms, conditions, fees, charges and lending criteria apply to all loans.'
      />
      <FormikCheckbox
        formikProps={formikProps}
        name={nameOf<CarLoanFormValues>('cashiesFee')}
        label='I understand that Green Light Auto may provide a financial benefit to the referring party up to the value of $500.00 where my introduction results in an approved and funded loan by Green Light Auto.'
      />
      <FormikCheckbox
        formikProps={formikProps}
        name={nameOf<CarLoanFormValues>('termsAndConditions')}
      >
        I have read, understood and agreed to the terms of the Green Light Auto Finance <a href="javascript:void(0)" onClick={() => setState(ps => ({ ...ps, showPrivacyModal: true }))}>Privacy Policy</a> and <a href="javascript:void(0)" onClick={() => setState(ps => ({ ...ps, showAcknowledgementAndConsentModal: true }))}>Acknowledgment and Consent</a>.
      </FormikCheckbox>
      <PrivacyPolicyModal
        open={state.showPrivacyModal}
        onClose={() => setState((ps) => ({ ...ps, showPrivacyModal: false }))}
      />
      <AcknowledgmentAndConsentModal
        open={state.showAcknowledgementAndConsentModal}
        onClose={() => setState((ps) => ({ ...ps, showAcknowledgementAndConsentModal: false }))}
      />
      <FormikCheckbox
        formikProps={formikProps}
        name={nameOf<CarLoanFormValues>('softEnquiryConsent')}
      >
        I consent to Green Light Auto conducting and recording my consent to the <a href="javascript:void(0)" onClick={() => setState(ps => ({ ...ps, showLoanScenarioProcessModal: true }))}>Loan Scenario Process</a> and give my authorisation for CC Acquisitions Pty Ltd to make the request to access my credit reporting information.
      </FormikCheckbox>
      <LoanScenarioProcessModal
        open={state.showLoanScenarioProcessModal}
        onClose={() => setState((ps) => ({ ...ps, showLoanScenarioProcessModal: false }))}
      />
      <FormikCheckbox
        formikProps={formikProps}
        name={nameOf<CarLoanFormValues>('marketingConsent')}
      >
        I <a href="javascript:void(0)" onClick={() => setState(ps => ({ ...ps, showMarketingModal: true }))}>consent to be contacted</a> about products and services that may interest me.
      </FormikCheckbox>
      <MarketingModal
        open={state.showMarketingModal}
        onClose={() => setState((ps) => ({ ...ps, showMarketingModal: false }))}
      />
    </React.Fragment>
  );
}

export default DeclarationFields;