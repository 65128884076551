import React from 'react';
import { Page, Row, Button } from '../shared';
import { useAppContext } from '../state-manager/app-context';

interface UnavailableProps { }
const Unavailable: React.FC<UnavailableProps> = () => {

  const { navigate } = useAppContext();

  return (
    <div id='centrelink-unavailable-page'>
      <Page
        title='Centrelink temporarily unavailable'
        lead='We are experiencing issues connecting to myGov.'>
        <Row size='medium'>
          <p className='text-center'>
            <img style={{ width: '60px' }} src='/images/data/_loans/mygov.png' alt='myGov' />
          </p>
          <p className='text-center'>
            We are unable to automatically retrieve your Centrelink Income Statement at this time.
        </p>
          <p className='text-center'>
            To speed up your application, please download a copy of your Centrelink Income Statement with Future Entitlements through the <a href="https://my.gov.au/" target="_blank" rel="noopener noreferrer">myGov website</a>. Once logged in, you may need to accept the new terms and conditions of use.
        </p>
          <p className='text-center'>
            Centrelink services will allow you to 'Request a Document' and save a copy of your Centrelink Income Statement to your device. Please save and send a copy of your Centrelink Income Statement to <a href="mailto:pfconline@cashconverters.com?Subject=Centrelink%20Income%20Statement">pfconline@cashconverters.com</a>
          </p>
          <p className='text-center'>
            We apologise for any inconvenience and thank you for your patience.
        </p>
        </Row>
        <Row size='x-small'>
          <Button
            id='skip_this_step'
            variant='primary'
            text='Skip and complete'
            onClick={() => navigate('ThankYou')}
          />
        </Row>
      </Page>
    </div>
  );
}
export default Unavailable;