import qs from 'qs';

export const getQueryParam = (url: string, paramName: string, isCaseSensitive?: boolean) => {
  if (!url || !paramName) return undefined;
  const queryParams = qs.parse(url, { ignoreQueryPrefix: true });
  if (!queryParams) return undefined;
  const compiledParamName = isCaseSensitive ? paramName : paramName.toLowerCase();
  const paramKey = Object.keys(queryParams).find(key => {
    return isCaseSensitive ? key === compiledParamName : key.toLowerCase() === compiledParamName;
  });
  return !paramKey ? undefined : queryParams[paramKey] as string;
}