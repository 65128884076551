import React from 'react';
import classNames from 'classnames';
import { OptionModel } from '../../../types/shared';

export interface SelectBoxProps extends React.HTMLProps<HTMLSelectElement> {
  errorMessage?: string
  hideLabel?: boolean
  wrapperClasses?: string
  isValid?: boolean
  variant?: string
  label?: string
  helpText?: string
  options?: OptionModel<string>[]
  emptyFirstOption?: boolean
  emptyOptionLabel?: string
}

class SelectBox extends React.Component<SelectBoxProps> {

  focus() {
    const input = (this.refs.input as any);
    if (input && input.focus) input.focus();
  }

  render() {
    const { options, emptyFirstOption, emptyOptionLabel, wrapperClasses, id, name, isValid, errorMessage, hideLabel, label, variant, helpText, ...rest } = this.props;

    const compiledIsValid = isValid === undefined ? true : isValid;

    const wrapperClassName = classNames({
      'form__row': true,
      'form__select-wrap': !compiledIsValid,
      [wrapperClasses!]: wrapperClasses !== undefined
    });

    const labelClassName = classNames({
      'form__label': true,
      'form__select-wrap': true,
      'form__error': !compiledIsValid,
      'form__label-emphasized': variant === 'secure'
    });

    const compiledOptions = (options || []).slice();
    if (emptyFirstOption) {
      compiledOptions.unshift({ label: emptyOptionLabel || '', value: "" })
    }

    return (
      <div data-test={`form-control-${name}`} className={wrapperClassName}>
        <label id={`${id}_label`} className={labelClassName}>
          {hideLabel ? <span className='form__label-hide'>{label}</span> : label}
          <select
            ref='select'
            itemRef='input'
            className='form__select'
            id={id}
            name={name}
            aria-describedby={`${id}_help_text`}
            {...rest}
          >
            {compiledOptions.map((option, index) => {
              return (
                <option key={`option_${index}`} value={option.value}>{option.label}</option>
              );
            })}
          </select>
        </label>
        {helpText && <p id={`${id}_help_text`} className="form__help-text" dangerouslySetInnerHTML={{ __html: helpText }}></p>}
        {!compiledIsValid && <span className="form__error-message">{errorMessage}</span>}
      </div>
    );
  }
}

export default SelectBox;
