import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { notify } from 'react-notify-toast';
import { Page, Row } from '../shared';
import * as CarLoanApi from '../../api/car-loan';
import Form, { CarLoanFormValues } from './form';
import Complete from './car-loan-complete';
import { useAppContext } from '../state-manager/app-context';
import { getQueryParam } from '../../utils/url-helper';
import { v4 } from 'uuid';

interface State {
  pageLoaded: boolean
  completedDetails?: CarLoanFormValues
  referralCode?: string
  referrer?: string,
  leadId?: string
}
interface CarLoansProps extends RouteComponentProps { }

const CarLoans: React.FC<CarLoansProps> = ({ history, location }) => {

  const { loading, setLoading } = useAppContext();

  const [state, setState] = useState<State>({
    pageLoaded: false,
    referralCode: undefined,
    referrer: undefined,
    leadId: undefined
  });

  useEffect(() => {
    try {
      const referralCode = getQueryParam(location.search, 'referralCode');
      const referrer = getQueryParam(location.search, 'referrer');
      setState(ps => ({ ...ps, pageLoaded: true, referralCode: referralCode, referrer: referrer, leadId: v4() }))
    }
    catch {
      //Ignore any error here
      setState(ps => ({ ...ps, pageLoaded: true }));
    }
  }, []);


  const handleSubmit = (values: CarLoanFormValues) => {
    setLoading(true);

    CarLoanApi.newApplication({
      leadId: state.leadId,
      referrer: state.referrer,
      referralCode: values.referralCode,
      title: values.title,
      firstName: values.firstName,
      middleName: values.middleName,
      surname: values.surname,
      dateOfBirth: values.dateOfBirth,
      driversLicenceNumber: values.driversLicenceNumber,
      email: values.email,
      mobile: values.mobile,

      street: `${values.streetNumber} ${values.streetName}`,
      suburb: values.suburb,
      state: values.state,
      postCode: values.postCode,

      message: values.message,

      loanPurpose: values.loanPurpose,
      loanAmount: values.loanAmount,
      employmentStatus: values.employmentStatus,
      netMonthlyIncome: values.netMonthlyIncome,
      maritalStatus: values.maritalStatus,
      numberOfDependants: values.numberOfDependants,
      residentialStatus: values.residentialStatus,
      durationAtCurrentAddress: values.durationAtCurrentAddress,
      durationAtCurrentEmployer: values.durationAtCurrentEmployer,
      desiredLoanTerm: values.desiredLoanTerm,

      totalLivingExpenses: values.totalLivingExpenses,
      totalHousingExpenses: values.totalHousingExpenses,
      totalLoanExpenses: values.totalLoanExpenses,
      totalCreditCardLimits: values.totalCreditCardLimits,
      totalOtherOngoingExpenses: values.totalOtherOngoingExpenses,

      cashiesFee: values.cashiesFee,
      glaAdmission: values.glaAdmission,
      termsAndConditions: values.termsAndConditions,
      softEnquiryConsent: values.softEnquiryConsent,
      marketingConsent: values.marketingConsent,

      awareOfImpactToPay: values.awareOfImpactToPay,
      awareOfImpactToPayExplanation: values.awareOfImpactToPay === 'Yes' ? values.awareOfImpactToPayExplanation : ""
    })
      .then((r) => setState(ps => ({ ...ps, completedDetails: values })))
      .catch(r => notify.show('An error occured.  Please try again', 'error'))
      .finally(() => setLoading(false));
  }

  if (!state.pageLoaded) {
    return null;
  }

  return (
    <Page
      contentClassName={!state.completedDetails ? 'pt-0' : undefined}
      topContent={<img className='car-loan-banner' src="/images/car-loan-hero-banner.png" alt="Car Loans" />}
      title={!state.completedDetails ? undefined : 'Enquiry Submitted!'}>

      {!state.completedDetails && (
        <React.Fragment>
          <Row>
            <h2>Green Light Auto Finance Referral</h2>
            <p>Thanks to our partners at Green Light Auto Finance, we can help you get you into your next car with simple finance that works for you.</p>
            <p>Loans are available from $6,000 to $45,000 with flexible loan terms and payment options. Joint applications are accepted and you could receive conditional approval in 24 hours.</p>
            <hr />
          </Row>
          <Form
            onSubmit={handleSubmit}
            submitting={loading}
            referralCode={state.referralCode}
          />
        </React.Fragment>
      )}
      {state.completedDetails && <Complete values={state.completedDetails!} leadId={state.leadId!} />}
    </Page>
  );
}

export default CarLoans;