import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps } from '../../../types/tracker';
interface BankStatementsAddedProps extends BaseTrackerContentProps {
}

const BankStatementsAdded: React.FC<BankStatementsAddedProps> = (props) => {

  return (
    <Page
      title="Bank statements received"
      lead="Thanks, we’ve received your updated bank statements and are reviewing these as part of your assessment.">
      <Row>
        <TrackerPageMainContent
          action={{
            descriptor: "Keep an eye on your email or…",
            callToAction: {
              type: "bookmark",
              label: "Save this page",
            }
          }}
          optional={{
            descriptor: "You can check this page any time for updates and information.",
          }}
          {...props}
        />
      </Row>
      <Row>
        <TrackerPageSteps
          {...props}
          progress={{
            callToAction: {
              type: "bookmark",
              label: "Save this page",
            }
          }}
        />
      </Row>
    </Page>
  );
}

export default BankStatementsAdded;
