import React from 'react';
import { PageInfo } from '../../../types/shared';
import Alert from './alert';

interface PageInfoAlertProps {
  className?: string
  pageInfo?: PageInfo
  id?: string
}

const PageInfoAlert: React.FC<PageInfoAlertProps> = ({ pageInfo, ...rest }) => {
  if (!pageInfo) return null;

  return (
    <React.Fragment>
      {pageInfo.error && <Alert variant='error' message={pageInfo.error} {...rest} />}
      {!pageInfo.error && pageInfo.info && <Alert variant='info' message={pageInfo.info} {...rest} />}
    </React.Fragment>
  );
}

export default PageInfoAlert;