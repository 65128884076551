import React from 'react';
import { RouteComponentProps } from 'react-router';

import PersonalDetailsForm, { PersonalDetailsFormValues } from './personal-details-form';
import { Page, Row } from '../../../shared';
import { SubmitPersonalDetailsRequest } from '../../../../api/loan-application/api models';
import * as LoanApi from '../../../../api/loan-application';
import { loanStateHasFeature } from '../../../app-service';
import * as LoanHelper from '../../../../utils/loan-helper';
import canBeOnPage, { BasePageProps } from '../../../hoc/can-be-on-page';
import { prefixes } from '../../../../utils/page-prefixes';

export interface SubmitPersonalDetailsProps extends BasePageProps, RouteComponentProps { }

const SubmitPersonalDetails: React.FC<SubmitPersonalDetailsProps> = ({ appContext }) => {

  let kycInteval: any = null;

  //Used as reference to know if component is unmounted.
  //We need this info for the interval async calls to make sure they don't
  //try and do stuff after its been unmounted
  const componentUnMounted = React.useRef(false);
  React.useEffect(() => {
    return () => { componentUnMounted.current = true; };
  }, []);


  const { uiState, setLoading, loading, setUiState, navigate, navigateUnsafe, executeCommandAndUpdateState, safeApiCall } = appContext;

  const handleSubmit = (values: SubmitPersonalDetailsRequest) => {
    values.loanApplicationId = uiState.loanApplicationId!;
    setLoading(true);

    //No kyc so just submit as normal and browse to next page
    if (!loanStateHasFeature(uiState, 'Kyc')) {

      executeCommandAndUpdateState(() => LoanApi.submitPersonalDetails(values), true).then(r => {
        switch(uiState.options.applicationType) {
          case 'LineOfCredit':
          case 'CreditLimitIncrease':
            navigateUnsafe(`${prefixes.loc}/submit-mobile-verification`)
            break;
          default:
            navigate('MobileVerification');
            break;
        }
      });
      return;
    }

    //Different from standard command will be checking for additional info required
    safeApiCall(() => LoanApi.submitPersonalDetails(values)).then(() => {

      let numberOfchecks = 1;
      const intervalMs = 3500;

      //Check response to implelemt kyc.  If requires more info then go to additional info page
      checkUpdatesForKyc();

      kycInteval = setInterval(() => {
        if (componentUnMounted.current) {
          clearInterval(kycInteval);
          return;
        }

        numberOfchecks += 1;
        if (numberOfchecks < 4) {
          checkUpdatesForKyc();
        }
        //Bail
        else {
          if (kycInteval) clearInterval(kycInteval);
          setLoading(false);
          navigate('MobileVerification');
        }
      }, intervalMs);
    }).catch(() => setLoading(false));
  };

  /**
   * Will check if kyc has finished check and if more info is required
   */
  const checkUpdatesForKyc = () => {
    if (componentUnMounted.current) return;

    safeApiCall(() => LoanApi.getState({ loanApplicationId: uiState.loanApplicationId })).then((response) => {

      if (componentUnMounted.current) return;

      setUiState(response.data);
      const check = response.data.customerCheck;
      if (check && check.completed) {
        setLoading(false);
        if (kycInteval) clearInterval(kycInteval);
        if (check.requiresMoreInfo) {
          navigate('PersonalDetailsConfirm');
        } else {
          navigate('MobileVerification');
        }
      }
    })
  }

  const getInitialValues = () => {
    let initialValues = uiState.personalDetails as PersonalDetailsFormValues;
    if (uiState.prefilledPersonalDetails && !initialValues.consent) {
      //Patch prefilled details onto the initial values
      const mapped = LoanHelper.toSubmitPersonalDetailsRequest(uiState.prefilledPersonalDetails, initialValues.consent, initialValues.marketing)
      initialValues = { ...initialValues, ...mapped };
      initialValues.loanApplicationId = uiState.loanApplicationId; //Ensure Source Id
    }

    if (initialValues) initialValues.addressSearch = LoanHelper.getAddressLineForSubmitPersonalDetails(initialValues)!;
    return initialValues;
  }

  return (
    <Page title="Your details" lead="Tell us a little about yourself to get started.">
      <Row size="x-small">
        <PersonalDetailsForm
          initialValues={getInitialValues()}
          submitting={loading}
          onSubmit={handleSubmit}
        />
      </Row>
    </Page>
  );
}

export default canBeOnPage(SubmitPersonalDetails, 'PersonalDetails');