import React, { Component } from 'react';
import Cleave from 'cleave.js/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

export interface NumberSliderProps {
  id: string
  value: number
  min: number
  max: number
  increment: number
  onBlur?: (e: any) => void
  onChange: (value: number) => void
  prefix?: string
  label?: string
  subLabel?: string
  numeralThousandsGroupStyle?: 'none' | 'thousand' | 'lakh' | 'wan'
  hideSliderBar?: boolean
  hideButtons?: boolean
  name: string
  disabled?: boolean
}

const isLineOfCredit = () => {
  return window.location.href.includes('line-of-credit')
}

export class NumberSlider extends Component<NumberSliderProps, any> {
  plusMouseDownTimed: any
  minusMouseDownTimed: any

  constructor(props: NumberSliderProps) {
    super(props);

    this.plusMouseDownTimed = null;
    this.minusMouseDownTimed = null;
    this._onMinusClicked = this._onMinusClicked.bind(this);
    this._onMinusMouseDown = this._onMinusMouseDown.bind(this);
    this._onMinusMouseUp = this._onMinusMouseUp.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onPlusClicked = this._onPlusClicked.bind(this);
    this._onPlusMouseDown = this._onPlusMouseDown.bind(this);
    this._onPlusMouseUp = this._onPlusMouseUp.bind(this);
  }

  _onPlusClicked() {
    const isLoc = isLineOfCredit();
    if (this.props.value === 4600 && this.props.max > 4600 && !isLoc )   // Increase from 4600 to 5050 to skip invalid values
    {
      let newValue = 5050;
      this.props.onChange(Number(newValue));
    }
    else if (this.props.value < this.props.max && !isLoc) {
      let newValue = 0 + Number(this.props.value) + Number(this.props.increment);
      this.props.onChange(Number(newValue));
    }
  }

  _onMinusClicked() {
    const isLoc = isLineOfCredit();

    if (this.props.value === 5050   && !isLoc)    // Decrease from 5050 to 4600 to skip invalid values
    {
      let newValue = 4600;
      this.props.onChange(Number(newValue));
    }
    else if (this.props.value > this.props.min && !isLoc) {
      let newValue = 0 + Number(this.props.value) - Number(this.props.increment);
      this.props.onChange(Number(newValue));
    }
  }

  _repeatIncrement() {
    this._onPlusClicked();
    this.plusMouseDownTimed = setTimeout(this._repeatIncrement.bind(this), 50);
  }

  _repeatDecrement() {
    this._onMinusClicked();
    this.minusMouseDownTimed = setTimeout(this._repeatDecrement.bind(this), 50);
  }

  _onPlusMouseDown() {
    this.plusMouseDownTimed = setTimeout(this._repeatIncrement.bind(this), 300);
  }

  _onPlusMouseUp() {
    clearTimeout(this.plusMouseDownTimed);
  }

  _onMinusMouseDown() {
    this.minusMouseDownTimed = setTimeout(this._repeatDecrement.bind(this), 300);
  }

  _onMinusMouseUp() {
    clearTimeout(this.minusMouseDownTimed);
  }

  _onChange(e: any) {
    let newValue = e.target.rawValue;
    if (this.props.prefix) {
      newValue = newValue.replace(this.props.prefix, '');
    }
    this.props.onChange(Number(newValue));
  }

  _onBlur(e: any) {
    if (this.props.onBlur) this.props.onBlur(e);

    if (this.props.value < this.props.min) {
      this.props.onChange(Number(this.props.min));
      return;
    }
    if (this.props.value > this.props.max) {
      this.props.onChange(Number(this.props.max));
      return;
    }

    let validValue = this.roundDownToValidValue(this.props.value);
    if (validValue !== this.props.value) {
      this.props.onChange(Number(validValue));
    }
  }

  roundDownToValidValue(value: number) {
    if (value > 4600 && value < 5050 && !isLineOfCredit()) return 4600;   // Values between 4600 and 5050 are invalid
    let result = value % this.props.increment;
    if (result > 0) return value - result;
    return value;
  }



  render() {
    const { label, subLabel, prefix, numeralThousandsGroupStyle, hideSliderBar, hideButtons, name, id, disabled } = this.props;
    const cleaveOptions = { prefix: prefix, numeral: true,numeralPositiveOnly: true ,numeralThousandsGroupStyle: numeralThousandsGroupStyle || 'none' };
    let percentComplete = (this.props.value - this.props.min) / (this.props.max - this.props.min) * 100;
    return (
      <div className="form__row integerselect">
        <label className="integerselect__label" htmlFor="amount">{label}</label>
        <div className="integerselect__slider">
          {!hideButtons && (
            <button
              disabled={disabled}
              onClick={this._onMinusClicked}
              onMouseDown={this._onMinusMouseDown}
              onMouseUp={this._onMinusMouseUp}
              className="integerselect__slider-button">
              <FontAwesomeIcon icon={faMinusCircle} /><span className="label hidden">Less</span>
            </button>
          )}
          <div className="integerselect__slider-gutter">
            <Cleave
              disabled={disabled}
              onBlur={this._onBlur}
              options={cleaveOptions}
              className="integerselect__slider-value"
              id={id}
              name={name}
              onChange={this._onChange}
              onKeyDown={this._onChange}
              value={this.props.value}
            />

            {!hideSliderBar && (
              <div className="integerselect__slider-bar" style={{ width: `${percentComplete}%` }}></div>
            )}
          </div>
          {!hideButtons && (
            <button
              disabled={disabled}
              onClick={this._onPlusClicked}
              onMouseDown={this._onPlusMouseDown}
              onMouseUp={this._onPlusMouseUp}
              className="integerselect__slider-button">
              <FontAwesomeIcon icon={faPlusCircle} /><span className="label hidden">More</span>
            </button>
          )}
        </div>
        <div className="integerselect__note">{subLabel}</div>
      </div>
    );
  }
}

export default NumberSlider;