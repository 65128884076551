import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import dynamics from 'dynamics.js';
import CloseCircle from './close-circle';
import EventStack from '../../../../types/active-event-stack';
import keycode from 'keycode';
import { inject } from 'narcissus';

const styles = {
  dialog: {
    boxSizing: 'border-box',
    position: 'relative',
    background: 'white',
    padding: 20,
    color: '#333',
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.4)',
    borderRadius: 10,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    left: -50,
    display: 'block',
    width: 40,
    height: 40,
    transition: 'transform 0.1s',
    // backgroundImage: require('../images/modal-dialog-close.png'),
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: '40px 40px',
    '&&:hover': {
      transform: 'scale(1.1)',
    },
  },
};

interface ModalDialogProps {
  id?: string
  onClose?: (event: any) => void
  className?: string // css class in addition to .ReactModalDialog
  width?: number | string // width
  topOffset?: number, 
  leftOffset?: number, 
  margin?: number // the margin around the dialog
  componentIsLeaving?: boolean,
  style?: any,
  left?: number,
  recenter?: () => void,
  top?: number,
  dismissOnBackgroundClick?: boolean,
}


// This decorator centers the dialog
export default class ModalDialog extends React.Component<ModalDialogProps> {

  eventToken: any
  didAnimateInAlready = false;
 
  getWidth = () => this.props.width === undefined ? 'auto' : this.props.width;
  getMargin = () => this.props.margin === undefined ? 20 : this.props.margin;
  getDismissOnBackgroundClick = () => this.props.dismissOnBackgroundClick === undefined ? true : this.props.dismissOnBackgroundClick;
  
  
  componentWillMount = () => {
    /**
     * This is done in the componentWillMount instead of the componentDidMount
     * because this way, a modal that is a child of another will have register
     * for events after its parent
     */
    this.eventToken = EventStack.addListenable([
      [ 'click', this.handleGlobalClick ],
      [ 'keydown', this.handleGlobalKeydown ],
    ]);
  };
  componentWillReceiveProps = (nextProps: ModalDialogProps) => {
    if (nextProps.topOffset !== null && this.props.topOffset === null) {
      // This means we are getting top information for the first time
      if (!this.didAnimateInAlready) {
        // Double check we have not animated in yet
        this.animateIn();
      }
    }

    if (nextProps.componentIsLeaving && !this.props.componentIsLeaving) {
      const node = ReactDOM.findDOMNode(this);
      dynamics.animate(node, {
        scale: 1.2,
        opacity: 0,
      }, {
        duration: 300,
        type: dynamics.easeIn,
      });
    }
  };
  
  componentWillUnmount = () => {
    EventStack.removeListenable(this.eventToken);
  };

  shouldClickDismiss = (event: any) => {
    const { target } = event;
    // This piece of code isolates targets which are fake clicked by things
    // like file-drop handlers
    if (target.tagName === 'INPUT' && target.type === 'file') {
      return false;
    }
    if (!this.getDismissOnBackgroundClick()) {
      if (target !== this.refs.self || (this.refs.self as any).contains(target)) return false;
    } else {
      if (target === this.refs.self || (this.refs.self as any).contains(target)) return false;
    }
    return true;
  };
  handleGlobalClick = (event: any) => {
    if (this.shouldClickDismiss(event)) {
      if (typeof this.props.onClose == 'function') {
        this.props.onClose(event);
      }
    }
  };
  handleGlobalKeydown = (event: any) => {
    if (keycode(event) === 'esc') {
      if (typeof this.props.onClose == 'function') {
        this.props.onClose(event);
      }
    }
  };
  animateIn = () => {
    this.didAnimateInAlready = true;

    // Animate this node once it is mounted
    const node = ReactDOM.findDOMNode(this);

    // This sets the scale...
    if (document.body.style.transform == null) {
      (node as any).style.WebkitTransform = 'scale(0.5)';
    } else {
      (node as any).style.transform = 'scale(0.5)';
    }

    dynamics.animate(node, {
      scale: 1,
    }, {
      type: dynamics.spring,
      duration: 500,
      friction: 400,
    });
  };
  render = () => {
    const {
      props: {
        children,
        className,
        componentIsLeaving,
        left, 
        leftOffset,
        onClose,
        recenter, 
        style,
        top, 
        topOffset,
        dismissOnBackgroundClick,
        ...rest
      },
    } = this;

    const margin = this.getMargin();

    const dialogStyle = {
      position: 'absolute',
      marginBottom: margin,
      width: this.getWidth(),
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      ...style,
    };

    const divClassName = classNames(inject(styles.dialog), className);

    return <div {...rest}
      ref="self"
      className={divClassName}
      style={dialogStyle}
    >
      {
        onClose ?
        <a className={inject(styles.closeButton)} onClick={onClose}>
          <CloseCircle diameter={30}/>
        </a> :
        null
      }
      {children}
    </div>;
  };
}