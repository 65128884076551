
export type DatePattern = 'ddmmyyyy' | 'mmyyyy' | 'yyyymm' | 'yyyymmdd' | 'yyyy'
export type Delimeter = '-' | '/'

export const resolveMomentFormatForCleave = (datePattern?: DatePattern, delimiter?: Delimeter, override?: string) => {
  if (override) return override;
  const pattern = resolveCleaveDatePattern(datePattern);
  return pattern.map(e => {
    switch (e.toLowerCase()) {
      case 'd': return 'DD';
      case 'm': return 'MM';
      default: return 'YYYY';
    }
  }).join(delimiter || '/');
}

export const resolveCleaveDatePattern = (datePattern?: DatePattern) => {
  const compiledPattern: DatePattern = !datePattern ? 'ddmmyyyy' : datePattern;
  switch (compiledPattern) {
    case 'yyyymm': return ['Y', 'm'];
    case 'yyyymmdd': return ['Y', 'm', 'd'];
    case 'mmyyyy': return ['m', 'Y'];
    case 'yyyy': return ['Y'];
    case 'ddmmyyyy':
    default:
      return ['d', 'm', 'Y'];
  }
}