import React, { useState } from "react";
import {
  Page,
  Row,
  Button,
  Submit,
  SaveProgressButton,
  ErrorWrapper,
} from "../../shared";
import { RouteComponentProps } from "react-router";

import * as IncomeService from "./income-service";
import * as BankApi from "../../../api/bank-statement";
import IncomeItemPanel, { validator } from "./income-item-panel";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { SubmitIncomeRequest } from "../../../api/bank-statement/api models";
import canBeOnPage, { BasePageProps } from "../../hoc/can-be-on-page";
import FindIncomeDescriptionModal from "./find-description-modal";
import ConfirmRadio from "../../shared/form/ConfirmRadio";

interface IncomeProps extends RouteComponentProps, BasePageProps {}

interface State {
  incomeItems: IncomeService.IncomeFormDto[];
  submitCount: number;
  showFindDescriptionModal: boolean;
  customerExpectsIncomeToReduceDuringLoanTerm: boolean | null;
}

const Income: React.FC<IncomeProps> = ({ appContext }) => {
  const { uiState, executeCommandAndUpdateState, navigate, loading } =
    appContext;

  const [state, setState] = useState<State>({
    incomeItems: IncomeService.getInitiaIncomeFormDto(uiState),
    submitCount: 0,
    showFindDescriptionModal: false,
    customerExpectsIncomeToReduceDuringLoanTerm: uiState.customerExpectsIncomeToReduceDuringLoanTerm! ,
  });

  const [incomeReducedError, setIncomeReducedError] = useState(false);

  const leadText =
    uiState.detectedIncomes && uiState.detectedIncomes.length > 0
      ? "We've identified the following income from your bank statements. Please review below."
      : "We've been unable to identify your income from your bank statements. Please tell us about your income below.";

  const handleChange = (index: number, value: IncomeService.IncomeFormDto) => {
    setState((oldState) => {
      const newState = { ...oldState };
      newState.incomeItems[index] = value;
      return newState;
    });
  };

  const handleRemove = (index: number) => {
    setState((oldState) => {
      const newState = { ...oldState };
      newState.incomeItems.splice(index, 1);
      return newState;
    });
  };

  const handleAdd = (transaction?: string) => {
    setState((oldState) => {
      const newState: State = { ...oldState, showFindDescriptionModal: false };
      const income = IncomeService.emptyIncome();
      income.wasFoundFromTransactions = transaction !== undefined;
      income.transactionDescription = transaction;
      newState.incomeItems.push(income);
      return newState;
    });
  };

  // this is to handle the toggling of the confirmradio as we are not handling it alonside
  // other formik components
  const handleConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setIncomeReducedError(false);
    setState((ps) => ({
      ...ps,
      customerExpectsIncomeToReduceDuringLoanTerm:
        event.target.value === "true",
    }));
  };

  const handleSubmit = () => {
    
    if (state.customerExpectsIncomeToReduceDuringLoanTerm === null || state.customerExpectsIncomeToReduceDuringLoanTerm === undefined) {
      setState((ps) => ({ ...ps, submitCount: ps.submitCount + 1 }));
      setIncomeReducedError(true);
      return;
    }
    if (
      state.incomeItems.length <= 0 ||
      state.incomeItems.some((e) => !validator.isValid(e, {} as any))
    ) {
      setState((ps) => ({ ...ps, submitCount: ps.submitCount + 1, customerExpectsIncomeToReduceDuringLoanTerm: state.customerExpectsIncomeToReduceDuringLoanTerm }));
      return;
    }

    const request: SubmitIncomeRequest = {
      analysisId: uiState.analysisId!,
      declaredIncomes: state.incomeItems.map(IncomeService.toDeclaredIncome),
      customerExpectsIncomeToReduceDuringLoanTerm: state.customerExpectsIncomeToReduceDuringLoanTerm
    };

    executeCommandAndUpdateState(
      () => BankApi.submitIncome(request),
      true
    ).then(() => navigate("ExpenseDeclaration"));
  };

  const hasIncomeItems = state.incomeItems.length ? true : false;
  return (
    <Page title="Confirm your income" lead={leadText}>
      <Row size="large">
        {state.incomeItems.map((incomeItem, index) => {
          return (
            <div
              key={(incomeItem as any).id}
              data-test={`income-item-panel-${index}`}
            >
              <IncomeItemPanel
                initialValues={incomeItem}
                onChange={(values) => handleChange(index, values)}
                onRemove={() => handleRemove(index)}
                submitCount={state.submitCount}
              />
            </div>
          );
        })}
        <Row size="large">
          <p className="text-center mb-5">
            <strong>Income not listed?</strong> <br />
            Help us find it on your bank statement using the button below:
          </p>
          <ErrorWrapper
            id="add-button-error-wrapper"
            errorMessage="Must have at least 1 source of income."
            showError={state.submitCount > 0 && !hasIncomeItems}
          >
            <div className="button__pill-group">
              <Button
                className="m-0"
                id="additional-income"
                text="Add additional income"
                faIcon={faPlusCircle}
                iconPlacement="before"
                onClick={() =>
                  setState((ps) => ({ ...ps, showFindDescriptionModal: true }))
                }
                variant={"pill"}
              />
            </div>
          </ErrorWrapper>
        </Row>
      </Row>

      {hasIncomeItems && (
        <p className="text-center">
          The above details are true and up to date.
        </p>
      )}
      <Row size="small">
        <ConfirmRadio
          onChange={(event) => handleConfirmChange(event)}
          legend="Do you have any reason to believe your income will be reduced during the course of this loan?"
          name="forseeable-income"
          description="Some examples include, but are not limited to, reduced hours at work, Centrelink benefit stopping, planned unpaid leave, end of employment contract, retirement, etc."
          displayErrorAsAlert={incomeReducedError}
          showTrue={state.customerExpectsIncomeToReduceDuringLoanTerm}
          reverse={true}
        />
      </Row>
      <Row size="x-small">
        <Submit
          id="income-submit-button"
          onSubmit={handleSubmit}
          text="Submit"
          variant="primary"
          disabled={loading}
        />
        <SaveProgressButton />
      </Row>
      <FindIncomeDescriptionModal
        show={state.showFindDescriptionModal}
        transactions={uiState.bankTransactions}
        chosenDescriptions={state.incomeItems.map(
          (i) => i.transactionDescription!
        )}
        onClose={() =>
          setState((ps) => ({ ...ps, showFindDescriptionModal: false }))
        }
        onChoose={(d) => handleAdd(d)}
        onICantFindClick={() => handleAdd()}
      />
    </Page>
  );
};

export default canBeOnPage(Income, "IncomeDeclaration");
