import React from 'react';
import classNames from 'classnames';
import { ModalContainer, ModalDialog } from './react-modal-dialog';

interface SimpleModalProps {
  show?: boolean
  title?: string
  onClose: () => void
  className?: string
}

const SimpleModal: React.FC<SimpleModalProps> = ({ title, show, onClose, className, children }) => {

  const classes = classNames({
    'modal': true,
    'alert-modal': true,
    [className!]: className !== undefined
  });

  return !show ? null : (
    <ModalContainer className={className} onClose={onClose}>
      <ModalDialog onClose={onClose} className={classes}>
        {title && <h1>{title}</h1>}
        {children}
      </ModalDialog>
    </ModalContainer>
  );
};

export default SimpleModal;