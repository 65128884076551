import React, { useState, useRef } from "react";

import Skeleton from "react-loading-skeleton";
import classNames from "classnames";
import scrollHelper from "../../../utils/scroll-helper";
import { ScrollPosition } from "../../../types/scroll-helper";

type AmountTime = "Weekly" | "Fortnightly" | "Monthly";
const amountTimes: AmountTime[] = ["Weekly", "Fortnightly", "Monthly"];
// const amountPercentage: number = 100 / amountTimes.length;

interface AmountTotalsProps {
  className?: string;
  amount: number;
  repayment: number;
  term: number;
  calculating: boolean;
}

interface AmountTotalsState {
  value: AmountTime;
}

const AmountTotals: React.FC<AmountTotalsProps> = ({
  className,
  calculating,
  amount,
  repayment,
}) => {
  const [state, setState] = useState<AmountTotalsState>({
    value: "Fortnightly",
  });

  const swipeContainerRef = useRef<HTMLDivElement | null>(null);

  const handleRadioSelect = () => {
    //this is real hacky, in an ideal world we would just use something like swiper.js or intersection observer
    // but the intersection observer api and no carousel plugin (that is still maintained) played nicely with sonic.
    // this will need to be altered if we add/remove payment frequencies.
    if (!scrollHelper.canScroll(swipeContainerRef)) return;

    setTimeout(() => {
      const position: ScrollPosition = scrollHelper.getPosition(
        swipeContainerRef
      ) as ScrollPosition;
      const newValue =
        position.percentageScrolledX > 25 && position.percentageScrolledX < 55
          ? "Fortnightly"
          : position.percentageScrolledX < 25
          ? "Weekly"
          : "Monthly";
      setState({ ...state, value: newValue });
    }, 100);
  };

  const onChange = (value: AmountTime) => {
    if (state.value === value) return;

    setState((ps) => ({
      ...ps,
      value: value,
      calculatedRepayment: getRepayment(),
    }));
  };

  const getRepayment = () => {
    var divider = 1;
    if (state.value === "Fortnightly") {
      divider = 2;
    }
    if (state.value === "Weekly") {
      divider = 4.32;
    }

    return (repayment / divider) | 0;
  };

  const renderButtonLabel = () => {
    return (
      <div
        className="repayment-box d-flex align-items-center"
        id="amount-totals-repayment"
      >
        <div className="flex-1 text-center">
          <span style={{ fontWeight: 600 }}> ${getRepayment()}.00 </span>
          <span style={{ fontWeight: "normal" }}>
            {" "}
            / Estimated {state.value} Repayment
          </span>
        </div>
      </div>
    );
  };

  const classes = classNames({
    "amount-total": true,
    [className!]: className !== undefined,
  });
  return (
    <>
      <fieldset>
        <h2 className="mb-0">How often do you receive your pay?</h2>
        <p className="text-center">How often you receive your pay will impact your repayment amount.</p>
        <div className="fade-container">
          <div
            className="swipe-container"
            ref={swipeContainerRef}
            onTouchEnd={() => handleRadioSelect()}
          >
            {amountTimes.map((title, index) => {
              return (
                <div
                  className="radio-wrapper swipe-container__child"
                  tabIndex={0}
                  key={`radio-${index}`}
                >
                  <input
                    type="radio"
                    name="amount-time"
                    id={title}
                    onChange={() => onChange(title)}
                    checked={state.value === title}
                  />
                  <label htmlFor={title}>{title}</label>
                </div>
              );
            })}
          </div>
        </div>
      </fieldset>
      <div className={classes}>
        <div>
          <div>{renderButtonLabel()}</div>
        </div>
          <b>Please note:</b> This repayment is an example only. Actual
          repayment amount may vary subject to lending and credit criteria.
      </div>
    </>
  );
};

export default AmountTotals;
