import React, { useEffect, useState } from 'react';
import { get } from "js-cookie";
import queryString from 'query-string';
import Skeleton from 'react-loading-skeleton';
import { Page, Row, Submit } from '../../shared';
import * as LoanApi from '../../../api/loan-application';
import { useAppContext } from '../../state-manager/app-context';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { StartLoanApplicationRequest, AddReferrer } from '../../../api/loan-application/api models';
import { RouteComponentProps } from 'react-router';
import * as api from '../../../api/loan-application';
import { PrefilledPersonalDetails } from '../../../types/loan';
import { cookieNames } from '../../app-service';
import { prefixes } from '../../../utils/page-prefixes';


// this is the line of credit increase start page.

interface QueryParams {
  personalDetailsToken: string
}

interface ReferrerParams {
  referrer: string
}

export interface StartLoanApplicationState {
  loadingPrefilledPersonalDetails: boolean
  prefilledPersonalDetails?: PrefilledPersonalDetails
}

export interface StartLoanApplicationProps extends RouteComponentProps { }

export const StartLoanApplicationLoc: React.FC<StartLoanApplicationProps> = (props) => {

  const [state, setState] = useState<StartLoanApplicationState>({
    loadingPrefilledPersonalDetails: true
  });

  const { sourceId, loading, executeCommandAndUpdateState, navigateUnsafe,navigate } = useAppContext();

  //try get prefilled personal details
  useEffect(() => {
    const queryParams = (queryString.parse(props.location.search) as any) as QueryParams;
    // we may need to move this when we add loc application to sonic.
    if (!queryParams || !queryParams.personalDetailsToken) {
      setState((ps) => ({ ...ps, loadingPrefilledPersonalDetails: false }));
      return;
    }

    api.decryptPersonalDetails(queryParams.personalDetailsToken).then(response => {
      if (response.data.success) {
        setState((ps) => ({ ...ps, prefilledPersonalDetails: response.data.data }));
      }
    }).finally(() => setState((ps) => ({ ...ps, loadingPrefilledPersonalDetails: false })));
  }, []);

  const buildRequest = () => {
    //Features should be controlled by adobe analytics
    const request: StartLoanApplicationRequest = { loanApplicationId: sourceId!, applicationType: 'LineOfCredit' };
    if ((window as any).sonicApplicationFeatures) request.features = (window as any).sonicApplicationFeatures;
    if (state.prefilledPersonalDetails) {
      request.prefilledPersonalDetails = state.prefilledPersonalDetails;
      request.channel = 'Myloans';
    }
    if (!request.channel) request.channel = 'CashiesHomePage';
    return request;
    }

  async function handleSubmit() {
    const request = buildRequest();
    await executeCommandAndUpdateState(() => LoanApi.startLoanApplication(request), true);
    navigate("LoanDetailsLoc")
    handleClickId();
    handleAddReferrer();
  }

  const handleClickId = () => {
    var gclid = get(cookieNames.googleClickId);
    if (gclid) {
      const splitId = gclid.split('.').map(e => e.trim());
      gclid = splitId[splitId.length - 1];
    }

    LoanApi.associateWith({
      gclid: gclid ?? null,
      fbclid: get(cookieNames.facebookClickId) ?? null,
      fbBrowserId: get(cookieNames.facebookBrowserId) ?? null,
      googleAnalytics: get(cookieNames.googleAnalytics) ?? null,
      loanApplicationId: sourceId!
    });
  };

  const handleAddReferrer = () => {
    const referrerParams = (queryString.parse(props.location.search) as any) as ReferrerParams;
    if (!referrerParams || referrerParams.referrer) {
      const request: AddReferrer = { loanApplicationId: sourceId!, referrer: {name: referrerParams.referrer}  };
      LoanApi.addReferrer(request);
    }
  };

  const getReturningCustomerButtonText = () => {
    const details = state.prefilledPersonalDetails;
    if (!details || !details.firstName) return 'Returning Customer';
    const names: string[] = [details.firstName];
    names.push(details.surname);
    return `Continue as ${names.join(' ')}`;
  }

  return (
    <Page
      title='New loan application'
      lead='Please have the following items at hand.'>
      <Row>
        <div className="row checklist">
          <div className="medium-4 columns">
            <img src="/images/icons/loans/your-mobile-phone.svg" alt="Your Mobile Phone" />
            <h3>Your Australian mobile phone</h3>
            <p>So we can secure your application</p>
          </div>
          <div className="medium-4 columns">
            <img src="/images/icons/loans/your-banking-login.svg" alt="Your Banking Login" />
            <h3>Your internet banking login</h3>
            <p>So you can upload your bank statements</p>
          </div>
          <div className="medium-4 columns">
            <img src="/images/icons/loans/your-mygov-login.svg" alt="Your myGov Login" />
            <h3>Your myGov login</h3>
            <p>Only required if you receive Centrelink payments</p>
          </div>
          <div className="columns">
            <div className="row lending-notice">
              <p>Please note you must be an <strong>Australian resident</strong> to be eligible for a Personal Loan.</p>
              <h3>Responsible lending notice</h3>
              <p><FontAwesomeIcon icon={faExclamationTriangle} className='mr-10' /><strong>WARNING - Do you really need a loan today?*</strong></p>
              <p>It can be expensive to borrow small amounts of money and borrowing may not solve your money problems.</p>
              <p>Check your options <span style={{ textDecoration: 'underline' }}>before</span> you borrow:</p>
              <ul>
                <li>For information about other options for managing bills and debts, ring <a href="tel:1800007007" className="url-tel">1800 007 007</a> from anywhere in Australia to talk to a free and independent financial counsellor</li>
                <li>Talk to your electricity, gas, phone or water provider to see if you can work out a payment plan</li>
                <li>If you are on government benefits, ask if you can receive an advance from Centrelink: <a href="https://www.humanservices.gov.au/advancepayments" target="_blank" rel="noopener noreferrer">www.humanservices.gov.au/advancepayments</a></li>
              </ul>
              <p>The Government&apos;s <a href="https://www.moneysmart.gov.au/" target="_blank" rel="noopener noreferrer">MoneySmart</a> website shows you how small amount loans work and suggests other options that may help you. </p>
              <p>* This statement is an Australian Government requirement under the <em>National Consumer Credit Protection Act 2009.</em></p>
            </div>
          </div>
        </div>
      </Row>
      <Row size='x-small'>
        <h3>Lets get started!</h3>
        {state.loadingPrefilledPersonalDetails && (
          <React.Fragment>
            <div className='mb-15'><Skeleton height={50} /></div>
            <div className='mb-15'><Skeleton height={50} /></div>
          </React.Fragment>
        )}
        {!state.loadingPrefilledPersonalDetails && (
          <React.Fragment>
            {!state.prefilledPersonalDetails && (
              <Submit id='submit_new_customer' text='Continue' onSubmit={() => handleSubmit()} disabled={loading} />
            )}
            {state.prefilledPersonalDetails && (
              <Submit id='submit_returning_customer' text={getReturningCustomerButtonText()} onSubmit={() => handleSubmit()} variant='primary' disabled={loading} />
            )}
          </React.Fragment>
        )}
      </Row>
    </Page>
  );
}

export default StartLoanApplicationLoc;
