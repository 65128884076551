let idCounter = 0;
const prefix = 'active_event_';

function uid() {
  ++idCounter;
  return String(prefix) + idCounter;
}

function last(array: any[]) {
  return array[array.length - 1];
}

if (typeof document != 'undefined') {
  document.addEventListener('click', onEvent.bind(null, 'click'), true);
  document.addEventListener('keydown', onEvent.bind(null, 'keydown'));
  document.addEventListener('keyup', onEvent.bind(null, 'keyup'));
}

let listenables : any[] = [];

function onEvent(type: any, event: any) {
  const listenable = last(listenables);
  if (listenable) {
    const handler = listenable.events[type];
    if (typeof handler == 'function') {
      handler(event);
    }
  }
}

const EventStack = {
  addListenable(listenArray: any[]) {
    /* ex: [['click', clickHandler], ['keydown', keydownHandler]] */
    const id = uid();
    listenables.push({
      id,
      events: listenArray.reduce(
        (memo, [ type, fn ]) => {
          memo[type] = fn;
          return memo;
        },
        {}
      ),
    });
    return id;
  },
  removeListenable(id: any) {
    const idx = listenables.findIndex(x => x.id === id)
    listenables.splice(idx, 1);
  }
};

export default EventStack;