import React from 'react';
import { FormikProps } from 'formik';
import { Row, Button, Alert, StatusAlert, SaveProgressButton } from '../../shared';
import { NABHelpText, getErrorAlertByErrorMessage, getBankProviderButtonColour, getTopBankByName } from '../bank-helper';
import { BankForm, TestBankLoginDetails } from '../../../types/bank-statement';
import AutoForm from '../../shared/form/auto-form/auto-form';
import { OptionModel } from '../../../types/shared';
import { addSpacesOnCaps } from '../../../utils/string-helper';
import { toAutoFormPropertyDefinition } from '../../../utils/form-helper';

interface BankLoginFormProps {
  onSubmit: (values: any) => void
  onAddNewBank: () => void
  submitting?: boolean
  formDetails: BankForm
}

const BankLoginForm: React.FC<BankLoginFormProps> = ({ onAddNewBank, onSubmit, formDetails, submitting }) => {

  const handleTestAccountChoose = (username: string, formikProps: FormikProps<any>) => {
    const testLogin = TestBankLoginDetails.find(e => e.username === username);
    if (!testLogin) {
      formikProps.setValues({
        ...formikProps.values,
        "Username": undefined,
        "Password": undefined
      });
    } else {
      formikProps.setValues({
        ...formikProps.values,
        "Username": testLogin.username,
        "Password": testLogin.password
      });
    }
  }

  const getAutoFormProperties = () => {
    const autoFormProps = formDetails.fields.map(toAutoFormPropertyDefinition);
    if (formDetails.provider === 'Test') {
      autoFormProps.unshift({
        name: 'TestOptions',
        display: 'Test Accounts',
        type: 'select',
        options: {
          options: TestBankLoginDetails.map<OptionModel<string>>(e => ({ label: addSpacesOnCaps(e.username), value: e.username })),
          onChange: handleTestAccountChoose
        },
      });
    }

    return autoFormProps;
  }


  const resolveTopContent = () => {
    let message: string | undefined = formDetails.additionalInfo;
    const bank = getTopBankByName(formDetails.bankName || '');
    if (bank?.type === 'NAB') message = NABHelpText;

    const hasMessage = message ? true : false;

    return (
      <React.Fragment>
        <div id='login-panel-header'>
          <h3 className={'text-left'}>{formDetails.bankName}</h3>
        </div>
        {hasMessage && <Alert message={message} variant='info' className='text-center' data-test='additional-bank-info' />}
        <StatusAlert message={formDetails.status} variant='info' className='text-center' data-test='bank-login-status' />
      </React.Fragment>
    )
  }

  const resolveAboveButtonContent = () => {
    return !formDetails.error ? undefined :
      getErrorAlertByErrorMessage(formDetails.error);
  }

  const resolveBelowButtonContent = () => {
    return !formDetails.error ? undefined :
      (
        <p className="form__help-text">
          Info: multiple incorrect attempts may lock your account. Contact your bank if unsure of your internet banking details.
        </p>
      );
  }

  return (
    <React.Fragment>
      <Row size='large' variant='secure'>
        <AutoForm
          submitting={submitting}
          formId='bank-login-form'
          headerContent={resolveTopContent()}
          onSubmit={onSubmit}
          fields={getAutoFormProperties()}
          aboveButtonContent={resolveAboveButtonContent()}
          belowButtonContent={resolveBelowButtonContent()}
          submitButtonStyle={getBankProviderButtonColour(formDetails.bankName || '')}
          submitButtonText='Connect'
          hideBack
        />
      </Row>
      <Row size='small'>
        <Button
          id='choose_bank'
          variant='secondary'
          text='Choose a different bank'
          onClick={onAddNewBank}
          data-test='bank-login-change-bank'
        />
        <SaveProgressButton />
      </Row>
    </React.Fragment>
  );
};

export default BankLoginForm;