import React, { useEffect } from 'react';
import { Page, Row, Button } from '../../../shared';
import { useAppContext } from '../../../state-manager/app-context';
import { IdType } from '../../../../types/identity';
import { loanStateOptionsHasFeature } from '../../../app-service';
import PersonalDetailsDisplay, { PersonalDetailsDisplayVariant } from '../shared/personal-details-display';

export interface AdditionalInfoItem { type: IdType, data: any, fromServer?: boolean };

export interface ConfirmDetailsPageProps {
  variant?: PersonalDetailsDisplayVariant
}

const ConfirmDetailsPage: React.FC<ConfirmDetailsPageProps> = ({ variant }) => {

  const { uiState: { personalDetails, options, customerCheck }, navigate } = useAppContext();

  //If feature not turned on then skip to next page
  useEffect(() => {
    const notallowed = !loanStateOptionsHasFeature(options, 'Kyc') ||
      !customerCheck || !customerCheck.requiresMoreInfo;

    if (notallowed) navigate('MobileVerification');
  });

  return (
    <Page title="Just checking" lead="Are these details correct?">
      <Row size="x-small">
        <PersonalDetailsDisplay details={personalDetails} variant={variant} />
        <Button id='personal-details-confirm-confirm' text='They are correct' onClick={() => navigate('MobileVerification')} variant='primary' />
        <Button id='personal-details-confirm-confirm' text="Oops I'll change it" onClick={() => navigate('PersonalDetails')} variant='secondary' />
      </Row>
    </Page>
  );
}

export default ConfirmDetailsPage;