import React from 'react';
import classNames from 'classnames';

interface TrackerProgressItemProps {
  index: number
  title: string
  error?: boolean
  status?: string
  hasCTA?: boolean
  className?: string
}
const TrackerProgressItem: React.FC<TrackerProgressItemProps> = ({ index, title, error, status = 'todo', hasCTA, children, className }) => {

  const classes = classNames({
    [`progress-tracker__item progress-tracker__item--${status}`]: true,
    'progress-tracker__item--error': error ? true : false,
    'progress-tracker__item--has-cta': hasCTA ? true : false,
    [className!]: className !== undefined
  });

  return (
    <li className={classes} data-index={index}>
      <strong>{title}</strong>
      <div className="progress-tracker__item__content">
        {children}
      </div>
    </li>
  );
};

export default TrackerProgressItem;
