import React from 'react';
import Checkbox, { CheckboxProps } from '../checkbox';
import { safeGetValue, safeGetError, shouldShowError } from './formik-helper';
import { FormikProps } from 'formik';

interface FormikCheckboxProps<T> extends CheckboxProps {
  name: string
  formikProps: FormikProps<T>
}

const FormikCheckbox: React.FC<FormikCheckboxProps<any>> = ({ formikProps, name, onChange, onBlur, ref, id, ...rest }) => {

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    formikProps.setFieldValue(name, event.currentTarget.checked);
    if (onChange) onChange(event);
  };

  const handleBlur = (event: any) => {
    if (formikProps && formikProps.handleBlur) {
      formikProps.handleBlur(event);
    }

    if (onBlur) onBlur(event);
  };

  return (
    <Checkbox
      {...rest}
      ref={ref as any}
      id={id || name}
      name={name}
      checked={safeGetValue(formikProps, name) === true}
      onChange={handleChange}
      onBlur={handleBlur}
      isValid={!shouldShowError(formikProps, name)}
      errorMessage={safeGetError(formikProps, name)}
    />
  );
};

export default FormikCheckbox;