import React from 'react';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '..';
import InfoTooltip from './info-tooltip';

export interface TextBoxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  errorMessage?: string
  hideLabel?: boolean
  isValid?: boolean
  variant?: string
  label?: string
  helpText?: string
  bottomContent?: any
  faIconPrefix?: IconDefinition
  faIconSuffix?: IconDefinition
  displayErrorAsAlert?: boolean
  infoTooltipText?: string
  rightOfInputContent?: any
}

class TextBox extends React.Component<TextBoxProps, any> {


  focus() {
    const input = (this.refs.input as any);
    if (input && input.focus) input.focus();
  }

  render() {
    const { id, name, displayErrorAsAlert, isValid, errorMessage, hideLabel, label, variant,
      helpText, faIconPrefix, faIconSuffix, infoTooltipText, bottomContent, children,
      rightOfInputContent, className, ...rest } = this.props;

    const compiledIsValid = isValid === undefined ? true : isValid;

    const labelClassName = classNames({
      'form__label': true,
      'form__error': !compiledIsValid,
      'form__label-emphasized': variant === 'secure',
      'input-with-icon': faIconPrefix !== undefined || faIconSuffix !== undefined,
      'input-with-icon-left': faIconPrefix !== undefined,
      'input-with-icon-right': faIconSuffix !== undefined
    });

    const containerClasses = classNames({
      'form__row': true,
      [className!]: className !== undefined
    });

    return (
      <div data-test={`form-control-${name}`} className={containerClasses}>
        <label id={`${id}_label`} className={labelClassName}>
          {hideLabel && <span className='form__label-hide'>{label}</span>}
          {!hideLabel && (
            <React.Fragment>
              <span className='mr-5'>{label}</span>
              {infoTooltipText && <InfoTooltip text={infoTooltipText} />}
            </React.Fragment>
          )}
          <div className='text-box-input-wrapper'>
            <input
              ref='input'
              type='text'
              className={`form__input${variant === 'secure' ? ' form__input-code' : ''}`}
              id={id}
              name={name}
              aria-describedby={`${id}_help_text`}
              {...rest}
            />
            {rightOfInputContent}
          </div>
          {faIconPrefix && <FontAwesomeIcon className='input-icon-left' icon={faIconPrefix} />}
          {faIconSuffix && <FontAwesomeIcon className='input-icon-right' icon={faIconSuffix} />}
        </label>
        {helpText && <p id={`${id}_help_text`} className="form__help-text" dangerouslySetInnerHTML={{ __html: helpText }}></p>}
        {bottomContent}
        {!compiledIsValid && !displayErrorAsAlert && <span className="form__error-message">{errorMessage}</span>}
        {!compiledIsValid && displayErrorAsAlert && <Alert variant='error' message={errorMessage!} />}
        {children}
      </div>
    );
  };
}


export default TextBox;
