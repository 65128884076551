import { camelCase, capitalize } from 'lodash';
import { isArray } from './object-helper';

export const containsString = (stringVal: string, stringToCheck: string) => {
  return !stringVal || !stringToCheck ? false : stringVal.toLowerCase().indexOf(stringToCheck) !== -1;
};

export const containsStringArray = (stringVal: string, stringArrayToCheck: string[]) => {
  return !stringVal || !stringArrayToCheck || !stringArrayToCheck.length ? false :
    stringArrayToCheck.some(e => stringVal.toLowerCase().indexOf(e) !== -1);
};

export const replaceCapitalsWithDashes = (text: string) => {
  return !text ? text : text.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
}

export const addSpacesOnCaps = (text: string) => {
  return !text ? text : text.replace(/([A-Z])/g, ' $1').trim()
}

export const replaceSpacesWithDashes = (text: string) => {
  return !text ? text : text.replace(/\s/g, '-');
}

export const removeSpaces = (text: string) => {
  return !text ? text : text.replace(/\s/g, '');
}

export const removeStars = (text?: string) => {
  return !text ? text : text.replace(/\*/g, '');
}

export const removeTrailingSlash = (text: string) => {
  return !text ? text : text.replace(/\/$/, "");
}

export const toCamelCase = (text: string) => {
  return camelCase(text);
}

export const toCapitalCase = (text: string) => {
  return capitalize(text);
}

export const capitalizeFirstLetter = (text: string) => {
  return !text ? text : text.charAt(0).toLowerCase() + text.slice(1);
}

//https://stackoverflow.com/questions/31712808/how-to-force-javascript-to-deep-copy-a-string
export const cloneString = (text: string) => {
  return (' ' + text).slice(1);
}

export const safeJsonStringToArray = (json?: string) => {
  if (!json) return undefined;
  try {
    const array = JSON.parse(json);
    return isArray(array) ? array : undefined;
  } catch (e) {
    return undefined;
  }
}