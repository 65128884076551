import React from 'react';
import classNames from 'classnames';

interface TrackerProgressProps {
  current: string
  lapsed?: boolean
  className?: string
}

const TrackerProgress: React.FC<TrackerProgressProps> = ({children, current, lapsed, className}) => {

  const classes = classNames({
    [`progress-tracker progress-tracker--${current}`]: true,
    'progress-tracker--lapsed': lapsed === true,
    [className!]: className !== undefined
  });

  return (
    <ul className={classes}>
      {children}
    </ul>
  );
};

export default TrackerProgress;
