import React from 'react';
import { TrackerLoanApplication, TrackerItemContentProgress } from '../../../../../types/tracker';
import * as Service from '../../../tracker-service';
import { TrackerProgressItem } from '../..';

interface ContractStepProps {
  application: TrackerLoanApplication
  applicationId: string
  onSonicFollowUpRoute: (route: string) => void
  onSonicRoute: (route: string) => void
  status: string
  stepNumber: number
  isStepBeforeTermination: boolean
  progress?: TrackerItemContentProgress
}

const ContractStep: React.FC<ContractStepProps> = (props) => {
  const { application, applicationId, onSonicFollowUpRoute, onSonicRoute,
    status, stepNumber, isStepBeforeTermination, progress } = props;

  let contentBlock: any = undefined;
  const contentBlockArray: any[] = [];
  const hasCallToAction = progress && progress.callToAction ? true : false;

  if (status !== 'todo') {

    if (status === 'complete') {

      if (application.currentState === 'Declined' && isStepBeforeTermination) {
        contentBlock = (
          <div>
            <span className="label">Date:</span>
            {Service.formatDate(application.states.declined.entered!)}
            <br />
          </div>
        );
      } else if (application.currentState === 'Withdrawn' && isStepBeforeTermination) {
        contentBlock = (
          <div>
            <span className="label">Date:</span>
            {Service.formatDate(application.states.withdrawn.entered!)}
            <br />
          </div>
        );
      } else if (application.contractSigned) { // happy path
        contentBlock = (
          <div key="1">
            <span className="label">Date:</span>
            {Service.formatDate(application.contractSigned)}
            <br />
            <span className="label">Time:</span>
            {Service.formatTime(application.contractSigned)}
          </div>
        );
      }

    } else if (status === 'current') {

      if (!application.contractSigned) {
        contentBlockArray.push((
          <span key="1" className="currentStepStatus">
            Contract signing required as of <span style={{ whiteSpace: 'nowrap' }}>{Service.formatDate(application.states.contract.entered!)}</span>
          </span>
        ));
      } else {
        contentBlockArray.push((
          <span key="1" className="currentStepStatus">
            Contract signed on <span style={{ whiteSpace: 'nowrap' }}>{Service.formatDate(application.contractSigned)}</span>
          </span>
        ));
      }

      if (hasCallToAction && progress!.callToAction.action === '{{contractSigningUrl}}') {
        progress!.callToAction.action = application.contractSigningUrl;
      }

      if (hasCallToAction) {
        contentBlockArray.push((
          <div key="2">
            <div className="button-wrapper">
              {Service.renderCallToAction(progress!.callToAction, applicationId,
                onSonicFollowUpRoute, onSonicRoute, 'primary')}
            </div>
          </div>
        ));
      }
    }
  }

  const hasContentBlockArray = contentBlockArray.length ? true : false;

  return (
    <TrackerProgressItem
      title={application.contractSigned ? 'Contract Signed' : 'Sign loan contract'}
      key={stepNumber}
      index={stepNumber}
      hasCTA={hasCallToAction}
      status={status}
    >
      {contentBlock}
      {hasContentBlockArray && contentBlockArray}
    </TrackerProgressItem>
  );
}

export default ContractStep;