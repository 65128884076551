import React from "react";
import { faFileUpload, faTimesCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileMimeType, getExtensions } from "../../../../utils/file-helper";
import LoadingSpinner from "../../loader/loading-spinner";

export interface FileUploadDropContentProps {
  isDragActive?: boolean
  errorOnDrag?: boolean
  successOnDrag?: boolean
  fileTypes?: FileMimeType[]
  dragErrorText?: string
  uploading?: boolean
  uploadingMessage?: string
  maxSizeMb?: number
}

const FileUploadDropContent: React.FC<FileUploadDropContentProps> = ({ isDragActive, errorOnDrag, successOnDrag, fileTypes, dragErrorText, uploading, uploadingMessage, maxSizeMb }) => {

  const hasFileTypes = fileTypes && fileTypes.length ? true : false;
  const hasMaxSize = maxSizeMb ? true : false;

  return (
    <React.Fragment>
      {!isDragActive && !uploading && (
        <React.Fragment>
          <FontAwesomeIcon color='primary' size='3x' icon={faFileUpload} />
          <div><span className='mb-5'><strong>Choose a File</strong> or drag it here</span></div>
          {hasFileTypes && <div><span className='mb-5'>Allowed Files: <strong>{fileTypes!.map(getExtensions).flat().join(', ')}</strong></span></div>}
          {hasMaxSize && <div><span className='mb-5'>Max Size: <strong>{maxSizeMb} MB</strong></span></div>}
        </React.Fragment>
      )}
      {errorOnDrag && (
        <React.Fragment>
          <FontAwesomeIcon color='error' size='3x' icon={faTimesCircle} />
          <span className='mb-5'>{dragErrorText}</span>
        </React.Fragment>
      )}
      {successOnDrag && (
        <React.Fragment>
          <FontAwesomeIcon color='success' size='3x' icon={faCheckCircle} />
          <span className='mb-5'>File is looking good!</span>
        </React.Fragment>
      )}
      {uploading && <LoadingSpinner height={45} text={uploadingMessage || 'Uploading...'} />}
    </React.Fragment>
  )
};

export default FileUploadDropContent;