import React from 'react';
import { AutoForm, Alert, StatusAlert, SaveProgressButton, Button } from '../../shared';
import { CentrelinkLoginRequest } from '../../../api/centrelink/api models';
import { CentrelinkForm } from '../../../types/centrelink';
import { required } from '../../shared/form/formik/validators';
import SkipButton from '../components/skip-button';
import TroubleWithLogin from '../components/trouble-with-login';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface CentrelinkLoginFormProps {
  onSubmit: (values: CentrelinkLoginRequest) => void
  onSkip: () => void
  submitting?: boolean
  centrelinkId: string
  formDetails: CentrelinkForm
  canSkip: boolean
  manualUpload: () => void
}

const CentrelinkLoginForm: React.FC<CentrelinkLoginFormProps> = (props) => {
  const { submitting, formDetails, onSubmit, onSkip, canSkip, centrelinkId, manualUpload } = props

  const hasError = formDetails.error && formDetails.error.message ? true : false;

  return (
    <AutoForm<CentrelinkLoginRequest>
      formId='centrelink-login-form'
      initialValues={{ centrelinkId: centrelinkId }}
      headerContent={(
        <React.Fragment>
          {hasError && (
            <React.Fragment>
              <Alert message={formDetails.error!.message} variant='error' />
              <TroubleWithLogin />
              <Button
                id='manual-upload'
                text='Upload your Centrelink statements manually.'
                variant='tertiary-compact'
                faIcon={faArrowRight}
                iconPlacement='before'
                onClick={manualUpload}
              />
              {canSkip && <SkipButton onClick={onSkip} disabled={submitting} />}
            </React.Fragment>

          )}
          <StatusAlert message={formDetails.status} variant='info' />
        </React.Fragment>
      )}
      fields={[
        { type: 'text', name: 'username', display: 'Username', options: { validators: [required] } },
        { type: 'password', name: 'password', display: 'Password', options: { validators: [required] } },
      ]}
      hideBack
      submitButtonText='Login'
      submitting={submitting}
      submitButtonStyle={{ color: '#fff', background: '#005a46' }}
      aboveButtonContent={!formDetails.error ? undefined : (
        <p className="form__help-text">
          Info: multiple incorrect attempts may lock your account. Contact myGov if you are unsure of your credentials.
        </p>
      )}
      belowButtonContent={(
        <React.Fragment>
          <SaveProgressButton />
          <div className="text__lead">
            <br />
              Please note that the verification code for myGov will be sent to the mobile registered with myGov which may
              be different to the mobile used in this application.
        </div>
        </React.Fragment>
      )}
      onSubmit={onSubmit}
    />
  );
};

export default CentrelinkLoginForm;