import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { Page, Row, Submit } from '../../shared';
import ChooseAmountForm from './choose-amount-form';
import ReasonAccordian from './reason-accordian';
import { LoanSubReason } from '../../../types/loan';
import { needExtraInfoForReason } from './loan-details-helper';
import * as LoanApi from '../../../api/loan-application';
import { SubmitLoanDetailsRequest } from '../../../api/loan-application/api models';
import canBeOnPage, { BasePageProps } from '../../hoc/can-be-on-page';
import { getDefaultAmount, isValidAmount, isValidTerm, getDefaultTerm } from "../data";
import { overridedMinimumLoanAmount } from '../../app-service';

interface State {
  amount: number;
  term: number;
  reason?: string;
  subReason?: LoanSubReason;
  explanation?: string;
}

export interface SubmitLoanDetailsProps extends BasePageProps, RouteComponentProps { }

const SubmitLoanDetails: React.FC<SubmitLoanDetailsProps> = ({ appContext }) => {

  const {
    loading, executeCommandAndUpdateState, navigate, reasons,
    uiState: { loanApplicationId, product, selectedLoanReasons, usePilotFigures },
  } = appContext;

  if (usePilotFigures) {
    throw Error("usePilotFigures option no longer supported")
  }

  const extractLoanReasons = () => {
    if (selectedLoanReasons && selectedLoanReasons.length) {
      const selectedReason = selectedLoanReasons[0]
      const selectedReasonDetail = reasons.find(
        r => r.name === selectedReason.reason
      );
      if (selectedReasonDetail) {
        const selectedSubReason = selectedReasonDetail.subReasons.find(
          r => r.name === selectedReason.subReason
        );
        if (selectedSubReason) {
          return {
            reason: selectedReason.reason,
            subReason: selectedSubReason,
            explanation: selectedReason.explanation
          };
        }
      }
      return {};
    }
  }

  const amount = product
    && product.amount
    && isValidAmount(parseInt(product.amount, 10))
    ? parseInt(product.amount, 10) : getDefaultAmount();

  const term = product
    && product.term
    && isValidTerm(amount, parseInt(product.term, 10))
    ? parseInt(product.term, 10) : getDefaultTerm(amount);

  const [state, setState] = useState<State>({
    amount: amount,
    term: term,
    ...extractLoanReasons()
  });

  async function handleSubmit() {
    setState(ps => ({ ...ps, submitting: true }));
    const request: SubmitLoanDetailsRequest = {
      loanApplicationId: loanApplicationId,
      amount: state.amount,
      term: state.term,
      detail: state.explanation,
      reason: state.reason!,
      subReason: state.subReason!.name,
    };

    await executeCommandAndUpdateState(() => LoanApi.submitLoanDetails(request), true);
    navigate('PersonalDetails');
  }

  const canSubmit = () => {
    if (!state.reason || !state.subReason) {
      return false;
    }
    if ((!state.explanation || state.explanation.length > 499)
      && needExtraInfoForReason(state.amount, state.subReason)) {
      return false;
    }
    return true;
  }

  return (
    <Page
      title='Your Loan'
      lead='How much money are you requesting and what will you use it for?'>
      <Row>
        <ChooseAmountForm
          overridedMinimumLoanAmount={overridedMinimumLoanAmount}
          disabled={loading}
          amount={amount}
          term={term}
          onChange={(newAmount, newTerm) => {
            setState(ps => ({ ...ps, amount: newAmount, term: newTerm }));
          }}
          showAmountTotals
        />
      </Row>
      <Row
        title='What will you use it for?'
        lead='Choose from the below categories and pick the option that best fits.'
      />
      <Row size='x-small'>
        <ReasonAccordian
          initial={{ reason: state.reason, subReason: state.subReason, explanation: state.explanation }}
          disabled={loading}
          loanAmount={state.amount}
          onChange={(r, sr, e) => setState(ps => ({ ...ps, reason: r, subReason: sr, explanation: e }))}
          reasons={reasons}
        />
      </Row>
      <Row size='x-small'>
        <Submit
          id='submit'
          text='Next'
          disabled={!canSubmit() || loading}
          onSubmit={handleSubmit}
        />
      </Row>
    </Page>
  );
}

export default canBeOnPage(SubmitLoanDetails, 'LoanDetails');