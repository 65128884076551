import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export type CardVariant = 'confirm' | 'fail' | 'information' | 'disable';
interface CardProps {
  id?: string
  className?: string
  variant?: CardVariant
  disable?: boolean
}

const Card: React.FC<CardProps> = ({ className, children, variant, ...rest }) => {

  const compiledClass = classNames({
    'card': true,
    'card__fail': variant === 'fail',
    'card__confirm': variant === 'confirm',
    'card__information': variant === 'information',
    [className!]: className !== undefined
  });

  return (
    <div className={compiledClass} {...rest}>
      {variant === 'fail' && <FontAwesomeIcon className='card-icon' icon={faMinusCircle} />}
      {variant === 'information' && <FontAwesomeIcon className='card-icon' icon={faExclamationCircle} />}
      {variant === 'confirm' && <FontAwesomeIcon className='card-icon' icon={faCheckCircle} />}
      {children}
    </div>
  );
}

export default Card;