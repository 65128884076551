import React from 'react';
import * as Service from '../../../tracker-service';
import { TrackerProgressItem } from '../..';
import { TrackerItemContentProgress } from '../../../../../types/tracker';

interface WithdrawnStepProps {
  applicationId: string
  onSonicFollowUpRoute: (route: string) => void
  onSonicRoute: (route: string) => void
  status: string
  stepNumber: number
  isStepBeforeTermination: boolean
  progress?: TrackerItemContentProgress
}

const WithdrawnStep: React.FC<WithdrawnStepProps> = (props) => {
  const { applicationId, onSonicFollowUpRoute, onSonicRoute,
    status, stepNumber, progress } = props;

  const hasCallToAction = progress && progress.callToAction ? true : false;
  const title = progress && progress.subStatus ? progress.subStatus : 'Application withdrawn';

  return (
    <TrackerProgressItem
      key={stepNumber}
      index={stepNumber}
      title={title}
      error={true}
      status={status}
      hasCTA={hasCallToAction}
    >
      {hasCallToAction && (
        <div className="button-wrapper">
          {Service.renderCallToAction(progress!.callToAction, applicationId,
            onSonicFollowUpRoute, onSonicRoute, 'primary')}
        </div>
      )}
    </TrackerProgressItem>
  );
}

export default WithdrawnStep;