import React, { useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, SimpleModal } from '../../shared';

interface AboutThisProcessProps {
}

const AboutThisProcess: React.FC<AboutThisProcessProps> = () => {

  const [showInformation, setShowInformation] = useState<boolean>(false);
  const toggle = () => setShowInformation(!showInformation);

  return (
    <div>
      <Button
        id='about_this_process'
        variant='tertiary'
        size='small'
        text='More about this process'
        faIcon={faInfoCircle}
        iconPlacement='before'
        onClick={toggle}
      />
      <SimpleModal show={showInformation} onClose={toggle}>
        <h2>More about this process</h2>
        <ul>
          <li>We only download your Centrelink Income statement to ensure you can afford to pay back your loan.</li>
          <li>It's one-time access.</li>
          <li>Your myGov details are never stored.</li>
          <li>The connection is secured by bank level 256-bit encryption.</li>
          <li>Over 500,000 Cash Converters customers have used this to fast-track their applications.</li>
        </ul>
      </SimpleModal>
    </div>
  );
}

export default AboutThisProcess;