import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps } from '../../../types/tracker';

interface InAssessmentProps extends BaseTrackerContentProps {
}

const InAssessment: React.FC<InAssessmentProps> = (props) => {

  return (
    <Page
      title="In assessment"
      lead="Your application is with our assessment team. We will send any updates to your email, usually in less than 48 hours.">
      <Row>
        <TrackerPageMainContent
          {...props}
          action={{
            title: "Keep an eye on your email or…",
            callToAction: {
              type: "bookmark",
              label: "Save this page",
            }
          }}
          optional={{
            descriptor: "You can check this page any time for updates and information.",
          }}
        />
      </Row>
      <Row>
        <TrackerPageSteps
          {...props}
          progress={{
            callToAction: {
              type: "bookmark",
              label: "Save this page",
            }
          }}
        />
      </Row>
    </Page>
  );
}

export default InAssessment;
