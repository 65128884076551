import React from 'react';
import { Formik } from 'formik';
import { FormikValidator, Submit, FormikSideEffects, Button, FormikMobilePhone } from '../../shared';
import { nameOf } from '../../../utils/object-helper';
import { requiredWithMessage, mobilePhoneReggex } from '../../shared/form/formik/validators';
import { ResetMobileVerificationRequest } from '../../../api/loan-application/api models';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface ResetMobileVerificationFromProps {
  onSubmit: (values: ResetMobileVerificationRequest) => void
  submitting?: boolean
  onSkip?: () => void
  initialValues?: ResetMobileVerificationRequest
}

export interface ResetMobileVerificationFormValues extends ResetMobileVerificationRequest { }

const ResetMobileVerificationForm: React.FC<ResetMobileVerificationFromProps> = (props) => {

  const validator = new FormikValidator<ResetMobileVerificationFormValues, ResetMobileVerificationFromProps>({
    fields: [
      { name: 'mobile', validators: [requiredWithMessage("Please enter a mobile number so that we can contact you."), mobilePhoneReggex] }
    ]
  });

  const formId = 'reset-mobile-verification-form';
  return (
    <Formik
      initialValues={validator.getInitial(props.initialValues)}
      onSubmit={props.onSubmit}
      validate={(v) => validator.validate(v, props)}
      render={(formikProps) => {
        return (
          <form id={formId} onSubmit={formikProps.handleSubmit}>
            <h4><strong>Mobile Number</strong></h4>
            <FormikMobilePhone id={formId} name={nameOf<ResetMobileVerificationFormValues>("mobile")} formikProps={formikProps} variant='secure' placeholder='0___ ___ ___' />
            <Submit disabled={props.submitting} id='mobile-verification-reset' onSubmit={formikProps.handleSubmit} text='Continue' />
            <Button disabled={props.submitting} id='mobile-verification-skip' onClick={() => { if (props.onSkip) props.onSkip(); }} text='Skip this step' variant='tertiary' faIcon={faArrowRight} iconPlacement='before' />
            <p><strong>Note:</strong> If you skip this step you won't be able to save the progress of your application or continue where you left off if you time out.</p>
            <FormikSideEffects formId={formId} />
          </form>
        );
      }}
    />
  );
};

export default ResetMobileVerificationForm;