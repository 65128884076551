import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps } from '../../../types/tracker';

interface ProofOfAccountOwnershipRequestedProps extends BaseTrackerContentProps {
}

const ProofOfAccountOwnershipRequested: React.FC<ProofOfAccountOwnershipRequestedProps> = (props) => {

  return (
    <Page
      title="Proof of account owner needed"
      lead="You're almost there. But, we need to confirm that you're an owner of the provided bank account before we can continue.">
      <Row>
        <TrackerPageMainContent
          {...props}
          action={{
            title: "Proof of account ownership",
            descriptor: "Please provide a copy of one of the following documents:\n- A PDF copy of your E-Statement\n- A screenshot from your PC or mobile of the relevant information (web version or banking app)\n- A scan of a statement printed at your local banking branch",
            callToAction: {
              type: "uploadForm",
              label: "Provide proof documents",
              documentType: 'AccountOwnership'
            }
          }}
        />
      </Row>
      <Row>
        <TrackerPageSteps {...props} />
      </Row>
    </Page>
  );
}

export default ProofOfAccountOwnershipRequested;
