import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Page, Row, Card } from '../../shared';
import { useAppContext } from '../../state-manager/app-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { analyticsConversion } from '../../../analytics-logging';
import canBeOnPage from '../../hoc/can-be-on-page';

interface ThankyouProps {
}

const Thankyou: React.FC<ThankyouProps> = () => {

  const [state] = useState({
    dateSubmitted: moment().format('DD MMM YYYY'),
    timeSubmitted: moment().format('h:mm a')
  });

  const { sourceId, uiState: { summary, personalDetails, product, options: { applicationType } } } = useAppContext();
  const { firstName, surname, email, mobile, amount, withdrawAmount, fullReason } = summary;

  const isLocIncrease = applicationType === 'CreditLimitIncrease';
  const isLocApplication = applicationType === 'LineOfCredit';

  useEffect(() => {
    analyticsConversion(sourceId!, personalDetails, product);
  }, []);


  return (
    <Page
      title={isLocIncrease || isLocApplication ? 'Line of Credit Application Submitted!' : 'Application submitted!'}>
      <Row
        size='medium'>
        <h1><small>Thanks <span className="fs-hide">{firstName}</span>! You will hear from us soon.</small></h1>
        <p className="text__lead">A confirmation email is on it’s way. Our team will assess your application and send updates to <strong className="fs-hide">{email}</strong></p>
        <p className="text__lead">This can take up to 24 - 48 hours, however it&#39;s usually sooner.</p>
      </Row>
      <Row>
        <Card variant='confirm' className='loanoverview'>
          <h4>{isLocIncrease || isLocApplication ? 'Line of Credit application submitted' : 'Loan application submitted'}</h4>
          <ul>
            <li><strong>Date:</strong> {state.dateSubmitted}</li>
            <li><strong>Time:</strong> {state.timeSubmitted}</li>
          </ul>
          <h4>Your details</h4>
          <ul>
            <li><strong>Name:</strong> <span className="fs-hide">{firstName} {surname}</span></li>
            <li><strong>Email:</strong> <span className="fs-hide">{email}</span></li>
            <li><strong>Phone:</strong> <span className="fs-hide">{mobile}</span></li>
            <li><strong>{isLocIncrease || isLocApplication ? 'Credit Limit requested:' : 'Cash in your account:'}</strong> ${amount}</li>
            {!isLocApplication || <li><strong>Withdraw Amount requested:</strong> ${withdrawAmount}</li>}
            {isLocIncrease || isLocApplication || <li><strong>Loan purpose:</strong> {fullReason}</li>}
          </ul>
        </Card>
      </Row>
      <Row size='medium'>
        <h1>Application progress</h1>
        <div className="application__progress">
          <ul>
            <li className="application__progress-item approved p-relative"><i className='item-step' /><FontAwesomeIcon icon={faCheck} className='p-5 complete-icon' /> Application submitted</li>
            <li className="application__progress-item current"><i className="item-step counter"></i>Application review</li>
            <li className="application__progress-item pending"><i className="item-step counter"></i>Application assessment</li>
            <li className="application__progress-item pending"><i className="item-step counter"></i>Sign loan contract</li>
            {isLocIncrease || isLocApplication || <li className="application__progress-item pending"><i className="item-step counter"></i>Money sent to your account</li>}
          </ul>
        </div>
      </Row>
      <Row
        size='medium'
        title='What happens next?'>
        {isLocIncrease || isLocApplication ? (
          <p className='text__lead'>Once our assessment is complete we will email <strong className="fs-hide">{email}</strong> the outcome of your application. If approved this will contain a link to your contract.</p>
        ) : (
          <p className='text__lead'>Once our assessment is complete we will email <strong className="fs-hide">{email}</strong> the outcome of your application. If approved this will contain a link to your contract. Once signed the money will be deposited directly into your account.</p>
        )}
      </Row>
      <Row
        size='medium'
        title='Further questions'
        lead='We will contact you if we have any further questions in relation to your applications. If you would like to know more, please view our frequently asked questions:'>
        {isLocIncrease || isLocApplication ? (
          <div className="text__faq">
            {isLocApplication || <p><strong>Q. How long before my Credit Limit Increase is approved?</strong><br />
              A. Once approved, the Credit Limit Increase should reflect on your Line of Credit within 24 - 48 hours.</p>}
            <p><strong>Q. Do I get instant approval for a {isLocApplication ? 'Line of Credit' : 'Credit Limit Increase'} application?</strong><br />
              A. We don’t offer an instant approval. However, whether you apply online or in-store, your application will always be assessed by a person, not a computer, within 48 business hours after all necessary documents are received.</p>
            <p><strong>Q. How will I know the outcome of my application?</strong><br />
              A. We will be in touch with you as soon as we have assessed your application.</p>
          </div>
        ) : (
          <div className="text__faq">
            <p><strong>Q. How long before I get the money?</strong><br />
              A. Once approved, the fund for your Personal Loan will be in your account at some point the next business day.</p>
            <p><strong>Q. Do I get instant approval for a Personal Loan?</strong><br />
              A. We don’t offer an instant approval. However, whether you apply online or in-store, your application will always be assessed by a person, not a computer, within 48 business hours after all necessary documents are received.</p>
            <p><strong>Q. How will I know the outcome of my application?</strong><br />
              A. We will be in touch with you as soon as we have assessed your application.</p>
          </div>
        )}
      </Row>
      <Row size='x-small'>
        <p><a href="https://www.cashconverters.com.au/cash-loans" className="button button--primary">Return to homepage</a></p>
      </Row>
    </Page>
  );
};


export default canBeOnPage(Thankyou, 'ThankYou');
