import React from 'react';
import { Alert } from '..';

export interface RadioProps extends React.HTMLProps<HTMLInputElement> {
  errorMessage?: string
  hideLabel?: boolean
  isValid?: boolean
  label?: string
  helpText?: string
  displayErrorAsAlert?: boolean
}

class Radio extends React.Component<RadioProps> {

  focus() {
    const input = (this.refs.input as any);
    if(input && input.focus) input.focus();
  }
  
  render() {
    const { id, displayErrorAsAlert, type, isValid, errorMessage, hideLabel, label, helpText, checked, ...rest } = this.props;

    const compiledIsValid = isValid === undefined ? true : isValid;

    return (
      <div>
        <input
          ref='input'
          className='form__input form__input-radio'
          id={id}
          type='radio'
          tabIndex={0}
          checked={checked}
          {...rest}
        />
        <label
          id={`${id}_label`}
          htmlFor={id}
          tabIndex={-1}
          className='form__row form__label form__label-radio'
        >
          <i className='icon icon-form-radio'></i>
          <div dangerouslySetInnerHTML={{__html: label || ''}}></div>
          {!compiledIsValid && !displayErrorAsAlert && <span className="form__error-message">{errorMessage}</span> }
          {!compiledIsValid && displayErrorAsAlert && <Alert variant='error' message={errorMessage!} /> }
        </label>
      </div>
    );
  }
}

export default Radio;
