import React, { CSSProperties } from 'react';

interface ModalBackgroundProps {
  onClose?: () => void
  duration: number
  backgroundColor: string
  zIndex: number
}

interface ModalBackgroundState {
  transparent: boolean
  componentIsLeaving?: boolean
}

export default class ModalBackground extends React.Component<ModalBackgroundProps, ModalBackgroundState> {
  
  
  state = {
    // This is set to false as soon as the component has mounted
    // This allows the component to change its css and animate in
    transparent: true,
    componentIsLeaving: undefined
  }

  componentDidMount = () => {
    // Create a delay so CSS will animate
    requestAnimationFrame(() => this.setState({ transparent: false }));
  }

  componentWillLeave = (callback: any) => {
    this.setState({
      transparent: true,
      componentIsLeaving: true,
    });

    const duration = this.getDuration();

    // There isn't a good way to figure out what the duration is exactly,
    // because parts of the animation are carried out in CSS...
    setTimeout(() => callback(), duration);
  }

  getChild = () => {
    const { onClose, children } = this.props;
    const child = React.Children.only(children);
    const cloneProps = {
      onClose: onClose,
      componentIsLeaving: this.state.componentIsLeaving,
    };
    if (!cloneProps.onClose) {
      delete cloneProps.onClose;
    }
    return React.cloneElement(child as any, cloneProps);
  }

  getDuration = () => this.props.duration === undefined ? 300 : this.props.duration;
  getBackgroundColor = () => this.props.backgroundColor === undefined ? '#182738' : this.props.backgroundColor;
  getZIndex = () => this.props.zIndex === undefined ? 5 : this.props.zIndex;

  render = () => {
    const { transparent } = this.state;

    const duration = this.getDuration();
    const backgroundColor = this.getBackgroundColor();
    const zIndex = this.getZIndex();

    const overlayStyle: CSSProperties = {
      opacity: transparent ? 0 : 0.85,
      position: 'absolute',
      backgroundColor: backgroundColor,
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      transition: `opacity ${duration / 1000}s`,
      WebkitTransition: `opacity ${duration / 1000}s`,
      cursor: 'pointer',
    };

    const containerStyle: CSSProperties = {
      opacity: transparent ? 0 : 1,
      overflowY: 'auto',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      transition: `opacity ${duration / 1000}s`,
      WebkitTransition: `opacity ${duration / 1000}s`,
      cursor: 'pointer',
    };

    const style: CSSProperties = {
      // This position needs to be fixed so that when the html/body is bigger
      // than the viewport, this background still shows up in the center.
      // This is particularly useful on edusight-home pages.
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: zIndex,
    };

    return <div style={style}>
      <div style={overlayStyle}/>
      <div style={containerStyle}>{this.getChild()}</div>
    </div>;
  }
}