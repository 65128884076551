import React, { forwardRef, useState } from 'react';
import AccordionItem, { BaseAccordionItemProps } from './accordian-item';

interface AccordionProps {
  items: BaseAccordionItemProps[]
  selectedItem?: BaseAccordionItemProps
}

interface State {
  openState: any
}

const Accordion = forwardRef<HTMLButtonElement, AccordionProps>(({ items, selectedItem }, selectedItemRef ) => {

  const getInitialState = () => {
    const initialState: State = { openState: {} };
    items.forEach((_, key) => {
      initialState.openState[key] = false;
    });

    return initialState;
  }
  const [state, setState] = useState<State>(getInitialState());

  const handleToggle = (key: number) => {
    const newState = { ...state };

    Object.keys(state.openState).forEach(k => {
      if (k === `${key}`) {
        newState.openState[k] = !newState.openState[k];
      } else {
        newState.openState[k] = false;
      }
    });

    setState(newState);
  }

  return (
    <div className='accordion'>
      {!selectedItem && items.map((item, key) => (
        <AccordionItem
          key={key}
          {...item}
          id={`item_${key}`}
          isActive={state.openState[key]}
          onToggle={() => handleToggle(key)}
          ref={state.openState[key] ? selectedItemRef : undefined}
        />
      ))}
      {selectedItem && (
        <AccordionItem
          {...selectedItem}
          id={'selected_item'}
          isActive={true}
          ref={selectedItemRef}
        />
      )}
    </div>
  );
});

export default Accordion;