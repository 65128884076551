import React from 'react';
import { SimpleModal } from '../../shared';

interface MarketingModalProps {
  open: boolean
  onClose: () => void
}

const MarketingModal: React.FC<MarketingModalProps> = ({ open, onClose }) => {

  return (
    <SimpleModal className='terms-modal' show={open} onClose={onClose}>
      <h2>Green Light Auto Group Pty Ltd <span>Product Promotion<span></span></span></h2>
      <h3>Acknowledgment and Consent</h3>
      <p>I authorise and acknowledge that Green Light Auto Group Pty Ltd may use my personal information to help promote its products or services or those of its related companies and alliance partners; disclose my personal information to its related companies or alliance partners in connection with that purpose; and tell me about its products or services or those of its related companies or alliance partners, and that I may withdraw my consent in the future by contacting Green Light Privacy Officer at <a href="mailto:privacy@greenlightauto.finance">privacy@greenlightauto.finance</a>.</p>
    </SimpleModal>
  );
}

export default MarketingModal;