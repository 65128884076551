import React from 'react';

import { ProgressBox } from '../../shared';
import { CentrelinkDownloadProgress } from '../../../types/centrelink';

interface DownloadProgressProps {
  progress: CentrelinkDownloadProgress
  submitting: boolean
  onReattempt?: () => void
}


const DownloadProgress: React.FC<DownloadProgressProps> = ({ progress, submitting, onReattempt }) => {

  return (
    <>
      <ProgressBox
      logoSrc='/images/data/_loans/mygov.png'
      heading='myGov'
      skipped={progress.isSkipped}
      reattempted={progress.isReattempted}
      failed={progress.isFailed}
      errorMessage={progress.errorMessage}
      onReattempt={onReattempt}
      disabled={submitting}
      steps={[
        { incompleteText: 'Connecting', completeText: 'Secured & connected', isComplete: progress.isLoggedIn },
        { incompleteText: 'Requesting documents', completeText: 'Documents received', isComplete: progress.isDownloaded }
      ]}
      />
    </>
  );
}

export default DownloadProgress;