import React from 'react';
import { v4 } from 'uuid';
import { faTimesCircle, faDollarSign, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikProps } from 'formik';
import { FormikNumberTextBox, FormikSelectBox, FormikValidator, Button, FormikSideEffects } from '../../../shared';
import { toOptionModels, nameOf } from '../../../../utils/object-helper';
import { FrequencyType, IncomeType, EmploymentType } from '../../../../types/bank-statement';
import { required, numberGreaterThan, reggex, numberLessThan, maxCharLength } from '../../../shared/form/formik/validators';
import { IncomeFormDto } from '../income-service';
import EmploymentFields from './employment-fields';
import TransactionField from './transaction-field';


interface IncomePanelProps {
  onChange: (values: IncomeFormDto) => void,
  onRemove: () => void,
  initialValues: IncomeFormDto,
  submitCount?: number
}

const requiredIfNotCentrelink = (value: any, values?: IncomeFormDto) => {
  if (values && values.incomeType !== 'Centrelink') return required(value);
  return null;
}

const requiredIfEmployment = (value: EmploymentType, values?: IncomeFormDto) => {
  if (values && values.incomeType === 'Employment') {
    if (value === 'None') return 'Field is required';
    return required(value);
  }
  return null;
}


export const validator = new FormikValidator<IncomeFormDto, IncomePanelProps>({
  fields: [
    { name: 'amount', validators: [required, numberGreaterThan(0), numberLessThan(100000), reggex(/^[0-9]*$/, 'Please enter an amount with no decimals')] },
    { name: 'frequencyType', validators: [required] },
    { name: 'incomeType', validators: [required] },
    { name: 'transactionDescription', validators: [requiredIfNotCentrelink, maxCharLength(100)] },
    { name: 'employmentType', validators: [requiredIfEmployment] },
    { name: 'employerName', validators: [requiredIfEmployment, maxCharLength(100)] },
    { name: 'employerPhone', validators: [requiredIfEmployment, maxCharLength(100)] },
    { name: 'employmentType', validators: [requiredIfEmployment, maxCharLength(100)] },
  ]
});

const IncomePanel: React.FC<IncomePanelProps> = (props) => {
  const { onChange, onRemove, initialValues } = props;

  const handleIncomeTypeChange = (event: React.FormEvent<HTMLSelectElement>, formikProps: FormikProps<IncomeFormDto>) => {
    const value = event.currentTarget.value as IncomeType
    if (formikProps.values.incomeType === value) return;
    formikProps.setValues({
      ...formikProps.values,
      incomeType: value,
      employerName: undefined,
      employerPhone: undefined,
      employmentType: undefined,
    }, true);
  }

  const frequencyTypeOptions: FrequencyType[] = ['Weekly', 'Fortnightly', 'Monthly'];
  const incomeTypesOptions: IncomeType[] = ['Employment', 'Centrelink', 'Other'];

  const formId = v4();

  const getTitle = (values: IncomeFormDto) => !values.incomeType ? 'Income' : `${values.incomeType} Income`;

  return (
    <div data-test='income-item-panel' className="income-item-panel">
      <Formik<IncomeFormDto>
        initialValues={validator.getInitial(initialValues)}
        onSubmit={() => { }}
        validate={(v) => validator.validate(v, props)}
        render={(formikProps) => {
          return (
            <form id={formId} onSubmit={formikProps.handleSubmit}>
              <h3 className="income-item-amount-title">
                {getTitle(formikProps.values)}
                {formikProps.values.isDetected && 
                <div>
                  {<FontAwesomeIcon className='alert-icon' icon={faExclamationCircle}/>}
                  {'Detected From Bank Statement'}
                </div>}
              </h3>
              <div className='income-item-amount-frequency-container'>
                <FormikNumberTextBox
                  faIconPrefix={faDollarSign}
                  name={nameOf<IncomeFormDto>('amount')}
                  label='Amount'
                  formikProps={formikProps}
                />
                <FormikSelectBox
                  options={toOptionModels(frequencyTypeOptions, true)}
                  emptyFirstOption
                  emptyOptionLabel='Please select'
                  name={nameOf<IncomeFormDto>('frequencyType')}
                  label='Frequency type'
                  formikProps={formikProps}
                />
              </div>
              <TransactionField formikProps={formikProps} />
              <FormikSelectBox
                options={toOptionModels(incomeTypesOptions, true)}
                emptyFirstOption
                emptyOptionLabel='Please select'
                disabled={formikProps.values.isDetected}
                onChange={(e) => handleIncomeTypeChange(e, formikProps)}
                name={nameOf<IncomeFormDto>('incomeType')}
                label='Income type'
                formikProps={formikProps}
              />
              {formikProps.values.incomeType === 'Employment' && (
                <EmploymentFields formikProps={formikProps} />
              )}
              <div className="button__pill-group">
                <Button
                  data-test='remove-income-item-button'
                  id={`remove_income_${v4()}`}
                  variant='pill'
                  size='small'
                  text='Remove'
                  onClick={() => { if (onRemove) onRemove(); }}
                  faIcon={faTimesCircle}
                  iconPlacement="before"
                />
              </div>
              <FormikSideEffects formId={formId} parentsubmitTriggerCount={props.submitCount} onChange={onChange} />
            </form>
          );
        }}
      />
    </div>
  );
}


export default IncomePanel;