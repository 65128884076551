import moment from 'moment';

export const validateField = (value: any, validation: any[]) => {
  if (validation && validation.length > 0) {
    for (const validator of validation) {
      switch (validator.type) {
        case 'required':
        {
          if (!value || /^\s*$/.test(value)) {
            return {
              isValid: false,
              errorMessage: validator.message
            };
          }
          break;
        }
        case 'mustBeTrue':
        {
          if (value !== true) {
            return {
              isValid: false,
              errorMessage: validator.message
            };
          }
          break;
        }
        case 'maxLength':
        {
          if (value && value.length > validator.maxLength) {
            return {
              isValid: false,
              errorMessage: validator.message
            };
          }
          break;
        }
        case 'ageRange':
        {
          if (!value) break;
          const startDate = moment().subtract(validator.maximum, 'years');
          const endDate = moment().subtract(validator.minimum, 'years');
          if (!moment(value, 'DD/MM/YYYY').isBetween(startDate, endDate, '[]' as any)) {
            return {
              isValid: false,
              errorMessage: validator.message
            };
          }
          break;
        }
        case 'regex':
        {
          if (!value) break;
          const regex = new RegExp(validator.expression);
          if (!regex.test(value)) {
            return {
              isValid: false,
              errorMessage: validator.message
            };
          }
          break;
        }
        case 'range':
        {            
          if (value && (Number(value) < validator.minimum || Number(value) > validator.maximum)) {
            return {
              isValid: false,
              errorMessage: validator.message
            };
          }
          break;
        }
        case 'equals':
        {
          if (value === validator.compare) {
            return {
              isValid: false,
              errorMessage: validator.message
            };
          }
          break;
        }
      }
    }
  }
  return {
    isValid: true,
    errorMessage: ''
  };
};
