import { Field, ErrorWithType } from './shared';

export type BankStatementPage = 'select' | 'login' | 'mfa' | 'another' | 'downloading' | 'income' | 'expenses' | 'loans';

export interface Bank {
  id: number
  name: string
  aliases: string[]
  supportsOpenBanking: boolean;
  supportsScreenScraping: boolean;
}

export interface StatementProgress {
  consumerId?: string
  bankId?: string
  bankName: string
  provider?: string | 'OpenBanking'
  isLoggedIn?: boolean
  hasAccounts?: boolean
  hasTransactions?: boolean
  isNormalised?: boolean
  isAnalysed?: boolean
  isFailed?: boolean
  errorMessage?: string
}

export type IncomeType = 'None' | 'Employment' | 'SelfEmployment' | 'Centrelink' | 'Other'
export const incomeTypes: IncomeType[] = ['None', 'Employment', 'SelfEmployment', 'Centrelink', 'Other'];

export type FrequencyType = 'None' | 'Weekly' | 'Fortnightly' | 'Monthly';
export const frequencyTypes: FrequencyType[] = ['None', 'Weekly', 'Fortnightly', 'Monthly'];

export type EmploymentType = 'None' | 'FullTime' | 'PermanentPartTime' | 'NonPermanentPartTime' | 'Casual' |
  'SelfEmployed' | 'Contractor' | 'Unemployed' | 'Retired' | 'VeteransAffairsPension';
export const employmentTypes: EmploymentType[] = ['None', 'FullTime', 'PermanentPartTime',
  'NonPermanentPartTime', 'Casual', 'SelfEmployed', 'Contractor', 'Unemployed', 'Retired', "VeteransAffairsPension" ];

export type ExpenseType = 'None' | 'Accommodation' | 'Groceries' | 'Communication' | 'Insurance' | 'Transport' | 'Bnpl' |'Utilities' | 'Childcare' | 'Other';
export const expenseTypes: ExpenseType[] = ['None', 'Accommodation', 'Groceries', 'Communication', 'Insurance', 'Transport', 'Bnpl', 'Utilities', 'Childcare', 'Other'];

export interface DeclaredIncome {
  incomeType?: IncomeType | ''
  frequencyType: FrequencyType | ''
  amount?: number | string
  suburbOrPhone?: string
  employerName: string
  employmentType?: EmploymentType | ''
  isDetected?: boolean
  wasFoundFromTransactions?: boolean
}

export interface DeclaredIncomeWithValidation extends DeclaredIncome {
  validation: any
}

export interface DetectedIncome {
  incomeType: IncomeType
  frequencyType: FrequencyType
  amount: number
  monthlyAmount: number
  description: string
}

export interface Expense {
  amount?: string
  frequency: FrequencyType
  explanation?: string
  freetext?: string
  additionalExplanation?: string
}

export interface ExpenseViewModel extends Expense {
  enabled?: boolean
  validation?: any
  showExplanation?: boolean
}

interface Loan {
  name: string
  amount: number
  frequency: string
}

interface BnplDebit {
  name: string
  amount: number
  frequency: string
}

export interface DetectedBnplLoans {
  existingBnpl: BnplDebit[]
}

export interface DetectedLoans {
  explanation?: string
  existingLoans: Loan[]
}

export interface BankForm {
  bankId: number
  bankName?: string
  heading?: string
  provider?: string
  status?: string
  error?: ErrorWithType
  fields: Field[]
  additionalInfo?: string
}

export interface ExpenseDictionary {
  [key: string]: Expense;
}

export interface BankStatementStateExpense {
  accommodation: Expense
  groceries: Expense
  communication: Expense
  insurance: Expense
  transport: Expense
  bnpl: Expense
  utilities: Expense
  childcare: Expense
  other: Expense
}

interface TestDetails {
  username: string
  password: string
}

export const IncomeWithCentrelinkNoRandomTestAccount: TestDetails = { username: 'IncomeWithCentrelinkNoRandom', password: 'IncomeWithCentrelinkNoRandom' };
export const IncomeWithCentrelinkTestAccount: TestDetails = { username: 'IncomeWithCentrelink', password: 'IncomeWithCentrelink' };
export const IncomeWithVeteranAffairsPensionAccount: TestDetails = { username: 'IncomeWithVeteranAffairsPension', password: 'IncomeWithVeteranAffairsPension' };
export const IncomeOnlyTestAccount: TestDetails = { username: 'IncomeOnly', password: 'IncomeOnly' };
export const CentrelinkOnlyTestAccount: TestDetails = { username: 'CentrelinkOnly', password: 'CentrelinkOnly' };
export const IncomeWithSaccTestAccount: TestDetails = { username: 'IncomeWithSaac', password: 'IncomeWithSaac' };
export const SaccOnlyTestAccount: TestDetails = { username: 'SaacOnly', password: 'SaacOnly' };
export const JointOneTestAccount: TestDetails = { username: 'JointOne', password: 'JointOne' };
export const JointTwoTestAccount: TestDetails = { username: 'JointTwo', password: 'JointTwo' };
export const BuyNowPayLaterAccount: TestDetails = { username: 'BuyNowPayLaterUser', password: 'BuyNowPayLaterUser' };
export const TestBankLoginDetails: TestDetails[] = [
  IncomeWithCentrelinkNoRandomTestAccount,
  IncomeWithCentrelinkTestAccount,
  IncomeWithVeteranAffairsPensionAccount,
  IncomeOnlyTestAccount,
  CentrelinkOnlyTestAccount,
  IncomeWithSaccTestAccount,
  SaccOnlyTestAccount,
  JointOneTestAccount,
  JointTwoTestAccount,
  BuyNowPayLaterAccount
];

