import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps } from '../../../types/tracker';

interface LapsedProps extends BaseTrackerContentProps {
}

const Lapsed: React.FC<LapsedProps> = (props) => {

  return (
    <Page
      title="Application lapsed"
      lead="It’s been a while since you visited this page and your application has lapsed. You can either start a new application or contact our customer service team.">
      <Row>
        <TrackerPageMainContent
          {...props}
          action={{
            title: "Start a new application",
            descriptor: "After a new application is submitted, you will receive an updated link to this page to track your progress.",
            callToAction: {
              type: "sonicRoute",
              label: "Apply now",
              action: "/loan-application/"
            }
          }}
        />
      </Row>
      <Row>
        <TrackerPageSteps
          {...props}
          progress={{
            callToAction: {
              type: "sonicRoute",
              label: "Start a new application",
              action: "/loan-application/"
            }
          }}
        />
      </Row>
    </Page>
  );
}

export default Lapsed;
