
interface LocConfigData {
  amount: number;
  term: number;
  repayment: number;
}

export const validLocConfigs: LocConfigData[] = [
  {
    "amount": 400,
    "term": 18,
    "repayment": 32
  },
  {
    "amount": 450,
    "term": 18,
    "repayment": 36
  },
  {
    "amount": 500,
    "term": 18,
    "repayment": 40
  },
  {
    "amount": 550,
    "term": 18,
    "repayment": 43
  },
  {
    "amount": 600,
    "term": 18,
    "repayment": 47
  },
  {
    "amount": 650,
    "term": 18,
    "repayment": 51
  },
  {
    "amount": 700,
    "term": 18,
    "repayment": 55
  },
  {
    "amount": 750,
    "term": 18,
    "repayment": 59
  },
  {
    "amount": 800,
    "term": 18,
    "repayment": 63
  },
  {
    "amount": 850,
    "term": 18,
    "repayment": 67
  },
  {
    "amount": 900,
    "term": 18,
    "repayment": 71
  },
  {
    "amount": 950,
    "term": 18,
    "repayment": 75
  },
  {
    "amount": 1000,
    "term": 18,
    "repayment": 79
  },
  {
    "amount": 1050,
    "term": 18,
    "repayment": 83
  },
  {
    "amount": 1100,
    "term": 18,
    "repayment": 87
  },
  {
    "amount": 1150,
    "term": 18,
    "repayment": 91
  },
  {
    "amount": 1200,
    "term": 18,
    "repayment": 95
  },
  {
    "amount": 1250,
    "term": 18,
    "repayment": 99
  },
  {
    "amount": 1300,
    "term": 18,
    "repayment": 103
  },
  {
    "amount": 1350,
    "term": 18,
    "repayment": 107
  },
  {
    "amount": 1400,
    "term": 18,
    "repayment": 111
  },
  {
    "amount": 1450,
    "term": 18,
    "repayment": 115
  },
  {
    "amount": 1500,
    "term": 18,
    "repayment": 119
  },
  {
    "amount": 1550,
    "term": 18,
    "repayment": 122
  },
  {
    "amount": 1600,
    "term": 18,
    "repayment": 126
  },
  {
    "amount": 1650,
    "term": 18,
    "repayment": 130
  },
  {
    "amount": 1700,
    "term": 18,
    "repayment": 134
  },
  {
    "amount": 1750,
    "term": 18,
    "repayment": 138
  },
  {
    "amount": 1800,
    "term": 18,
    "repayment": 142
  },
  {
    "amount": 1850,
    "term": 18,
    "repayment": 146
  },
  {
    "amount": 1900,
    "term": 18,
    "repayment": 150
  },
  {
    "amount": 1950,
    "term": 18,
    "repayment": 154
  },
  {
    "amount": 2000,
    "term": 18,
    "repayment": 158
  },
  {
    "amount": 2250,
    "term": 36,
    "repayment": 119
  },
  {
    "amount": 2500,
    "term": 36,
    "repayment": 132
  },
  {
    "amount": 2750,
    "term": 36,
    "repayment": 145
  },
  {
    "amount": 3000,
    "term": 36,
    "repayment": 159
  },
  {
    "amount": 3250,
    "term": 36,
    "repayment": 172
  },
  {
    "amount": 3500,
    "term": 36,
    "repayment": 185
  },
  {
    "amount": 3750,
    "term": 36,
    "repayment": 198
  },
  {
    "amount": 4000,
    "term": 36,
    "repayment": 212
  },
  {
    "amount": 4250,
    "term": 36,
    "repayment": 225
  },
  {
    "amount": 4500,
    "term": 36,
    "repayment": 238
  },
  {
    "amount": 4750,
    "term": 36,
    "repayment": 251
  },
  {
    "amount": 5000,
    "term": 36,
    "repayment": 264
  },
  {
    "amount": 5250,
    "term": 36,
    "repayment": 278
  },
  {
    "amount": 5500,
    "term": 36,
    "repayment": 291
  },
  {
    "amount": 5750,
    "term": 36,
    "repayment": 304
  },
  {
    "amount": 6000,
    "term": 36,
    "repayment": 317
  },
  {
    "amount": 6250,
    "term": 36,
    "repayment": 331
  },
  {
    "amount": 6500,
    "term": 36,
    "repayment": 344
  },
  {
    "amount": 6750,
    "term": 36,
    "repayment": 357
  },
  {
    "amount": 7000,
    "term": 36,
    "repayment": 370
  },
  {
    "amount": 7250,
    "term": 36,
    "repayment": 383
  },
  {
    "amount": 7500,
    "term": 36,
    "repayment": 397
  },
  {
    "amount": 7750,
    "term": 36,
    "repayment": 410
  },
  {
    "amount": 8000,
    "term": 36,
    "repayment": 423
  },
  {
    "amount": 8250,
    "term": 36,
    "repayment": 436
  },
  {
    "amount": 8500,
    "term": 36,
    "repayment": 450
  },
  {
    "amount": 8750,
    "term": 36,
    "repayment": 463
  },
  {
    "amount": 9000,
    "term": 36,
    "repayment": 476
  },
  {
    "amount": 9250,
    "term": 36,
    "repayment": 489
  },
  {
    "amount": 9500,
    "term": 36,
    "repayment": 502
  },
  {
    "amount": 9750,
    "term": 36,
    "repayment": 516
  },
  {
    "amount": 10000,
    "term": 36,
    "repayment": 529
  }
];

