import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export interface PillButtonProps {
  className?: string
  id: string
  text: string
  toggleOn?: boolean
  hideIcon?: boolean
}

export interface PillButtonFullProps extends PillButtonProps {
  onChange: (id: string) => void
}

const PillButton: React.FC<PillButtonFullProps> = ({ className, onChange, id, text, toggleOn, hideIcon }) => {
  const buttonClassName = classNames({
    'button__pill-selection': true,
    'button__pill-remove': toggleOn,
    [className!]: className !== undefined
  });
  return (
    <button
      type='button'
      id={`${id}_button_pill`}
      className={buttonClassName}
      onClick={() => onChange(id)}>
      {!hideIcon && <FontAwesomeIcon className='mr-5' icon={toggleOn ? faTimesCircle : faPlusCircle} />}
      {text}
    </button>
  );
}

export default PillButton;