import React from 'react';
import Alert, { AlertProps } from './alert';

interface StatusAlertProps extends AlertProps {
}

interface StatusReplacementsProps {
  enabled: boolean
  status: string
  replacement?: string
}

const StatusAlert: React.FC<StatusAlertProps> = ({ message, ...rest }) => {
  if (!message) return null;

  const statusReplacements : StatusReplacementsProps[] = (window as any).statusReplacements ?? [];
  let statusReplacement = statusReplacements.find(o => o.status === message && o.enabled === true);

  if (statusReplacement)
  {
    message = statusReplacement.replacement;
  }

  return (
    <React.Fragment>
      {message && <Alert message={message} {...rest} />}
    </React.Fragment>
  );
}

export default StatusAlert;