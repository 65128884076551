import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { useAppContext } from '../state-manager/app-context';
import * as LoanApi from '../../api/loan-application';
import { NotFoundPage } from '../shared';
import { removeTrailingSlash } from '../../utils/string-helper';
import { getPagesByPrefix } from '../routes';
import { getComponent } from '../routes/route-component-helper';

export interface LoanApplicationProps extends RouteComponentProps { }

export const LoanApplication: React.FC<LoanApplicationProps> = (props) => {

  //Route Props
  const { match, location } = props;

  const { setErrorMessage, resetSourceId, resetSessionToken, navigate, setLoading, setUiState, sourceId } = useAppContext();
  const [pageInitialising, setPageInitialising] = useState(true);

  const locationPath = removeTrailingSlash(location.pathname);
  const matchPath = removeTrailingSlash(match.path);

  //Called when a new person is visiting the page to associate them a source Id
  async function visit() {
    const visitResponse = await LoanApi.visit();
    resetSourceId(visitResponse.data.id);
    resetSessionToken(visitResponse.data.sessionToken);
    if (locationPath !== `${matchPath}/start`) navigate('Start');
  };

  const initFinished = () => {
    setPageInitialising(false);
    setLoading(false);
  }

  const shouldStartAgain = () => {
    const locationPath = removeTrailingSlash(location.pathname);
    const matchUrl = removeTrailingSlash(match.url);
    const matchPath = removeTrailingSlash(match.path);
    return locationPath === matchUrl || locationPath === `${matchPath}/start`;
  }

  async function init() {
    try {
      setLoading(true);

      //Start again
      if (!sourceId || shouldStartAgain()) {
        await visit();
        return;
      }
      const stateResponse = await LoanApi.getState({ loanApplicationId: sourceId! });
      setUiState(stateResponse.data);
    }
    catch (e) {
      setErrorMessage('Sorry, we were not able to process your request.', false);
    }
    finally {
      initFinished();
    }
  }

  useEffect(() => {
    init();
  }, []);

  if (!sourceId || pageInitialising) {
    return null;
  }

  return (
    <React.Fragment>
      <Switch>
        {getPagesByPrefix('loan').map(page => (
          <Route
            key={page.fullPath()}
            exact
            path={page.fullPath()}
            component={getComponent(page.page)}
          />
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    </React.Fragment>
  )
}

export default LoanApplication;