import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';

import * as LoanApi from '../../api/loan-application';
import { useAppContext } from '../state-manager/app-context';
import ResumeModel from './resume-model';

interface SessionTokenParams {
  loanApplicationId: string,
  expiry: string,
  signature: string
  jobId?: string
}

interface RouteProps { id: string }
interface ResumeProps extends RouteComponentProps<RouteProps> { }
const Resume: React.FC<ResumeProps> = ({ match: { params: { id } }, location }) => {

  const { setLoading, setErrorMessage, resume, resetSessionToken } = useAppContext();

  async function init() {
    try {
      const queryParams = (queryString.parse(location.search) as any) as SessionTokenParams;
      if (!queryParams || !queryParams.expiry || !queryParams.signature) {
        // no-op, either for existing /resume links or internal redirects to this page (which we can update later)
      } else {
        queryParams.loanApplicationId = id;
        const sessionToken = JSON.stringify(queryParams);
        resetSessionToken(sessionToken);
      }
      const state = (await LoanApi.getState({ loanApplicationId: id })).data;
      await LoanApi.resumeLoanApplication({ loanApplicationId: id });
      resume(new ResumeModel(state));
    } catch (e) {
      setErrorMessage('Sorry, we were not able to process your request.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return null;
}

export default Resume;

