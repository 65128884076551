import React from 'react';
import { FormikProps } from 'formik';
import { FormikSelectBox, FormikTextBox } from '../../../shared';
import { toOptionModels, nameOf } from '../../../../utils/object-helper';
import { EmploymentType } from '../../../../types/bank-statement';
import { IncomeFormDto } from '../income-service';

interface EmploymentFieldsProps {
  formikProps: FormikProps<IncomeFormDto>
}

const EmploymentFields: React.FC<EmploymentFieldsProps> = ({ formikProps }) => {

  const employmentOptions: EmploymentType[] = ['FullTime', 'PermanentPartTime', 'NonPermanentPartTime', 'Casual', 'SelfEmployed', 'Contractor', 'Unemployed', 'Retired', 'VeteransAffairsPension'];
 
  const prepopulate = () => {
    if(formikProps.values.employmentType === "VeteransAffairsPension") {
      formikProps.setFieldValue('employerName', 'Not Applicable')
      formikProps.setFieldValue('employerPhone', 'Not Applicable')
      formikProps.setFieldTouched ('employerName', false)
      formikProps.setFieldTouched ('employerPhone', false)
      return;
    }
    formikProps.setFieldValue('employerName', '')
    formikProps.setFieldValue('employerPhone', '')
  }

  return (
    <div>
      <FormikSelectBox
        options={toOptionModels(employmentOptions, true)}
        emptyFirstOption
        emptyOptionLabel='Please select'
        name={nameOf<IncomeFormDto>('employmentType')}
        label='Employment Status'
        formikProps={formikProps}
        onClick= {prepopulate}
      />

      <FormikTextBox
      name={nameOf<IncomeFormDto>('employerName')}
      label='Name of employer / business'
      formikProps={formikProps}
      />
      <FormikTextBox
      name={nameOf<IncomeFormDto>('employerPhone')}
      label='Employer phone number'
      formikProps={formikProps}
      />
    </div>
  )
};


export default EmploymentFields;