import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Page, Row, TextBox, Submit, SaveProgressButton } from '../../shared';
import { validateField } from '../../../utils/validate-field';
import LoanPanel from './loan-panel';
import * as BankApi from '../../../api/bank-statement';
import canBeOnPage, { BasePageProps } from '../../hoc/can-be-on-page';

interface LoansProps extends BasePageProps, RouteComponentProps {  }
interface State {
  explanation: string
  loanExplanationIsValid: boolean
  errorMessage: string
}

const Loans: React.FC<LoansProps> = ({ appContext }) => {
  const { navigate, executeCommandAndUpdateState, loading, uiState: { analysisId, detectedLoans, hasCentrelinkIncome } } = appContext;

  const [state, setState] = useState<State>({
    explanation: !detectedLoans ? '' : detectedLoans.explanation || '',
    loanExplanationIsValid: true,
    errorMessage: ''
  });
 
  const validateExplanation = (explanation: string) => {
    const maxLengthValidation = [{ type: 'maxLength', maxLength: 100, message: 'Please use less than 100 characters' }];
    return validateField(explanation, maxLengthValidation);
  }
  
  const handleExplanationChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const validation = validateExplanation(value);
    setState((ps) => ({ ...ps, explanation: value, loanExplanationValid: validation.isValid, errorMessage: validation.errorMessage }));
  };
  
  function canSubmit(): boolean {
    if (!(state.loanExplanationIsValid)) return false;
    
    return true;
  }
  
  const handleSubmit = () => {
    if (!canSubmit()) return;
    const command = () => BankApi.confirmLoans({ analysisId: analysisId!, explanation: state.explanation });
    executeCommandAndUpdateState(command, true)
      .then((newState) => {
        navigate(newState.hasCentrelinkIncome ? 'CentrelinkLogin' : 'ThankYou');
      });
  }

  const { existingLoans } = detectedLoans;
  const hasLoans = existingLoans && existingLoans.length > 0;
  const lead = hasLoans ? 'We\'ve identified that you have these existing loans.' : 'We were unable to identify that you have any existing loans.';
  const buttonText = hasCentrelinkIncome ? 'Continue' : 'Complete Application';
  
  return (
    <Page
      title='Confirm your loans'
      lead={lead}>
      {hasLoans ? (
        <Row>
          {hasLoans && existingLoans.map((loan, index) => (<LoanPanel {...loan} key={index} />))}
          <div className='text-center'>
            Have your loan repayments changed recently?<br />
            If different from the above, please provide more details.<br /><br />
          </div>
        </Row>
      ) : (
          <Row>
            <div className='text-center'>
              Have you recently applied for another loan?<br />
              If we've missed it, please provide more details.<br /><br />
            </div>
          </Row>
        )}
      <Row>
        <TextBox id='explanation' name='explanation' placeholder='Add details'
          value={state.explanation}
          onChange={handleExplanationChange}
          isValid={state.loanExplanationIsValid}
          errorMessage={state.errorMessage} />
      </Row>
      <Row>
        <p className="text-center">The above details are true and up to date.</p>
      </Row>
      <Row size='small'>
        <Submit
          data-test='submit-button'
          id='submit'
          text={buttonText}
          onSubmit={handleSubmit}
          disabled={loading}
        />
        <SaveProgressButton />
      </Row>
    </Page>
  );
}


export default canBeOnPage(Loans, 'LoanDeclaration');