import React from 'react';

interface IconProps {
  type: string
}

const Icon: React.FC<IconProps> = ({ type }) => {
  return (
    <i className={`icon-${type}`} />
  );
}

export default Icon;
