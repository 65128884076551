
import React from 'react';
import moment from 'moment-timezone';

import { AppContextProps } from '../state-manager/app-context';
import * as TrackerApi from '../../api/tracker';
import { TrackerLoanApplication, TrackerItemStatus, TrackerItemCallToActionProps } from '../../types/tracker';
import CallToAction, { CallToActionVariant } from './shared/call-to-action';
import { capitalizeFirstLetter } from '../../utils/string-helper';

export async function refreshTrackerLoanAsync(props: AppContextProps, loanId: string) {
  const { setTrackerError, setTrackerApplication, resetSourceId } = props;

  try {
    const trackerResponse = await TrackerApi.getLoanApplication({ loanApplicationId: loanId });
    setTrackerApplication(trackerResponse.data);
    resetSourceId(loanId);
    return trackerResponse.data;
  }
  catch (error) {
    setTrackerError(error);
  }

  return null;
}

export async function exchangeTrackerSessionTokenForResume(props: AppContextProps, loanId: string) {
  const { setTrackerError, resetSessionToken } = props;
  try {
    const trackerResponse = await TrackerApi.exchangeForResumeSessionToken({ loanApplicationId: loanId });
    resetSessionToken(trackerResponse.data);
  }
  catch (error) {
    setTrackerError(error);
  }

}

export const getTrackerItemStatusfromState = (state: TrackerLoanApplication) => {
  let status: TrackerItemStatus = state.subState as any;
  if (state.currentState === 'Contract' && state.contractSigned) {
    status = 'contractSigned';
  } else if (state.lapsed && state.subState === 'Declined') {
    status = 'lapsedAndDeclined';
  } else if (state.lapsed) {
    if (state.isOnline) {
      status = 'lapsed';
    } else {
      status = 'lapsedInStore';
    }
  } else if (!state.isOnline && state.subState === 'Withdrawn') {
    status = 'withdrawnInStore';
  }
  return capitalizeFirstLetter(status) as TrackerItemStatus;
}

export const formatDate = (dateTime: Date) => {
  if (dateTime === null) return undefined;
  return moment.tz(dateTime, moment.tz.guess()).format('DD / MM / YYYY');
}
export const formatTime = (dateTime: Date) => {
  if (dateTime === null) return undefined;
  return moment.tz(dateTime, moment.tz.guess()).format('hh:mma zz');
}

export const renderCallToAction = (callToAction: TrackerItemCallToActionProps, applicationId: string,
  onSonicFollowUpRoute: (route: string) => void, onSonicRoute: (route: string) => void, variant: CallToActionVariant = 'primary') => {
  return (
    <CallToAction
      {...callToAction}
      applicationId={applicationId}
      variant={variant}
      onSonicFollowUpRoute={onSonicFollowUpRoute}
      onSonicRoute={onSonicRoute}
    />
  );
};