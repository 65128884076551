import React from 'react';
import { FormikProps } from 'formik';
import { IncomeFormDto } from '../../income-service';
import TransactionInput from './input';

interface TransactionFieldProps {
  formikProps: FormikProps<IncomeFormDto>
}

const TransactionField: React.FC<TransactionFieldProps> = (props) => {
  const { formikProps } = props;

  const { values: { isDetected, wasFoundFromTransactions } } = formikProps;
  const isDisabled = isDetected || wasFoundFromTransactions;

  return (
    <React.Fragment>
      {isDisabled && <TransactionInput formikProps={formikProps} disabled />}
      {!isDisabled && (
        <TransactionInput
          infoTooltipText='As Per Bank Statement (e.g. Payroll Employer 1234)'
          formikProps={formikProps}
        />
      )}
    </React.Fragment >
  )
};


export default TransactionField;