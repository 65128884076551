import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps } from '../../../types/tracker';

interface ProofOfNameChangeRequestedProps extends BaseTrackerContentProps {
}

const ProofOfNameChangeRequested: React.FC<ProofOfNameChangeRequestedProps> = (props) => {

  return (
    <Page
      title="Proof of change of name needed"
      lead="You're almost there. But, we need to confirm your change of name before we can continue.">
      <Row>
        <TrackerPageMainContent
          {...props}
          action={{
            title: "Proof of change of name",
            descriptor: "Please provide a copy of one of the following documents:\n- Marriage certificate\n- Divorce certificate\n- Change of Name Certificate issued by the government",
            callToAction: {
              type: "uploadForm",
              label: "Provide change of name documents",
              documentType: 'NameChange'
            }
          }}
        />
      </Row>
      <Row>
        <TrackerPageSteps {...props} />
      </Row>
    </Page>
  );
}

export default ProofOfNameChangeRequested;
