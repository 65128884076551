import React from 'react';


interface BookmarkButtonProps { 
  title?: string
  url?: string
  className?: string
}

/* eslint-disable no-restricted-globals */
const BookmarkButton:React.FC<BookmarkButtonProps> = ({ title = document.title, url = window.location.href, className = 'button button-primary', children }) => {

  const handleClick = (event: any) => {
    event.preventDefault();
    if ((window as any).sidebar && (window as any).sidebar.addPanel) { // Mozilla Firefox Bookmark
      (window as any).sidebar.addPanel(document.title, window.location.href, '');
    } else if (window.external && ('AddFavorite' in window.external)) { // IE Favorite
      (window as any).external.AddFavorite(location.href, document.title);
    } else { // webkit - safari/chrome
      alert('Press ' + (navigator.userAgent.toLowerCase().indexOf('mac') !== -1 ? 'Command/Cmd' : 'CTRL') + ' + D to bookmark this page.');
    }
  };

  // Op 7+ && Fx >= 23
  if ( (window as any).opera || (window as any).sidebar ) {    
    return (
      <a href={url} title={title} className={className} rel="sidebar">{children}</a>
    );
  }

  return (
    <a className={className} onClick={(event) => handleClick(event)}>{children}</a> //eslint-disable-line jsx-a11y/anchor-is-valid
  );
};

export default BookmarkButton;


