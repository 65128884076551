import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

interface LoanPanelProps {
  name: string
  amount: number
  frequency: string
}

const LoanPanel: React.FC<LoanPanelProps> = ({ amount, frequency, name }) => {
  return (
    <div data-test='loan-panel' className="loan-panel fs-hide">
      <FontAwesomeIcon className='loan-icon' icon={faCheckCircle} />
      <div className="loan-panel__title">{name}</div>
      <div className="loan-panel__amount">
        <div className="loan-panel__value">{amount}</div>
        <div className="loan-panel__frequency">{frequency}</div>
      </div>
    </div>
  );
};

export default LoanPanel;