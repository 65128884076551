import React from 'react';
import { FormikProps } from "formik";
import { AutoFormPropertyDefinition } from './auto-form-types';
import { addSpacesOnCaps } from '../../../../utils/string-helper';
import FormikNumberTextBox from '../formik/formik-number-text-box';
import FormikCheckbox from '../formik/formik-checkbox';
import FormikSelectBox from '../formik/formik-select-box';
import FormikTextBox from '../formik/formik-text-box';
import FormikCaptchaBox from '../formik/formik-captcha-box';
import FormikDateValueTextBox from '../formik/formik-date-value-text-box';

export function renderInputComponent(property: AutoFormPropertyDefinition, formikProps: FormikProps<any>, submitting?: boolean, key?: any) {
  let sharedProps = {
    key: key,
    label: property.display,
    formikProps: formikProps,
    name: property.name as any,
    disabled: submitting || false
  };

  if (!sharedProps.label && property.options && property.options.displayFromName) {
    sharedProps.label = addSpacesOnCaps(property.name as string)
  }

  if (property.options && property.options.htmlAttr) sharedProps = { ...sharedProps, ...property.options.htmlAttr };

  const emptyFirstOption = !property.options || !property.options.exludeEmptyFirstOptionOnSelect;
  const placeholder = !property.options ? undefined : property.options.placeholder;
  const infoTooltip = !property.options ? undefined : property.options.infoTooltip;
  const imageData = !property.options ? undefined : property.options.imageData;
  const defaultValue = !property.options ? undefined : property.options.defaultValue;
  const onChange = !property.options ? undefined : property.options.onChange;

  switch (property.type) {
    case 'number':
      return <FormikNumberTextBox placeholder={!property.options ? undefined : property.options.placeholder} type="number" {...sharedProps} />;
    case 'checkbox':
      return <FormikCheckbox {...sharedProps} />;
    case 'select':
      return (
        <FormikSelectBox
          {...sharedProps}
          placeholder={!property.options ? undefined : property.options.placeholder}
          emptyFirstOption={emptyFirstOption}
          options={!property.options ? [] : property.options.options || []}
          onChange={(e) => {
            if (onChange) onChange(e.currentTarget.value, formikProps);
          }}
        />
      );
    case 'password':
      return (
        <FormikTextBox
          type='password'
          {...sharedProps}
          infoTooltipText={infoTooltip}
          onChange={(e) => {
            if (onChange) onChange(e.currentTarget.value, formikProps);
          }}
        />
      );
    case 'captcha':
      return <FormikCaptchaBox {...sharedProps} imageData={imageData} />;
    case 'date':
      return <FormikDateValueTextBox {...sharedProps} placeholder={placeholder} />;
    case 'script':
      break;
    case 'header':
      return <h4 key={key}>{defaultValue && defaultValue()}</h4>;
    case 'paragraph':
      return <p key={key}>{defaultValue && defaultValue()}</p>;
    case 'instructions':
      return <p className='form__help-text text-center' key={key}>{defaultValue && defaultValue()}</p>;
    case 'text':
    default:
      return (
        <FormikTextBox
          {...sharedProps}
          onChange={(e) => {
            if (onChange) onChange(e.currentTarget.value, formikProps);
          }}
        />
      );
  }
}