import React from 'react';
import { TrackerLoanApplication, TrackerItemContentProgress } from '../../../../../types/tracker';
import * as Service from '../../../tracker-service';
import { TrackerProgressItem } from '../..';

interface AssessmentStepProps {
  application: TrackerLoanApplication
  applicationId: string
  onSonicFollowUpRoute: (route: string) => void
  onSonicRoute: (route: string) => void
  status: string
  stepNumber: number
  isStepBeforeTermination: boolean
  progress?: TrackerItemContentProgress
}

const AssessmentStep: React.FC<AssessmentStepProps> = (props) => {
  const { application, applicationId, onSonicFollowUpRoute, onSonicRoute,
    status, stepNumber, isStepBeforeTermination, progress } = props;

  let contentBlock: any = undefined;
  const contentBlockArray: any[] = [];
  const hasCallToAction = progress && progress.callToAction ? true : false;

  var { approvedApplication } = application;

  if (status === 'complete') {
    if (application.currentState === 'Declined' && isStepBeforeTermination) {
      contentBlock = (
        <div>
          <span className="label">Date:</span>
          {Service.formatDate(application.states.declined.entered!)}
          <br />
        </div>
      );
    } else if (application.currentState === 'Withdrawn' && isStepBeforeTermination) {
      contentBlock = (
        <div>
          <span className="label">Date:</span>
          {Service.formatDate(application.states.withdrawn.entered!)}
          <br />
        </div>
      );
    } else if (approvedApplication) {
      contentBlock = (
        <div>
          <span className="label">Date:</span>{Service.formatDate(approvedApplication.timestamp)}<br />
          <span className="label">Approved for:</span>${approvedApplication.loanAmount.toFixed(2)}
        </div>
      );
    }
  } else {
    if (status === 'current') {
      contentBlockArray.push((
        <span key="1" className="currentStepStatus">
          Began assessment on <span style={{ whiteSpace: 'nowrap' }}>{Service.formatDate(application.states.assessment.entered!)}</span>
        </span>
      ));
    }
    if (progress && progress.followUp) {
      contentBlockArray.push(<span key="2">{progress.followUp}</span>);
    }

    if (hasCallToAction) {
      contentBlockArray.push((
        <div key="3" className="button-wrapper">
          {Service.renderCallToAction(progress!.callToAction, applicationId,
            onSonicFollowUpRoute, onSonicRoute, 'primary')}
        </div>
      ));
    }
  }

  const hasContentBlockArray = contentBlockArray.length ? true : false;

  return (
    <TrackerProgressItem
      title={status === 'complete' ? 'Application assessed' : 'Application assessment'}
      key={stepNumber}
      index={stepNumber}
      hasCTA={hasCallToAction}
      status={status}
    >
      <div>
        {contentBlock}
        {hasContentBlockArray && contentBlockArray}
      </div>
    </TrackerProgressItem>
  );
}

export default AssessmentStep;