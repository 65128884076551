import React, { useState } from 'react';
import { DocumentUploadForm, Button } from '../../shared';
import { useAppContext } from '../../state-manager/app-context';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface ManualDocumentUploadProps {
  applicationId: string
  centrelinkId: string
  centrelinkLogin: () => void
}

const ManualDocumentUpload: React.FC<ManualDocumentUploadProps> = ({ applicationId, centrelinkId, centrelinkLogin }) => {
  const [complete, setComplete] = useState<boolean>(false);

  const appContext = useAppContext();

  const { uiState: { completionStatus: { centrelinkComplete } } } = appContext;

  return (
    <React.Fragment>
      {!complete && (
        <DocumentUploadForm
          applicationId={applicationId}
          centrelinkId={centrelinkId}
          documentType={'CentrelinkStatement'}
          onSuccess={() => setComplete(true)}
          isFollowUp={false}
          submitButtonText={'Upload Documents'}
        />
      )}
      {!complete && (
        <Button
          id='login'
          text='Go back to the Centrelink Login page'
          variant='tertiary-compact'
          faIcon={faArrowRight}
          iconPlacement='before'
          onClick={centrelinkLogin}
        />
      )}
      {complete && (
        <p style={{ textAlign: 'center' }}>
          Thanks for uploading your documents.
        </p>
      )}
      {centrelinkComplete && (
        <p style={{ textAlign: 'center' }}>
          Please click below to submit your application.
        </p>
      )}
    </React.Fragment>
  );
};

export default ManualDocumentUpload;