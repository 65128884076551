import React, { useState, useEffect } from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';

import { useAppContext } from '../state-manager/app-context';
import * as BankApi from '../../api/bank-statement';
import * as LoanApi from '../../api/loan-application';
import { UiState } from '../../types/state';
import ResumeModel from '../resume/resume-model';
import { getQueryParam } from '../../utils/url-helper';
import { getPagesByPrefix } from '../routes';

import NotFoundPage from '../shared/pages/not-found-page';
import { getComponent } from '../routes/route-component-helper';

export interface BankStatementProps extends RouteComponentProps { }
export const BankStatement: React.FC<BankStatementProps> = (props) => {

  const { match, location } = props;

  const { setErrorMessage, setUiState, uiState, setLoading, sourceId, navigate } = useAppContext();
  const [pageInitialising, setPageInitialising] = useState(true);

  async function init() {

    try {
      setLoading(true);

      if (!sourceId) {
        navigate('Root', false);
        return;
      }

      let shouldUpdateUiState = false;
      let currentState: UiState = !uiState ? (undefined as any) : { ...uiState };
      if (!currentState) {
        currentState = (await LoanApi.getState({ loanApplicationId: sourceId })).data;
        shouldUpdateUiState = true;
      }


      //We are up to Bank.  Check now if we need to create an analysis Id or update follow up state
      const shouldReset = getQueryParam(location.search, 'reset')?.toLowerCase() === 'true';
      const isFollowUp = getQueryParam(location.search, 'isFollowUp')?.toLowerCase() === 'true';

      if (shouldReset || isFollowUp) {

        if (shouldReset) {
          await BankApi.create({ loanApplicationId: sourceId });
        }

        if (isFollowUp) {
          await LoanApi.updateFollowUpState({ loanApplicationId: sourceId, state: 'BankStatements' })
        }

        currentState = (await LoanApi.getState({ loanApplicationId: sourceId })).data;
        shouldUpdateUiState = true;
      }

      const resumeModel = new ResumeModel(currentState);
      if (!resumeModel.page || resumeModel.page.prefix === 'loan') {
        navigate('Resume', false, sourceId);
        return;
      }

      if (!currentState.analysisId) {
        await BankApi.create({ loanApplicationId: sourceId });
        currentState = (await LoanApi.getState({ loanApplicationId: sourceId })).data;
        shouldUpdateUiState = true;
      }


      //Now we are good to go
      if (shouldUpdateUiState) setUiState(currentState);

      //Navigate to first page if on the index page
      if (match.url === location.pathname) navigate('BankSelect', false);
      setPageInitialising(false);
      setLoading(false);
    }
    catch (e) {
      setErrorMessage('Sorry, we were not able to process your request.', false);
    }
    finally {
      setPageInitialising(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    init();
  }, []);

  if (!sourceId || pageInitialising) {
    return null;
  }

  return (
    <React.Fragment>
      <Switch>
        {getPagesByPrefix('bank').map(page => (
          <Route
            key={page.fullPath()}
            exact
            path={page.fullPath()}
            component={getComponent(page.page)}
          />
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    </React.Fragment>
  )
}

export default BankStatement;