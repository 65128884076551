import React, { ChangeEvent } from 'react';
import AutoComplete, { AutoCompleteProps } from '../auto-complete';
import { safeGetValue, safeGetError, shouldShowError } from './formik-helper';
import { FormikProps } from 'formik';

interface FormikAutoCompleteProps<T> extends AutoCompleteProps {
  formikProps: FormikProps<T>
}

const FormikAutoComplete: React.FC<FormikAutoCompleteProps<any>> = ({ formikProps, onChange, inputProps, ...rest }) => {

  const handleChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    if (formikProps && formikProps.handleChange) {
      formikProps.handleChange(e);
    }

    if (onChange) onChange(e, value);
  };

  const handleBlur = (e: any) => {
    if (formikProps && formikProps.handleBlur) {
      formikProps.handleBlur(e);
    }
  };

  const compiledInputProps: any = !inputProps ? {} : inputProps;
  compiledInputProps.onBlur = handleBlur;
  return (
    <AutoComplete
      {...rest}
      value={safeGetValue(formikProps, compiledInputProps.name)}
      inputProps={compiledInputProps}
      onChange={handleChange}
      isValid={!shouldShowError(formikProps, compiledInputProps.name)}
      errorMessage={safeGetError(formikProps, compiledInputProps.name)}
    />
  );
};

export default FormikAutoComplete;