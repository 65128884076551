import Axios from "axios";
import { faFileExcel, faFileImage, faFilePdf, faFile } from '@fortawesome/free-solid-svg-icons';

interface DownloadUrlOptions {
  url: string,
  type: 'GET' | 'POST'
  postBody?: any
  suggestedFileName?: string
}

export function downloadUrl<T = any>(options: DownloadUrlOptions) {
  return new Promise<T>((resolve, reject) => {
    if (options.type === 'GET') {
      downloadUrlViaGet(options, resolve);
    } else {
      downloadUrlViaOther(options, resolve, reject);
    }
  });
}

const downloadUrlViaGet = (options: DownloadUrlOptions, promiseResolve: () => void) => {
  const a: any = document.createElement('a');
  a.style.display = 'none';
  a.setAttribute('href', options.url);
  a.setAttribute('download', options.suggestedFileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  promiseResolve();
}

const downloadUrlViaOther = (options: DownloadUrlOptions, promiseResolve: (response: any) => void, promiseReject: (error?: any) => void) => {
  Axios({ url: options.url, method: options.type, data: options.postBody, responseType: 'blob' })
    .then(response => {
      const a: any = document.createElement('a');
      a.style.display = 'none';
      a.setAttribute('href', window.URL.createObjectURL(new Blob([response.data])));
      a.setAttribute('download', options.suggestedFileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return promiseResolve(response);
    })
    .catch(e => promiseReject(e));
}

export const downloadJson = (obj: any, suggestedFileNameWithoutExtension: string) => {
  const dataHref = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(obj))}`;
  const a: any = document.createElement('a');
  a.style.display = 'none';
  a.setAttribute('href', dataHref);
  a.setAttribute('download', `${suggestedFileNameWithoutExtension}.json`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const toUploadFormData = (model: any) => {
  const data = new FormData();
  Object.keys(model).forEach(key => {
    data.append(key, model[key]);
  });
  return data;
}

export const getFileSize = (file: File) => {
  let size = file.size;
  const types: string[] = ['Bytes', 'KB', 'MB', 'GB'];
  let index = 0;

  while (size > 900) {
    size /= 1024;
    index++;
  }

  return `${Math.round(size * 100 / 100)} ${types[index]}`;
};

export const toMb = (bytes: number) => (bytes / (1024 * 1024));

export const getExtension = (file: File) => {
  const extension = !file || !file.name ? '' : file.name.split('.').reverse()[0];
  return extension.toLowerCase();
}

export type FileMimeType = 'CSV' | 'Excel' | 'Image' | 'PDF';

export const getMimes = (type: FileMimeType) => {
  switch (type) {
    case 'CSV':
      return ['text/csv', 'application/vnd.ms-excel'];
    case 'Excel':
      return ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    case 'Image':
      return ['image/png', 'image/bmp', 'image/gif', 'image/jpeg', 'image/jpg', 'image/tiff', 'image/svg+xml', 'image/x-icon'];
    case 'PDF':
      return ['application/pdf'];
    default: return []
  }
}

export const getExtensions = (type: FileMimeType) => {
  switch (type) {
    case 'CSV':
      return ['.csv', '.xlsx'];
    case 'Excel':
      return ['.xlsx'];
    case 'Image':
      return ['.png', '.gif', '.jpeg', '.jpg'];
    case 'PDF':
      return ['.pdf'];
    default: return []
  }
}

const getFileType = (file: File) => {
  let type: FileMimeType | undefined = undefined;
  if (!file || !file.type) return type;
  if (getMimes('Excel').some((m) => m.toLowerCase() === file.type)) {
    type = 'Excel';
  } else if (getMimes('CSV').some((m) => m.toLowerCase() === file.type)) {
    type = 'CSV';
  } else if (getMimes('Image').some((m) => m.toLowerCase() === file.type)) {
    type = 'Image';
  }
  else if (getMimes('PDF').some((m) => m.toLowerCase() === file.type)) {
    type = 'PDF';
  }
  return type;
}

export const resolveIcon = (file: File) => {
  const type = getFileType(file);
  switch (type) {
    case 'CSV':
    case 'Excel':
      return faFileExcel;
    case 'Image':
      return faFileImage;
    case 'PDF':
      return faFilePdf;
    default:
      return faFile;
  }
}