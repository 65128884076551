import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { Page, Row, Submit } from '../../shared';
import ChooseAmountForm from './choose-amount-form';
import { LoanSubReason } from '../../../types/loan';
import * as LoanApi from '../../../api/loan-application';
import { SubmitLoanDetailsRequest } from '../../../api/loan-application/api models';
import canBeOnPage, { BasePageProps } from '../../hoc/can-be-on-page';
import { getMinAmount, getDefaultAmount, getTerm, isValidAmount } from "../data";
import ReasonAccordian from '../../1-loan-application/submit-loan-details/reason-accordian';
import { needExtraInfoForReason } from './loan-details-helper';

interface State {
  amount: number
  withdrawAmount: number;
  term: number
  reason?: string
  subReason?: LoanSubReason
  explanation?: string
}

export interface SubmitLoanDetailsProps extends BasePageProps, RouteComponentProps { }

const SubmitLoanDetails: React.FC<SubmitLoanDetailsProps> = ({ appContext }) => {

  const {
    loading, executeCommandAndUpdateState, navigate, reasons,
    uiState: { loanApplicationId, product, selectedLoanReasons },
  } = appContext;

  const extractLoanReasons = () => {
    if (selectedLoanReasons && selectedLoanReasons.length) {
      const selectedReason = selectedLoanReasons[0]
      const selectedReasonDetail = reasons.find(
        r => r.name === selectedReason.reason
      );
      if (selectedReasonDetail) {
        const selectedSubReason = selectedReasonDetail.subReasons.find(
          r => r.name === selectedReason.subReason
        );
        if (selectedSubReason) {
          return {
            reason: selectedReason.reason,
            subReason: selectedSubReason,
            explanation: selectedReason.explanation
          };
        }
      }
      return {};
    }
  }

  var minAmount = getMinAmount(0);

  const amount = product
    && product.amount
    && isValidAmount(parseInt(product.amount))
    ? parseInt(product.amount) : getDefaultAmount();

  const withdrawAmount = selectedLoanReasons
    && selectedLoanReasons.length
    && selectedLoanReasons[0].amount
    && isValidAmount(selectedLoanReasons[0].amount)
    ? Math.min(amount, selectedLoanReasons[0].amount) : minAmount;

  const [state, setState] = useState<State>({
    amount: amount,
    term: getTerm(amount),
    withdrawAmount: withdrawAmount,
    ...extractLoanReasons()
  });

  async function handleSubmit() {
    setState(ps => ({ ...ps, submitting: true }));
    const request: SubmitLoanDetailsRequest = {
      loanApplicationId: loanApplicationId,
      amount: state.amount,
      initialAmount: state.withdrawAmount,
      term: state.term,
      detail: state.explanation,
      reason: state.reason!,
      subReason: state.subReason!.name,
    };

    await executeCommandAndUpdateState(() => LoanApi.submitLoanDetails(request), true);
    navigate(`PersonalDetailsLoc`);
  }

  const canSubmit = () => {
    if (!state.reason || !state.subReason) return false;
    if (state.withdrawAmount < minAmount || state.amount < minAmount) return false;
    if ((!state.explanation || state.explanation.length > 499) && needExtraInfoForReason(state.withdrawAmount, state.subReason)) return false;

    return true;
  }
  return (
    <Page title='Your Line Of Credit'>
      <Row>
        <ChooseAmountForm
          disabled={loading}
          amount={amount}
          withdrawAmount={withdrawAmount}
          onChange={(newAmount, newTerm, newWithdrawAmount) => {
            setState(ps => ({ ...ps, amount: newAmount, term: newTerm, withdrawAmount: newWithdrawAmount }));
          }}
        />
      </Row>

      <Row size='x-small'>
        <ReasonAccordian
          initial={{ reason: state.reason, subReason: state.subReason, explanation: state.explanation }}
          disabled={loading}
          loanAmount={state.withdrawAmount}
          onChange={(r, sr, e) => setState(ps => ({ ...ps, reason: r, subReason: sr, explanation: e }))}
          reasons={reasons}
        />
      </Row>

      <Row size='x-small'>
        <Submit
          id='submit'
          text='Next'
          disabled={!canSubmit() || loading}
          onSubmit={handleSubmit}
        />
      </Row>
    </Page>
  );
}

export default canBeOnPage(SubmitLoanDetails, 'LoanDetailsLoc');