import React, { useState } from "react";
import { SliderWithAmount } from "../../shared";
import { getMinAmount, getMaxAmount, getRepayment, getIncrement, getTerm, isValidAmount, getValidAmounts } from "../data";
import AmountTotals from "./amount-totals";

interface ChooseAmountFormProps {
  amount: number,
  withdrawAmount: number,
  onChange: (amount: number, term: number, withdrawAmount: number) => void;
  disabled?: boolean;
}

interface State {
  amount: number;
  withdrawAmount: number;
  initialWithdrawAmount: number,
  term: number;
  repayment: number;
  submitting: boolean;
  incrementAmount: number;
  incrementWithdrawAmount: number;
}

const ChooseAmountForm: React.FC<ChooseAmountFormProps> = ({
  amount,
  withdrawAmount,
  onChange,
  disabled,
}) => {
  const validAmounts = getValidAmounts();
  const minAmount = getMinAmount(0);
  const maxAmount = getMaxAmount();

  const term = getTerm(amount);

  const [state, setState] = useState<State>({
    amount,
    withdrawAmount,
    initialWithdrawAmount: withdrawAmount,
    term,
    repayment: getRepayment(amount, term),
    submitting: false,
    incrementAmount: getIncrement(amount),
    incrementWithdrawAmount: getIncrement(withdrawAmount)
  });

  const handleAmountChange = (newAmount: number) => {
    if (isValidAmount(newAmount)) {
      const newTerm = getTerm(newAmount);
      const newWithdrawAmount = Math.min(state.withdrawAmount, newAmount);
      update({
        ...state,
        amount: newAmount,
        term: newTerm,
        withdrawAmount: newWithdrawAmount,
        initialWithdrawAmount: newWithdrawAmount,
        repayment: getRepayment(newAmount, newTerm),
        incrementAmount: getIncrement(newAmount),
        incrementWithdrawAmount: getIncrement(newWithdrawAmount),
      });
    }
  };

  const handleWithdrawAmountChange = (newAmount: number) => {
    newAmount = Math.max(minAmount, Math.min(newAmount, state.amount))
    if (isValidAmount(newAmount)) {
      update({
        ...state,
        withdrawAmount: newAmount,
        incrementWithdrawAmount: getIncrement(newAmount)
      });
    }
  };

  const update = (newState: State) => {
    setState(newState);
    onChange(newState.amount, newState.term, newState.withdrawAmount);
  };

  return (
    <>
      <h2 className="mb-0">How much would you like your credit limit to be?</h2>
      <SliderWithAmount
        disabled={disabled}
        steps={validAmounts}
        value={amount}
        min={minAmount}
        max={maxAmount}
        inputIncrement={state.incrementAmount}
        onChange={handleAmountChange}
        idPrefix="choose-amount"
        label=""
        labelPrefix="$"
      />
      <AmountTotals
        className="mt-30"
        amount={state.amount}
        term={state.term}
        repayment={state.repayment}
        calculating={false}
      />

      <h2 className="mt-30 text-center">
        How much of your credit limit would like to withdraw today?
      </h2>
      <p className="mt-30 text-center -mx-6">
        The repayment amount is based on the entire credit limit. This means you
        will repay the withdrawn amount in less than 36 months if you don't
        withdraw the full credit limit.
      </p>
      <SliderWithAmount
        disabled={disabled}
        steps={validAmounts}
        value={state.initialWithdrawAmount}
        min={minAmount}
        max={state.amount}
        inputIncrement={state.incrementWithdrawAmount}
        onChange={handleWithdrawAmountChange}
        idPrefix="choose-amount-today"
        label=""
        labelPrefix="$"
      />
      <h2 className="mt-30 text-center">What will you use it for?</h2>
      <p className="mt-30 text-center">
        Choose from the below categories and pick the option that best fits.
      </p>
    </>
  );
};

export default ChooseAmountForm;
