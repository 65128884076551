import React from 'react';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface NumberTextBoxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  errorMessage?: string
  wrapperClasses?: string
  hideLabel?: boolean
  isValid?: boolean
  variant?: string
  label?: string
  helpText?: string
  faIconPrefix?: IconDefinition
  faIconSuffix?: IconDefinition
  min?: number
  max?: number
  step?: number
}

class NumberTextBox extends React.Component<NumberTextBoxProps> {

  focus() {
    const input = (this.refs.input as any);
    if (input && input.focus) input.focus();
  }


  render() {
    const { helpText, name, errorMessage, faIconPrefix, faIconSuffix, step, min, max, id, label, hideLabel, variant, isValid, wrapperClasses, ...rest } = this.props;
    const compiledIsValid = isValid === undefined ? true : isValid;

    const wrapperClassName = classNames({
      'form__row': true,
      [wrapperClasses!]: wrapperClasses !== undefined
    });

    const labelClassName = classNames({
      'form__label': true,
      'form__error': !compiledIsValid,
      'form__label-emphasized': variant === 'secure',
      'input-with-icon': faIconPrefix !== undefined || faIconSuffix !== undefined,
      'input-with-icon-left': faIconPrefix !== undefined,
      'input-with-icon-right': faIconSuffix !== undefined
    });

    return (
      <div data-test={`form-control-${name}`} className={wrapperClassName}>
        <label id={`${id}_label`} className={labelClassName}>
          {hideLabel ? <span className='form__label-hide'>{label}</span> : label}
          <input
            ref='input'
            type='number'
            name={name}
            className={'form__input'}
            id={id}
            step={step || 1}
            min={min || 0}
            max={max || 100000}
            aria-describedby={`${id}_help_text`}
            {...rest}
          />
          {faIconPrefix && <FontAwesomeIcon className='input-icon-left' icon={faIconPrefix} />}
          {faIconSuffix && <FontAwesomeIcon className='input-icon-right' icon={faIconSuffix} />}
        </label>
        {helpText && <p id={`${id}_help_text`} className="form__help-text" dangerouslySetInnerHTML={{ __html: helpText }}></p>}
        {!compiledIsValid && <span className="form__error-message">{errorMessage}</span>}
      </div>
    );
  }

}

export default NumberTextBox;
