import React from 'react';
import { RouteComponentProps } from 'react-router';

import { Page, Row, Alert } from '../../shared';
import AboutThisProcess from '../components/about-this-process';
import FormHeader from '../components/form-header';
import SkipButton from '../components/skip-button';
import TroubleWithLogin from '../components/trouble-with-login';
import * as CentrelinkApi from '../../../api/centrelink';
import { MfaRequest } from '../../../api/centrelink/api models';
import { useAppContext } from '../../state-manager/app-context';
import MfaForm from './mfa-form';

interface MfaProps extends RouteComponentProps { }

const Mfa: React.FC<MfaProps> = (props) => {

  const appContext = useAppContext();

  const {
    loading, executeCommandAndUpdateState, navigate,
    uiState: { centrelinkId, centrelinkMfaForm, completionStatus }
  } = appContext;

  const handleSubmit = (values: MfaRequest) => {
    const fortunaValues = {providerId: centrelinkMfaForm.providerId, token: centrelinkMfaForm.token,
      reference: centrelinkMfaForm.reference, credentials: values.credentials, centrelinkId: values.centrelinkId };

    const command =
    centrelinkMfaForm.provider === 'Proviso' ? () => CentrelinkApi.mfa(values) :
    centrelinkMfaForm.provider === 'Fortuna' ? () => CentrelinkApi.fortunaMfa(fortunaValues) :
        () => CentrelinkApi.mfa(values);
    executeCommandAndUpdateState(command, true).then(newState => {
      const hasError = newState.centrelinkMfaForm && newState.centrelinkMfaForm.error;
      if (!hasError) {
        navigate('CentrelinkDownloading')
      }
    })
  }

  const handleSkip = () => {
    const command = () => CentrelinkApi.skipLogin({ centrelinkId: centrelinkId! });
    executeCommandAndUpdateState(command, true).then(() => navigate('CentrelinkDownloading', false));
  }

  const hasPreviouslySubmitted = completionStatus.applicationSubmittedDates && completionStatus.applicationSubmittedDates.length > 0;

  return (
    <Page
      title='Your Centrelink statements'
      lead='In order to accurately assess your financial situation we require access to your Centrelink statements. Connect to your myGov account below.'>
      <Row size='x-small'>
        <AboutThisProcess />
      </Row>
      <Row size='small' variant='secure'>
        <FormHeader />
        <h3>{centrelinkMfaForm.heading}</h3>
        <React.Fragment>
          {centrelinkMfaForm.error && (
            <React.Fragment>
              <Alert message={centrelinkMfaForm.error.message} variant={centrelinkMfaForm.error.type as any} />
              <TroubleWithLogin />
              {!hasPreviouslySubmitted && <SkipButton onClick={handleSkip} disabled={loading} />}
            </React.Fragment>
          )}
        </React.Fragment>
        <MfaForm
          centrelinkId={centrelinkId!}
          formDetails={centrelinkMfaForm}
          onSubmit={handleSubmit}
          submitting={loading}
        />
      </Row>
    </Page>
  );
}

export default Mfa;