import React from 'react';
import classNames from 'classnames';

type RowSize = 'x-small' |'small' | 'medium' | 'large' | 'x-large';
interface RowProps {
  size?: RowSize
  title?: string
  lead?: string
  variant?: string
}

const Row: React.FC<RowProps> = ({ size, variant, title, lead, children }) => {
  
  const compiledSize: RowSize = !size ? 'large' : size;

  const columnClassName = classNames({
    'medium-centered columns': true,
    'medium-6': compiledSize === 'x-small',
    'medium-7': compiledSize === 'small',
    'medium-8': compiledSize === 'medium',
    'medium-9': compiledSize === 'large',
    'medium-10': compiledSize === 'x-large',
    'canvas__wrapper-secure': variant === 'secure'
  });

  return (
    <div className="row">
      <div className={columnClassName}>
        { title && <h2>{title}</h2> }
        { lead && <p className="text__lead">{lead}</p> }
        {children}
      </div>
    </div>
  );
}
 
export default Row;