import React, { useState } from "react";
import { v4 } from 'uuid';
import NumberSlider from './number-slider';
import Slider from './slider';
import { getClosest } from '../../../utils/number-helper';

interface State {
  value: number
  initialValue: number;
}

export interface SliderWithAmountProps {
  idPrefix?: string
  label?: string
  disabled?: boolean
  min: number
  max: number
  value: number
  labelPrefix?: string
  onChange?: (amount: number) => void
  steps?: number[]
  inputIncrement?: number
}

const SliderWithAmount: React.FC<SliderWithAmountProps> = ({ idPrefix, labelPrefix, steps, label, min, max, value, onChange, disabled, inputIncrement }) => {

  const compiledIdPrefix = idPrefix || v4();

  const [state, setState] = useState<State>({ value, initialValue: value });
  if (state.initialValue !== value) {
    setState({ value, initialValue: value });
  }

  const handleNumberSliderChange = (newValue: number) => {
    setState({ ...state, value: newValue });
    if (steps && steps.length) {
      newValue = getClosest(steps, newValue);
    }
    if (onChange) {
      onChange(newValue);
    }
  }

  const handleSliderChange = (newValue: number) => {
    if (steps && steps.length) {
      newValue = getClosest(steps, newValue);
    }
    setState({ ...state, value: newValue });
    if (onChange) {
      onChange(newValue);
    }
  }

  return (
    <React.Fragment>
      <NumberSlider
        disabled={disabled}
        hideButtons
        hideSliderBar
        numeralThousandsGroupStyle='none'
        id={`${compiledIdPrefix}-total`}
        name={`${compiledIdPrefix}-total`}
        label={label}
        value={state.value}
        min={min}
        max={max}
        increment={inputIncrement || 1}
        prefix={labelPrefix}
        onChange={(v) => handleNumberSliderChange(v)}
      />
      <Slider
        disabled={disabled}
        id={`${compiledIdPrefix}-slider`}
        labelPrefix={labelPrefix}
        steps={steps || []}
        value={state.value}
        min={min}
        max={max}
        onValuesUpdated={({ values }) => handleSliderChange(values[0])}
        onChange={({ values }) => handleSliderChange(values[0])}
      />
    </React.Fragment>

  );
};

export default SliderWithAmount;