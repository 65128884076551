import React from 'react';
import { AutoForm } from '../../shared';
import { MfaRequest } from '../../../api/centrelink/api models';
import { CentrelinkForm } from '../../../types/centrelink';
import { AutoFormPropertyDefinition, AutoFormInputType } from '../../shared/form/auto-form/auto-form-types';
import { SaveProgressButton } from '../../shared';

interface CentrelinkMfaFormProps {
  onSubmit: (values: MfaRequest) => void
  submitting?: boolean
  centrelinkId: string
  formDetails: CentrelinkForm
}

const CentrelinkMfaForm: React.FC<CentrelinkMfaFormProps> = (props) => {
  const { submitting, formDetails, onSubmit, centrelinkId } = props

  const mapType = (type?: string) => {
    let field: AutoFormInputType = type as any;
    if (type === 'instructions') field = 'paragraph';
    return field;
  }

  const getFields = () => {
    const fields = formDetails.fields.map<AutoFormPropertyDefinition>((field) => ({
      name: field.name,
      display: field.label,
      type: mapType(field.type),
      options: { placeholder: field.placeholder, imageData: field.imageData, options: field.options, defaultValue: () => field.value }
    }));

    return fields;
  }

  return (
    <AutoForm
      formId='centrelink-mfa-form'
      fields={getFields()}
      hideBack
      submitButtonText='Continue'
      submitting={submitting}
      submitButtonStyle={{ color: '#fff', background: '#005a46' }}
      onSubmit={(v) => onSubmit({ centrelinkId: centrelinkId, credentials: v })}
      aboveButtonContent={!formDetails.error ? undefined : (
        <p className="form__help-text">
          Info: multiple incorrect attempts may lock your account. Contact myGov if you are unsure of your credentials.
        </p>
      )}
      belowButtonContent={(
        <React.Fragment>
          <SaveProgressButton />
          <div className="text__lead">
            <br />
            Please note that the verification code for myGov will be sent to the mobile number registered with myGov which may
            be different to the mobile used in this application.
        </div>
        </React.Fragment>

      )}
    />
  );
};

export default CentrelinkMfaForm;