import { PersonalDetailsModel } from "../types/loan";

export const getFullName = (details: PersonalDetailsModel) => {
  if (!details) return '';
  const parts: string[] = []
  if (details.firstName) parts.push(details.firstName);
  if (details.middleName) parts.push(details.middleName);
  if (details.surname) parts.push(details.surname);
  return parts.join(' ');
}

export const getFullAddress = (details: PersonalDetailsModel) => {
  if (!details) return '';
  const parts: string[] = []
  if (details.unitOrLevel) parts.push(details.unitOrLevel);
  if (details.streetNumber) parts.push(details.streetNumber);
  if (details.street) parts.push(details.street);
  if (details.suburb) parts.push(details.suburb);
  if (details.state) parts.push(details.state);
  if (details.postcode) parts.push(details.postcode);
  return parts.join(' ');
}
