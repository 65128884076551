import React, { useEffect, useState } from "react";
import { AppContextProps, useAppContext } from '../state-manager/app-context';
import ResumeModel from "../resume/resume-model";
import { ResumePageType } from '../resume/resume-types'

export interface BasePageProps {
  appContext: AppContextProps
  resumeModel: ResumeModel
}

interface State {
  finished: boolean
  didNavigate: boolean
  resumeModel: ResumeModel
}

function canBeOnPage<P extends BasePageProps>(Component: React.ComponentType<P>, pageType: ResumePageType) {
  return function WithAppContextComponent(props: Pick<P, Exclude<keyof P, keyof BasePageProps>>) {

    const appContext = useAppContext();

    const [state, setState] = useState<State>({
      didNavigate: false,
      finished: false,
      resumeModel: {} as any
    });

    useEffect(() => {
      //Assumption is we need to have ui state before this gets hit
      const { uiState, navigate } = appContext;

      const newState: State = { finished: true, didNavigate: false, resumeModel: {} as any };

      if (!uiState) {
        navigate('Root');
        newState.didNavigate = true;
      }

      if (!newState.didNavigate) {
        //If we cant be on the page send to resume and it will figure it out
        newState.resumeModel = new ResumeModel(uiState);
        if (!newState.resumeModel.canBeOnPage(pageType)) {
          navigate('Resume', true, uiState.loanApplicationId);
          newState.didNavigate = true;
        }
      }

      setState(newState);
    }, []);


    if (!state.finished || state.didNavigate) {
      return null;
    }

    //We g
    return (
      <Component {...props as P} appContext={appContext} resumeModel={state.resumeModel} />
    );
  }
}

export default canBeOnPage;