import React from 'react';
import { BankLoginRequest } from '../../../api/bank-statement/api models';
import { SimpleModal } from '../../shared';
import { getHelpTextForBankName, renderHelpTextComponent } from '../bank-helper';

interface HelpModalProps {
  show: boolean
  onClose: () => void
  bankName?: string
}

export interface BankLoginFormValues extends BankLoginRequest { }

const HelpModal: React.FC<HelpModalProps> = ({ show, onClose, bankName }) => {

  return (
    <SimpleModal show={show} onClose={onClose}>
      <br />
      <h2>Need help finding your login details</h2>
      <div>
        {renderHelpTextComponent(getHelpTextForBankName(bankName || ''))}
      </div>
    </SimpleModal>
  );
};

export default HelpModal;