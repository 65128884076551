import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface FooterProps {
  copyrightYear: string;
  releaseVersion: string;
}

const Footer: React.FC<FooterProps> = ({ copyrightYear, releaseVersion }) => {
  return (
    <footer id='footer' className='footer'>
      <div className='row'>
        <div className='medium-8 column footer__left'>
          <img src='/images/cash-converters-rev.svg' className='footer__brand' alt='Cash Converters' />
          <div className='footer__phone'>Ph. 13 22 74</div>
          <div className='footer__legal'>
            <ul>
              <li><a target='_blank' rel='noopener noreferrer' href='https://www.cashconverters.com.au/resources/terms-conditions'>Terms &amp; Conditions</a></li>
              <li><a target='_blank' rel='noopener noreferrer' href='https://www.cashconverters.com.au/resources/privacy-policy'>Privacy Policy</a></li>
              <li><a target='_blank' rel='noopener noreferrer' href='https://www.cashconverters.com.au/resources/dispute-resolution-policy'>Dispute Resolution Policy</a></li>
              <li><a target='_blank' rel='noopener noreferrer' href='https://www.cashconverters.com.au/resources/responsible-lending'>Responsible Lending</a></li>
            </ul>
          </div>
          <div className='footer__acl'>Australian Credit License 391436, 391415</div>
        </div>
        <div className='medium-4 column footer__right'>
          <div className='footer__warning'>
            <a target='_blank' rel='noopener noreferrer' href='https://www.cashconverters.com.au/warning-about-borrowing'>
              <FontAwesomeIcon icon={faExclamationTriangle} className='mr-5' />
              <span className='icon-warning'>Warning about Borrowing</span>
            </a>
          </div>
          <div className='footer__copyright'>&copy; {copyrightYear} Cash Converters Pty Ltd.</div>
          <div className='footer__abn'>ABN 75 009 288 804</div>
          <div className='footer__version'>Version: {releaseVersion}</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;