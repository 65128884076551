import React from 'react';

import ModalPortal from './modal-portal';
import ModalBackground from './modal-background';

/**
 * This is a shorthand that combines the portal and background, because it is
 * not often that I use one without the other. I have separated them out in
 * the source code so that one can build other combinations of Background and
 * Portal.
 */

const ModalContainer: React.FC<any> = ({ children, ...rest }) => {
  return <ModalPortal {...rest}>
    <ModalBackground {...rest}>
      {children}
    </ModalBackground>
  </ModalPortal>;
};

export default ModalContainer;