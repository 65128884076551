import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import * as Service from './tracker-service';
import { useAppContext } from '../state-manager/app-context';

import TrackerErrorPage from './tracker-error-page';
import AwaitingContractSigning from './tracker-pages/awaiting-contract-signing';
import BankStatementsAdded from './tracker-pages/bank-statements-added';
import BankStatementsRequested from './tracker-pages/bank-statements-requested';
import CentrelinkStatementsAdded from './tracker-pages/centrelink-statements-added';
import CentrelinkStatementsRequested from './tracker-pages/centrelink-statements-requested';
import ContractSigned from './tracker-pages/contract-signed';
import Declined from './tracker-pages/declined';
import FundsDisbursed from './tracker-pages/funds-disbursed';
import InAssessment from './tracker-pages/in-assessment';
import InReview from './tracker-pages/in-review';
import Lapsed from './tracker-pages/lapsed';
import LapsedAndDeclined from './tracker-pages/lapsed-and-declined';
import LapsedInStore from './tracker-pages/lapsed-in-store';
import ProofDocumentsReceived from './tracker-pages/proof-documents-received';
import ProofOfAccountOwnershipRequested from './tracker-pages/proof-of-account-ownership-requested';
import ProofOfIdRequested from './tracker-pages/proof-of-id-requested';
import ProofOfNameChangeRequested from './tracker-pages/proof-of-name-change-requested';
import Withdrawn from './tracker-pages/withdrawn';
import WithdrawnInStore from './tracker-pages/withdrawn-in-store';
import { BaseTrackerContentProps } from '../../types/tracker';

interface SessionTokenParams {
  loanApplicationId: string,
  expiry: string,
  signature: string
}

interface RouteParams { id: string }

interface TrackerProps extends RouteComponentProps<RouteParams> { }

const Tracker: React.FC<TrackerProps> = ({ match: { params: { id } }, location }) => {

  const appContext = useAppContext();
  const { setLoading, navigateUnsafe, resetSourceId, resetSessionToken, trackerError, trackerApplication } = appContext;

  //On Init
  useEffect(() => {
    const queryParams = (queryString.parse(location.search) as any) as SessionTokenParams;
    if (!queryParams || !queryParams.expiry || !queryParams.signature) {
      // later we'll require the the params to exist, for now we'll continue to suppor the old /tracker links
      Service.refreshTrackerLoanAsync(appContext, id);
    } else {
      queryParams.loanApplicationId = id;
      const sessionToken = JSON.stringify(queryParams);
      resetSessionToken(sessionToken);

      Service.refreshTrackerLoanAsync(appContext, id)
      .then(r =>
        {

          if (r) {
            const status = Service.getTrackerItemStatusfromState(r);

            // if we're in a 'follow up' state, exchange for a 'resume' token for the subsequent doc upload or bank/centrelink flow
            if (status === "bankStatementsRequested" ||
              status === "centrelinkStatementsRequested" ||
              status === "proofOfAccountOwnershipRequested" ||
              status === "proofOfIdRequested" ||
              status === "proofOfNameChangeRequested") {
                Service.exchangeTrackerSessionTokenForResume(appContext, id);
            }
          }
        });
    }
  }, []);

  const handleSonicFollowUpRoute = (route: string) => {
    setLoading(true);
    resetSourceId(id);
    navigateUnsafe(route);
  }

  const handleSonicRoute = (route: string) => {
    setLoading(true);
    navigateUnsafe(route);
  }

  if (trackerError) return <TrackerErrorPage />;

  const status = Service.getTrackerItemStatusfromState(trackerApplication);
  const sharedProps: BaseTrackerContentProps = {
    application: trackerApplication,
    applicationId: id,
    onSonicFollowUpRoute: handleSonicFollowUpRoute,
    onSonicRoute: handleSonicRoute
  };

  switch (status) {
    case 'awaitingContractSigning': return <AwaitingContractSigning {...sharedProps} />;
    case 'bankStatementsAdded': return <BankStatementsAdded {...sharedProps} />;
    case 'bankStatementsRequested': return <BankStatementsRequested {...sharedProps} />;
    case 'centrelinkStatementsAdded': return <CentrelinkStatementsAdded {...sharedProps} />;
    case 'centrelinkStatementsRequested': return <CentrelinkStatementsRequested {...sharedProps} />;
    case 'contractSigned': return <ContractSigned {...sharedProps} />;
    case 'declined': return <Declined {...sharedProps} />;
    case 'fundsDisbursed': return <FundsDisbursed {...sharedProps} />;
    case 'inAssessment': return <InAssessment {...sharedProps} />;
    case 'inReview': return <InReview {...sharedProps} />;
    case 'lapsed': return <Lapsed {...sharedProps} />;
    case 'lapsedAndDeclined': return <LapsedAndDeclined {...sharedProps} />;
    case 'lapsedInStore': return <LapsedInStore {...sharedProps} />;
    case 'proofDocumentsReceived': return <ProofDocumentsReceived {...sharedProps} />;
    case 'proofOfAccountOwnershipRequested': return <ProofOfAccountOwnershipRequested {...sharedProps} />;
    case 'proofOfIdRequested': return <ProofOfIdRequested {...sharedProps} />;
    case 'proofOfNameChangeRequested': return <ProofOfNameChangeRequested {...sharedProps} />;
    case 'withdrawn': return <Withdrawn {...sharedProps} />;
    case 'withdrawnInStore': return <WithdrawnInStore {...sharedProps} />;
    default: return null;
  }
}

export default Tracker;
