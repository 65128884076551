import React from 'react';
import Form from './form';
import * as LoanApi from '../../../api/loan-application';
import { SubmitMobileVerificationRequest } from '../../../api/loan-application/api models';
import { Page, Row, PageInfoAlert } from '../../shared';
import { pageHasError } from '../../../utils/error-helper';
import canBeOnPage, { BasePageProps } from '../../hoc/can-be-on-page';
import { RouteComponentProps } from 'react-router';
import { prefixes } from '../../../utils/page-prefixes';

export interface SubmitMobileVerificationProps extends BasePageProps, RouteComponentProps { }

const SubmitMobileVerification: React.FC<SubmitMobileVerificationProps> = ({ appContext }) => {

  const { uiState, navigateUnsafe, navigate, executeCommandAndUpdateState, loading } = appContext;

  const isLoc = (() => {
    switch (uiState.options.applicationType) {
      case 'CreditLimitIncrease':
      case 'LineOfCredit':
        return true;
      default:
        return false;
    }
  })();

  const handleSubmit = (values: SubmitMobileVerificationRequest) => {
    values.loanApplicationId = uiState.loanApplicationId!;
    executeCommandAndUpdateState(() => LoanApi.submitMobileVerification(values), true).then(newState => {
      if (!pageHasError(newState.mobileVerificationData)) {
        navigate('BankSelect');
      }
    })
  };

  return (
    <Page title="Security check" lead="We've just sent you an SMS. Please enter the 4 digit code sent to the mobile number you provided to continue with your application.">
      <Row size="small" variant='secure'>
        {uiState.mobileVerificationData && <PageInfoAlert pageInfo={uiState.mobileVerificationData} className={'text-center'} />}
        <Form
          submitting={loading}
          onSubmit={handleSubmit}
          onSkip={() => isLoc? navigateUnsafe(`${prefixes.loc}/reset-mobile-verification`) : navigate('ResetMobileVerification')}
        />
      </Row>
    </Page>
  );
}

export default canBeOnPage(SubmitMobileVerification, 'MobileVerification');