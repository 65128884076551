import React, { useMemo } from 'react';
import { LoanProduct, ApplicationType } from '../../../types/loan';
import CashPreview from './cash-preview';

interface HeaderProps {
  loanProduct?: LoanProduct
  path: string,
  applicationType?: ApplicationType
}

const Header: React.FC<HeaderProps> = ({ loanProduct, path, applicationType }) => {

  const showLoanProduct = (
    loanProduct &&
    applicationType === 'Loan' &&
    path !== '/loan-application/' &&
    path !== '/loan-application/start/' &&
    path !== '/loan-application/submit-loan-details/' &&
    path.indexOf('/tracker/') === -1 &&
    path.indexOf('car-loans') === -1) &&
    !path.includes('line-of-credit');

  return (
    <header id="header" className="header">
      <div className="row">
        <div className="medium-6 column">
          <img src="/images/cash-converters.svg" className="logo" alt="Cash Converters" />
        </div>
        <div className="medium-6 column">
          {showLoanProduct && <CashPreview loanProduct={loanProduct!} />}
        </div>
      </div>
    </header>
  );
};

export default Header;