import React, { useState } from 'react';
import Popover from 'react-tiny-popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoanProduct } from '../../../types/loan';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

interface CashPreviewProps {
  loanProduct: LoanProduct
}

const CashPreview: React.FC<CashPreviewProps> = ({ loanProduct }) => {

  const [show, setShow] = useState(false);

  return (
    <div className='loan-preview'>
      <Popover
        containerClassName='loan-preview__overlay-container'
        isOpen={show}
        position='bottom'
        content={(
          <ul className='loan-preview__overlay'>
            <li>
              <div className='label'>Establishment Fee</div>
              <div className='value'>${loanProduct.fees}</div>
            </li>
            <li>
              <div className='label'>Loan Amount</div>
              <div className='value'>${loanProduct.borrowed}</div>
            </li>
            <li>
              <div className='label'>Estimated Weekly Repayments</div>
              <div className='value'>${loanProduct.repayment}</div>
            </li>
            <li>
              <div className='label'>Total over {loanProduct.term} months</div>
              <div className='value'>${loanProduct.total}</div>
            </li>
          </ul>
        )}
      >
        <label className="loan-preview__label">
          Cash in Your Account
        <button
            onClick={() => setShow(!show)}
            className="loan-preview__toggle">
            ${loanProduct.amount}
            <FontAwesomeIcon className='ml-5' icon={show ? faChevronUp : faChevronDown} />
          </button>
        </label>
      </Popover>
    </div>

  );
};

export default CashPreview;