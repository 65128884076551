import React from 'react';
import classNames from 'classnames';

interface StatusBoxProps {
  type?: string
  className?: string
}

const StatusBox: React.FC<StatusBoxProps> = ({ children, type, className }) => {
  const classes = classNames({
    'card': true,
    [`card__${type}`]: type !== undefined,
    [className!]: className !== undefined
  });

  return (
    <div className="tracker-status">
      <div className={classes}>
        {children}
      </div>
    </div>
  );
};

export default StatusBox;


