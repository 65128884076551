import React, { useEffect, useState, useRef } from 'react';
import { Accordion, Radio, Alert, TextBox, Button } from '../../shared';
import { BaseLoanReason, LoanSubReason } from '../../../types/loan';
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { needExtraInfoForReason } from './loan-details-helper';

interface State {
  reason?: string
  subReason?: LoanSubReason
  explanation?: string
  amount?: number
  explanationTouched?: boolean
  shouldFocus?: boolean
}

interface InitialData {
  reason?: string
  subReason?: LoanSubReason
  explanation?: string
}

interface ReasonAccordianProps {
  initial?: InitialData
  loanAmount: number
  reasons: BaseLoanReason[]
  onChange: (reason: string, subReason: LoanSubReason, explanation?: string) => void
  disabled?: boolean
}

const ReasonAccordian: React.FC<ReasonAccordianProps> = ({ reasons, onChange, loanAmount, disabled, initial }) => {

  const resolveInitialState = () => {
    const state: State = {};
    if (!initial || !initial.reason || !initial.subReason) return state;
    state.reason = initial.reason;
    state.subReason = initial.subReason;
    state.explanation = initial.explanation;
    return state;
  }
  const [state, setState] = useState<State>(resolveInitialState());
  const selectedItemRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    onChange(state.reason!, state.subReason!, state.explanation);
    if (state.shouldFocus) {
      if (selectedItemRef.current) {
        selectedItemRef.current.focus();
      }
      setState({...state, shouldFocus: false});
    }
  }, [state]);

  const resolveIcon = (reason: string) => {
    const iconName = reason
      .replace(/ /g, '')
      .replace(/\//g, '-')
      .replace('&', '-')
      .replace(',', '-');

    return `/images/icons/loans/${iconName}.svg`
  }

  const handleChange = (partialState: Partial<State>) => {
    setState(ps => {
      const newState = ({ ...ps, ...partialState });
      return newState;
    });
  }

  const handleReset = () => {
    setState(ps => ({ ...ps, reason: undefined, subReason: undefined, explanation: undefined, explanationTouched: false, shouldFocus: true }));
  }

  const explanationRequiredFailed = () => !state.explanation ? true : false;
  const explanationMaxLengthFailed = () => !explanationRequiredFailed() && state.explanation && state.explanation.length > 499 ? true : false;
  const resolveReasonError = () => explanationMaxLengthFailed() ? 'Must be less than 500 characters' : 'Field is required';

  //TODO MOVE THIS TO FORMIK.  THIS IS PRETTY MESSY
  return (
    <React.Fragment>
      <div className='form__row loanSelect'>
        <Accordion
          ref={selectedItemRef}
          items={reasons.map((reason, index) => {
            const { name } = reason;
            return {
              title: name,
              icon: resolveIcon(name),
              children: reason.subReasons.map((subReason, subIndex) => (
                <Radio
                  disabled={disabled}
                  key={`${index}_${subIndex}`}
                  id={`reason_${index}_${subIndex}`}
                  label={subReason.name}
                  name={subReason.name}
                  onChange={() => handleChange({ reason: reason.name, subReason: subReason })} />
              ))
            };
          })}
          selectedItem={(!state.reason || !state.subReason) ? undefined : {
            title: state.reason,
            icon: resolveIcon(state.reason),
            children: (
              <div>
                <Radio
                  disabled={disabled}
                  id='selectedReason'
                  label={state.subReason!.name}
                  name={state.subReason!.name}
                  checked
                  readOnly
                />
                {state.subReason.warning && (
                  <Alert
                    message={state.subReason.warning}
                    variant={state.subReason.hardstop ? 'error' : 'info'}
                  />
                )}
                {!state.subReason.hardstop && needExtraInfoForReason(loanAmount, state.subReason) &&
                  <TextBox
                    disabled={disabled}
                    id='reason_detail'
                    label='More Information'
                    name='reason_detail'
                    placeholder='Please enter a detailed explanation'
                    value={state.explanation || ''}
                    isValid={state.explanationTouched && !explanationRequiredFailed() && !explanationMaxLengthFailed()}
                    errorMessage={resolveReasonError()}
                    onChange={(e) => handleChange({ explanation: e.currentTarget.value })}
                    onBlur={() => handleChange({ explanationTouched: true })}
                  />
                }
              </div>
            )
          }}
        />
      </div>

      {state.reason && state.subReason && (
        <Button
          id='change_selection'
          text='Change selection'
          onClick={handleReset}
          variant='tertiary'
          size='full'
          faIcon={faArrowLeft}
          iconPlacement='before'
        />
      )}
    </React.Fragment>
  );
};

export default ReasonAccordian;