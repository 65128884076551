import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps } from '../../../types/tracker';

interface ContractSignedProps extends BaseTrackerContentProps {
}

const ContractSigned: React.FC<ContractSignedProps> = (props) => {

  return (
    <Page
      title="You've been approved!"
      lead="Congratulations, your application has been approved and we've received your signed contract.">
      <Row>
        <TrackerPageMainContent
          {...props}
          action={{
            title: "Keep an eye on your email or…",
            callToAction: {
              type: "bookmark",
              label: "Save this page",
            }
          }}
          optional={{
            descriptor: "You can check this page any time for updates and information.",
          }}
        />
      </Row>
      <Row>
        <TrackerPageSteps
          {...props}
          progress={{
            callToAction: {
              type: "bookmark",
              label: "Save this page",
            }
          }}
        />
      </Row>
    </Page>
  );
}

export default ContractSigned;
