import React from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../shared';

interface SkipButtonProps {
  onClick: () => void
  disabled?: boolean
}

const SkipButton: React.FC<SkipButtonProps> = ({ onClick, disabled }) => {

  return (
    <Button
      id='skip-centrelink'
      text='Skip this step'
      variant='tertiary-compact'
      faIcon={faArrowRight}
      iconPlacement='before'
      onClick={onClick}
      disabled={disabled}
    />
  );
}

export default SkipButton;