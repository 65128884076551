import React, { useState } from 'react';
import classNames from 'classnames';

import Button, { ButtonVariant } from '../buttons/button';
import { useAppContext } from '../../state-manager/app-context';
import SaveProgressModel from './save-progress-modal';

interface SavePageButtonProps {
  className?: string
  variant?: ButtonVariant
}

const SavePageButton: React.FC<SavePageButtonProps> = ({ className, variant }) => {

  const { uiState, setLoading, loading } = useAppContext();

  const canSaveProgress = () => {
    const hasMobileAndEmail = uiState && uiState.personalDetails && uiState.personalDetails.mobile && uiState.personalDetails.email;
    const hasSubmitted = uiState && uiState.completionStatus && uiState.completionStatus.applicationSubmittedDates && uiState.completionStatus.applicationSubmittedDates.length > 0;
    return hasMobileAndEmail && !hasSubmitted;
  }

  const [showModal, setShowModal] = useState<boolean>(false);

  //Must have email and mobile listed and have not submitted yet
  if (!canSaveProgress()) {
    return null;
  }

  const compiledClass = classNames({
    'save-page-button': true,
    [className!]: className !== undefined
  });

  return (
    <div className={compiledClass}>
      <Button variant={variant} text='Save progress' onClick={() => setShowModal(true)} />
      <SaveProgressModel onClose={() => setShowModal(false)} uiState={uiState} disabled={loading} setLoading={setLoading} show={showModal} />
    </div>
  );
}

export default SavePageButton;