import React from 'react';
import PillButton, { PillButtonProps } from './pill-button';

interface PillButtonGroupProps {
  buttons: PillButtonProps[]
  onChange?: (itemId: any) => void
  noIcon?: boolean
}

const PillButtonGroup: React.FC<PillButtonGroupProps> = ({ onChange, noIcon, buttons }) => {
  return (
    <div className='button__pill-group'>
      {buttons.map((button, index) =>
        <PillButton
          hideIcon={noIcon}
          key={button.id}
          {...button}
          onChange={() => { if (onChange) onChange(button.id); }} />
      )}
    </div>
  );
}

export default PillButtonGroup;