import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { Page, Row, Submit } from '../../shared';
import ChooseAmountForm from './choose-amount-form';
import { LoanSubReason } from '../../../types/loan';
import * as LoanApi from '../../../api/loan-application';
import { SubmitLoanDetailsRequest } from '../../../api/loan-application/api models';
import canBeOnPage, { BasePageProps } from '../../hoc/can-be-on-page';
import { getMinAmount, getDefaultAmount, isValidAmount, getTerm } from '../data';
import { prefixes } from '../../../utils/page-prefixes';

interface State {
  amount: number
  term: number
  reason?: string
  subReason?: LoanSubReason
  explanation?: string
}

export interface SubmitLoanDetailsProps extends BasePageProps, RouteComponentProps { }

const SubmitLoanDetails: React.FC<SubmitLoanDetailsProps> = ({ appContext }) => {

  const {
    loading, executeCommandAndUpdateState, navigateUnsafe,
    uiState: { loanApplicationId, product, prefilledPersonalDetails },
  } = appContext;

  const originalCreditLimit = prefilledPersonalDetails?.creditLimit || getMinAmount(0);
  const minAmount = getMinAmount(originalCreditLimit);

  const amount = Math.max(minAmount, product
    && product.amount
    && isValidAmount(parseInt(product.amount))
    ? parseInt(product.amount) : getDefaultAmount());

  const [state, setState] = useState<State>({
    amount: amount,
    term: getTerm(amount),
    reason: 'Line Of Credit',
    subReason: { name: 'Credit Limit Increase' },
    explanation: '',
  });

  async function handleSubmit() {
    setState(ps => ({ ...ps, submitting: true }));

    const request: SubmitLoanDetailsRequest = {
      loanApplicationId: loanApplicationId,
      amount: state.amount,
      term: state.term,
      detail: state.explanation,
      reason: state.reason!,
      subReason: state.subReason!.name
    };

    await executeCommandAndUpdateState(() => LoanApi.submitLoanDetails(request), true);

    navigateUnsafe(`${prefixes.loc}/submit-personal-details`);
  }

  const canSubmit = () => {
    if (!state.reason || !state.subReason) return false;

    return true;
  }
  return (
    <Page
      title='Increase Credit Limit'
      lead=''>
      <Row>
        <ChooseAmountForm
          amount={state.amount}
          minAmount={minAmount}
          originalCreditLimit={originalCreditLimit}
          disabled={loading}
          onChange={(newAmount, newTerm) => setState(ps => ({ ...ps, amount: newAmount, term: newTerm }))}
        />
      </Row>

      <Row size='x-small'>
        <Submit
          id='submit'
          text='Next'
          disabled={!canSubmit() || loading}
          onSubmit={handleSubmit}
        />
      </Row>
    </Page>
  );
}

export default canBeOnPage(SubmitLoanDetails, 'LoanDetailsLocIncrease');