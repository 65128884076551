import React from 'react';
import { Page, Row } from '../../shared';
import TrackerPageMainContent from '../shared/tracker-page-main-content';
import TrackerPageSteps from '../shared/tracker-page-steps';
import { BaseTrackerContentProps } from '../../../types/tracker';

interface AwaitingContractSigningProps extends BaseTrackerContentProps {
}

const AwaitingContractSigning: React.FC<AwaitingContractSigningProps> = (props) => {

  return (
    <Page
      title="You've been approved!"
      lead="Congratulations, your application has been approved. It's time to sign the contract.">
      <Row>
        <TrackerPageMainContent
          action={{
            title: "Sign contract online",
            callToAction: {
              type: "externalLink",
              label: "Sign now",
              action: props.application.contractSigningUrl
            }
          }}
          {...props}
        />
      </Row>
      <Row>
        <TrackerPageSteps
          {...props}
          progress={{
            callToAction: {
              type: "externalLink",
              label: "Sign now",
              action: props.application.contractSigningUrl
            }
          }}
        />
      </Row>
    </Page>
  );
}

export default AwaitingContractSigning;
