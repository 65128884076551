import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export interface BaseAccordionItemProps {
  title: string
  icon?: string
  children: React.ReactNode
}

export interface AccordionItemProps extends BaseAccordionItemProps {
  id: string
  isActive?: boolean
  onToggle?: () => void
}

const AccordionItem = forwardRef<HTMLButtonElement, AccordionItemProps>(
  ({ id, isActive, onToggle, title, icon, children }, ref) => {
    return (
      <div className={`accordion__item accordion__item--${isActive ? 'open' : 'closed'}`}>
        <button
          id={id}
          ref={ref}
          className='accordion__item__title-wrapper'
          onClick={() => onToggle && onToggle()}>
          <img className='accordion__item__icon' src={icon} alt={title} />
          <span className='accordion__item__title'>{title}</span>
          {onToggle && <FontAwesomeIcon icon={faChevronDown} className='accordion__item__arrow' />}
        </button>
        <div className='accordion__item__content'>
          <div className='accordion__item__content-wrapper'>
            {children}
          </div>
        </div>
      </div>
    );
  }
);

export default AccordionItem;