import React from 'react';
import classNames from 'classnames';
import Cleave from 'cleave.js/react';
import moment, { Moment } from 'moment';
import { ChangeEvent } from 'cleave.js/react/props';
import * as DateFormHelpers from './date-form-helpers';


export interface DateTextBoxProps {
  className?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>, momentValue?: Moment) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  label?: string
  placeholder?: string
  id?: string
  name?: string
  value?: any
  disabled?: boolean
  helpText?: string
  isValid?: boolean
  errorMessage?: string
  ref?: any
  datePattern?: DateFormHelpers.DatePattern
  delimiter?: DateFormHelpers.Delimeter
}

interface DateOfBirthState {
  key: number,
}

class DateTextBox extends React.Component<DateTextBoxProps, DateOfBirthState> {

  constructor(props: DateTextBoxProps) {
    super(props);

    this.state = {
      key: 1,
    }
  }

  //If the date pattern changes we need cleave to be destroyed and recreated.  Changing the key does this
  componentWillReceiveProps?(nextProps: Readonly<DateTextBoxProps>) {
    if (nextProps.datePattern !== this.props.datePattern) {
      this.setState((ps) => ({ ...ps, key: ps.key + 1 }));
    }
  }

  focus() {
    const input = (this.refs.input as any);
    if (input && input.focus) input.focus();
  }

  handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { onChange, datePattern } = this.props;
    if (!onChange) return;
    const momentValue = moment(event.currentTarget.value, DateFormHelpers.resolveCleaveDatePattern(datePattern));
    onChange(event, momentValue.isValid() ? momentValue : undefined);
  }

  render() {
    const { id, label, helpText, isValid, errorMessage, name, datePattern, delimiter, placeholder, className, ...rest } = this.props;
    const compiledIsValid = isValid === undefined ? true : isValid;


    const classes = classNames({
      'form__row': true,
      [className!]: className !== undefined
    });

    return (
      <div data-test={`form-control-${name}`} className={classes}>
        <label id={`${id || name}_label`} className={isValid ? 'form__label' : 'form__label form__error'}>
          {label}
          <Cleave
            key={this.state.key}
            placeholder={DateFormHelpers.resolveMomentFormatForCleave(datePattern, delimiter, placeholder)}
            name={name}
            ref='input'
            type='tel'
            className='form__input'
            id={id}
            aria-describedby={`${id}_help_text`}
            options={{ date: true, delimiter: delimiter || '/', datePattern: DateFormHelpers.resolveCleaveDatePattern(datePattern) }}
            onChange={(e) => this.handleChange(e)}
            {...rest}
          />
        </label>
        {helpText && <p id={`${id}_help_text`} className="form__help-text" dangerouslySetInnerHTML={{ __html: helpText }}></p>}
        {!compiledIsValid && <span className="form__error-message">{errorMessage}</span>}
      </div>
    );
  }
};


export default DateTextBox;
