import React, { useState, useEffect } from "react";
import classNames from 'classnames';
import { useDropzone, DropEvent } from 'react-dropzone';
import List, { ListItem } from "../../list";
import * as FileHelper from "../../../../utils/file-helper";
import FileUploadDropContent from './file-upload-drop-content';
import Alert from "../../alert/alert";

export interface FileUploadProps {
  label?: string
  name: string
  isValid?: boolean
  errorMessage?: string

  maxSizeMb?: number
  fileTypes?: FileHelper.FileMimeType[]
  validate?: (file: File, event?: DropEvent) => string

  onChange?: (files: File[], event?: DropEvent) => void
  disabled?: boolean
  multiple?: boolean
  uploading?: boolean
  uploadingMessage?: string
}

interface State {
  accepted: File[]
}

const FormFileUpload: React.FC<FileUploadProps> = (props) => {

  const { children, isValid, errorMessage, label, onChange, validate, fileTypes, name, disabled, uploading, uploadingMessage, maxSizeMb, ...rest } = props;

  const [fileState, setFileState] = useState<State>({ accepted: [] });
  const [dragErrorText, setDragErrorText] = useState<string | undefined>(undefined);
  const [validationError, setValidationError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (validationError) {
      setTimeout(() => setValidationError(undefined), 3000);
    }
  }, [validationError]);

  const validateFile = (file: File, event?: DropEvent) => {
    let errors: string[] = [];
    if (validate) {
      const tempError = validate(file, event);
      if (tempError) errors.push(tempError);
    }

    if (fileTypes && fileTypes.length) {
      const tempError = validateMime(file.type, fileTypes);
      if (tempError) errors.push(tempError);
    }

    return !errors.length ? null : `${file.name} - ${errors.join(', ')}`;
  }

  const validateAll = (newFiles: File[]) => {
    return validateMaxSize(newFiles, maxSizeMb || 0);
  }

  const validateMaxSize = (files: File[], sizeMb: number) => {

    if (!files || !files.length || sizeMb <= 0) return null;

    let filesSize = 0;
    files.forEach(f => filesSize += f.size);

    if (fileState.accepted) {
      fileState.accepted.forEach(f => {
        filesSize += f.size
      });
    }

    return FileHelper.toMb(filesSize) > sizeMb ? `File/s cannot exceed ${sizeMb} MB` : null;
  }

  const validateMime = (fileType: string, checking: FileHelper.FileMimeType[]) => {
    if (!checking) return null;
    if (!fileType) return `Must be of type/s: ${checking.join(', ')}`;

    const mimes = checking.map(FileHelper.getMimes).flat(1).filter(a => a);
    if (!mimes.some((mime) => mime.toLowerCase() === fileType)) {
      return `Must be of type/s: ${checking.join(', ')}`;
    }
  }

  const onDrop = (acceptedFiles: File[], rejectedFiles: File[], event: DropEvent) => {
    const newState: State = props.multiple ? { ...fileState } : { accepted: [] };

    const errors: string[] = [];

    //Validate All first
    const tempError = validateAll(acceptedFiles);
    if (tempError) errors.push(tempError);

    if (!errors.length) {
      //Validate Individual Files
      if (acceptedFiles && acceptedFiles.length) {
        acceptedFiles.forEach((file) => {
          const tempError = validateFile(file, event);
          if (tempError) {
            errors.push(tempError);
          } else {
            newState.accepted.push(file);
          }
        });
      }
    }

    if (errors.length) {
      setValidationError(errors.join(' | '));
    }

    setFileState(newState);
    if (onChange) onChange(newState.accepted, event);
  }

  const onDragEnter = (e: React.DragEvent<HTMLElement>) => {
    if (!e.dataTransfer.items || !e.dataTransfer.items.length || !fileTypes || !fileTypes.length) return;

    let errors: number = 0;
    for (var i = 0; i < e.dataTransfer.items.length; i++) {
      if (validateMime(e.dataTransfer.items[i].type, fileTypes)) {
        errors++;
      }
    }

    setDragErrorText(!errors ? undefined : `${errors} file/s must be of type/s: ${fileTypes.join(', ')}`);
  }

  const onDragLeave = (e: React.DragEvent<HTMLElement>) => setDragErrorText(undefined);

  const handleRemove = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
    e.stopPropagation();
    e.bubbles = false;
    const newState = { ...fileState };
    newState.accepted.splice(index, 1);
    setFileState(newState);
    if (onChange) onChange(newState.accepted);
  }

  const { getRootProps, getInputProps, isDragActive, } = useDropzone({ ...rest, onDrop, onDragEnter, onDragLeave });

  const compiledIsValid = isValid === undefined ? true : isValid;

  const errorOnDrag = isDragActive && dragErrorText ? true : false;
  const successOnDrag = isDragActive && !dragErrorText ? true : false;
  const containerClasses = classNames({
    'file-upload-container': true,
    'drag-active': isDragActive,
    'error-on-drag': errorOnDrag,
    'success-on-drag': successOnDrag
  });

  return (
    <React.Fragment>
      {validationError && <Alert center variant='error' message={validationError} />}
      <div data-test={`form-control-${name}`} className='form__row'>
        <div>
          {label && <label id={`${name}_label`}>{label}</label>}
          <div className={containerClasses} {...getRootProps()}>
            <input {...getInputProps()} disabled={disabled} />
            <FileUploadDropContent
              dragErrorText={dragErrorText}
              errorOnDrag={errorOnDrag}
              successOnDrag={successOnDrag}
              fileTypes={fileTypes}
              isDragActive={isDragActive}
              uploading={uploading}
              uploadingMessage={uploadingMessage}
              maxSizeMb={maxSizeMb}
            />
          </div>
          {!compiledIsValid && <span className="form__error-message">{errorMessage}</span>}
          <List className='mt-10'>
            {fileState.accepted.map((f, i) => {
              return (
                <ListItem
                  disabled={disabled}
                  faIcon={FileHelper.resolveIcon(f)}
                  primaryText={f.name}
                  secondaryText={FileHelper.getFileSize(f)}
                  onDelete={(e) => handleRemove(e, i)}
                />
              )
            })}
          </List>
          {children}
        </div>

      </div>
    </React.Fragment>
  )
};

export default FormFileUpload;