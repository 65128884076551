import React, { useEffect } from 'react';
import { Row, Card } from '../shared';
import { CarLoanFormValues } from './form';
import { carloanAnalyticsConversion } from '../../car-loan-analytics-logging';

interface CarLoansProps {
  values: CarLoanFormValues,
  leadId: string
}

const CarLoanComplete: React.FC<CarLoansProps> = ({ values, leadId }) => {

  useEffect(() => {
    carloanAnalyticsConversion(leadId);
  }, []);

  return (
    <Row>
      <h1><small>Thanks <span className="fs-hide">{values.firstName}</span>! You will hear from us soon.</small></h1>
      <p className="text__lead">Our team will assess your enquiry and send updates to <strong className="fs-hide">{values.email}</strong></p>
      <p className="text__lead">This can take up to 24 - 48 hours, however it&#39;s usually sooner.</p>
      <Card variant='confirm' id='car-loan-complete-card'>
        <h4>Your details</h4>
        <ul>
          <li><strong>Name:</strong> <span className="fs-hide">{values.firstName} {values.surname}</span></li>
          <li><strong>Email:</strong> <span className="fs-hide">{values.email}</span></li>
          <li><strong>Phone:</strong> <span className="fs-hide">{values.mobile}</span></li>
          <li><strong>Loan Amount:</strong> ${values.loanAmount}</li>
          <li><strong>Loan purpose:</strong> {values.loanPurpose}</li>
        </ul>
      </Card>
    </Row>
  );
}

export default CarLoanComplete;