import React from 'react';
import { FormikProps } from 'formik';
import { safeGetError, shouldShowError } from './formik-helper';
import FileUpload, { FileUploadProps } from '../file-upload';
import { DropEvent } from 'react-dropzone';

interface FormikFileUploadProps<T> extends FileUploadProps {
  name: string
  formikProps: FormikProps<T>
}

function FormikFileUpload<T>(props: FormikFileUploadProps<T>) {

  const handleChange = (files: File[], event?: DropEvent) => {
    const { onChange, formikProps, name, multiple } = props;
    if (formikProps) {
      const value = !multiple ? files[0] : files;
      formikProps.setFieldValue(name, value);
    }

    if (onChange) onChange(files, event);
  }

  const { formikProps, name, ...rest } = props;

  return (
    <FileUpload
      name={name}
      onChange={handleChange}
      isValid={!shouldShowError(formikProps, name)}
      errorMessage={safeGetError(formikProps, name)}
      {...rest}
    >
    </FileUpload>
  )
};

export default FormikFileUpload;