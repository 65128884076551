import React from 'react';
import { StatusBox } from '.';
import * as Service from '../tracker-service';
import { TrackerItemContentAction, TrackerItemContentOptionalAction } from '../../../types/tracker';

interface TrackerPageMainContentProps {
  applicationId: string
  action?: TrackerItemContentAction
  optional?: TrackerItemContentOptionalAction
  onSonicFollowUpRoute: (route: string) => void
  onSonicRoute: (route: string) => void
}

const TrackerPageMainContent: React.FC<TrackerPageMainContentProps> = ({ applicationId, action, optional, onSonicFollowUpRoute, onSonicRoute }) => {

  return (
    <div>
      {action && (
        <StatusBox type={action.type}>
          <h2>{action.title && action.title}</h2>
          {action.descriptor && <p>{action.descriptor}</p>}
          {action.callToAction && Service.renderCallToAction(action.callToAction,
            applicationId, onSonicFollowUpRoute, onSonicRoute)}
        </StatusBox>
      )}
      {optional && (
        <div className="tracker-text">
          {optional.descriptor && <p className="text__lead">{optional.descriptor}</p>}
          {optional.callToAction && Service.renderCallToAction(optional.callToAction,
            applicationId, onSonicFollowUpRoute, onSonicRoute, 'tertiary')}
        </div>
      )}
    </div>

  )
}

export default TrackerPageMainContent;