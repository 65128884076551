import React, { useState } from 'react';
import classNames from 'classnames';
import ProgressOptions from './progress-options';
import ResumeModel from '../../resume/resume-model';
import  { ResumePage } from '../../resume/resume-types';
import { SonicPageType } from '../../../types/route-types';

export type StepType = 'Start' | 'Details' | 'Bank' | 'Income' | 'Expenses' | 'Complete' | 'DetailsLoc';
export interface Step {
  number: number
  type: StepType
  name: string
  image?: string
  options?: any[]
}

interface ProgressStepProps {
  current: Step
  step: Step
  index: number
  resumeModel: ResumeModel
}

const ProgressStep: React.FC<ProgressStepProps> = ({ current, step, index, resumeModel }) => {

  const isActive = step.number <= current.number;
  const [show, setShow] = useState<boolean>(false);

  const classes = classNames({
    'progress__bar-step': true,
    [`step${step.number}`]: true,
    'active': isActive && step.image,
    'pulse': !show && current.type === step.type
  });

  const pagesByApplicationType = (): SonicPageType[] => {
    switch(true) {
      case resumeModel.isLoc:
        return ['LoanDetailsLoc', 'PersonalDetailsLoc', 'MobileVerification'];
      case resumeModel.isCreditIncrease:
        return ['LoanDetailsLocIncrease', 'PersonalDetailsLoc', 'MobileVerification'];
      default:
        return ['LoanDetails', 'PersonalDetails', 'MobileVerification']
    }
  };

  const resolveProgressOptions = (): ResumePage[] => {
    if (!isActive || resumeModel.isComplete)
      return [];

    const pageTypes = ((): SonicPageType[]  => {
      switch (step.type) {
        case 'Details':
          return pagesByApplicationType();
        case 'Bank':
          if (!resumeModel.canBeOnPage('BankStatements')) {
            return ['BankSelect'];
          } else {
            return ['BankAnother'];
          }
        case 'Income':
          return ['IncomeDeclaration'];
        case 'Expenses':
          return ['ExpenseDeclaration', 'LoanDeclaration'];
        default:
          return [];
      }
    })();

    if (!pageTypes.length)
      return [];

    return resumeModel.completedPages.filter(e => pageTypes.includes(e.type));
  }

  return (
    <span key={'progressbar' + index} className={classes}>
      {step.image && <i><img src={step.image} alt={`Step ${step.number} ${step.name}`} /></i>}
      {isActive && <ProgressOptions show={show} onToggle={() => setShow(!show)} pages={resolveProgressOptions()} />}
    </span>
  );
}

export default ProgressStep;
