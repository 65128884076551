import React from 'react';
import classNames from 'classnames';
import { PersonalDetailsModel } from '../../../../types/loan';
import * as LoanStateHelper from '../../../../utils/loan-state-helper';
import { IconButton } from '../../../shared';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

export type PersonalDetailsDisplayVariant = 'two-columns' | 'two-columns-box';

interface PersonalDetailsDisplayProps {
  className?: string
  details: PersonalDetailsModel
  variant?: PersonalDetailsDisplayVariant
  onEdit?: () => void
}

const PersonalDetailsDisplay: React.FC<PersonalDetailsDisplayProps> = ({ className, details, variant, onEdit }) => {

  const classes = classNames({
    'personal-details-display': true,
    'two-columns': variant === 'two-columns' || variant === 'two-columns-box',
    'box': variant === 'two-columns-box',
    [className!]: className !== undefined
  });

  const renderRow = (label: string, value: string, className?: string) => {
    const rowClass = classNames({ 'personal-details-display-row': true, [className!]: className !== undefined });
    return (
      <div className={rowClass}>
        <div className='personal-details-display-title'>{label}</div>
        <div className='personal-details-display-value'>{value}</div>
      </div>
    )
  }

  return (
    <div className={classes}>
      {details && (
        <div className='personal-details-display-data'>
          {onEdit && <IconButton icon={faEdit} onClick={onEdit} className='personal-details-edit-button' />}
          {renderRow('Name', LoanStateHelper.getFullName(details))}
          {renderRow('Mobile', details.mobile)}
          {renderRow('Email', details.email)}
          {renderRow('Date of Birth', details.dateOfBirth)}
          {renderRow('Address', LoanStateHelper.getFullAddress(details), 'address-details')}
        </div>
      )}
    </div>
  );
};

export default PersonalDetailsDisplay;