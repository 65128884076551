import axios from 'axios';
import appSettings from '../../settings';
import * as Models from './api models';

export const relativeUrls = {
  //Visit
  getOptions: '/options',
}

const root = appSettings.apiUrl;

export const getOptions = () => {
  return axios.get<Models.UiOptions>(`${root}${relativeUrls.getOptions}`);
};