import axios from 'axios';
import appSettings from '../../settings';
import { BaseCommandRequest, PreloadRequest, CentrelinkLoginRequest, MfaRequest, PreloadResponse } from './api models';

export const relativeUrls = {
  preload: '/command/preload',
  login: '/command/login',
  fortunaLogin: '/command/fortunaLogin',
  mfa: '/command/mfa',
  fortunaMfa: '/command/fortunaMfa',
  reattempt: '/command/reattempt',
  skipLogin: '/command/skipLogin',
  skipStatement: '/command/skipStatement',
};

const baseUrl = `${appSettings.apiUrl}/centrelink`;

export const preload = (request: PreloadRequest) => {
  return axios.post<PreloadResponse>(`${baseUrl}${relativeUrls.preload}`, request)
};

export const login = (request: CentrelinkLoginRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.login}`, request)
};

export const fortunaLogin = (request: CentrelinkLoginRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.fortunaLogin}`, request)
};

export const mfa = (request: MfaRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.mfa}`, request)
};

export const fortunaMfa = (request: MfaRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.fortunaMfa}`, request)
};

export const reattempt = (request: BaseCommandRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.reattempt}`, request)
};

export const skipLogin = (request: BaseCommandRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.skipLogin}`, request)
};

export const skipStatement = (request: BaseCommandRequest) => {
  return axios.post(`${baseUrl}${relativeUrls.skipStatement}`, request)
};