import { Dictionary } from './shared';
import { CallToActionType } from '../app/tracker/shared/call-to-action';
import { DocumentType } from './shared';

export type TrackerState = 'Review' | 'Submission' | 'Assessment' | 'Contract' | 'MoneySent' | 'Declined' | 'Withdrawn';
export const TrackerStates: TrackerState[] = ['Review', 'Submission', 'Assessment', 'Contract', 'MoneySent', 'Declined', 'Withdrawn'];
export const WithdrawnTrackerStates: TrackerState[] = ['Declined', 'Withdrawn'];
export const DefaultTrackerStates: TrackerState[] = TrackerStates.filter(e => !WithdrawnTrackerStates.includes(e));

export type TrackerSubState = 'Submitted' | 'InReview' | 'InAssessment' | 'AwaitingContractSigning' | 'Declined' |
  'FundsDisbursed' | 'Withdrawn' | 'BankStatementsRequested' | 'BankStatementsAdded' | 'CentrelinkStatementsRequested' |
  'CentrelinkStatementsAdded' | 'ProofOfAccountOwnershipRequested' | 'ProofOfIdRequested' | 'ProofOfNameChangeRequested' |
  'ProofDocumentsReceived';

export type LoanType =  'None' | 'CashAdvance' | 'PersonalLoan' | 'Macc' | 'AccelerateLoan' | 'CarboodleLease' | 'Max' | 'Loc' | 'SimpleLoan';

interface TrackerApplication {
  loanAmount: number
  withdrawAmount?: number
  loanType: LoanType
  reason: { reason: string, subReason: string }
  timestamp: Date
}

interface DocumentRequest {
  requestType: string
  requested: Date
  requestReason: string
}

export interface TrackerStateMetadata {
  name: string
  entered?: Date
}

export interface TrackerLoanApplication {
  applicantsName: string
  submittedApplication: TrackerApplication
  approvedApplication: TrackerApplication
  approvedBankAccountNumber: string
  contractSigned?: Date
  disbursedTime?: Date
  currentState: TrackerState
  subState: TrackerSubState
  declineReasons: string[]
  contractSigningUrl: string
  lapsed: boolean
  latestDocumentRequest: DocumentRequest
  states: Dictionary<TrackerStateMetadata>
  loanApplicationId?: string
  isOnline?: boolean //Cant see this in the api model??
}

export type TrackerItemStatus =
  'inReview' |
  'inAssessment' |
  'centrelinkStatementsAdded' |
  'bankStatementsAdded' |
  'centrelinkStatementsRequested' |
  'bankStatementsRequested' |
  'proofOfAccountOwnershipRequested' |
  'proofOfIdRequested' |
  'proofOfNameChangeRequested' |
  'proofDocumentsReceived' |
  'awaitingContractSigning' |
  'contractSigned' |
  'fundsDisbursed' |
  'declined' |
  'withdrawn' |
  'withdrawnInStore' |
  'lapsed' |
  'lapsedInStore' |
  'lapsedAndDeclined'

export type TrackerCallToActionType = 'bookmark' | 'link' | 'externalLink' | 'sonicFollowUpRoute' | 'sonicRoute' | 'uploadForm';
export type TrackerContentActionType = 'aaa';

export interface TrackerItem {
  statusTitle: string
  statusDescriptor: string
  content: TrackerItemContent
}

export interface TrackerItemContent {
  action?: TrackerItemContentAction
  optional?: TrackerItemContentOptionalAction
  progressBar?: TrackerItemContentProgress
}

export interface TrackerItemContentProgress {
  subStatus?: string
  followUp?: string
  callToAction: TrackerItemCallToActionProps
}

export interface TrackerItemContentAction {
  type?: TrackerContentActionType
  title?: string
  descriptor?: string
  callToAction: TrackerItemCallToActionProps
}

export interface TrackerItemContentOptionalAction {
  descriptor: string
  callToAction?: TrackerItemCallToActionProps
}

export interface TrackerItemCallToActionProps {
  type: CallToActionType
  label: string
  action?: string
  documentType?: DocumentType
}

export interface BaseTrackerContentProps {
  applicationId: string
  application: TrackerLoanApplication
  onSonicFollowUpRoute: (route: string) => void
  onSonicRoute: (route: string) => void
}
