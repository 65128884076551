import axios from 'axios';
import { FormikProps } from "formik";
import AppSettings from '../../../../../settings';
import { nameOf } from '../../../../../utils/object-helper';
import { FormValidatorField } from '../formik-validator';
import { required, maxCharLength, alphaNumsSpacesHyphensSlashesOnly, noMultiSpecialChars, reggex, startsWithNumer, noFullStops } from '../validators';
import { SubmitPersonalDetailsAddress, AddressSearchRequest } from '../../../../../types/loan';
import FormikValidator from '../../formik/formik-validator';
import { stateRanges } from '../../../../../types/shared';



export interface AddressFormValues extends SubmitPersonalDetailsAddress {
  addressSearch: string
}

export interface FormikAddressSearchProps {
  onBlur?: (e: any) => void
  placeholder?: string
  label: string
  formikProps: FormikProps<any>
  onSelectAddress?: (address: SubmitPersonalDetailsAddress) => void
}

export interface AddressSearchState {
  manuallyEntered?: boolean
  open: boolean
  addresses: any[]
  loading?: boolean
}

const validPostCodeState = (value: string, values: SubmitPersonalDetailsAddress) => {
  if (!values.state || !values.postcode) return null;
  const postCodeNumber = parseInt(values.postcode);
  const stateWithRange = stateRanges.find(e => e.state === values.state);
  if (!stateWithRange) return null;
  if (!stateWithRange.ranges.some(e => postCodeNumber >= e.min && postCodeNumber <= e.max)) {
    return 'Make sure your postcode is valid for your selected state'
  }
  return null;
}

export const standardAddressFieldValidators: FormValidatorField<any, any>[] = [
  { name: nameOf<AddressFormValues>('addressSearch'), validators: [] },
  { name: nameOf<AddressFormValues>('unitOrLevel'), validators: [maxCharLength(5), noMultiSpecialChars, reggex(/^(?!.*(unit|no|flat).*).*$/i, 'Please do not enter words like \'flat\', \'unit\', \'no\', etc')] },
  { name: nameOf<AddressFormValues>('streetNumber'), validators: [required, maxCharLength(8), startsWithNumer, alphaNumsSpacesHyphensSlashesOnly, noMultiSpecialChars] },
  { name: nameOf<AddressFormValues>('street'), validators: [required, maxCharLength(40), noFullStops, noMultiSpecialChars] },
  { name: nameOf<AddressFormValues>('state'), validators: [required] },
  { name: nameOf<AddressFormValues>('postcode'), validators: [required, validPostCodeState, reggex(/^[0-9]{4}$/, 'Postcode should be a 4-digit number')] },
  { name: nameOf<AddressFormValues>('suburb'), validators: [required, maxCharLength(50), noFullStops, noMultiSpecialChars,] },
]

export const standardAddressValidator = new FormikValidator<any, any>({
  fields: standardAddressFieldValidators
});

export const clearManualAddress = (props: FormikAddressSearchProps) => {
  props.formikProps.setValues({
    ...props.formikProps.values,
    [nameOf<AddressFormValues>('unitOrLevel')]: undefined,
    [nameOf<AddressFormValues>('streetNumber')]: undefined,
    [nameOf<AddressFormValues>('street')]: undefined,
    [nameOf<AddressFormValues>('state')]: undefined,
    [nameOf<AddressFormValues>('postcode')]: undefined,
    [nameOf<AddressFormValues>('suburb')]: undefined,
  });

  props.formikProps.setTouched({
    ...props.formikProps.touched,
    [nameOf<AddressFormValues>('addressSearch')]: false,
    [nameOf<AddressFormValues>('unitOrLevel')]: false,
    [nameOf<AddressFormValues>('streetNumber')]: false,
    [nameOf<AddressFormValues>('street')]: false,
    [nameOf<AddressFormValues>('state')]: false,
    [nameOf<AddressFormValues>('postcode')]: false,
    [nameOf<AddressFormValues>('suburb')]: false,
  });
}
export const handleParseAddress = (text: string, props: FormikAddressSearchProps, setState: React.Dispatch<React.SetStateAction<AddressSearchState>>) => {
  axios.get<SubmitPersonalDetailsAddress>(`${AppSettings.apiUrl}/parseAddress/${text}`)
    .then((response) => {
      props.formikProps.setValues({
        ...props.formikProps.values,
        [nameOf<AddressFormValues>('addressSearch')]: text,
        [nameOf<AddressFormValues>('unitOrLevel')]: response.data.unitOrLevel,
        [nameOf<AddressFormValues>('streetNumber')]: response.data.streetNumber,
        [nameOf<AddressFormValues>('street')]: response.data.street,
        [nameOf<AddressFormValues>('state')]: response.data.state,
        [nameOf<AddressFormValues>('postcode')]: response.data.postcode,
        [nameOf<AddressFormValues>('suburb')]: response.data.suburb,
      });

      if (props.onSelectAddress) props.onSelectAddress(response.data);
    })
    .catch(() => clearManualAddress(props))
    .finally(() => setState(ps => ({ ...ps, open: false, manuallyEntered: false, })))
}

const afterSearchAddress = (addresses: string[], text: string, setState: React.Dispatch<React.SetStateAction<AddressSearchState>>) => {
  if (addresses && addresses.length === 0) {
    let noResultsMessage = `No matches for '${text}' ... click to enter manually.`;
    addresses = [noResultsMessage];
  } else {
    addresses = [...addresses.slice(0, 9), 'My address isn\'t listed'];
  }
  setState((ps) => ({ ...ps, addresses: addresses, loading: false, open: true }));
}

export const searchAddresses = (text: string, setState: React.Dispatch<React.SetStateAction<AddressSearchState>>) => {
  if (!text) {
    afterSearchAddress([], '', setState);
  }

  const request: AddressSearchRequest = {
    searchTerm: text
  }

  axios.get<string[]>(`${AppSettings.apiUrl}/addressSearch`, { params: request })
    .then((response) => {
      if (response.data) {
        afterSearchAddress(response.data, text, setState);
      } else {
        afterSearchAddress([], text, setState);
      }
    })
    .catch(() => afterSearchAddress([], text, setState));
}