import { RouteComponentProps } from 'react-router';
import React, { useEffect, useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { Page, Row, Button, SaveProgressButton } from '../../shared';
import * as BankApi from '../../../api/bank-statement';
import { MultiFactorAuthRequest } from '../../../api/bank-statement/api models';
import AboutThisProcessModal from './about-this-process-modal';
import { useAppContext } from '../../state-manager/app-context';
import { Field } from '../../../types/shared';
import { flattenFields } from '../../../utils/form-helper';
import Form from './form';

interface BankMfaMfaProps extends RouteComponentProps { }

interface State {
  fields: Field[]
  formKey: number
}
const BankMfa: React.FC<BankMfaMfaProps> = () => {

  const { uiState, navigate, executeCommandAndUpdateState } = useAppContext();

  const [state, setState] = useState<State>({
    fields: flattenFields(uiState.bankMfaForm?.fields),
    formKey: 1
  });

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (!uiState.bankMfaForm) {
      navigate('BankSelect', false);
    }
  }, []);

  const handleSubmit = (values: any) => {
    const { bankMfaForm, analysisId, consumerId } = uiState;

    //Build credentials
    const credentials: any[] = [];

    for (const field of flattenFields(bankMfaForm.fields)) {
      //Only take the field if it has a name.  The banks do weird stuff and provide fields that are disabled
      //and blocked out.  The backend wont know how to handle fields with no name
      if (field.name) {
        credentials.push({
          id: field.name,
          label: field.label,
          value: values[field.name]
        });
      }
    }

    const request: MultiFactorAuthRequest = {
      analysisId: analysisId!,
      consumerId: consumerId!,
      credentials: credentials
    };

    const command = bankMfaForm.provider === 'Yodlee' ?
      () => BankApi.yodleeMfa(request) :
      bankMfaForm.provider === "Proviso" ?
      () => BankApi.provisoMfa(request) :
      () => BankApi.fortunaMfa(request);

    executeCommandAndUpdateState(command, true).then(newState => {
      if (!newState.bankMfaForm) {
        navigate('BankAnother', true);
      } else if (newState.bankMfaForm?.fields) {
        setState(ps => ({ ...ps, formKey: ps.formKey + 1, fields: flattenFields(uiState.bankMfaForm.fields) }))
      }
    });
  }

  if (!uiState.bankMfaForm) {
    return null;
  }

  return (
    <Page
      title='Your bank statements'
      lead='Your bank is requesting more information to continue.'>
      <Row size='x-large'>
        <Button
          id='about_this_process'
          variant='tertiary'
          size='small'
          text='More about this process'
          faIcon={faInfoCircle}
          iconPlacement='before'
          onClick={() => setShowModal(true)}
        />
      </Row>
      <Row size='small' variant='secure'>
        <Form
          key={state.formKey}
          fields={state.fields}
          bankName={uiState.bankMfaForm.bankName || ''}
          error={uiState.bankMfaForm.error}
          onSubmit={handleSubmit}
        />
        <Button
          id='choose_bank'
          variant='secondary'
          text='Choose a different bank'
          onClick={() => navigate('BankSelect', false)}
          data-test='bank-mfa-change-bank'
        />
        <SaveProgressButton />
      </Row>
      <AboutThisProcessModal
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </Page>
  );
}

export default BankMfa; 
