import packageJson from '../../package.json';

export interface AppSettingsProps {
  apiUrl: string
  documentApiUrl: string
  trackerApiUrl: string
  adobeLaunchFilename: string
  copyrightYear: string
  releaseVersion: string
}

let appSettings: AppSettingsProps | undefined = undefined;

const { version: releaseVersion } = packageJson;

if (process.env.NODE_ENV === 'development') {
  appSettings = {
    apiUrl: 'http://localhost:51200/api',
    documentApiUrl: 'http://localhost:7071/api',
    trackerApiUrl: 'http://localhost:51200/api/tracker',
    adobeLaunchFilename: 'launch-EN8ef54241ed9a48d4a235e9c1c2b25315-development.min.js',
    copyrightYear: `${new Date().getFullYear()}`,
    releaseVersion: `${releaseVersion}-local`,
  };
} else {
  appSettings = {
    apiUrl: '#{LoanApplicationApiUrl}',
    documentApiUrl: '#{DocumentApiUrl}',
    trackerApiUrl: '#{TrackerApiUrl}',
    adobeLaunchFilename: '#{AdobeLaunchFilename}',
    copyrightYear: '#{CopyrightYear}',
    releaseVersion,
  };
}

(window as any).appSettings = { ...appSettings, adobeLaunchFilename: '' };
export default appSettings as AppSettingsProps;