import { SonicFeatures } from './shared';

export type ChannelIndicatorType = 'Myloans' | 'CashiesHomePage'
export type LoanFollowUpType = 'None' | 'BankStatements' | 'CentrelinkStatement';
export const LoanFollowUpTypes: LoanFollowUpType[] = ['None', 'BankStatements', 'CentrelinkStatement'];
export type ApplicationType = 'None' | 'Loan' | 'LineOfCredit' | 'CreditLimitIncrease';

export interface Referrer {
  name: string
}

export interface LoanBanding {
  min: number
  max: number
}

export interface BaseLoanReason {
  name: string
  subReasons: LoanSubReason[]
  tags?: string[]
}

export interface LoanReason extends BaseLoanReason {
  amount: number
}

export interface LoanSubReason {
  banding?: { min: number, max: number }
  name: string
  hardstop?: boolean
  warning?: string
  tags?: string[]
  overBandingChoices?: string[]
}

export interface LoanConfiguration {
  amounts: number[]
  terms: number[]
  figures: any
}

export interface LoanProduct {
  amount: string
  term: string
  fees: string
  borrowed: string
  repayment: string
  total: string
}

export interface ApplicationSummary {
  firstName: string
  surname: string
  email: string
  mobile: string
  amount: string
  withdrawAmount?: string
  fullReason: string
}

export interface SubmitPersonalDetailsAddress {
  unitOrLevel?: string
  streetNumber: string
  street: string
  suburb: string
  state: string
  postcode: string
}

export interface AddressSearchRequest {
  searchTerm: string
}

export interface PrefilledPersonalDetails {
  title: string
  firstName: string
  middleName?: string
  surname: string
  dateOfBirth: Date
  mobilePhone: string
  email: string

  unitOrLevel?: string
  streetNumber: string
  street: string
  suburb: string
  region: string
  postCode: string

  creditLimit: number
  applicationType: ApplicationType
}

export type PersonalDetailsTitle = 'Mr' | 'Mrs' | 'Miss' | 'Ms'
export const PersonalDetailsTitles = ['Mr', 'Mrs', 'Miss', 'Ms']

export interface PersonalDetailsModel extends SubmitPersonalDetailsAddress {
  title: PersonalDetailsTitle
  firstName: string
  middleName?: string
  surname: string
  dateOfBirth: string
  mobile: string
  email: string
  consent: boolean
  marketing: boolean
}

export interface LoanReasonSelection {
  amount?: number
  reason: string
  subReason: string
  explanation?: string
  note?: string
  forbidden?: boolean
  banding?: LoanBanding
  overBandingChoices?: string[]
}

export interface LoanConfigurations {
  reasons: BaseLoanReason[]
}

export interface LoanStateOptions {
  enabledFeatures: SonicFeatures[]
  applicationType: ApplicationType
}
