import React from 'react'
import CSS from 'csstype';
import { ErrorWithType } from '../../types/shared';
import { Alert } from '../shared';

export interface TopBankModel {
  type: TopBankType
  names: string[]
}

export interface TopBankHelpModel {
  bank: TopBankType
  helpMessage: string[]
}

export type TopBankType = 'CommBank' | 'NAB' | 'ANZ' | 'Westpac' | 'Bendigo Bank' | 'Heritage Bank' | 'Suncorp' | 'St George' | 'BankWest' | 'ING' | 'Other'

const topBanks: TopBankModel[] = [
  { type: 'CommBank', names: ['CommBank', 'Commonwealth Bank', 'Commonwealth'] },
  { type: 'NAB', names: ['NAB', 'National Australia Bank'] },
  { type: 'ANZ', names: ['ANZ'] },
  { type: 'Westpac', names: ['Westpac'] },
  { type: 'Bendigo Bank', names: ['Bendigo', 'Bendigo Bank'] },
  { type: 'Heritage Bank', names: ['Heritage', 'Heritage Bank'] },
  { type: 'Suncorp', names: ['Suncorp', 'Suncorp Bank'] },
  { type: 'St George', names: ['St George', 'St George Bank', 'Saint George', 'Saint George Bank'] },
  { type: 'BankWest', names: ['BankWest'] },
  { type: 'ING', names: ['ING', 'ING Direct'] }
]

export const getTopBankByName = (name: string) => {
  if (!name) return undefined;
  return topBanks.find(e => e.names.map(a => a.toLowerCase()).includes(name.toLowerCase()));
}

export const isTopBank = (name: string, bank: TopBankType) => {
  const topBank = getTopBankByName(name);
  return topBank && topBank.type === bank;
}

export const getHelpTextForBankName = (name: string) => {
  const foundBankToCheck = getTopBankByName(name);
  const type: TopBankType = foundBankToCheck ? foundBankToCheck.type : 'Other';
  const messageObject = topBanksRecoverCredentialsHelp.find(e => e.bank === type);
  return messageObject ? messageObject.helpMessage : undefined;
}

const topBanksRecoverCredentialsHelp: TopBankHelpModel[] = [
  {
    bank: 'CommBank', helpMessage: [
      "Navigate to your banks log on page.",
      "Choose I've forgotten on my log on details.",
      "Follow the prompts to reset your log on details.",
      "Alternatively, contact your bank directly for assistance."
    ]
  },
  {
    bank: 'NAB', helpMessage: [
      "Navigate to your banks login page.",
      "Click forgotten your password.",
      "Follow the prompts to reset your login details.",
      "Alternatively, contact your bank directly for assistance."
    ]
  },
  {
    bank: 'ANZ', helpMessage: [
      "Navigate to your banks login page.",
      "Click forgot login details.",
      "You will need your card number, PIN and mobile phone.",
      "Follow the prompts to recover your login details.",
      "Alternatively, contact your bank directly for assistance."
    ]
  },
  {
    bank: 'Westpac', helpMessage: [
      "If you have registered a security device with your bank, you can use this to reset your password instantly.",
      "Otherwise, navigate to your banks sign in page.",
      "Click forgot customer ID or password.",
      "Follow the prompts to recover your details.",
      "Alternatively, contact your bank directly for assistance."
    ]
  },
  {
    bank: 'Bendigo Bank', helpMessage: [
      "Navigate to your e-banking logon screen.",
      "Select I forgot my password.",
      "Follow the prompts to recover your logon details.",
      "Alternatively, you can contact your bank directly via phone to recover your details."
    ]
  },
  {
    bank: 'Heritage Bank', helpMessage: [
      "If you have forgotten your password try contacting your bank for a temporary password.",
      "This temporary password can be used to reset your login details."
    ]
  },
  {
    bank: 'Suncorp', helpMessage: [
      "If you have registered your phone with your bank, you can use reset your password on their website.",
      "Otherwise, navigate to your banks login page.",
      "Click forgotten password.",
      "Follow the prompts to recover your login details.",
      "Alternatively, contact your bank directly for assistance."
    ]
  },
  {
    bank: 'St George', helpMessage: [
      "Navigate to your banks logon page.",
      "Click forgotten your security number or forgotten your password.",
      "Follow the prompts to reset your login details.",
      "Alternatively, contact your bank directly for assistance."
    ]
  },
  {
    bank: 'BankWest', helpMessage: [
      "If you have registed a phone number with your bank you can reset your secure code online.",
      "Navigate to your banks login page.",
      "Select reset your secure code.",
      "Follow the prompts to reset your login details.",
      "Alternatively, contact your bank directly for assistance."
    ]
  },
  {
    bank: 'ING', helpMessage: [
      "Navigate to your banks login page.",
      "Click on forgotten your access code.",
      "Follow the steps to recover your login details.",
      "Alternatively, contact your bank directly for assistance."
    ]
  },
  {
    bank: 'Other', helpMessage: [
      "Try contacting your bank directly for assistance.",
      "Alternatively, try resetting your password via your banks login page."
    ]
  }
]

export function renderHelpTextComponent(helpTextByLine: string[] | undefined) {
  return (
    <ul>
      {helpTextByLine && helpTextByLine.map((line, key) => <li key={key}>{line}</li>)}
    </ul>
  )
}

//handles changing the alert depending on the case of proviso error 30004
export function getErrorAlertByErrorMessage(error: ErrorWithType) {
  return (
    <div>
      {error.message === errorMessageToChangeAlert
        ? <Alert message={alternateErrorMessage} variant={error.type as any} className={'text-center'} data-test='bank-login-error' errorIconExclamation={true} />
        : <Alert message={error.message} variant={error.type as any} className={'text-center'} data-test='bank-login-error' />}
    </div>
  );
}

export const NABHelpText = "NAB recently updated their terms and conditions. In order to continue processing your application, please ensure you have recently logged into your NAB Internet Banking at nab.com.au and accepted them.";

//string constants for the case above
const errorMessageToChangeAlert = "Your financial institution requires you to perform some manual action before we can export data from your account. Please log in to your financial institution's online banking (desktop site) and complete the action requested after you log in.";
const alternateErrorMessage = "<strong>There is a problem</strong> <br />Your financial institution requires you to log in to their online banking website and perform an action there first before you can continue. This is most likely to be a popup message. Please do this first then continue here.";

export const getBankProviderButtonColour = (bankName: string) => {
  let style: CSS.Properties | undefined;
  const topBank = getTopBankByName(bankName);
  if (!topBank) return undefined;
  switch (topBank.type) {
    //Commonwealth Bank
    case 'CommBank':
      style = { color: '#000', background: '#ffc20e' };
      break;
    //Suncorp Bank
    case 'Suncorp':
      style = { color: '#fff', background: '#004346' };
      break;
    //Westpac
    case 'Westpac':
      style = { color: '#fff', background: '#ed1a3b' };
      break;
    //ANZ
    case 'ANZ':
      style = { color: '#fff', background: '#064165' };
      break;
    //Bendigo Bank
    case 'Bendigo Bank':
      style = { color: '#fff', background: '#b10034' };
      break;
    //NAB
    case 'NAB':
      style = { color: '#fff', background: '#c02628' };
      break;
  }
  return style;
}