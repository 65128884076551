import React from 'react';
import Rheostat, { PublicState } from 'rheostat';

interface SliderProps {
  id?: string
  value: number
  min?: number
  max?: number
  steps: any[]
  labelPrefix?: string
  labelSuffix?: string
  hideLabels?: boolean
  onChange?(publicState: PublicState): any;
  onValuesUpdated?(publicState: PublicState): any;
  disabled?: boolean
}

const Slider: React.FC<SliderProps> = ({ id, value, steps, labelPrefix, labelSuffix, hideLabels, onChange, onValuesUpdated, min, max, disabled }) => {

  const hasSnap = steps && steps.length ? true : false;

  const compiledMin = min || Math.min(...steps);
  const compiledMax = max || Math.max(...steps);

  return (
    <div id={id} className='slider-container'>
      <Rheostat
        disabled={disabled}
        onValuesUpdated={onValuesUpdated}
        onChange={onChange}
        values={[value]}
        min={compiledMin}
        max={compiledMax}
        snap={hasSnap}
        snapPoints={steps}

      />
      {!hideLabels && (
        <div className='slider-details'>
          <div className='slider-min'>
            {labelPrefix && <span className='mr-5'>{labelPrefix}</span>}
            <span>{compiledMin}</span>
            {labelSuffix && <span className='ml-5'>{labelSuffix}</span>}
          </div>
          <div className='slider-max'>
            {labelPrefix && <span className='mr-5'>{labelPrefix}</span>}
            <span>{compiledMax}</span>
            {labelSuffix && <span className='ml-5'>{labelSuffix}</span>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Slider;