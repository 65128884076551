import React from 'react';

export interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  isValid?: boolean
  errorMessage?: string
}

class Checkbox extends React.Component<CheckboxProps> {

  focus() {
    const input = (this.refs.input as any);
    if (input && input.focus) input.focus();
  }

  render() {
    const { id, name, type, errorMessage, label, isValid, children, ...rest } = this.props;

    const compiledIsValid = isValid === undefined ? true : isValid;

    return (
      <div data-test={`form-control-${name}`}>
        <input
          id={id}
          name={name}
          ref='input'
          className='form__input form__input-checkbox'
          type='checkbox'
          {...rest}
        />
        <label
          id={`${id}_label`}
          htmlFor={id}
          className='form__row form__label form__label-checkbox'>
          <i className='icon icon-form-checkbox'></i>
          <div dangerouslySetInnerHTML={{ __html: label || '' }}></div>
          {children}
          {!compiledIsValid && <span className='form__error-message'>{errorMessage}</span>}
        </label>
      </div>
    );
  }
}

export default Checkbox;
