import React from 'react';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'tertiary-compact' | 'pill' | 'pill-remove';
export interface ButtonProps {
  id?: string
  text: string
  disabled?: boolean
  className?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  variant?: ButtonVariant,
  size?: 'full' | 'small'
  faIcon?: IconDefinition
  iconPlacement?: 'before' | 'after'
}

const Button: React.FC<ButtonProps> = ({ className, text, disabled, onClick, variant, size, faIcon, iconPlacement, ...rest }) => {
  const compiledVariant = !variant ? 'secondary' : variant;
  const compiledSize = !size ? 'full' : variant;

  const compiledClassName = classNames({
    [`button button-${compiledVariant}`]: true,
    [`button--${compiledSize}`]: true,
    [`button-tertiary button__pill-selection`]: compiledVariant === 'pill',
    [`button-tertiary button__pill-selection button__pill-remove`]: compiledVariant === 'pill-remove',
    [className!]: className !== undefined
  });

  return (
    <button
      {...rest}
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={compiledClassName}>
      {faIcon && iconPlacement === 'before' && <FontAwesomeIcon className='mr-10' icon={faIcon} />}
      {text}
      {faIcon && iconPlacement === 'after' && <FontAwesomeIcon className='ml-10' icon={faIcon} />}
    </button>
  );
}

export default Button;